.space-profile-package-section {
  text-align: left;
  padding: 40px 0 20px 0;

  @include desktop {
    padding-top: 23px;
    padding-bottom: 10px;
  }

  .section-title {
    color: $black;
    @include font-size(18);
    font-weight: $black-bold;
    margin-bottom: 10px;
    width: 130px;

    @include desktop {
      margin-bottom: 0px;
      position: relative;
      top: 25px;
      
    }
  }
  .ant-tabs {

      @include desktop {
        top: -10px;
      }

      @include tablet-landscape {
        top: -10px;
      }

    .ant-tabs-bar {
      border-bottom: unset;
      margin-bottom: 20px;

      @include desktop {
        margin-bottom: 30px;
        position: relative;
        left: 130px;
        bottom: 0px;
      }

      .ant-tabs-nav-container {
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-scroll {
            .ant-tabs-nav {
              .ant-tabs-tab {
                border-radius: 100px;
                border: 1px solid rgba(112, 112, 112, 0.2);
                background: $white;
                color: $black;
                font-size: 15px;
                margin-right: 5px;
                min-width: 84px;
                text-align: center;

                &.ant-tabs-tab-active {
                  background: $ms-blue;
                  border: none;
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    .ant-tabs-content {
      .ant-tabs-tabpane {
        .package-grid {
          padding: 0;
          justify-content: space-between;

          .package-card-wrapper {
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            height: auto;
            margin-bottom: 20px;
            width: 100%;

            @include desktop {
              width: 49%;
              height: 180px;
            }

            @include tablet-landscape {
              width: 49%;
              height: 200px;
            }

            .add-package-modal {
              .md-paper {
                border-radius: 24px;
                height: 204px !important;
                width: 305px !important;

                .md-dialog-content--padded {
                  max-height: fit-content !important;
                  padding: 0px;

                  .btn-wrap {
                    border-top: 0.5px solid #e2e2e2;
                    display: flex;
                    margin-top: 26px;
                    padding: 10px 30px;

                    .btn {
                      border-radius: 23px;
                      @include font-size(13);
                      font-weight: $black-bold;
                      height: 40px;
                      text-transform: uppercase;
                      width: 120px;

                      &.btn-add {
                        background-color: $yellow;
                      }
  
                      &.btn-cancel {
                        border: 1px solid #e2e2e2;
                        margin-right: 5px;
                      }
                    }
                  }

                  .guest-input {
                    display: flex;
                    justify-content: space-between;
                    padding: 0px 30px 0px 40px;

                    .md-floating-label {
                      align-self: center;
                      color: $black;
                      @include font-size(15);
                      position: inherit;
                    }

                    .md-text-field {
                      border: 1px solid #e9e9e9;
                      border-radius: 25px;
                      height: 40px;
                      margin-top: 23px;
                      padding: 10px 0px 10px 20px;
                      width: 92px;
                    }

                    .md-divider  {
                      display: none;
                    }
                  }

                  .package-name {
                    color: $black;
                    @include font-size(18);
                    font-weight: $black-bold;
                    padding-top: 30px;
                    text-align: center;
                  }
                }
              }
            }

            .card-header {
              background-color: $gray;
              padding: 20px;
              border-top-right-radius: 10px;
              border-top-left-radius: 10px;

              .card-header-inner-wrap {

                display: flex;
                justify-content: space-between;
                
                .btn-add-package {
                  background-color: $ms-blue;
                  border-radius: 100px;
                  color: $white;
                  @include font-size(11);
                  font-weight: $bold;
                  padding: 7px 20px;
                  text-transform: uppercase;

                  &.md-text--disabled {
                    background-color: #BFBFBF;
                  }
                    
                  .md-ink-container  {
                    .md-ink--active {
                      background-color: transparent;
                    }            
                  }
                }

                .header-title {
                  align-self: center;
                  font-size: 15px;
                  font-weight: $extra-bold;
                  color: $black;
                  word-break: break-word;
                }

                .sub-title {
                  font-size: 11px;
                  word-break: break-word;
                }
              }
            }

            .content-area {
              padding: 15px 20px;
              color: $black;

              .content-block {
                .description-title {
                  font-size: 13px;
                  font-weight: $bold;
                  word-break: break-word;
                }

                .description {
                  color: #7b7b7b;
                  font-size: 11px;
                  word-break: break-word;
                }

                .price-tag {
                  color: #7b7b7b;
                  font-size: 11px;
                }

                .price {
                  font-size: 13px;
                  font-weight: $bold;
                  text-transform: uppercase;
                }

                .title-text {
                  font-weight: $bold;
                  font-size: 13px;
                }

                &.description-wrapper {
                  margin-bottom: 10px;
                }
              }
            }
          }

          .package-card-wrapper:nth-child(odd) {
            @include mobile {
              margin-right: 0;
            }

            @include tablet-portrait {
              margin-right: 0;
            }

            margin-right: 10px;
          }
        }
      }
    }

    &.disabled {
      .ant-tabs-nav-container {
        .ant-tabs-nav {
          display: none;
        }
      }
    }
  }
  .view-more-block {
    cursor: pointer;
    position: relative;
    right: -70%;
    text-align: right;
    top: -34px;
    width: 100px;
    z-index: 1;

    @include desktop {
      position: absolute;
      right: 27px;
      top: unset;
    }

    @include tablet-portrait {
      right: -85%;
    }

    @include mobile {
      height: 0px;
    }

    .view-more-wrapper {
      display: flex;
      align-items: baseline;
      font-weight: $bold;
      justify-content: flex-end;
      color: $ms-blue;

      .view-more {
        margin-right: 5px;
      }

      .view-less {
        margin-right: 5px;
      }

      img.view-more-img {
        transform: rotate(90deg);
        width: 9px;
      }

      .view-less-img {
        transform: rotate(270deg);
        width: 9px;
      }
    }
  }
}

.packages-outer-wrapper {
  max-height: 100vh;
  height: 100vh;
  overflow-y: auto;
  border: 1px solid #d9d9d9;
  border-top: none;
  position: relative;

  .section-divider {
    margin: 0;
  }

  .top-section-wrapper {
    padding: 25px;
    padding-bottom: 10px;
    text-align: left;

    .icons-wrapper {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;

      .icon-text-wrapper {
        align-items: end;
        display: flex;

        .icon-img {
          height: 15px;
          transform: rotate(180deg);
        }
        .icon-text {
          @include font-size(13);
          text-transform: uppercase;
        }
      }
      .close-btn-wrapper {
        .close-btn {
          border: unset;
          background: unset;

          &:focus {
            outline: unset;
          }
        }
      }
    }
  }

  .packages-button-wrapper {
    padding: 25px;
    padding-bottom: 10px;
    text-align: left;

    .error {
      &.error-text {
        top: unset !important;
        margin-top: 10px;
      }
    }
    @include tablet-portrait {
      text-align: center;
    }

    .title {
      @include font-size(25);
      color: $black;
      font-weight: $black-bold;
      margin-bottom: 10px;

      @include tablet-portrait {
        justify-content: center;
      }
    }

    .button-wrapper {
      display: flex;

      @include tablet-portrait {
        justify-content: center;
      }

      .packages-button {
        border-radius: 23px;
        border: 1px solid #d8d8d9;
        height: 40px;
        max-width: 84px;
        margin-top: 10px;
        width: 100%;

        &:hover {
          background: $ms-blue;
          border: 1px solid $ms-blue;
          color: $white;
        }

        &.active {
          background: $ms-blue;
          border: 1px solid $ms-blue;
          color: $white;
        }

        &.packages-button:first-of-type {
          margin-right: 5px;
        }
      }
    }
  }

  .packages-inner-wrapper {
    padding: 25px;
    padding-bottom: 150px;

    @include mobile {
      border: none;
    }

    .md-text-field {
      @include font-size(13);
    }

    .section-divider {
      border-bottom: 1px solid #d9d9d9;
      display: block;
      margin: 0 -25px;
      padding-top: 0;
    }

    .value-seat-day-block {
      padding-bottom: 15px;
      text-align: left;
      position: relative;

      .back-icon {
        cursor: pointer;

        .img {
          width: 14px;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
      }

      .total-title {
        @include font-size(13);
        display: inline-block;
        margin-right: 5px;
      }

      .value {
        @include font-size(20);
      }

      .close-btn {
        position: absolute;
        right: 0;

        @include desktop {
          display: none;
        }
      }
    }

    .summery-block-wrapper {
      display: flex;
      flex-direction: column;
      text-align: left;

      .title {
        font-weight: 900;
      }
      .summery-block-inner-wrapper {
        display: flex;

        img {
          margin-right: 10px;
          width: 13px;
        }
      }
    }

    .md-text-field--margin {
      margin-top: 15px;
    }

    .md-text-field--floating-margin {
      margin-top: 0;
    }

    .space-summary-wrapper {
      padding-top: 27px;

      .title {
        @include font-size(25);
        font-weight: $black-bold;
        text-align: left;
      }

      .detail-block {
        text-align: left;
        display: flex;
        align-items: baseline;
        margin-bottom: 10px;

        .img {
          width: 14px;
          margin-right: 10px;
        }

        &.detail-block:last-of-type {
          margin-bottom: 20px;
        }
      }

      .payment-method-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 15px 0;

        .payment-method {
          .img {
            margin-right: 10px;
          }
        }
        .blue-text-link {
          color: $bright-blue;
          cursor: pointer;
          font-weight: $bold;
          text-decoration: underline;
        }
      }
    }
  }

  .radio-btn-wrapper {
    padding-top: 20px;

    .ant-radio-group {
      width: 100%;

      .radio-btn-inner-wrapper {
        display: flex;
        justify-content: center;

        .ant-radio-button-wrapper {
          padding: 0;
          height: 45px;
          padding-top: 8px;
          border-color: #e2e2e2;
          box-shadow: none !important;
          color: $black;
          flex-grow: 1;

          @include mobile {
            padding: 0 30px;
            padding-top: 8px;
          }

          @include tablet-landscape {
            padding: 8px 15px;
          }

          &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            color: $white;
            background-color: $bright-blue;
          }

          &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
            outline: none;
          }
        }
      }

      .ant-radio-button-wrapper:first-child {
        border-radius: 20px 0 0 20px;
      }

      .ant-radio-button-wrapper:last-child {
        border-radius: 0 20px 20px 0;
      }

      .ant-radio-button-wrapper:only-child {
        border-radius: 20px;
      }
    }
  }

  .common-date-wrapper {
    width: 100%;

    .md-calendar-date--date {
      background: $white !important;
      padding: 0px !important;
    }

    .current-date {
      .md-calendar-date--date {
        border: 1px solid $bright-blue !important;
        background: $white !important;
        color: $bright-blue !important;
        padding: 6px !important;
      }

      .md-picker-text--active {
        border: 1px solid $bright-blue !important;
        color: $white !important;
        background-color: $bright-blue !important;
      }
    }

    .normal-date-cls {
      background: transparent !important;
    }

    .md-floating-label {
      left: 25px;
      top: -3px;
      @include font-size(13);
      color: $black;
      font-weight: 700;
      background-color: $white;

      @include mobile {
        top: -5px;
      }

      @include tablet-portrait {
        top: -5px;
      }
    }

    .md-icon {
      color: $black !important;
      font-size: 16px;
    }

    .md-text-field-icon--positioned {
      @include desktop {
        bottom: 7px !important;
      }

      @include tablet-portrait {
        bottom: 3px !important;
      }
    }

    .md-picker--inline {
      position: relative;
    }

    .md-text-field-container {
      width: 100%;

      .md-text-field-icon-container {
        .img {
          position: absolute;
          bottom: 4px;
          left: 20px;
          width: 14px;
          height: auto;

          @include mobile {
            bottom: 3px;
          }

          @include tablet-portrait {
            bottom: 3px;
          }

          @include tablet-landscape {
            bottom: 0px;
            left: 15px;
          }
        }

        .md-text--theme-primary {
          color: $black !important;
        }

        .md-text-field-divider-container {
          margin-left: 0;
          width: 100%;
        }
      }
    }

    &.common-inline-wrapper {
      width: 50%;
    }
  }
  .packages-qty-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @include tablet-portrait {
      justify-content: center;
    }

    .packages-menu-wrapper {
      margin-right: 5px;
      position: relative;

      .md-floating-label {
        color: $black;
        z-index: 5;
        background: $white;
        top: -19px;

        @include tablet-portrait {
          top: -25px;
        }

        @include mobile {
          top: -25px;
        }

        &.md-floating-label--inactive-sized {
          @include font-size(13);
        }

        &.md-floating-label--floating {
          @include font-size(10);

          &.md-text--theme-primary {
            @include font-size(10);
            left: 10px;

            @include tablet-landscape {
              @include font-size(7);
            }
          }

          &.md-text--secondary {
            left: 10px;
          }

          @include tablet-landscape {
            @include font-size(7);
            top: -20px;
          }
        }

        &.md-text--secondary {
          left: 20px;
        }

        @include tablet-landscape {
          @include font-size(10);
          top: -25px;
        }
      }

      .md-text-field {
        padding-left: 20px;
        min-width: 170px;

        @include tablet-landscape {
          min-width: unset;
          width: 140px;
        }

        @include tablet-portrait {
          min-width: unset;
          width: auto;
        }
      }

      .md-list--menu {
        border-radius: 24px;
        box-shadow: unset;
        border: 1px solid #e2e2e2;
        margin-top: 5px;
        width: 25vw;

        @include tablet-portrait {
          width: 45vw;
        }

        @include mobile {
          width: 80vw;
        }

        .package-items-wrapper {
          text-align: left;

          .package-item-wrapper {
            @include font-size(13);
            color: #7f7f7f;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 24px;
            padding-right: 24px;

            &:hover {
              cursor: pointer;
              border-radius: 12px;
              background: $light-gray3;

              .title-tick-wrapper {
                .tick-icon {
                  visibility: visible;
                }
              }
            }

            &:focus {
              border-radius: 12px;
              background: $light-gray3;
            }

            .title-tick-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .tick-icon {
                visibility: hidden;

                .img {
                  width: 14px;
                  height: 14px;
                  position: relative;
                  right: unset;
                  bottom: unset;
                }
              }
              .title {
                @include font-size(15);
                color: $black;
                font-weight: $bold;
                word-break: break-word;
              }
            }

            .sub-title {
              font-weight: $bold;
              word-break: break-word;
            }

            .description {
              word-break: break-word;
              max-width: 264px;
              width: 100%;
            }
          }
        }
      }
      // .ant-menu-horizontal {
      //     border: 1px solid #e2e2e2;
      //     border-radius: 25px;
      //     height: 45px;
      // }
      .down-arrow-img {
        bottom: 16px;
        cursor: pointer;
        position: absolute;
        right: 20px;
        transform: rotate(90deg);
        width: 8px;
      }
    }
    .qty-wrapper {
      .guest-input {
        .md-text-field-icon--positioned {
          position: absolute;
          bottom: 7px;
          left: 20px;
          width: 14px;
          height: auto;

          @include mobile {
            bottom: 4px;
          }

          @include tablet-portrait {
            bottom: 4px;
          }

          @include tablet-landscape {
            bottom: 0px;
            width: 14px;
            left: 15px;
          }
        }

        .md-text-field-divider-container--grow {
          margin-left: 0 !important;
        }

        .md-floating-label {
          color: $black;
          z-index: 5;
          background: $white;
          top: -19px;

          @include tablet-portrait {
            top: -25px;
          }

          @include mobile {
            top: -25px;
          }

          &.md-floating-label--inactive-sized {
            @include font-size(13);
          }

          &.md-floating-label--floating {
            @include font-size(10);
            left: 10px;

            &.md-text--theme-primary {
              @include font-size(10);
              left: 10px;

              @include tablet-landscape {
                @include font-size(7);
              }
            }

            &.md-text--secondary {
              left: 10px;
            }

            @include tablet-landscape {
              @include font-size(7);
              top: -20px;
            }
          }

          &.md-text--secondary {
            left: 20px;
          }

          @include tablet-landscape {
            @include font-size(10);
            top: -25px;
          }
        }

        .md-text-field {
          padding-left: 20px;

          @include tablet-landscape {
            width: 60px;
          }
        }
      }
    }

    .plus-icon-wrapper {
      cursor: pointer;
      margin-left: 5px;
    }
  }

  .seating-wrapper {
    width: 100%;
    position: relative;
    border-radius: 25px;
    height: 45px;
    padding-left: 0;
    margin-top: 15px;
    border: 1px solid #e2e2e2;
    z-index: 9;

    .md-ink-container {
      .md-ink {
        background: none;
      }
    }

    .img {
      position: absolute;
      top: 12px;
      left: 20px;
      z-index: 3;
    }

    .md-layover {
      width: 100%;
      z-index: 4;

      .md-btn {
        width: 100%;
        text-align: left;
        padding-left: 40px;
        font-size: 13px;
        padding-bottom: 0;
        text-transform: capitalize;
        color: rgba(0, 0, 0, 0.4);
        cursor: pointer;

        &.md-btn--color-primary-active {
          background-color: transparent;
        }
      }

      .md-list {
        width: 100%;
        text-align: left;
        top: 45px;
        padding: 0;

        .md-list-item {
          margin-bottom: 10px;

          .img {
            right: 15px;
            left: unset;
            width: 30px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .seating-list {
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';

      &:focus {
        outline: none;
      }

      .md-list {
        text-align: left;
        top: unset;
        width: 100%;
      }

      .md-icon {
        display: none;
      }

      .md-icon-separator {
        position: relative;

        .img {
          position: absolute;
          left: 0;
          left: 20px;
        }
      }
    }

    .seating-menu-wrapper {
      z-index: 2;
      display: flex;
      justify-content: space-between;

      &.md-list-item {
        margin-bottom: 10px;

        .img {
          right: 15px;
          left: 0 !important;
          width: 30px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .img {
        position: relative;
        top: unset;
        left: unset;
        width: 30px;
      }

      .md-fake-btn {
        width: 100%;
        padding: 0 20px;
      }

      .title {
        position: absolute;
        width: 70%;
        text-align: left;
        display: inline-block;
        left: 20px;
        font-size: 15px;
        word-break: break-word;
      }
    }

    .select-item-wrapper {
      z-index: 1;
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      border-radius: 25px;
      height: 40px;
      background: white;
      padding-left: 50px;
      position: absolute;
      top: 1px;
      width: 100%;

      .img {
        position: relative;
        top: unset;
        left: unset;
        width: 30px;
      }

      .title {
        font-size: 15px;

        .light-gray {
          font-size: 15px;
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }
  }

  .date-block-wrapper {
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    flex-direction: column;

    .md-picker .md-calendar-date--btn {
      &::after {
        width: 0 !important;
        height: 0 !important;
      }
    }

    .md-picker {
      @include react-md-pickers-make-portrait;
      box-shadow: unset;

      .md-picker-header {
        display: none;
      }

      .md-picker-content-container {
        border-radius: 5px;
        -webkit-box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.26);
        -moz-box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.26);
        box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.26);

        .md-picker-content {
          width: 327px;

          @include tablet-landscape {
            width: 280px;
          }

          .md-calendar-header {
            .md-calendar-controls {
              border-bottom: 1px solid #d9d9d9;
              margin-bottom: 15px;

              .md-title {
                font-weight: 800;
              }
            }
            .md-calendar-dow {
              width: 36px;
            }
          }
          .md-calendar-month {
            .md-calendar-date {
              width: 36px;
              height: 36px;
              margin-bottom: 5px;

              .md-calendar-date--btn {
                height: 36px;
                padding: 0;

                .md-calendar-date--date {
                  color: $bright-blue;

                  .disable-date-cls {
                    color: rgba(0, 0, 0, 0.3);
                    background: none !important;
                    cursor: not-allowed;
                  }

                  .normal-date-cls {
                    height: 36px;
                    width: 36px;
                    display: block;
                    padding: 6px;
                    border-radius: 36px;

                    &.current-date {
                      border: 1px solid $bright-blue;
                      background: $white !important;
                      color: $bright-blue;
                    }

                    &.current-date:hover {
                      border: 1px solid $bright-blue;
                      background: $bright-blue !important;
                      color: $white;
                    }
                  }

                  .calendar-select-days {
                    background: #edf5fc;
                  }

                  &.md-picker-text--active {
                    span {
                      background: $bright-blue !important;
                    }

                    .disable-date-cls {
                      background: none !important;
                      cursor: not-allowed;
                    }

                    .calendar-select-days {
                      color: $white;
                    }
                  }
                }

                &.md-calendar-date--btn-active {
                  .md-calendar-date--date {
                    color: $white;

                    span {
                      background: $bright-blue;
                    }
                    .calendar-select-days {
                      background: #edf5fc;
                    }
                  }

                  &:after {
                    background: transparent;
                  }
                }
                &.current-date {
                  .md-calendar-date--date {
                    background: $white;
                    border: 1px solid $bright-blue;
                    padding: 5px;
                  }

                  .md-picker-text--active {
                    background: $bright-blue;
                  }
                }

                &.current-date:hover {
                  .md-calendar-date--date {
                    background: $bright-blue;
                    color: $white;
                    border: 1px solid $bright-blue;
                  }
                }

                &.day-with-events {
                  background: #edf5fc;
                }

                &.start-range-date {
                  border-top-left-radius: 36px;
                  border-bottom-left-radius: 36px;
                  padding-left: 0;

                  .calendar-select-days {
                    background: $bright-blue;
                    color: $white;
                  }

                  .md-calendar-date--date {
                    .current-date {
                      background: $bright-blue !important;
                      color: $white;
                    }
                  }
                }

                &.end-range-date {
                  border-top-right-radius: 36px;
                  border-bottom-right-radius: 36px;
                  padding-right: 0;

                  .calendar-select-days {
                    background: $bright-blue;
                    color: $white;
                  }
                }
              }
            }
          }
        }

        .md-dialog-footer {
          padding: 8px 0;
          border-top: 1px solid #d9d9d9;
          display: flex;
          justify-content: space-between;
          margin: 0 8px;

          .md-text--theme-primary {
            color: $bright-blue;
          }

          .md-btn {
            text-transform: uppercase;
            font-weight: 800;

            .clear-all-btn {
              text-transform: capitalize;
              font-weight: 500;
            }

            &.md-btn--color-primary-active {
              background-color: $white;
            }
          }
        }
        &::before {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 0;
          left: 20%;
          box-sizing: border-box;
          border: 10px solid black;
          border-color: transparent transparent $white $white;
          transform-origin: 0 0;
          transform: rotate(135deg);
          box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.26);
        }
      }
    }

    .date-block {
      width: 100%;

      @include tablet-landscape {
        width: 100%;
      }

      .md-picker-container {
        .md-text-field-container {
          visibility: hidden;
          height: 0;
        }

        .md-picker--inline-icon {
          left: -5px;

          @include desktop {
            left: 0;
          }

          @include tablet-landscape {
            left: -24px;
          }

          &.md-picker--inline {
            position: relative;
          }
        }
      }

      .md-text-field-container {
        .md-text-field-icon--positioned {
          position: absolute;
          bottom: 7px;
          left: 20px;
          width: 14px;
          height: auto;

          @include mobile {
            bottom: 4px;
          }

          @include tablet-portrait {
            bottom: 4px;
          }

          @include tablet-landscape {
            bottom: 0px;
            width: 14px;
            left: 15px;
          }
        }

        .md-text-field-divider-container--grow {
          margin-left: 0 !important;
        }

        .md-text-field {
          @include font-size(13);
        }
      }

      .booking-range-picker {
        .md-calendar-date--date {
          background-color: $white;
          border-radius: none;
          padding: 0;
        }
      }
    }
    .plus-btn {
      width: 45px;
      height: 45px;
      border-radius: 45px;
      position: absolute;
      right: 0;
      top: 15px;
      background-color: #edf5fc;
      font-weight: 900;
      color: $bright-blue;

      @include tablet-landscape {
        width: 35px;
        height: 35px;
        border-radius: 35px;
      }
    }

    .minus-btn-wrapper {
      position: relative;
      width: 100%;

      &:not(:first-of-type) {
        .date-block {
          .md-text-field--margin {
            margin-top: 0;
          }
        }
      }
    }

    .minus-btn {
      width: 45px;
      height: 45px;
      border-radius: 45px;
      position: absolute;
      right: 0;
      top: 0;
      background-color: #fee5e5;
      font-weight: 900;
      color: #f35050;

      @include tablet-landscape {
        width: 35px;
        height: 35px;
        border-radius: 35px;
      }
    }

    &.common-date-block-wrapper {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;

      &.meet-class {
        .weekly-range-picker {
          width: 48%;
        }
      }

      .weekly-range-picker {
        width: 48%;

        .md-floating-label {
          left: 25px;
          top: -3px;
          @include font-size(13);
          color: $black;
          font-weight: 700;
          background-color: $white;

          @include mobile {
            top: -5px;
          }

          @include tablet-portrait {
            top: -5px;
          }
        }

        .md-picker--inline {
          position: relative;
        }
        .md-text-field-container {
          .md-text-field-icon-container {
            .img {
              position: absolute;
              bottom: 8px;
              left: 20px;
              width: 14px;
              height: auto;

              @include mobile {
                bottom: 3px;
              }

              @include tablet-portrait {
                bottom: 3px;
              }

              @include tablet-landscape {
                bottom: 0px;
                left: 15px;
              }
            }
            .md-text-field-divider-container {
              margin-left: 0;
            }
          }
        }
      }
      .start-end-wrapper {
        width: 100%;
        position: relative;

        .start-end-inner-wrapper {
          display: flex;
          width: 100%;

          .md-layover {
            width: 48%;

            &:not(:last-of-type) {
              margin-right: 10px;
            }

            .md-list {
              top: unset;
              width: 100%;
              padding: 0;
            }

            .md-floating-label {
              background-color: $white;
              top: 9px;
              @include font-size(13);
              color: $black;
              font-weight: 700;
              transform: none;
              z-index: 1;
              left: 20px;
            }

            .md-text-field {
              padding-left: 20px;
            }

            .md-text--theme-primary {
              color: $black;
            }
          }
        }

        .material-icons {
          display: none;
        }
      }
      .available-slot-wrapper {
        margin-top: 15px;
        .text-gray {
          color: rgba(0, 0, 0, 0.5);
          @include font-size(13);
          text-align: left;
        }
        .available-slot-inner-wrapper {
          display: flex;
          flex-wrap: wrap;

          .md-chip {
            flex: 0 0 48%;
            justify-content: center;
            margin: 5px;
            margin-bottom: 0;
            margin-left: 0;
            background-color: $white;
            border: 1px solid #d8d8d9;

            &:hover {
              background-color: $bright-blue;
            }

            &:focus {
              box-shadow: none;
              background-color: $bright-blue;

              .md-chip-text {
                color: $white;
              }
            }

            .md-chip-text {
              @include font-size(13);

              &:focus {
                outline: none;
                color: $white;
              }
            }

            &.md-chip--hover {
              .md-chip-text--hover {
                color: $white;
              }
            }
          }
        }
      }
      .duration-wrapper {
        width: 48%;

        .md-floating-label {
          margin-left: 25px;
          top: -3px;
          @include font-size(13);
          color: $black;
          font-weight: 700;
          background-color: $white;

          @include mobile {
            top: -5px;
          }

          @include tablet-portrait {
            top: -5px;
          }
        }
      }
    }
    &.meet-class {
      flex-wrap: wrap;

      .weekly-range-picker {
        width: 100%;
      }
    }

    &.hourly-block-class {
      .weekly-range-picker {
        width: 100% !important;
      }
    }
  }

  .md-picker--inline-icon {
    left: -3px;

    @include desktop {
      left: 0;
    }
  }

  .price-block-wrapper {
    margin-top: 20px;
    position: relative;
    width: 100%;

    .price-block-title {
      position: absolute;
      top: -32px;
      width: 100%;
      text-transform: uppercase;
      color: #d10000;
      @include font-size(15);
    }
    .inner-price-block-wrapper {
      display: flex;
      justify-content: space-between;
      @include font-size(13);
      padding-bottom: 7px;
      position: relative;
      word-break: break-all;

      &.total-value {
        @include font-size(15);
      }

      .value-cls {
        .blue-text-link {
          color: $bright-blue;
          text-decoration: underline;
        }

        .img {
          margin-left: 5px;
        }
      }

      .label-cls {
        .tooltip-icon {
          cursor: pointer;
          .tooltip-wrapper {
            display: none;
            top: 28px;
            position: absolute;
            padding: 20px;
            padding-bottom: 10px;
            background-color: rgba(0, 0, 0, 0.8);
            border-radius: 10px;
            width: 100%;
            margin-bottom: 80px;
            z-index: 11;

            .detail-price-wrapper {
              display: flex;
              justify-content: space-between;
              color: white;
              padding-bottom: 10px;

              &.final-row {
                font-weight: 700;
                padding-top: 10px;
                border-top: 0.5px solid $white;
              }

              &.price-tooltip {
                display: block;
              }

              .package-name {
                color: #7f7f7f;
                font-size: 11px;
                font-weight: $bold;
              }

              .price-detail {
                font-size: 13px;
              }

              .right-text {
                display: flex;
                justify-content: space-between;
              }
            }
            &::before {
              content: '';
              position: absolute;
              top: -13px;
              left: 50px;
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-bottom: 13px solid rgba(0, 0, 0, 0.7);
            }

            &.tax-tip {
              left: 0;

              &::before {
                left: 34px;
              }
            }
          }

          &:hover {
            .tooltip-wrapper {
              display: block;
            }
          }
        }
      }
    }

    .section-divider {
      margin: 0;
      margin-bottom: 10px;
      padding-top: 0;
    }
  }
  .continue-btn-wrapper {
    background-color: transparent;
    bottom: 0;
    position: fixed;
    right: 0;
    text-align: center;
    width: 100%;
    z-index: 9;

    @include desktop {
      right: 5%;
      width: 29%;
    }

    .btn-grid-wrapper {
      padding-left: 0;
      padding-bottom: 0;

      @include desktop {
        margin: 0 -8px;
        width: 100%;
      }

      .continue-btn-inner-wrapper {
        padding: 0 24px;
        border-top: 1px solid $light-gray;
        border-left: 1px solid $light-gray;
        border-right: 1px solid $light-gray;
        background-color: $white;
        padding-top: 10px;
        padding-bottom: 10px;

        .description {
          display: block;
          @include font-size(10.5);
          padding-bottom: 10px;

          .blue-text-link {
            color: $bright-blue;
            cursor: pointer;
            display: inline-block;
            text-decoration: underline;
            font-weight: $bold;
          }
        }

        .md-btn {
          color: $black;
          cursor: pointer;
          background-color: #ffdd57;
          border-radius: 25px;
          height: 50px;
          width: 100%;
          font-size: 13px;

          .small-text {
            color: rgba(0, 0, 0, 0.7);
            display: block;
            line-height: 9px;
            font-size: 11px;
            font-weight: 600;
            text-transform: none;

            .mr-4 {
              margin-right: 4px;
            }
          }

          &:disabled {
            pointer-events: none;
            border: 1px solid $light-gray !important;
            background-color: $light-gray !important;
            color: $black !important;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  .md-text-field {
    border: 1px solid #e2e2e2;
    border-radius: 25px;
    height: 40px;
    width: 107px;

    @include tablet-landscape {
      min-width: unset;
      width: 140px;
    }

    &:focus {
      border: 1px solid $bright-blue;
    }
  }

  .md-divider {
    display: none;
  }

  .coupon-code-popup {
    #simple-list-dialog {
      max-width: 335px !important;
      width: 100% !important;
      background-color: #009dff;
      color: $white;
      border-radius: 10px;

      .md-dialog-content {
        text-align: left;
        padding: 30px 25px 20px 25px;

        .main-title {
          color: $white;
          font-weight: 800;
        }

        .md-text-field-container {
          .md-text-field {
            margin-top: 0;
            margin-bottom: 30px;
            height: 50px;
            padding-left: 20px;
            background-color: $white;
            @include font-size(15);
            color: rgba(0, 0, 0, 0.4);
          }
        }

        .promo-btn-wrapper {
          text-align: right;

          .md-btn {
            color: $white;
            @include font-size(13);
            cursor: pointer;

            @include tablet-portrait {
              margin-right: 0;
            }

            margin-right: 10px;
          }
        }
      }
    }
  }
  .view-more-block {
    text-align: right;

    .view-more-wrapper {
      cursor: pointer;
      display: flex;
      align-items: baseline;
      justify-content: flex-end;
      color: $bright-blue;

      .view-more {
        margin-right: 5px;
      }

      .view-less {
        margin-right: 5px;
      }

      img.view-more-img {
        transform: rotate(90deg);
        width: 9px;
      }

      .view-less-img {
        transform: rotate(270deg);
        width: 9px;
      }
    }
  }
}

.booking-wrapper {
  .package-wrapper {
    background-color: #EEF8FC;
    display: flex;
    justify-content: space-between;
    font-weight: $bold;
    left: -26px;
    padding: 0px 5px 0px 30px;
    position: relative;
    text-align: left;
    width: 116%;

    .check-packages {
      background-color: $ms-blue;
      border-radius: 100px;
      color: $white;
      @include font-size(11);
      padding: 5px 10px;
      text-transform: uppercase;
    }

    .package-text {
      @include font-size(15);
      margin-bottom: 18px;
      padding-top: 25px;
      width: 134px;
    }

    .icon-img {
      position: relative;
    }

  }

  .text-block {
    background-color: #EEF8FC;
    display: flex;
    font-weight: $bold;
    height: auto;
    justify-content: space-between;
    left: -26px;
    padding-bottom: 10px;
    padding-left: 30px;
    position: relative;
    text-align: left;
    width: 116%;
    
    .main-text {
      width: 205px;
      padding-top: 25px;
      z-index: 2;
    }

    .icon-img {
      background-color: #EEF8FC;
      height: 116px;
      position: absolute;
      right: 0px;
      z-index: 1;

      @include tablet-landscape {
        height: 50px;
      }
    }
  }

  .package-outer-wrapper {
    background-color: #EEF8FC;
    padding: 10px 5px 28px 30px;
    position: relative;
    left: -26px;
    width: 116%;

    .package-inner-wrapper {
      text-align: left;

      .package-type {
        display: flex;
        align-items: center;
  
        .delete-icon {
          cursor: pointer;
          margin-right: 10px;
          width: 15px;
        }
  
        .guest-input {
          z-index: 2;

          .md-text-field {
            border: 1px solid $ms-blue !important;
            background-color: $white;
            height: 30px !important;
            padding-left: 16px;
            width: 72px;
          }
        }
  
        .package-name {
          @include font-size(13);
          font-weight: $regular;
          width: 210px;
        }
      }
    }
  }
}

//for mybooking section
.mybooking-package-section {
  .package-grid {
    padding: 0;
    justify-content: space-between;

    .package-card-wrapper {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      height: auto;
      margin-bottom: 10px;
      width: 100%;

      @include desktop {
        // width: 49%;
        // height: 180px;
      }

      @include tablet-landscape {
        // width: 49%;
        // height: 200px;
      }

      .card-header {
        background-color: rgba(0, 0, 0, 0.1);
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        display: flex;
        justify-content: space-between;
        padding: 8px 20px;

        .header-title {
          font-size: 15px;
          font-weight: $extra-bold;
          color: $black;
          word-break: break-word;
        }

        .sub-title {
          font-size: 11px;
          font-weight: normal !important;
          opacity: 0.5;
          word-break: break-word;
        }

        .card-header-right {
          text-align: right;

          .total-price {
            color: $black;
            font-weight: $extra-bold;
            @include font-size(15);
          }

          .sub-text {
            color: $black;
            font-size: 11px;
            opacity: 0.5;
            word-break: break-word;
          }
        }
      }

      .content-area {
        padding: 15px 20px;
        color: $black;

        .content-block {
          .description-title {
            font-size: 13px;
            font-weight: $bold;
            word-break: break-word;
          }

          .description {
            font-size: 11px;
            word-break: break-word;
            color: #7b7b7b;
          }

          .price {
            font-size: 13px;
            font-weight: $bold;
            text-transform: uppercase;
          }

          .price-tag {
            color: #7b7b7b;
            font-size: 11px;
          }

          &.description-wrapper {
            margin-bottom: 10px;
          }
        }
      }
    }

    .package-card-wrapper:nth-child(odd) {
      @include mobile {
        margin-right: 0;
      }

      @include tablet-portrait {
        margin-right: 0;
      }

      margin-right: 10px;
    }
  }
}
