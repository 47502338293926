.my-space {
  margin-top: 60px;

  .custom-container {
    @include desktop {
      width: 62%;
    }

    @include tablet-landscape {
      width: 92%;
    }
  }

  .header {
    padding: 35px 0 24px 0;

    .flex-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      color: $black;
      @include font-size(25);
      display: inline-block;
      font-weight: $black-bold;
      margin-bottom: 0;
      text-align: left;
    }

    .btn-teal-outline {
      &.btn-list {
        border-radius: 25px;
        width: 140px;
      }

      @include desktop {
        display: none;
      }

      @include tablet-portrait {
        display: none;
      }
    }

    @include desktop {
      border-bottom: 1px solid $gray;
      padding: 35px 0 20px 0;
    }

    @include tablet-portrait {
      border-bottom: 1px solid $gray;
      padding: 60px 0 20px 0;
    }
  }

  &.host-dashboard {
    .content {
      .space-grid {
        padding: 0;

        .spacy-left {
          border-right: 1px solid $gray;
          margin: 0;
          padding: 20px 30px 20px 0;

          .list {
            .list-items {
              cursor: pointer;
              color: $black;
              @include font-size(13);
              font-weight: $bold;
              display: block;
              padding: 8px 20px;
              text-align: left;

              &.active {
                border: 1px solid $host-green;
                border-radius: 25px;
                color: $host-green;
              }
            }
          }
        }

        .spacy-right {
          padding: 20px 0px;
          padding-top: 0;
          margin: 0;

          .card-list {
            margin-top: 20px;

            .card {
              background-color: $white;

              display: flex;
              align-items: flex-start;
              cursor: pointer;
              margin-bottom: 15px;

              .img {
                border-radius: 5px 0 0 5px;
                height: 102px;
                width: 100px;
                max-width: 100%;
                @include background-property();
                background-size: cover !important;

                @include desktop {
                  height: 103px;
                }
              }

              .ellipsis-img {
                cursor: pointer;
                height: 11px;
                width: 16px;
                max-width: 100%;
              }

              .content {
                border: 1px solid $light-gray;
                border-left: none;
                border-radius: 0 5px 5px 0;
                flex: 1;
                min-height: 102px;
                min-width: 68%;
                padding: 5px 15px;
                text-align: left;

                .title {
                  color: $black-op-50;
                  display: block;
                  @include font-size(11);
                  font-weight: 600;
                  text-transform: uppercase;
                }

                .sub-title {
                  color: $black;
                  display: block;
                  @include font-size(15);
                  font-weight: $black-bold;
                  margin-bottom: 3px;
                }

                .status {
                  border-radius: 23px;
                  display: inline-block;
                  @include font-size(11);
                  margin-bottom: 1px;
                  padding: 5px 6px;

                  &.active {
                    background-color: rgba($color: #0671d4, $alpha: 0.07);
                    color: #0671d4;
                  }

                  &.declined {
                    background-color: rgba($color: #f35150, $alpha: 0.07);
                    color: #f35150;
                  }

                  &.pending {
                    background-color: rgba($color: #997c00, $alpha: 0.07);
                    color: #997c00;
                  }
                }

                .flex-wrap {
                  @include flex-row;
                  justify-content: space-between;
                }

                .popover {
                  cursor: pointer;
                }

                .favourites {
                  .icon {
                    color: #f35050;
                    height: 10px;
                    width: 10px;
                  }

                  .number {
                    color: $black;
                    @include font-size(13);
                    font-weight: $black-bold;
                    margin: 0 3px;
                  }

                  .gray-text {
                    color: $black-op-50;
                    @include font-size(13);
                  }
                }

                @include desktop {
                  min-width: 77%;
                  min-height: 103px;
                }
              }
            }
          }

          @include desktop {
            padding: 20px 0px 20px 30px;
          }

          @include tablet-portrait {
            padding: 20px 0px 20px 30px;
          }

          @include mobile {
            width: 100%;
          }

          .my-space-details {
              margin-top: 0;

            .content {
              .btn-manage {
                @include mobile {
                  margin: 0 10px 5px 0;
                }

                @include tablet-portrait {
                  margin: 0 10px 5px 0;
                }
              }
              .custom-container {
                @include desktop {
                  width: 100%;
                }
              }
            }
            .header  {
              padding: 8px 0 20px 0;

              @include mobile {
                padding-top: 35px;
              }

              .custom-container {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  &.guest-dashboard {
    .content {
      .space-grid {
        .spacy-left {
          .list {
            .list-items {
              &.active {
                border: 1px solid $ms-blue;
                color: $ms-blue;
              }
            }
          }
        }
      }

      .host-search-bar-wrap {
        .autocomplete-content {
          .wrap { 
            .list {
              .list-item {
                &.active {
                  background-color: $ms-blue;
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }

  .web-only-wrap {
    display: none;

    @include desktop {
      display: block;
    }
  }

  .host-search-bar-wrap {
    position: relative;

    .arrow-up {
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      position: absolute;
      border-bottom: 11px solid $white;
      right: 17px;
      top: 43px;
    }

    .search-input {
      background-color: $white;
      border-radius: 23px;
      border: 1px solid #d8d8d9 !important;
      box-shadow: none !important;
      color: $black !important;
      height: 45px !important;
      width: 100% !important;
      padding: 8px 18px;
      margin-top: 0;
      display: flex;
      align-items: center;

      .md-text-field {
        @include font-size(15);
        color: $black;

        &::placeholder {
          color: $black-op-50;
        }
      }

      .md-text-field-icon + .md-text-field-divider-container {
        margin-left: 0px;
      }

      .md-text-field--margin {
        padding-left: 10px;

        @include mobile {
          margin-top: 0 !important;
        }

        @include tablet-portrait {
          margin-top: 0 !important;
          height: 22px;
        }

        @include desktop {
          margin-top: 0;
          height: 22px;
        }
      }

      .md-divider {
        display: none;
      }

      @include tablet-landscape {
        width: 100% !important;
        height: 35px !important;
        margin: 0 auto;
        border-radius: 60px;
        box-shadow: 0px 2px 6px #00000029;
        margin-top: 65px;

        &.search-input-focus {
          padding-right: 137px;
        }
      }

      @include desktop {
        width: 100%;
        height: 35px !important;
        margin: 0 auto;
        border-radius: 60px;
        box-shadow: 0px 2px 6px #00000029;

        &.search-input-focus {
          padding-right: 137px;
        }
      }

      .md-text-field-icon-container {
        @include mobile {
          align-items: center;
          height: 32px;
        }

        @include tablet-portrait {
          height: 40px;
          align-items: center;
        }

        @include desktop {
          height: 40px;
          align-items: center;
        }

        .search-icon {
          height: 11px;
          margin-bottom: 0;
          opacity: 0.25;

          @include mobile {
            width: 11px;
            height: 11px;
          }
        }

        .md-text-field-divider-container {
          @include mobile {
            margin-left: 10px;
          }
        }
      }
    }

    .autocomplete-content {
      width: 100%;
      border-radius: 24px;
      background-color: $white;
      z-index: 100;
      outline: none;
      position: absolute;
      text-align: left;

      .wrap {
        padding: 30px 26px;

        .list {
          margin-bottom: 20px;

          @include mobile {
            margin-bottom: 30px;
          }

          .title {
            color: $black;
            @include font-size(15);
            font-weight: $black-bold;
            margin: 0 6px;
            margin-bottom: 4px;
            text-transform: uppercase;
          }

          .list-item {
            border: 1px solid #d8d8d9;
            border-radius: 23px;
            background-color: $white;
            display: inline-block;
            color: $black;
            cursor: pointer;
            height: 32px;
            margin: 3px;
            padding: 4px 16px;

            @include mobile {
              height: 40px;
              padding: 8px 16px;
            }

            &.active {
              background-color: #2a8485;
              color: $white;

              .img {
                filter: invert(0);
              }
            }

            &.category-list-item {
              @include mobile {
                padding: 8px 21px;
              }
            }
          }

          &:last-child() {
            margin-bottom: 0px;
          }
        }
      }

      .footer {
        border-top: 1px solid $light-gray;
        padding: 12px 30px;
        display: flex;
        justify-content: flex-end;
      }

      @include desktop {
        margin: 17px auto;
      }
    }

    .btn-wrap {
      background-color: $white;

      @include mobile {
        border-top: 1px solid $light-gray;
        position: fixed;
        padding: 12px 20px;
        width: 100%;
        right: 0px;
        bottom: 0px;
      }

      @include tablet-portrait {
        border-top: 1px solid $light-gray;
        position: fixed;
        padding: 12px 20px;
        width: 100%;
        right: 0px;
        bottom: 0px;
      }
    }

    .search-btn {
      height: 45px;
      border-radius: 23px;
      padding: 14px 15px;
      cursor: pointer;
      display: block;
      color: $black;
      background-color: $yellow;
      text-align: center;
      @include font-size(13);
      width: 100%;
      font-weight: $extra-bold;
      text-transform: uppercase;

      @include desktop {
        height: 36px;
        padding: 10px 15px;
        width: 166px;
      }

      @include tablet-landscape {
        height: 36px;
        padding: 10px 15px;
      }
    }
  }

  .host-dialog {
    .dialog-header {
      .close-icon-wrap {
        text-align: right;

        .close-icon {
          cursor: pointer;
        }

        @include mobile {
          margin-top: 30px;
          padding: 0 25px;
        }

        @include tablet-portrait {
          margin-top: 30px;
          padding: 0 25px;
        }
      }

      @include desktop {
        margin-bottom: 30px;
      }
    }

    .md-list {
      @include mobile {
        box-shadow: none;
        background: transparent;
        max-height: 400px;
      }

      @include tablet-portrait {
        box-shadow: none;
        background: transparent;
        max-height: 400px;
      }
    }
  }

  @include desktop {
    margin-top: 80px;
  }
}

.space-main-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
  top: 0;
}

.navbar-footer {
  background-color: $white;
  border-top: 1px solid $light-gray;

  position: fixed;
  width: 100%;
  bottom: -1px;

  .footer-list {
    color: $black;
    display: inline-block;
    @include font-size(9);
    padding: 13px 30px;
    width: 50%;

    &.active {
      color: #118488;
      font-weight: $extra-bold;
    }

    &:first-child {
      border-right: 1px solid $light-gray;
    }

    .icon {
      display: block;
      @include font-size(15);
      margin: 0 auto;
      margin-bottom: 8px;
    }
  }

  @include desktop {
    display: none;
  }

  @include tablet-portrait {
    display: none;
  }
}


.calendar-page {
  padding: 20px;
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.calendar-icon {
  width: 40px;
  height: 40px;
}

.sync-button {
  background: none;
  border: none;
  cursor: pointer;
}

.calendar-body {
  background-color: #f0f0f0;
  padding: 20px;
}

.google-calendar-modal .google-calendar-header {
  text-align: center;
  margin-bottom: 20px;
}

.google-calendar-modal .google-calendar-header h2 {
  margin: 0;
}

.google-calendar-modal .google-calendar-header p {
  margin: 0;
  color: #999;
}

.assign-button {
  width: 100%;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-top: 20px;
}

.assign-button:hover {
  background-color: #0056b3;
}

.reservation-modal h2 {
  text-align: center;
  margin-bottom: 20px;
}
// calendar sync styles
.flex-row-calandar{
  @include flex-row;
  position:'relative'
}
.no-flex-wrap{
  flex-wrap: nowrap;
}
.goglesync-btn{
  background-color: $white;
  height: 40px;
  min-width: 174px;
  border-radius: 100px;
  @include flex-row;
  justify-content: center;
  border:1px solid $light-gray;
  margin-right: 10px;
  padding: 0px 16px;
  cursor: pointer;
  color: $black;
  font-weight: $bold;
  font-size: 13px;

  .goglesync-btn:focus{
    border: 1px solid $black;
  }
}
.goglesync-btn-green{
  border:1px solid $dark-green-III;
  border:1px solid $dark-green-III;
  color:$dark-green-III;
  
}
.goglesync-btn-gray{
  border:1px solid $light-gray;
  color: $black;
}

.sync-img{
  width: 22px;
  height: 20px;
  margin-right: 5px;
}
.down-img{
  width: 10px;
  height: 5px;
  margin-left: 7px;
}
.unlink-btn{
  min-width: 116px;
  height:40px;
  border-radius: 100px;
  @include flex-row;
  border: 1px solid $dark-green;
  color: $dark-green;
  font-size: 12px;
  font-weight: $extra-bold;
  background-color: $white;
  padding: 0px 12px;
  margin-right: 25px;
  cursor: pointer;

  @include mobile{
    margin-right: 0px;
  }
}



