.loading-wrap {

    .result-bar {

        .blur-menu-wrapper {
            @include flex-row;
            justify-content: flex-start;
        }

        .btn-loading {
            background-color: #f2f3f2;
            border-radius: 17px;
            border: none;
            height: 35px;
            min-width: 85px;
            margin-right: 5px;  
            outline: none; 
            
            &:hover {
                background-color: #f2f3f2;
            }
        }    
    }
    
    .result-grid {
        .md-cell {
            margin: 0;
            margin-bottom: 10px;
            margin-right: 16px;        

            @include mobile {
                margin-right: 0;
                width: 100%;
            }
        }
         
        .card-image {
            border-radius: 4px !important;
            height: 200px;
            margin: 0;
        }
        
        .card-detail {        
            .card-title {
                height: 15px;   
                margin: 10px 0;
                width: 55%;
            }
            
            .card-description {
                height: 10px;   
                width: 44%;
                margin: 10px 0;
            }
        }    

    }   
    
    .section-spacee {
        margin-top: 100px;

        .space-left {
            .content-wrap {
                border-top: 1px solid $light-gray;
                margin-top: 20px;     
                padding: 10px 0;                 
                
                .content-title {
                    display: block;
                    height: 15px;   
                    margin: 10px 0;
                    width: 25%;
                }

                .content-block-wrap {
                    @include flex-row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding: 10px 0;

                    .content-block {
                        width: 10%;
                        height: 50px;
                        margin-right: 10px;
                    }
                }
            }

            .rating-wrap {
                .title {
                    font-weight: $black-bold;
                    @include font-size(18);
                }

                .image {
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                }

                .wrap {
                    border-bottom: 1px solid $light-gray;
                    padding: 10px 0;
                }

                .special-grid {
                    padding: 0;
                }

                .flex-wrap {
                    @include flex-row;

                    @include mobile {
                        width: 20%;
                    }

                }

                .inline-wrap {
                    display: inline-block;

                    @include mobile {
                        width: 80%;
                        padding: 0 20px;
                    }

                    @include tablet-portrait {
                        width: 80%;
                        padding: 0 20px;
                    }
                }

                .grid-title {
                    display: block;
                    height: 10px;   
                    width: 10%;
                    margin: 10px 0;

                    @include mobile {
                        width: 30%;
                    }

                    @include tablet-portrait {
                        width: 30%;
                    }
                }

                .date {
                    display: block;
                    height: 10px;   
                    width: 12%;
                    margin: 10px 0;

                    @include mobile {
                        width: 40%;
                    }

                    @include tablet-portrait {
                        width: 40%;
                    }
                }

                .card-description {
                    height: 10px;   
                    width: 90%;
                    margin: 10px 0;
                }
            }
        }

        .space-right {
            border: 1px solid $light-gray;
            border-top: none;
            height: 100vh;
            left: unset;
            max-height: 100vh;
            overflow-y: auto;
            right: -1%;

            .wrapper {
                padding: 10px 25px;
                border-bottom: 1px solid $light-gray;
            }
            
            .input-wrap {
                border-radius: 25px;
                display: block;
                height: 45px;
                margin-bottom: 10px;
                width: 100%;
            }         

            .num-wrapper {
               width: 100%;
               clear:both;
               overflow:hidden;

               .number-wrap {
                    display: block;
                    float: left;
                    height: 15px;
                    margin-bottom: 10px;
                    text-align: left;
                    width: 30%;
                }

                .count-wrap {
                    display: block;
                    float: right;
                    height: 15px;
                    margin-bottom: 10px;
                    width: 10%;
                }
            }
        }
    }

    .loading {
        position: relative;
        background-color: #f2f3f2;
        overflow: hidden;
               
        &::after {
          display: block;
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          transform: translateX(-100%);
          top: 0;
          background: linear-gradient(90deg, transparent, rgb(228, 228, 228), transparent);
          animation: loading 1.5s infinite;
        }
    }
      
    @keyframes loading {
        100% {
          transform: translateX(100%);
        }
    }
}
