.space-popover {
  cursor: pointer;
  z-index: 10;

  .ant-popover-inner-content {
    padding: 0;
    min-width: 113px;
  }

  .action-popover {
    .list-items {
      border-bottom: 1px solid $light-gray;
      color: #2a8485;
      display: block;
      font-weight: 600;
      padding: 10px 15px;

      .icon {
        margin-right: 10px;
      }
    }
  }

  .support-popover {
    .list-item-wrap {
      padding-left: 0;
    }

    .list-items {
      border-bottom: 1px solid $light-gray;
      color: $black;
      cursor: pointer;
      display: block;
      font-size: 13px;
      padding: 15px 20px;
      list-style-type: none;

      .icon {
        margin-right: 10px;
        height: 13px;
        width: 13px;
      }

      #cancel-booking {
        .icon {
          height: 12px;
          width: 12px;
        }
      }
    }
  }

  .reservation-popover {
    .list-items {
      border-bottom: 1px solid $light-gray;
      color: $black;
      cursor: pointer;
      display: block;
      font-size: 15px;
      font-weight: 600;
      padding: 10px 15px;

      .icon {
        margin-right: 16px;
        width: 14px;
        height: 14px;
      }

      &.cancel {
        color: #f35050;
      }
    }
  }
}

.report-dropdown-input {
  &.padding-wrap {
    padding: 10px;
  }

  .border-top {
    border-top: 1px solid $light-gray;
    padding: 10px 15px;
  }

  .ant-checkbox-group {
    padding: 10px 15px;

    &:last-of-type {
      margin-bottom: 0px !important;
    }
  }

  .btn-search {
    width: 47%;
    font-weight: $extra-bold;
    background-color: transparent;
    border: none;
    height: 0;
    outline: 0;
    box-shadow: none;
    color: $host-green !important;
    font-size: 13px;
  }

  .btn-reset {
    width: 47%;
    background-color: transparent;
    height: 0;
    color: $black !important;
    border: none;
    outline: 0;
    box-shadow: none;
    font-size: 13px;
  }

  .ant-checkbox-wrapper {
    margin-bottom: 10px;
    font-size: 14px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    span {
      font-size: 14px;
      color: $black;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $host-green !important;
    border-color: $host-green !important;
  }

  .ant-checkbox-checked:after {
    border: 1px solid $host-green !important;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: $host-green !important;
  }

  .report-input {
    border: 1px solid $light-gray !important;
    border-radius: 25px;
    width: 175px;
    margin-bottom: 10px;
    display: block;
  }
}

.booking-cancellation-notification {
  box-shadow: 0px 1px 10px #00000040 !important;
  border-radius: 24px !important;
  padding: 15px 20px !important;
  width: 311px;

  .ant-notification-notice-message {
    color: $black;
    font-size: 15px;
    font-weight: $black-bold;
    margin-bottom: 0;
  }

  .title {
    .icon {
      height: 15px;
      margin-right: 15px;
      vertical-align: middle;
      width: 15px;
    }

    .text {
      vertical-align: middle;
    }
  }

  .ant-notification-notice-description {
    color: $black;
    font-size: 15px;
    margin-left: 30px;
    margin-bottom: 0;
  }

  .ant-notification-close-x {
    .ant-notification-close-icon {
      color: $black;
      font-size: 10px;
    }
  }
}
.calender-sync-msg-popup-main{
  min-width: 287px;
  max-width: 327px;
.calender-sync-msg-popup{
  height: 96px;
  @include flex-row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0px 20px 0px 20px;
  border-radius: 8px !important;
  background-color: $white;
  
  text-align: left;
 
}
}
.title-calnder{
  font-size: 13px;
  font-weight:$regular ;
  color: $black;
  min-width: 200px;
}
.icon-error{
  height: 26px;
  width: 26px;
  margin-right: 15px;
}