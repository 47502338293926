.seating-property-wrap {
    .seating-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        
        .wrap {
           flex: 0 0 22.34%;
           margin-right: 8px;

           @include tablet-portrait {
            flex: 0 0 auto;
            margin-right: 10px;
           }

           @include desktop {
                 flex: 0 0 auto;
                 margin-right: 10px;
           }
        }

        .text-seating{               
            @include font-size(11);
            display: block;
            text-align: center;
            margin-top: 3px;

            @include tablet-portrait {
                text-align: left;
            }

            @include desktop {
                text-align: center;
            }
        }

        .text-num {
            display: block;
            @include font-size(11);
            font-weight: $bold;
            text-align: center;

            @include tablet-portrait {
                text-align: left;
            }

            @include desktop {
                text-align: center;
            }
        }

        .seating {      
            padding: 15px 8px;
            width: 100%;
            border-radius: 10px;
            border: 1px solid $gray;
            height: 65px;
            text-align: center;

            .seating-bg {
                @include background-property;
                background-size: contain;
                height: 38px;
                width: 47px;
                display: inline-block;
            }          

            @include tablet-portrait {
                height: 76px;
                width: 76px;
            }

            @include desktop {
                height: 76px;
                width: 76px;
                padding: 20px 15px;
            }
        }
    }
}