body {
  background-color: $white !important;
}

.container {
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.custom-container {
  width: 100%;

  @include desktop {
    width: 90%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
input,
textarea,
html {
  font-family: $font-family !important;
  color: $black;
}

.capital-cls {
  text-transform: uppercase;
}

// .ant-tooltip-inner {
//   min-width: 28px !important;
//   min-height: 28px !important;
//   padding: 7px !important;
//   background-color: $bright-blue !important;
//   text-align: center !important;
//   width: auto;
//   height: auto;
//   border-radius: 50% !important;
//   font-size: 11px;
//   margin-bottom: -3px;
// }

// .ant-tooltip-arrow:before {
//   width: 10px !important;
//   height: 10px !important;
//   background-color:$bright-blue !important;

// }

.ant-modal-mask {
  background-color: rgba($color: $black, $alpha: 0.75) !important;
}
