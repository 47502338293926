.section-my-bookings {
  padding-top: 50px;
  text-align: left;

  @include desktop {
    padding-top: 80px;
  }

  .header-wrapper {
    padding: 20px;
    height: 20%;
    width: 100%;
    border-bottom: 1px solid #d4d3d3;

    .header-title {
      font-weight: bold;
      font-size: 30px;
      padding-left: 10%;
    }
  }

  .no-bullets {
    right: 10px;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .content-wrapper {
    .status-wrapper {
      position: absolute;
      left: 0px;
      width: 25%;
      height: 100%;
      align-items: flex-start;
      border-right: 1px solid #d4d3d3;

      .status-list-wrapper {
        align-content: flex-end;
      }
    }

    .status-content {
      position: absolute;
      right: 0px;
      width: 75%;
    }
  }
}

.status-list {
  padding: 15px;

  .status-lable-wrapper {
    border: 1px solid black;
    border-radius: 20px;
    align-items: left;
    height: 20px;
    width: 80px;
    justify-content: space-between;
    padding: 8px;
  }
}

.space-list {
  overflow: 'auto';
  height: fit-content;
  scroll-behavior: smooth;
}

.space-list-item-wrapper {
  height: 120px;
  width: 50%;
  margin: 10px 0;

  border: 1px solid black;

  .space-list-item {
    width: 100%;
    height: 100%;

    .space-image-wrapper {
      position: absolute;
      left: 0px;
      align-items: flex-start;
      width: 50%;

      .space-image {
        height: 115px;
        width: 115px;
        padding: 1px;
      }
    }
    .space-content-wrapper {
      position: absolute;
      width: auto;
      left: 60px;
      align-items: flex-end;
    }
  }
}
