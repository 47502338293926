.my-space-details {
    margin-top: 58px;

    .custom-container {
        @include desktop {
            width: 62%;
        }
    }

    .header {
        border-bottom: 1px solid $gray;
        padding: 35px 0 20px 0;
        text-align: left;

        .flex-wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .ellipsis-img {
          cursor: pointer;
          height: 11px;
          width: 20px;
          max-width: 100%;  
        }

        .arrow-img {
          cursor: pointer;
          display: inline-block;
          height: 18px;
          @include font-size(8);
          margin-right: 7px;
          transform: rotate(180deg);
        }

        .title {
            color: $black;
            display: inline-block;
            @include font-size(15);
            font-weight: $black-bold;
            margin-bottom: 0;
            text-align: left;
            vertical-align: middle;
        }

        .favourites {
          display: inline-block;
          .icon {
              color: #f35050;
              height: 10px;
              width: 10px;
          }

          .number {
              color: $black;
              @include font-size(13);
              font-weight: $black-bold;
              margin: 0 3px;                                 
          }

          .gray-text {
              color: $black-op-50;
              @include font-size(13);
          }
      }
    }

    &.host-dashboard {
      
      .header-content {
        padding-top: 25px;  
        padding-bottom: 25px; 
        text-align: left; 
        
        .title {
            color: $black-op-50;
            display: inline-block;
            @include font-size(13);
            font-weight: 600;
            margin-right: 6px;
        }

        .sub-title {
            color: $black;
            display: block;
            @include font-size(25);
            font-weight: $black-bold;
            margin-bottom: 3px;
        }

        .status {
            border-radius: 23px;
            display: inline-block;
            @include font-size(12);
            margin-bottom: 1px;
            padding: 5px 6px;

            &.active {
                background-color: rgba($color: #0671d4, $alpha: 0.07);
                color: #0671d4; 
            }

            &.declined {
                background-color: rgba($color: #f35150, $alpha: 0.07);
                color: #f35150; 
            }

            &.pending {
                background-color: rgba($color: #997C00, $alpha: 0.07);
                color: #997C00; 
            }
        }

        .flex-wrap {
            @include flex-row;
            justify-content: space-between;
        }

        .popover {
            cursor: pointer;
        }

        .favourites {
            .icon {
                color: #f35050;
                height: 10px;
                width: 10px;
            }

            .number {
                color: $black;
                @include font-size(13);
                font-weight: $black-bold;
                margin: 0 3px;                                 
            }

            .gray-text {
                color: $black-op-50;
                @include font-size(13);
            }
        }
      }

      .content {
        text-align: left;
        margin-bottom: 30px;

        .btn {
          font-weight: $bold;
          font-size: 13px !important;
          height: 40px;
          min-width: 130px;
          padding: 11px 12px;

          &:first-child {
            margin-right: 5px;
          }

          @include desktop{
            padding: 11px 15px;
          }
          
          @include tablet-portrait {
            padding: 11px 15px;
          }
        }
        .btn-manage{
          margin: 0 10px 5px 0;
        }

        .img-wrap {
          margin-top: 30px;
          margin-bottom: 30px;
          overflow-x: scroll;
          white-space: nowrap;

          .img {
            border-radius: 5px;
            display: inline-block;
            margin-right: 10px;
            height: 100px;
            width: 100px;
            max-width: 100%; 
            @include background-property();
            background-size: cover !important;    
          }

          @include desktop {
             overflow-x: hidden;
             white-space: normal;
          }
        }

        .collapse-content {
          background-color: #ffffff;
          border-color: #dddddd;
          box-shadow: 0px 0px 3px #0000001A;
          border-radius: 5px;
          margin-bottom: 10px;

          .panel-content {
            border-bottom: 1px solid #dddddd;
            border-radius: 5px;

            .ant-collapse-header {
              color: $black;
              font-weight: $bold;
              font-size: 13px;
              padding: 14px 20px;

              .anticon {
                right: 20px;
                text-align: right;

                svg {
                  path {
                    color: #118488;
                  }
                }
              }
            }
          }
        }
      }
    }

    @include desktop {
      margin-top: 80px;  
    }

    @include mobile {
      position: fixed;
      width: 100%;
      top: 65px;
      margin-top: 0;
      z-index: 10;
      background-color: $white;
      height: 100%;
      right: 0;
    }
}