.space-section {
  border-radius: 32px;
  background-color: $white;
  margin-top: 24px;
  padding-bottom: 50px;
  padding-top: 99px;

  .custom-container {
    text-align: -webkit-center;
    text-align: -moz-center;
    width: 100%;

    @include desktop{
      width: 92%;
    }

    @include large-desktop{
      width: 86%;
    }

    @include exlarge-desktop{
      width: 92%;
    }

    &.container {
      max-width: 1760px;

      @include desktop{
        padding-right: 0px;
      padding-left: 0px;
      }
      


    }
    .title {
      background: transparent linear-gradient(90deg, $red 0%, $error 100%) 0% 0% no-repeat padding-box;
      @include font-size(25);
      font-weight: $black-bold;
      margin-bottom: 15px;
      padding-top: 40px;
      text-align: center;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: unset;
      -webkit-text-stroke-color: unset;

      @include desktop {
        border-top: unset;
        padding-top: 0;
        @include font-size(28);
        width: 500px;
      }

      @include tablet-portrait {
        width: 445px;
      }

      @include mobile {
        padding: 40px 20px 0px 20px;
      }
    }
    
    .grid-wrap {
      display: flex;
      height: 475px;
      justify-content: space-around;

      .category-name {
        align-items: center;
        background-color: $black;
        border-radius: 8px;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        color: $white;
        display: flex;
        font-weight: $bold;
        height: 52px;
        @include font-size(15);
        justify-content: center;
        opacity: 0.75;
        padding: 15px 5px;
        position: relative;

        p {
          color: $white !important;
          @include font-size(15);
          font-weight: $semi-bold;

          @include desktop {
            @include font-size(18);
          }
        }

        @include desktop {
          height: 40px;
          padding: 12px 6px;
        }
      }      
     }

    .space-tile {
      @include background-property();
      background-size: cover;
      background-position: top;
      border-radius: 8px;
      height: 100%;
      min-width: 100%;
      min-height: 100%;
      width: 100%;
     
      .img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

      &:hover {
        .hover-content {
          align-items: center;
          display: block;
        }

        .category-name {
          opacity: 0.9;
          top: 0px !important;
        }
      }
    }

    .grid-1 {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      min-width: 39%;
      
      .category-wrap-1 {
        display: contents;

          .category-name {
            top: 435px;
          }

          .hover-content {
            height: 435px;
            padding: 200px 50px 10px 50px;
          }

          &:hover {
            top: 0px !important;
          }
      }
    }

    .grid-2 {
      display: grid;
      grid-template-columns: 1fr 1fr; 
      grid-template-rows: auto auto;
      margin-left: 16px;
      margin-right: 16px;
      min-width: 40%;

      .category-wrap-2 {
        display: grid;
        grid-column: 1 / span 2;
        grid-row: 1 / span 1;
        height: 231px;
        margin-bottom: 15px;

        .category-name {
          top: 191px;
        }

        .hover-content {
          height: 191px;
          padding: 90px 50px 10px 50px;
        }
      }

      .category-wrap-3 {
        display: grid;
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
        height: 230px;
        margin-right: 8px;

        .category-name {
          top: 190px;
        }

        .hover-content {
          height: 190px;
          padding: 50px 5px 10px 5px;
        }
      }

      .category-wrap-4 {
        display: grid;
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
        height: 230px;
        margin-left: 8px;

        .category-name {
          top: 190px;
        }

        .hover-content {
          height: 190px;
          padding: 50px 10px 10px 10px;
        }
      }
    }

    .grid-3 {
      display: grid;
      grid-template-columns: auto; 
      grid-template-rows: auto auto auto;
      min-width: 19%;
      width: 233px;

      .category-wrap-5 {
        display: grid;
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        height: 148px;
        margin-bottom: 15px;

        .category-name {
          top: 108px;
        }

        .hover-content {
          height: 108px;
          padding: 18px 15px 20px 15px;

          .hover-text {
            margin-bottom: 10px;
          }
        }
      }

      .category-wrap-6 {
        display: grid;
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
        height: 148px;
        margin-bottom: 15px;

        .category-name {
          top: 108px;
        }

        .hover-content {
          height: 108px;
          padding: 18px 15px 20px 15px;

          .hover-text {
            margin-bottom: 10px;
          }
        }
      }

      .category-wrap-7 {
        display: grid;
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
        height: 148px;

        .space-tile {
          background-position: 50% 56%;
          .category-name {
            top: 108px;
          }
  
          .hover-content {
            height: 108px;
            padding: 18px 15px 20px 15px;
  
            .hover-text {
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .hover-content {
      display: none;
      background-color: rgba(0, 0, 0, 0.75);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      cursor: pointer;
      position: inherit;

      @include tablet-landscape {
        padding-left: 15px;
        padding-right: 15px;
      }

      .hover-text {
        color: $white;
        width: 100%;
        word-break: break-word;
      }

      .explore-btn {
        @include font-size(11);
        border: 1px solid $white;
        border-radius: 23px;
        background-color: transparent;
        color: $white;
        height: 32px;
        text-transform: uppercase;
        width: 108px;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .sub-title {
      color: $black;
      line-height: 27px;
      margin-bottom: 20px;
      text-align: center;
      @include font-size(15);
      width: 1000px;

      @include desktop {
        margin-top: 12px;
        margin-bottom: 45px;
        @include font-size(20);
      }
      .red-text {
        color: $error;
        text-transform: uppercase;
      }

      @include tablet-landscape {
        width: 805px;
      }

      @include tablet-portrait {
        width: 100%;
      }

      @include mobile {
        padding: 0 20px;
        width: 100%;
      }
    }

    .space-card-wrap {
      width: 100%;
      margin: 0 auto;

      .flex-wrap {
        overflow: hidden;
        
        .space-card {
          box-shadow: unset;
          border-radius: 10px;
          cursor: pointer;
          display: inline-block;
          height: 200px;
          margin-bottom: 20px;
          width: 48%;
          z-index: 1;

          &.space-card:first-child() {
            width: 100%;
          }

          &.space-card:nth-child(even) {
             float: left;
            margin-right: 10px;

            @include tablet-portrait {
              margin-right: 20px;
            }
          }

          &.space-card:nth-child(odd):not(:first-child) {
              float: right;
          }

          .hover-content {
            display: none;
            height: 100%;
            padding-left: 25px;
            padding-right: 25px;
            position: absolute;
            width: 100%;

            @include tablet-landscape {
              padding-left: 15px;
              padding-right: 15px;
            }

            .hover-text {
              color: $white;
              max-width: 121px;
              width: 100%;
              word-break: break-word;

              @include desktop {
                max-width: 121px;
              }

              @include tablet-landscape {
                max-width: unset;
              }
            }

            .explore-btn {
              @include font-size(13);
              border: 1px solid $white;
              border-radius: 23px;
              background-color: transparent;
              color: $white;
              height: 38px;
              text-transform: uppercase;
              width: 108px;

              &:hover {
                background-color: transparent;
              }
            }
          }

          &:hover {
            .md-media--16-9 {
              background-color: rgba(0, 0, 0, 0.75);

              .hover-content {
                align-items: center;
                display: block;
                padding-top: 12px;
              }
            }
          }

          @include tablet-landscape {
            max-width: 160px;
            width: 16.55%;
            min-width: unset;
          }

          @include tablet-portrait {
            height: 250px;
            width: 48%;
          }

          @include desktop {
            margin: 0;
          }

          @include large-desktop {
            max-width: unset;
          }

          .md-media {
            border-radius: 10px;

            &.md-media--16-9 {
              transition: 0.2s;

              @include mobile {
                padding-bottom: 200px;
              }

              @include tablet-portrait {
                padding-bottom: 250px;
              }
            }
          }

          .img {
            @include background-property;
            background-size: cover;
            background-position: 50% 50%;
            border-radius: 10px 10px 0 0;
            height: 200px;
            z-index: -1;

            @include desktop {
              height: auto;
            }

            @include tablet-portrait {
              background-position: 50% 30%;
              height: 250px;
            }
          }
        }

        .md-card-text{
          align-items: center;
          background-color: $black;
          border-radius: 10px;
          border-top-left-radius: unset;
          border-top-right-radius: unset;
          color: $white;
          display: flex;
          height: 52px;
          justify-content: center;
          opacity: 0.75;
          padding: 15px 5px;
          position: relative;
          top: 148px;

          p {
            @include font-size(15);
            font-weight: $semi-bold;
            color: $white !important;

            @include desktop {
              @include font-size(18);
            }
          }

          @include desktop {
            height: 45px;
            padding: 12px 6px;
          }

          @include tablet-portrait {
            top: 198px;
          }
        }

        .mobile-empty {
          @include mobile {
            min-width: 10px;
          }
        }

        @include tablet-portrait {
          justify-content: unset;
        }

        @include mobile {
          justify-content: unset;
        }
      }

      @include mobile {
        padding-top: 14px;
      }

      @include tablet-portrait {
        padding-top: 20px;
      }
    }
  }

  &.featured-section {

    .custom-container {
      max-width: 1760px;
    }

    .scroll-btn {
      background: transparent;
      border: none;
      cursor: pointer;
      left: 49%;
      outline: none;
      scroll-behavior: smooth;

      .arrow-icon {
        width: 32px;
      }

      @include desktop {
        margin-top: 60px;
      }
    }

    .quill {
      .ql-toolbar {
        display: none;
      }
  
      .ql-container {
        &.ql-snow {
          border: none;
        }
  
        .ql-editor {
  
          p {
            @include font-size(45);
            font-weight: $extra-bold;
            margin-bottom: 18px;
            text-align: center;

            @include mobile {
              display: grid;
              font-size: 28px;
              line-height: 35px;
            }

            @include tablet-portrait {
              display: grid;
              font-size: 28px;
              line-height: 35px;
            }
          }
        }
      }
    }

    .space-card-wrap {
      .grid-wrap{
        display: flex;
        flex-flow: wrap;
        height: auto;
        justify-content: space-around;
      
        .space-card {
          border-radius: 10px;
          box-shadow: 0px 2px 20px #00000029;
          margin-bottom: 3.4%;
          width: 30%;

          @include mobile {
            margin-bottom: 40px;
            width: 100%;
          }

          @include tablet-portrait {
            margin-bottom: 40px;
            width: 100%;
          }

          @include tablet-landscape {
            width: 47%;
          }

          .md-media {
            padding-bottom: 88.25%;

            @include mobile {
              padding-bottom: 97.5%;
            }
          }

          .space-card-img {
            background-size: cover;
            background-position: center;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            height: 355px;

            .img {
              background-size: cover;
              background-repeat: no-repeat;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              height: calc(100% - 76px);   
              bottom: 0;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              width: 100%;
              vertical-align: middle;
              border-style: none;

              @include mobile {
                height: 250px;
              }
            }
          }

          .space-card-content {
            align-items: center;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            padding: 18px 22px 18px 25px;
            position: absolute;
            width: 100%;

            .explore-btn {
              background: $pink;
              border-radius: 20px;
              color: $white;
              font-weight: $extra-bold;
              height: 40px;
              @include font-size(12);
              width: 86px;

              @include desktop {
                padding-top: 2px;
              }
            }

            .md-card-text {
              color: $black;
              font-weight: $bold;
              @include font-size(18);
              padding: 0px;
              text-align: left;
            }
          }
        }
      }
    }
  }

  @include desktop {
    margin-top: 96px;
    border-radius: 0;
    box-shadow: none;
    padding-top: 0;
    padding-bottom: 55px;
  }

  @include tablet-portrait {
    position: relative;
    padding-top: 0px;
    z-index: 1;
  }

  @include mobile {
    position: relative;
    padding-top: 0px;
    z-index: 1;
  }
}
