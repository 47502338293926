.media-section {
  padding: 5px 0 70px 0;

  .sc-bxivhb {
    padding: 10px 0;
  }

  .media-wrap {
    .title {
      @include font-size(25);
      font-weight: $black-bold;
      text-align: left;
      margin-bottom: 25px;

      @include desktop {
        @include font-size(28);
      }
    }

    .media-card {
      .btn-see-more {
        background-color: transparent;
        border: 1px solid transparent;
        box-shadow: none;

        .arrow-right {
          display: none;
          width: 16px;
          height: 16px;
          margin-left: 6px;

          @include desktop {
            display: inline;
          }
        }

        @include desktop {
          background-color: $white;
          border: 1px solid inherit;
          box-shadow: 0px 1px 4px #00000029;
          display: block;
          height: 40px;
          margin: 15px auto;
          margin-bottom: 0;
          width: 118px;
        }
      }

      .title {
        font-weight: $black-bold;
        @include font-size(15);
        color: $black;
        text-align: center;
      }

      .description {
        @include font-size(15);
        color: $black-op-60;
      }

      .img {
        border-radius: 8px;
        display: block;
        transform-style: preserve-3d;
        width: 107px;
        height: 107px;
        margin: 0 auto 17px;
      }

      @include desktop {
        border-radius: 32px;
        text-align: center;
        padding: 40px 27px;
        opacity: 0.6;
        box-shadow: 0px 2px 12px #00000029;
        background-color: $white;
        transform: scale(0.8, 0.8);
      }

      &.middle-card {
        opacity: 1;
        transform: scale(1, 1);
        position: relative;
        margin: 0 auto;
      }
    }

    .ant-carousel .slick-dots li button {
      width: 7px;
      height: 7px;
      background-color: $bright-blue;
      border-radius: 32px;
    }

    .container {
      max-width: 1760px;
    }
  }

  .ant-carousel .slick-dots-bottom {
    bottom: -30px;
  }

  .dots {
    display: block;
    margin: 0 auto;

    .dot {
      width: 7px;
      height: 7px;
      display: inline-block;
      border-radius: 50%;
      margin: 10px 4px;

      &.blue {
        background-color: $ms-blue;
      }

      &.gray {
        background-color: rgba($color: $ms-blue, $alpha: 0.25);
      }
    }

    @include desktop {
      display: none;
    }
  }

  .btn-arrow {
    background-color: $white;
    border: 1px solid $white;
    box-shadow: 0px 1px 4px #00000029;
    cursor: pointer;
    @include flex-row;
    justify-content: center;
    height: 36px;
    width: 36px;
    border-radius: 36px;
    color: $bright-blue;
    position: absolute;
    outline: 0 !important;
    top: 45%;

    &.right {
      right: -20px;
    }

    &.left {
      left: -20px;
    }

    .arrow {
      width: 12px;
      height: 12px;

      &.right {
        position: relative;
        transform: rotate(180deg);
      }

      &.left {
        position: relative;
      }
    }
  }

  @include desktop {
    padding: 60px 0 55px 0;
  }

  @include tablet-portrait {
    position: relative;
    top: -25px;
  }

  @include mobile {
    position: relative;
    top: -25px;
  }
}
