.signup-popup {
  padding: 0;

  @include mobile {
    z-index: 120 !important;
  }

  @include tablet-portrait {
    z-index: 120 !important;
  }

  .md-dialog-content--padded {
    padding: 0px;
  }

  .md-text-field--floating-margin {
    margin-top: 11px !important;
  }

  .signup-popup-wrap {
    .header {
      @include flex-row;
      @include font-size(16);
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid $gray;
      padding: 12px 24px;
      padding-top: 24px;

      @include desktop {
        padding: 20px 30px;
        padding-top: 25px;
        justify-content: space-between;
      }

      .title-text {
        color: $black;
        @include font-size(16);
      }
    }

    .error-active {
      color: $error !important;
      margin-bottom: 5px;

      .md-text-field {
        border-color: $error !important;
        color: $error !important;
      }
      .error-text {
        color: $error !important;
      }

      .md-password-btn {
        .material-icons {
          color: $error !important;
        }
      }

      .md-floating-label {
        color: $error !important;
      }
    }

    .btn-disable {
        opacity: 0.5;
        pointer-events: none;
    }
    .left-wrap {
      text-align: center;

      .arrow-icon {
        transform: rotate(180deg);
        width: 14px;
      }

      .link-text {
        color: $black;
        @include font-size(15);

        .link {
          color: $bright-blue;
          font-weight: 500;
        }

        @include desktop {
          margin-bottom: 0;
        }

      &.header-sub-text {

        @include desktop {
          position: absolute;
          top:48px;
        }
      }
      }
    }

    .right-wrap {
      float: right;

      .close-icon {
        cursor: pointer;
      }
    }

    .mobile-number-grid {
      padding: 5px 30px 0px 30px !important;

      .md-divider {
        display: none;
      }

      .list {
        padding: 30px 24px;

        .list-item {
          margin-bottom: 30px;
        }
      }

      .md-paper {
        width: auto !important;
      }

      .md-menu--select-field {
        color: $black;
        border: 1px solid #e9e9e9;
        border-radius: 25px;
        height: 45px;
        padding: 0 20px;
        width: 100%;
        margin: 0;
      }
    }  
  }

  .content {
    text-align: left;
    padding: 30px 24px;

    .forgot-text {
      color: $black;
      cursor: pointer;
      float: right;
      @include font-size(14);
      text-decoration: underline;
    }

    .error {
      .error-list {
        margin-bottom: 0;

        .error-text {
          color: $black;
          text-shadow: none;
          @include font-size(13);
        }
      }
    }

    .password-status {

      .btn-password {
        background: $white;
        border: 1px solid #E9E9E9;
        border-radius: 13px;
        color: $light-gray4;
        font-weight: $semi-bold;
        @include font-size(11);
        line-height: 12px;
        margin: 0px 5px 5px 0px;
        outline: none;
        padding: 6px 10px;

        &.error-text {
          border: 0.25px solid $error;
          color: $error;
        }

        &.success-text {
          border: 0.25px solid $dark-green;
          color: $dark-green;
        }

        .password-icon {
          margin-right: 5px;
          width: 10px;
        }
      }
    }

    .sub-text {
      color: $black;
      @include font-size(14);
      margin-bottom: 10px;
      text-align: center;
    }
    .md-grid {
      padding: 4px 0px;

      &.password-cls {
        margin-bottom: 6px;

        .md-text-field {
          width: 100%;
        }
        .md-password-btn {
          position: absolute;
          right: 15px;
          top: 22px;
        }
      }

      &.full-name-wrap {
        @include flex-row();
        justify-content: space-between;

        .half-width {
          width: 48%;
        }

        .md-text-field-container {
          width: 100%;
        }
      }

      &.password-input {
        padding: 0px;
        width: 100%;
      }

      &.email-input {
        padding: 4px 0px;
      }
    }

    .title {
      @include font-size(25);
      font-weight: $black-bold;
      margin-bottom: 15px !important;
    }

    .md-cell--bottom {
      width: 100%;
      margin: 0 !important;

      hr {
        display: none;
      }

      .md-floating-label {
        color: $black;
        left: 20px;
        top: -22%;
        background-color: $white;
        width: auto;
        padding: 0 3px;

        @include desktop {
          top: -9%;
        }
      }

      .md-text--theme-primary {
        color: $black;
        outline: none;
      }

      .md-text-field {
        color: $black;
        border: 1px solid #e9e9e9;
        border-radius: 25px;
        height: 45px;
        @include font-size(14);
        padding: 0 20px;
        -webkit-appearance: none;

        &.valid-input {
          border: 1px solid ;
        }
      } 
    }

    &.login-content {
      padding: 25px 38px 15px 38px;
    }

    @include desktop {
      padding: 30px 38px;
      padding-top: 25px;
    }

    @include mobile {
      &.custom-content {
        overflow-y: scroll;
        height: 460px;
      }
    }
  }

  .btn-wrap {
    padding: 24px;
    text-align: center;
    bottom: 0;
    border-top: 1px solid $gray;
    background-color: $white;
    width: 100%;

    .btn-signup {
      color: $white;
      font-weight: $black-bold;
      @include font-size(13);
      width: 100%;
      height: 45px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    @include desktop {
      border-top: 1px solid $gray;
      padding: 10px 35px 20px 35px;
      position: relative;
    }

    .link-wrap {
      .link-text {
        display: inline-block;
        @include font-size(14);

        &.sub-text {
          margin-bottom: 0px;
        }
      }
      .link {
        cursor: pointer;
        color: $bright-blue;
        display: inline-block;
        font-weight: $extra-bold;
        margin-left: 5px;
      }

      .signup-link {
        cursor: pointer;
        color: $black;
        @include font-size(14);
        margin-left: 5px;
        text-decoration: underline;
      }
    }
  }

  .md-paper {
    max-height: 100%;
    width: 100%;

    .md-dialog-content {
      max-height: 100% !important;
      width: 100%;

      @include desktop {
        width: 402px !important;
        border-radius: 24px;
      }
    }

    @include desktop {
      width: 402px !important;
      height: auto !important;
      border-radius: 24px;
    }
  }

  &.forgot-password {

    .btn-wrap{

      @include desktop {
        padding: 10px 30px 15px 30px;
      }
      .btn-signup {
        margin-bottom: 0px;
      }
    }

    .content {
      padding: 12px 24px 15px 24px;
      
      @include desktop{
        padding: 12px 30px 15px 30px;
      }

      @include mobile {
        &.custom-content {
          height: 520px;
        }
      }

      .input-wrap {
        width: 100%;
      }
      .text-wrap {
        display: flex;
        
        .img-success {
          height: 25px;
          margin-top: 6px;
          width: 25px;
        }
  
        .success {
          color: $green;
          margin: 0px;
          padding-left: 10px;
        }
      }

      .title {
        margin-bottom: 2px !important;
      }
    }
    .header {
      justify-content: space-between;
    }

    .sub-text {
      color: $black;
      display: flex;
      @include font-size(14);
      margin-top: 10px;
    }

    .text {
      color: $black-op-50;
      @include font-size(13);
      margin-bottom: 2px;

      @include desktop {
        @include font-size(15);
      }
    }
  }

  &.full-width-popup {
    .md-paper {
      @include mobile {
        max-height: 100%;
        width: 100%;
        max-width: 100%;
        height: 100%;
      }

      @include tablet-portrait {
        max-height: 100%;
        width: 100%;
        max-width: 100%;
        height: 100%;
      }
    }
  }

  &.login-popup {
    @include mobile {
      z-index: 112;
    }

    @include tablet-portrait {
      z-index: 112;
    }

    .label-wrap {
      padding: 10px 0 30px 0;
      .forgot-text {
        display: inline-block;
      }
      .md-selection-control-container {
        .md-btn--icon {
          .md-icon {
            font-size: 16px;
          }
        }

        #checkbox-read-material-design-spec-label {
          font-size: 15px;
        }
      }
    }
  }

  &.fb-login-popup {

    .header{

      .left-wrap {
        margin-right: 60px;
      }
      
      @include mobile { 
        border: none;  
      }

      @include desktop {
        padding-bottom: 22px;
      }

    }
   
    .content{
      padding:10px 26px 27px 26px;
      .title{
        @include font-size(25);
        margin-bottom: 10px !important;
        padding-left: 11px;

        @include mobile {
          padding-left: 0px;
        }
      }

      .sub-text{
        @include font-size(15);
        font-weight: $regular;
        letter-spacing: 0px;
        padding-left: 11px;

        @include mobile {
          padding-left: 0px;
        }
      }

      .input-wrap{

        .md-grid{
          display: block;
          text-align: center;
          .img{
            border: 2px solid #ffffff;
            border-radius: 50%;
            height: 120px;
            margin: 20px 0 10px 0;
            width: 120px;
          }

          .link-text{
            @include font-size(15);
            margin: 0 0 5px 0;
          }

          .user-name{
            @include font-size(15);
            font-weight: $black-bold;
          }
        }

      }

      @include mobile {   
        padding:10px 26px 6px 26px;
      }
    }

    .btn-wrap{
      .btn-facebook{
        background: #3C5899 0% 0% no-repeat padding-box;
        border: 2px solid #3C5899;
        border-radius: 23px;
        color: $white;
        cursor: pointer;
        height: 45px;
        width: 327px;
        opacity: 1;
        padding: 0px 30px;

        .img{
          float: left;
          opacity: 1;
          width: 10px;
        }
      }
      
      @include mobile { 
        border: none;  
        position: relative;
      }
    }
    
  }

  &.google-login-popup {

    .header{
      @include mobile { 
        border: none;  
      }

      @include desktop {
        padding-bottom: 22px;
      }

    }
   
    .content{
      padding:10px 38px 0px 38px;

      .input-wrap{

        .md-grid{
          display: block;
          text-align: center;
          .img{
            border: 2px solid #ffffff;
            border-radius: 50%;
            width: 120px;
            height: 120px;
            margin: 20px 0 10px 0;
          }

          .link-text{
            @include font-size(15);
            margin: 0 0 5px 0;
          }

          .user-name{
            @include font-size(15);
            font-weight: $black-bold;
          }
        }

      }

      .sub-text{
        @include font-size(15);
        font-weight: $regular;
        letter-spacing: 0px;
      }

      .title{
        @include font-size(25);
        margin-bottom: 10px !important;
      }

      @include mobile {   
        padding:10px 26px 6px 26px;
      }
    }
    
    .btn-wrap{
      .btn-google{
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #C6C6C6;
        border-radius: 23px;
        cursor: pointer;
        width: 327px;
        height: 45px;
        opacity: 1;
        padding: 0px 30px;
  
        .img{
          float: left;
          width: 20px;
          opacity: 1;
        }
      }

      @include mobile { 
        border: none;  
        padding-top: 0px;
        position: relative;
      }
    }
  }

  &.password-verification {

    @include mobile {
      z-index: 11;
    }

    @include tablet-portrait {
      z-index: 11;
    }
    
    .md-paper {
  
      .md-dialog-content {
        width: 100%;
  
        @include desktop {
          width: 356px !important;
        }

        .content {
          padding: 8px 36px 20px 35px;

          .input-wrap {

            .md-text-field {
              width: 100%;
            }

            .md-password-btn {
              right: 20px;
              top: 24px;
            }
          }

          .link-text {
            @include font-size(14);
            letter-spacing: 0px;
            margin-bottom: 0px;
          }
        }

        .btn-wrap {
          padding: 0px 36px 20px 36px;
          
          .btn-signup {
            margin: 20px 0px 0px 0px;
          }
        }

        .header {
          padding: 25px 30px 12px 30px;
        }
      }
  
      @include desktop {
        width: 356px !important;
        border-radius: 24px;
      }
    }
  
  }

  &.reset-password {
    .btn-wrap {
      @include desktop {
        border-top: 1px solid $gray !important;
      }
    }
    .content {

      @include mobile {
        &.custom-content {
          height: 520px;
        }
      }
      .input-wrap {
        .md-grid {
          .md-password-btn {
            left: 286px;
            position: absolute;
            top: 24px;
          }
          .md-text-field {
            width: 100%;
          }
  
        }
      }
    }

    .header {
      justify-content: flex-end;
    }
  }

  &.sms-verification {
    .md-paper {
      border-radius: 10px;
      height: auto !important;
      width: 335px !important;
  
      .md-dialog-content {
        width: 100%;
  
        @include desktop {
          width: 356px !important;
        }

        .content {
          padding: 0px 36px 30px 36px;
          text-align: center;

          .input-wrap {

            .sms-input-grid {
              justify-content: space-between;
              padding: 25px 25px 0px 25px !important;

              .input-cell {
                border-top: none;
                border-left: none;
                border-right: none;
                font-size: 35px;
                outline: none;
                text-align-last: center;
                width: 45px;

                &:focus {
                  border-top: none;
                  border-left: none;
                  border-right: none;
                }
              }
            }

            .md-text-field {
              width: 100%;
            }

            .md-password-btn {
              right: 20px;
              top: 24px;
            }
          }

          .link-text {
            margin-bottom: 0px;
            padding: 0px 22px;
          }

          .mobile-number{
            @include font-size(20);
            color: $bright-blue;
            font-weight: $bold;
          }

          .title {
            @include font-size(20);
          }

          @include mobile {
            padding: 40px 25px 30px 25px;
          }

          @include tablet-portrait {
            padding: 40px 25px 30px 25px;
          }
        }

        .btn-wrap {
          border-top: 0.5px solid $gray;
          border-radius: 10px;
          padding: 0px 26px 22px 26px;
          position: relative;

          .btn-signup {
            margin: 11px 0px 0px 0px;
          }

          @include desktop {
            padding: 0px 35px 22px 35px;
          }

        }

        .link-resend-code {
          font-weight: $black-bold;
          position: relative;
          top: -12px;
        }
        .header {

          @include mobile {
            display: none;
          }

          @include tablet-portrait {
            display: none;
          }
        }
      }
  
      @include desktop {
        border-radius: 24px;
        width: 356px !important;
      }
    }
  
  }

  &.social-media-popup {
    .content {
      color: $black;
      @include font-size(14);
      text-align: center;
      
      .button-wrap {
        .btn {
          border: 1px solid #E2E2E2;
          border-radius: 23px;
          color: $white;
          cursor: pointer;
          margin-bottom: 15px;
          outline: none;
          padding: 12px 25px;
          width: 100%;

          .img {
            float: left;
            height: 18px;
            width: 15px;
          }

          &.btn-email {
            background: #29ABE2;
          }

          &.btn-google {
            background: #E94335;
          }

          &.btn-fb {
            background: #3C5899;
          }
        }

        .link-text {
          font-weight: $bold;
          margin-bottom: 0px;
          padding-top: 15px;
          text-align: center;

          .signup-link {
            color: $ms-blue;
            text-transform: uppercase;
          }
        }

        .signup-link {
          color: $black;
          text-decoration: underline;
        }

        .text {
          color: $black;
        }
      }
    } 

    .header {
      border-bottom: 1px solid $gray;

      .title-text {
        color: $black;
        @include font-size(16);
        margin-left: 140px;
      }
    }
  }

}
