.navigation-section {
  position: relative;

  .blur-menu-wrapper {
    .popup-wrap {
      .popup-content {
        box-shadow: 0px 1px 6px #00000029;
        &:before {
          display: none;
        }
      }

      .btn {
        @include mobile {
          padding: 0;
        }
      }
    }
  }

  &.navigation-wrap {
    background-color: #EFF5F6;
    border-bottom: none;
    padding: 11px 20px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 11;

    @include desktop {
      padding: 18px 25px;
      z-index: 5;
    }
    .left-wrap {
      display: flex;
      float: left;

      .country-select-wrap {
        margin: 0 0 0 20px;

        .md-btn {
          color: $black;
          font-size: 15px;
          padding-left: 0px;
          text-transform: capitalize;

          &.md-btn--color-primary-active {
            background-color: transparent;
          }

          .md-ink-container  {
            .md-ink--active {
              background-color: transparent;
            }            
          }
          
          .img {
            height: 20px;
            margin-right: 8px;
            width: 20px;
          }
   
          @include mobile {
            margin: 0px;
            padding: 0px;
            text-align: left;
          }
        }

        .md-list {
          border-radius: 20px;
          box-shadow: 0px 1px 6px #00000029;
          margin-top: 55px;
          margin-left: -5px;
          padding: 0px;

          @include mobile {
            margin-top: 50px;
          }
       
          .md-list-item {
            border-bottom: 1px solid $light-gray;

            .md-list-tile {
              height: 54px;
              padding-left: 20px;

              &:hover{
                background-color: #EFF5F6;
              }

              .title {
                text-align: left;
                width: 165px;
              }
            }
          }

          .img {
            height: 16px;
            margin-right: 10px;
            width: 16px;
          }
        }

      }

      .img-logo {
        cursor: pointer;
        height: auto;
        max-width: 100%;
        width: 66px;
        display: inline-block;
        padding-top: 4px;


        @include mobile {
          height: 30px;
          width: auto;
        }
      }
    }

    .middle-wrap {
      color: $white;
      display: inline-block;
      left: 45.3%;
      position: absolute;
      vertical-align: -webkit-baseline-middle; 

      @include tablet-portrait {
        left: 42%;
      }

      @include tablet-landscape {
        left: 44%;
      }

      @include exlarge-desktop {
        left: 46.7%;
      }

      &.web {
        @include mobile {
          display: none;  
        }
      }

      .booking-text-wrap {
        display: inline-grid;

        .bold-text {
          font-weight: $black-bold;
          @include font-size(20);
          line-height: 18px;
          text-align: left;
        }

        .booking-text {
          font-weight: $semi-bold;
          @include font-size(11);
        }
      }

      .badge-icon {
        margin-right: 10px;
        vertical-align: -webkit-baseline-middle;
      }
    }

    .right-wrap {
      float: right;

      .navigation-list {
        @include flex-row;
        align-items: center;
        
        .navigation-list-items {
          color: $white;
          display: inline-block;
          padding-bottom: 3px;
          @include font-size(15);
          margin-right: 9px;

          @include desktop {
            margin-right: 11px;
          }

          &:last-child {
            margin-right: 0;
          }

          .btn-white-outline {
            border: 1px solid $white;
            border-radius: 18px;
            color: $white;
            height: 35px;
            padding: 6px 12px;
            max-width: 121px;
          }

          &.is-mobile {
            display: inline-block;

            @include desktop {
              display: none;
            }
          }

          &.is-web {
            display: none;

            @include desktop {
              display: inline-block;
            }
          }

          .profile-img {
            @include background-property();
            border-radius: 50%;
            display: inline-block;
            height: 28px;
            margin-top: 3px;
            vertical-align: middle;
            width: 28px;
          }

          &.navigator-burger {
            .icon {
              height: 14px;
              vertical-align: middle;
            }
          }

          .menu-wrap {
            color: $white;
          }

          .link-text {
            margin: 0;
            @include font-size(15);

            a {
              color: #006dd1;
            }

            .link {
              font-weight: $black-bold;
              cursor: pointer;
            }
          }
        }

        .btn-admin {
          border: none;
          padding-bottom: 0;
          background-color: transparent;
          vertical-align: middle;

          &.active {
            color: $bright-blue !important;
            font-weight: $extra-bold;
            border-bottom: 3px solid $bright-blue;
          }

          @include mobile {
            display: none;
          }
        }

        .btn-host {
          border: none;
          padding-bottom: 0;
          background-color: transparent;
          vertical-align: middle;

          &.active {
            color: #128489 !important;
            font-weight: $extra-bold;
            border-bottom: 3px solid #128489;
          }

          @include mobile {
            display: none;
          }
        }

        .btn-outline {
          border: none;
          border-radius: 8px;
          color: $black;
          height: 35px;
          margin-right: 7px;
          opacity: 1;
          padding: 7px !important;
          text-transform: none !important; 

          @include mobile {
            display: none;
          }

          @include tablet-portrait {
            display: none;
          }
        }

        .btn-help {
          border-radius:10px;
          color: $black;
          margin-right: 7px;
          padding: 7px;
          
          @include mobile {
            display: none;
          }
        }

        .btn-host {
          .btn-help {
            color: $black !important;
            margin-bottom: 2px;
   
            .md-ink-container  {
              .md-ink--active {
                background-color: transparent;
              }            
            }
          }
        }

        .md-ink-container {
          span {
            background: none;
          }
        }
      }

      @include mobile {
        margin-top: 4px;
      }
    }

    .hidden-search-wrap {
      display: none;
    }
  }

  .login-popover {
    margin-top: 0px;   
    margin-bottom: 20px;

    .overflow-wrapper {
      max-height: calc(100vh - 190px);
      margin-top:10px;
      overflow-y: auto;
    }

    &.auth {
      .btn-logout {
        background: transparent;
        border: none;
        color: $black;
        width: 100%;
        @include font-size(15);
        padding-left: 0px;
        text-align: left;

        &:hover{
          background-color:#EFF5F6;
          border-radius: 8px;
        }
        
        .img {
          margin-right: 18px;
        }
      }

      
      &.green {
        &::before {
          border-bottom: 12px solid $white;
        }

        .profile-img-wrap {
          background: $white;
          color: $white;
        }
      }

      .profile-img-wrap {
        border-radius: 20px;
        @include flex-row;
        justify-content: flex-start;
        align-items: center;
        color: $white;
        padding: 16px 30px 10px 30px;
        text-align: left;
        background: $white;
        border-radius: 20px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        flex-wrap: nowrap;
        height: 130px;
      
        @include desktop {
          height: 65px;
        }

        @include mobile {
          border-radius: 32px;
        }

        .circle-img {
          @include background-property();
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 12px;
        }

        img {
          padding-right: 12px;
        }

        .name {
          display: block;
          @include font-size(15);
          font-weight: $extra-bold;

          @include mobile {
            @include font-size(20);
          }
        }

        .link {
          display: block;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .list {
        .list-item {
          cursor: pointer;

          .notification {
            @include font-size(13);
            background: $bright-blue;
            border-radius: 50%;
            color: $white;
            display: inline-block;
            height: 20px;
            line-height: 20px;
            margin-left: 10px;
            text-align: center;
            width: 20px;
          }
          &.item {
            @include flex-row();
            align-items: baseline;
          }
        }
        &.button-wrap {
          padding-top: 0px;
          padding-bottom: 0px;
          margin-top:20px;
        }

        &.host-wrap {
          padding-top: 0px;
          padding-bottom: 0px;
          margin-top:0px;

          
        }

        &.button-log-wrap {
          margin: 5px 10px 0px 10px;
          padding: 5px 10px 5px 10px;

          &:hover{
          background-color: #EFF5F6;
          border-radius: 8px;
          
          }
        }
      }

      .sub-title {
        margin-bottom: 17px;
      }
      &:before {
        display: none;
        content: '';
        position: absolute;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid $white;

        @include desktop {
          right: 31px;
        }
      }
    }

    .list {
      border-bottom: 1px solid $gray;
      @include font-size(14);

      @include mobile {
        margin-bottom: 40px;
      }

      @include tablet-portrait {
        margin-bottom: 40px;
      }

      &.host-btn-wrap {
        padding-top: 0;
        padding-bottom: 0;

        .become-host {
          margin: 20px 0;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      .sub-title {
        color: rgba($black, $alpha: 0.35);
        text-transform: uppercase;
        @include font-size(12);
        font-weight: $black-bold;
        display: block;
        text-align: left !important;
      }

      .list-item {
        color: $black;
        cursor: pointer;
        display: block;
        @include font-size(15);
        margin: 5px 10px 5px 10px;
        padding: 5px 10px 5px 10px;
        text-align: left;

        &:hover {
          background-color: #EFF5F6;
          border-radius: 8px;
        }

        img {
          width: 13px;
          height: 13px;
          margin-right: 18px;
        }
      }

      .btn {
        width: 100%;
        border-radius: 23px;
        height: 45px;
        box-shadow: none;
        margin: 10px 0;
        padding: 0 20px;
        font-weight: 600;
        @include font-size(15);
        outline: none;
        cursor: pointer;
        vertical-align: middle;
        margin-bottom: 15px;
        margin-top: 0;

        .img {
          margin-right: 10px;
          width: 20px;
          height: 20px;
          float: left;
        }

        &.become-host {
          text-transform: uppercase;
          color: $white;
          background-color: #20ae8f;
          border: 2px solid #20ae8f;
          cursor: pointer;
        }

        &.btn-google {
          color: $black;
          background-color: transparent;
          border: 1px solid #d8d8d9;
          cursor: pointer;
        }

        &.btn-fb {
          color: $white;
          background-color: #3c5899;
          border: 2px solid #3c5899;
          cursor: pointer;
        }

        &.btn-email {
          color: $black;
          background-color: transparent;
          border: 1px solid #d8d8d9;
        }
      }

      &.button-wrap {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top:20px
      }
      &.currency-wrap {
        cursor: pointer;
        padding-top: 20px;
        color: $black;
        text-align: left;

        .text-uppercase {
          text-transform: uppercase;
          font-weight: $extra-bold;
        }
      }
    }
  }

  &.white-bg-navbar {
    background-color: $white;
    border-bottom: 1px solid #D9D9D9;   
    z-index: 11;
    transition: left 300ms linear;
    padding: 18px 25px;

    .search-icon {
      height: 22px;
      margin-bottom: 0;
      opacity: 1;
      position: absolute;
      left: 37px;
      top: 13px !important;
      z-index: 10;

      @include mobile {
        top: 17px !important;
      }
    }

    .hidden-search-wrap {
      display: block;
      padding: 0;
    }

    .left-wrap {
      float: none;

      @include desktop {
        float: left;
      }

      .country-select-wrap {
        background-color: #EFF5F6;
        border-radius: 23px;
        height: 35px;
        margin: 0 13px;
        width: 35px;

        .md-btn {
          min-width: 35px;
          padding: 6px 7px;
          text-align: left;

          .img {
            margin-right: 0px;
          }

          @include tablet-landscape {
            margin-top: -1px;
            padding: 0px 7px;
          }
        }
      }

      .white-logo {
        display: none;
      }
    }

    @include mobile {
      height: 71px;
      padding: 10px 20px;

      .left-wrap {
        float: left;
        padding-top: 5px;
      }
      .img-logo {
        display: none;
      }

      .navigation-list {
        display: block;
        padding-top: 12px;
      }
    }

    @include tablet-portrait {
      padding: 20px 24px;

      .left-wrap {
        float: left;
      }

      .img-logo {
        display: none;
      }

      .navigation-list {
        display: block;
      }
    }

    .navigation-list-items {
      color: $black !important;
      position: relative;

      .btn {
        z-index: 10;
      }
    }

    .menu-wrap {
      color: $black !important;
    }

    .btn-white-outline {
      color: $black !important;
      border: 1px solid $black !important;
    }

    .btn-teal-outline {
      border-radius: 18px;
      border: 1px solid $host-green !important;
      color: $host-green !important;
      height: 35px;
      margin-right: 15px;
      padding: 0 !important;
      text-transform: none !important;
      width: 121px;
    }

    .search-bar-wrap {

      @include mobile {
        top: 0px;
      }
      
      @include tablet-portrait {
        top: 0px;
      }

      .search-bar-inner-wrap {
        background: #EFF5F6 0% 0% no-repeat padding-box;
        border-radius: 60px;
        box-shadow: none;
        height: 50px;
        margin-left: 27px;
        opacity: 1;
        padding: 7px 7px 7px 15px;
        width: 720px;

        .ant-select-selection {
          background: #EFF5F6;

          @include mobile {
            background: transparent;
          }

          @include tablet-portrait {
            background: transparent;
          }
        }

        .activity-wrap {
          @include desktop {
            width: 196px;
          }

          .ant-select-selection {
            background-color: unset;
          }

          .activity-select {
            @include desktop {
              min-width: 196px;
            }
          }
        }

        .date-block {
          margin-top: 0;
          
          @include desktop {
            width: 27%;
          }

          .md-picker {
            @include desktop {
              left: 15px;
            }

            @include tablet-landscape {
              left: 15px;
            }
          }
        }

        @include mobile {
          background: #EFF5F6;
          height: 45px;
          margin-left: 0px;
          padding-left: 20px;
          width: 100%;
        }

        @include tablet-portrait {
          background: #EFF5F6;
          height: 50px;
          margin-left: 0px;
          padding-left: 20px;
          width: 100%;
        }

        @include tablet-landscape {
          width: 620px;
        }

        .custom-img {
          width: 18px;
        }

        .search-location-wrap {
          width: 235px;

          @include mobile {
            width: 100%;
          }

          @include tablet-portrait {
            width: 100%;
          }
        }
      }

      .search-input {
        background-color: #f2f3f2;
        border-radius: 23px;
        border: none !important;
        box-shadow: none !important;
        color: $black !important;
        height: 45px !important;
        width: 100% !important;
        padding: 8px 20px;
        margin-top: 0;
        display: flex;
        align-items: center;
        padding-left: 53px;

        .md-text-field {
          @include font-size(18);
          font-weight: 800;
          color: $black;

          &::placeholder {
            color: rgba(0, 0, 0, 0.25);
          }

          @include mobile {
            @include font-size(16);
          }
        }

        .md-text-field--margin {
          @include mobile {
            margin-top: 0 !important;
          }

          @include tablet-portrait {
            margin-top: 0 !important;
            height: 22px;
          }

          @include desktop {
            margin-top: 0;
            height: 22px;
          }
        }

        .md-divider {
          display: none;
        }

        @include tablet-landscape {
          width: 100% !important;
          margin: 0 auto;
          border-radius: 60px;
          box-shadow: 0px 2px 6px #00000029;
          margin-top: 65px;
          height: 55px;

          &.search-input-focus {
            padding-right: 137px;
          }
        }

        @include desktop {
          width: 100%;
          height: 55px;
          margin: 0 auto;
          border-radius: 60px;
          box-shadow: 0px 2px 6px #00000029;

          &.search-input-focus {
            padding-right: 137px;
          }
        }

        .md-text-field-icon-container {
          @include mobile {
            align-items: center;
            height: 32px;
          }

          @include tablet-portrait {
            height: 40px;
            align-items: center;
          }

          @include desktop {
            height: 40px;
            align-items: center;
          }

          .search-icon {
            height: 22px;
            margin-bottom: 0;
            opacity: 0.25;

            @include mobile {
              width: 11px;
              height: 11px;
            }
          }

          .md-text-field-divider-container {
            @include mobile {
              margin-left: 10px;
            }
          }
        }
      }

      .close-icon {
        margin-bottom: 15px;
        right: 20px;
        position: absolute;
        top: 35px;

        @include tablet-portrait {
          right: 35px;
        }

        @include mobile {
          top: 35px;
        }
      }

      .autocomplete-content {
        width: 100%;
        border-radius: 24px;
        margin: 0 auto;
        margin-top: 0px;
        padding: 20px 5px;
        padding-bottom: 20px;
        background-color: $white;
        z-index: 100;
        text-align: left;

        @include tablet-portrait {
          display: none;
          border-top: 1px solid $light-gray;
          padding-top: 20px;
          margin-top: 10px;
          border-radius: 0;
        }

        @include mobile {
          display: none;
          border-top: 1px solid $light-gray;
          padding-top: 20px;
          margin-top: 10px;
          border-radius: 0;
        }

        .wrap {
          @include tablet-portrait {
            margin: 0 15px;
          }

          @include mobile {
            margin: 0 15px;
          }

          .list {
            margin-bottom: 18px;

            .title {
              color: $black;
              @include font-size(13);
              font-weight: $black-bold;
              margin: 0 6px;
              text-transform: uppercase;
            }

            .list-item {
              border: 1px solid #d8d8d9;
              border-radius: 100px;
              background-color: $white;
              display: inline-block;
              color: $black;
              cursor: pointer;
              height: 40px;
              margin: 3px;
              padding: 9px 20px;

              &.active {
                background-color: $bright-blue;
                border: $bright-blue;
                color: $white;

                .img {
                  filter: invert(0);
                }
              }

              .img {
                margin-right: 5px;
                width: 15px;
                height: 15px;
                margin-bottom: 2px;
                filter: invert(1);
              }
            }

            &:last-child() {
              margin-bottom: 0px;
            }
          }
        }

        @include tablet-landscape {
          width: 100% !important;
          padding: 20px 15px;
          padding-bottom: 20px;
        }

        @include desktop {
          width: 100%;
          padding: 20px 15px;
          padding-bottom: 20px;
        }
      }

      // .autocomplete-content {
      //   @include mobile {
      //     display: none;
      //   }

      //   @include tablet-portrait {
      //     display: none;
      //   }
      // }

      #search-menu-list {
        margin: 0 auto;
        background-color: $white;
        z-index: 100;
        text-align: left;
        margin-top: 1px;
        box-shadow: none;

        .md-list-item {
          padding: 0 3px;
          background-color: transparent !important;
          @include font-size(15);
          color: $black;

          .md-tile-content {
            .md-text {
              &:before {
                content: '';
                width: 12px;
                height: 16px;
                background: url('../images/common/location.svg') no-repeat;
                margin-right: 10px;
                float: left;
              }
            }
          }

          &:active {
            background-color: red;
          }
        }

        @include desktop {
          border-radius: 32px;
          box-shadow: inherit;
        }
      }

      .search-btn {
        width: 81px;
        height: 40px;
        border-radius: 20px;
        padding: 11px 6px;
        cursor: pointer;
        display: block;
        color: $black;
        background-color: $pink;
        text-align: center;
        @include font-size(13);
        font-weight: $extra-bold;
        text-transform: uppercase;

        .img {
          height: 14px;
          width: 14px;
        }

        .btn-text {
          color: $white;
          font-size: 12px;
          font-weight: $extra-bold;
        }

        @include mobile {
          display: none;
        }

        @include tablet-portrait {
          right: 0.5% !important;
          position: absolute;
          top: 8px;
          width: 119px;
        }
      }

      @include desktop {
        padding: 0;
        top: 13px;
        right: inherit;
        left: 120px;
        width: 30%;
        margin: 0 auto;
        outline: none;
        position: absolute;
      }

      @include tablet-landscape {
        z-index: 1;
      }
    }
    .space-popup-modal {
      .modal {
        padding: 20px 10px;

        @include tablet-portrait {
          padding: 20px 0;
          text-align: left;
        }
        @include mobile {
          padding: 20px 0;
          text-align: left;
        }

        &:focus {
          outline: none;
        }

        .search-input {
          @include tablet-portrait {
            margin: 0 10px;
          }
          @include mobile {
            margin: 0 10px;
          }
        }
      }

      .search-btn-wrap {
        @include mobile {
          position: fixed;
          background-color: $white;
          width: 100%;
          left: 0;
          right: 0;
          padding: 15px 20px;
          z-index: 1;
          bottom: 0;
          border-top: 1px solid $light-gray;
        }

        @include tablet-portrait {
          position: fixed;
          background-color: $white;
          width: 100%;
          left: 0;
          right: 0;
          padding: 15px 20px;
          z-index: 1;
          bottom: 35px;
          border-top: 1px solid $light-gray;
        }

        .search-btn {
          display: block !important;
        }
      }

      .search-btn {
        width: 100%;
        height: 45px;
        border-radius: 23px;
        padding: 15px;
        cursor: pointer;
        display: block;
        color: $white;
        background-color: $pink;
        text-align: center;
        @include font-size(13);
        font-weight: $extra-bold;
        text-transform: uppercase;

        @include desktop {
          right: 1% !important;
          position: absolute;
          top: 8px;
          height: 40px;
          width: 119px;
        }

        @include tablet-portrait {
          margin: 10px;
          right: 6% !important;
          width: 85%;
        }

        @include tablet-landscape {
          position: absolute;
          right: 1.5%;
          top: 8px;
          width: 119px;
          height: 40px;
        }
      }
      .search-input {
        background-color: $white;
        border-radius: 60px;
        box-shadow: 0px 2px 6px #00000029;
        height: 55px;
        margin-top: 30px;
        padding: 5px 20px;
        padding-left: 53px;

        &.modal-input {
          width: 85% !important;
          margin-right: 10px;
          display: inline-block;
          border: 1px solid rgba($color: #404146, $alpha: 0.2) !important;
          box-shadow: none;
          margin-top: 0;

          @include mobile {
            margin: 0 20px;
            margin-right: 40px;
            padding: 8px 20px;
            width: 80% !important;
          }

          @include tablet-portrait {
            margin: 0 20px;
            width: 85%;
          }
        }

        .md-text-field {
          @include font-size(18);

          @include mobile {
            @include font-size(16);
          }
        }

        .md-text-field--margin {
          @include mobile {
            margin-top: 0;
          }

          @include desktop {
            margin-top: 14px;
          }
        }

        .md-divider {
          display: none;
        }

        @include tablet-landscape {
          width: 100% !important;
          margin: 0 auto;
          border-radius: 60px;
          box-shadow: 0px 2px 6px #00000029;
          margin-top: 55px;

          &.search-input-focus {
            padding-right: 137px;
          }
        }

        @include desktop {
          width: 100%;
          margin: 0 auto;
          border-radius: 60px;
          box-shadow: 0px 2px 6px #00000029;
          margin-top: 41px;

          &.search-input-focus {
            padding-right: 137px;
          }
        }

        .md-text-field-icon-container {
          @include mobile {
            align-items: center;
          }

          .search-icon {
            height: 22px;
            margin-bottom: 0;
            opacity: 0.25;

            @include mobile {
              width: 11px;
              height: 11px;
            }

            @include tablet-portrait {
              top: 38px !important;
              left: 40px !important;
            }

            @include mobile {
              top: 38px !important;
              left: 40px !important;
            }
          }

          .md-text-field-divider-container {
            @include mobile {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .spaces-wrap {
    @include mobile {
      .search-icon {
        left: 21px;
        top: 18px;
      }
    }

    @include tablet-portrait {
      .search-icon {
        left: 19px;
      }
    }
  }
}

.section-divider {
  border-bottom: 0.5px solid rgba(112, 112, 112, 0.2);
  padding-top: 15px;
  margin: 0 -15px;
}

.full-width-popup {
  &.mobile-login-popup {
    .md-paper {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;

      .login-popover {
        margin-bottom: 20px;

        .header-sec-wrapper {
          text-align: right;
          margin-right:15px;
        }

        .list {
          border-bottom: 1px solid $gray;
          padding:15px 0px 0px 0px;

          &.host-btn-wrap {
            padding-top: 0;
            padding-bottom: 0;

            .become-host {
              margin: 20px 0;
            }
          }

          &:last-child {
            border-bottom: none;
          }

          .link-text {
            .link {
              font-weight: $black-bold;
              cursor: pointer;
            }
          }

          .sub-title {
            color: rgba($black, $alpha: 0.35);
            text-transform: uppercase;
            @include font-size(12);
            font-weight: $black-bold;
            display: block;
            text-align: left !important;
          }

          .list-item {
            color: $black;
            display: block;
            margin: 0px 15px;
            padding: 5px 10px 5px 10px;
            text-align: left;
            font-size: 15px;

            &:hover{
              background-color:#EFF5F6;
              border-radius: 8px;
            }

            img {
              width: 14px;
              height: 13px;
              margin-right: 18px;
            }
          }

          .btn {
            width: 100%;
            border-radius: 23px;
            height: 45px;
            box-shadow: none;
            margin: 10px 0;
            padding: 0 20px;
            font-weight: 600;
            @include font-size(15);
            outline: none;
            cursor: pointer;
            vertical-align: middle;
            margin-bottom: 15px;
            margin-top: 0;

            .img {
              margin-right: 10px;
              width: 20px;
              height: 20px;
              float: left;
            }

            &.become-host {
              text-transform: uppercase;
              color: $white;
              background-color: #20ae8f;
              border: 2px solid #20ae8f;
              cursor: pointer;
            }

            &.btn-google {
              color: $black;
              background-color: transparent;
              border: 1px solid #d8d8d9;
              cursor: pointer;
            }

            &.btn-fb {
              color: $white;
              background-color: #3c5899;
              border: 2px solid #3c5899;
              cursor: pointer;
            }

            &.btn-email {
              color: $black;
              background-color: transparent;
              border: 1px solid #d8d8d9;
            }
          }

          &.button-wrap {
            padding-top: 20px;
            padding-bottom:15px;

            .btn {
              margin-bottom: 15px;
            }
          }
          &.currency-wrap {
            cursor: pointer;
            padding-top: 20px;
            color: $black;
            text-align: left;

            .text-uppercase {
              text-transform: uppercase;
              font-weight: $extra-bold;
            }

            @include mobile {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .md-dialog-content--padded {
      padding: 0px;
    }

    .close-icon-wrap {
      padding: 8px;
    }
}
  &.auth-mobile {
    .md-dialog-content--padded {
      padding: 0;

      .close {
        height: 70px;
        filter: invert(1);
        padding: 23px 22px;
        position:absolute;
        right: 0;
        text-align: right;
        z-index: 4;

      }

      .profile-img-wrap {
        border-radius: 0px;
       

        .circle-img {
          @include background-property();
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 15px;
        }
      }

      .list {
        &.button-wrap {
          margin-bottom: 0px;
          margin-top:0px;
          padding:10px 0px 5px 0px;
        
        }

        &.host-wrap {
          margin-bottom: 0;
          padding:0px 0px 10px 0px;
        }

        &.button-log-wrap {
          font-size: 15px;
          padding:5px 15px 5px 15px;
        }
      }
    }
  }
}

.currency-picker {
  .md-dialog {
    border-radius: 24px;
    max-width: 300px;
    width: 100%;

    .currency-picker-wrapper {
      color: $black;
      padding-bottom: 10px;
      position: relative;

      .img {
        position: absolute;
        right: 0;
      }

      .title {
        @include font-size(18);
        font-weight: $extra-bold;
        margin-bottom: 15px;
        padding-top: 10px;
        text-align: center;
      }

      .btn {
        background: $white;
        border: 0.5px solid #dddddd;
        border-radius: 29px;
        height: 45px;
        margin: 5px 0;
        max-width: 250px;
        padding: 12px 20px;
        width: 100%;

        &:focus {
          outline: none;
        }

        &.active {
          background: #dddddd;
        }

        .currency-selector {
          display: flex;
          justify-content: space-between;

          .text-uppercase {
            text-transform: uppercase;
            font-weight: $extra-bold;
          }
        }
      }
    }
  }
}

.email-confirm-popup {
  .close-btn {
    cursor: pointer;
    padding-bottom: 0;
    text-align: right;

    @include tablet-portrait {
      padding: 25px 20px;
    }

    @include mobile {
      padding: 25px 20px;
    }

    @include desktop {
      position: absolute;
      right: 25px;
      top: 25px;
    }
  }
  .bigger-font {
    font-size: 25px !important;
    text-align: left !important;
    margin-bottom: 20px;
  }
  .partitioned-input {
    padding-left: 10px;
    letter-spacing: 44px;
    border: 0;
    background-image: linear-gradient(to left, black 75%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 67px 1px;
    background-repeat: repeat-x;
    background-position-x: 40px;
    width: 290px;
    min-width: 290px;
    padding-right: 10px;
    @include font-size(35);

    &:focus {
      outline: none;
    }
  }

  .inner-div {
    left: 0;
    position: sticky;
  }

  .outer-div {
    width: 240px;
    overflow: hidden;
    margin: 0 auto;
    padding-top: 50px;
  }

  .md-dialog {
    @include mobile {
      width: 100% !important;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
    }

    @include tablet-portrait {
      width: 100% !important;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
    }

    @include desktop {
      max-width: 402px;
      width: 100%;
      border-radius: 24px;
    }
    .md-dialog-content {
      max-height: unset !important;
      padding: 0;
      @include desktop {
        padding: 40px 68px 60px 68px;
      }

      .form-wrapper {
        @include font-size(15);

        .blue-text {
          color: $bright-blue;
          display: block;
          @include font-size(20);
          font-weight: 700;
        }

        .main-title {
          @include font-size(25);
          width: 100%;
          text-align: left;
          margin-top: 30px;
          font-weight: $black-bold;

          @include desktop {
            margin-top: 0;
          }
        }

        .md-divider {
          display: none;
        }

        &.email-confirm-popup-inner {
          text-align: center;
          padding-top: 20px;

          @include mobile {
            border-top: 0.5px solid rgba(0, 0, 0, 0.5);
            padding: 25px 55px;
          }

          @include tablet-portrait {
            border-top: 0.5px solid rgba(0, 0, 0, 0.5);
            padding: 25px;
          }

          @include desktop {
            border-top: none;
          }

          .section-divider {
            border-bottom: 0.5px solid $black-op-50;
            padding-top: 0;

            @include desktop {
              display: none;
            }
          }
          .main-title {
            text-align: center;
            @include font-size(20);
          }

          .img {
            padding-bottom: 30px;

            @include mobile {
              padding-top: 40px;
            }
            @include tablet-portrait {
              padding-top: 40px;
            }
          }

          .description {
            margin-bottom: 30px;
            @include font-size(15);
            color: $black;
          }

          .payment-method-link {
            color: $bright-blue;
            font-weight: $bold;
          }

          .success-message-wrap {
            display: flex;

            .img-success {
              height: 25px;
              margin-top: 6px;
              width: 25px;
            }

            .success {
              @include font-size(13);
              color: $green;
              margin: 0px;
              text-align: left;
              padding-left: 10px;
            }
          }
        }
      }
    }
  }
  &.user-login-popup {
    .md-dialog {
      .md-dialog-content {
        .login-popover {
          .header-sec-wrapper {
            border-bottom: 1px solid #d8d8d9;
            padding: 20px 0 40px 0;
            text-align: right;

            @include desktop {
              border-bottom: unset;
              margin-bottom: 35px;
              padding: 0;
            }

            .close-icon {
              cursor: pointer;
              position: absolute;
              right: 10px;

              @include desktop {
                position: relative;
              }
            }
            .section-divider {
              @include desktop {
                display: none;
              }
            }
          }
          .list {
            &.button-wrap {
              @include mobile {
                padding-top: 20px;
              }

              @include desktop {
                padding: 0;
              }

              .btn {
                max-width: 100%;
                @include desktop {
                  max-width: 306px;
                }
              }
              .link-text {
                .link {
                  font-weight: $extra-bold;
                }
              }
            }
          }
        }
      }
    }
  }
}

.space-popup-modal {
  &.spaces-wrap {
    @include mobile {
      .modal {
        .search-icon {
          top: 38px !important;
        }

        .modal-input {
          padding-left: 37px !important;
        }
      }
    }

    @include tablet-portrait {
      .modal {
        .search-icon {
          top: 33px !important;
        }
      }
    }
  }

  .autocomplete-content {
    @include mobile {
      display: block !important;
    }

    @include tablet-portrait {
      display: block !important;
    }
  }
}
