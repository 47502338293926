.list-space-modal {
  .btn-space {
    font-weight: $extra-bold;
    font-size: 12px;
    height: 45px;
    min-width: 200px;

  }

  .content {
    color: $black;
    font-size: 14px;
  }

  .ant-modal-close-x {
    path {
      color: $black;
    }
  }

  .ant-modal-body {
    color: $black;
    font-size: 14px;
    padding: 24px 41px;
    padding-bottom: 12px;
    text-align: center;
  }

  .ant-modal-footer {
    border-top: none;
    text-align: center;
    padding-bottom: 40px;
  }

  .ant-modal-content {
    border-radius: 24px;
    max-width: 400px;
    margin: 0 auto;
  }

  .ant-modal-title {
    color: $black;
    font-weight: $semi-bold;
    text-align: center;
  }

  .ant-modal-header {
    border-radius: 24px 24px 0 0;
    border-bottom: 0.5px solid rgba(112, 112, 112, 0.2);
  }
}
