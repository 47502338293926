
.review-wrap {
    padding-top: 40px !important;

    .button-wrap{
        text-align: center;

        .btn-more {
            cursor: pointer;   
            background-color: $ms-blue;
            color: $white;
            border-radius: 35px;
            border: 1px solid transparent;
            padding: 0 30px;
            box-shadow: none;
            outline: none;
            @include font-size(13);
            font-weight: $bold;
            height: 35px;
            margin: 0px auto 15px auto;
            text-transform: uppercase;
    
            .arrow-right {
                width: 16px;
                height: 16px;
                margin-left: 6px;
        
                @include desktop {
                    display: inline;
                }
            }
    
        }
    }
    
    .content {
        padding-top: 20px !important;
      
    }
     
    .clearfix {
        overflow: hidden;
    }

    .content-review {
        line-height: 1.3;
        padding-top: 0;

        .date {
            @include font-size(13);
            display: block;
            color: rgba($black,0.5);
            margin-bottom: 12px;
        }

        .img-wrap {
            margin-right: 20px !important;
        }
    }  

    .ant-rate {
        color: #F35050;
        font-size: 14px;
    }

    .rate-review {
        display: block;
    }

    .title {
        float: left;
    }

    .total-rate {
        float: right;
        vertical-align: middle;

        .no-of-people {
            color: rgba($black,0.5);
            margin-right: 2px;
            vertical-align: middle;
        }

        .avg {
            font-weight: $black-bold;
            margin-right: 2px;
            vertical-align: middle;
        }
    }
}