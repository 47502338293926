.covid-msg-outer-wrapper {
  background: $ms-blue;
  margin-left: -25px;
  margin-right: -25px;
  position: relative;
  top: -18px;

  .covid-msg-inner-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 10px 25px 10px 20px;

    @include desktop {
      padding: 10px 25px;
    }

    .left-section {
      display: flex;
      align-items: center;
      width: 90%;

      .covid-icon {
        height: 20px;
      }

      .covid-title {
        @include font-size(15);
        color: $white;
        font-weight: $semi-bold;
        padding: 0 10px;

        @include desktop {
          padding-left: 20px;
          padding-right: 10px;
        }
      }

      .icon-tooltip-wrapper {
        .info-icon {
          height: 15px;
          cursor: pointer;
        }
        .tooltip-wrapper {
          display: none;
          border-radius: 5px;
          color: $white;
          position: absolute;
          background: $ms-blue;
          padding: 25px;
          text-align: left;
          width: 93%;
          word-break: break-word;
          top: 55px;
          left: 15px;
          z-index: 10;

          .yellow-text {
            color: #ffdd57;
          }

          @include desktop {
            left: 70px;
            width: 53%;
          }

          @include tablet-landscape {
            left: 70px;
            width: 53%;
          }

          @include tablet-portrait {
            left: 70px;
            width: 80%;
          }

          &:before {
            content: '';
            position: absolute;
            top: -13px;
            left: 218px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 13px solid $ms-blue;

            @include desktop {
              left: 165px;
            }

            @include tablet-landscape {
              left: 165px;
            }

            @include tablet-portrait {
              left: 165px;
            }
          }
        }
        &:hover {
          .tooltip-wrapper {
            display: block;
          }
        }
      }
    }

    .right-section {
      .close-icon {
        cursor: pointer;
        height: 9px;
        width: 9px;

        @include desktop {
          margin-right: 10px;
        }
      }
    }
  }
}

.covid-msg-popup {
  .md-dialog {
    border-radius: 23px;
    height: 482px;
    width: 340px;
    min-width: 340px;

    .md-dialog-content {
      padding-top: 25px;
      padding: 0;

      .btn-wrap {
        border-top: 1px solid $gray;
        padding: 15px 40px;

        .btn-covid-msg {
          background: $white;
          border: 1px solid $pink;
          border-radius: 25px;
          color: $pink;
          font-weight: $bold;
          height: 45px;
          @include font-size(13);
          text-transform: uppercase;
          width: 240px;
        }
      }

      .content {
        color: $black;
        @include font-size(15);
        line-height: 25px;
        padding: 20px 40px;

        @include desktop {
          line-height: 24px;
          padding: 25px 40px;
        }
      }

      .header {
        border-bottom: 1px solid $gray;
        display: flex;
        justify-content: flex-end;
        padding: 20px 30px;

        .close-icon {
          height: 10px;
          width: 10px;
        }

        .covid-icon {
          height: 19px;
          margin-right: 10px;
          width: 19px;
        }

        .title {
          color: $pink;
          font-weight: $semi-bold;
          @include font-size(15);
          margin-bottom: 0;
          margin-right: 25px;
        }
      }
    }
  }
}
