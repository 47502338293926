.admin-wrapper {
    &.reporting-wrap {
        margin: 20px 0;
        margin-bottom: 0px;

        .reporting-outer-wrap {
            margin-top: 130px;

            .ant-tabs-nav-scroll {
                text-align: -webkit-center;

                .ant-tabs-nav {
                    display: flex;
                    width: 1130px;
                }
            }

            .ant-tabs-bar {
                border-bottom: 1px solid #f6f6f6;
                padding-bottom: 15px;
            }
        }

        .reporting-inner-wrap {
            .ant-tabs-nav {
                left: 0;
            }

            .ant-tabs-bar {
                border-bottom: none;
            }
        }

        .title {
            color: $black;
            @include font-size(25);
            font-weight: $black-bold;
            margin-bottom: 25px;
            text-align: left;
        }

        .btn-csv-download-wrap {
            display: inline-flex;
            left: 500px;
            position: relative;
            top: -15px;
            text-align: right;
            z-index: 1;

            .btn-csv-download {
                @include font-size(13);
                color: $host-green;
                font-weight: $bold;
                margin-left: 8px;
                margin-bottom: 0px;
            }

            .download-icon {
                height: 18px;
                width: 18px;
            }
        }

        .loading-wrap {
            left: 500px;
            position: relative;
            top: -15px; 
        }

        .report-tab {
            top: -5px;

            .ant-tabs-bar {
                margin-bottom: 15px;
                text-align: left;
            }

            .ant-tabs-tab {
                border-radius: 25px;
                border:1px solid $light-gray;
                background-color: transparent;
                color: $black;
                @include font-size(13);
                height: 35px;
                line-height: 33px;
                margin-right: 5px;
                padding: 0px 12px;

                &.ant-tabs-tab-active  {
                    background-color: $host-green;
                    border: 1px solid $host-green;
                    color: $white;
                    font-weight: $bold;
                }
            }

            .ant-table-thead {
                th  {
                    background-color: #f2f2f2;
                    font-size: 13px;
                    font-weight: $extra-bold;
                    line-height: 15px;
                    padding: 10px 12px;
                    max-width: 113px;
                    overflow: hidden;

                    &.custom-width {
                        min-width: 113px;
                    }

                    .ant-table-filter-icon {
                        left: auto !important;
                    }
                }
            }
            .ant-table-body {
                overflow-x: auto;
                overflow-y: auto;
                max-height: 453px;

                td {
                    background-color: transparent;
                    color: $black;
                    font-size: 13px;
                    padding: 12px 12px;
                    max-width: 150px;
                    overflow: hidden;
                    word-break: break-word;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    .img {
                        margin-right: 10px;
                        width: 24px;
                    }
                }
            }
        }
    }
}
