.footer-section {
  width: 100%;

  .custom-container{
    width: 100%;

    @include desktop{
        width: 93%;
      }
      @include large-desktop{
        width: 87%;
      }
    
      @include exlarge-desktop{
        width: 94%;
      }

  }
  


  .footer-wrap {
    margin: 0 auto;
    position: relative;
    width: 100%;

    .footer-columns {
      @include flex-column;
      align-items: center;
      height: 50px;

      .footer-column {
        color: $white;

        .covid-icon {
          height: 17px;
          margin-right: 10px;
          width: 17px;
        }

        .covid-text {
          color: $white;
          font-weight: $semi-bold;
          @include font-size(14);
          text-decoration: underline;
        }

        .img {
          vertical-align: middle;
          margin: 0 10px;
          height: 16px;
        }
        &.cursor-point {
          cursor: pointer;
        }

        &.footer-left-column {
          @include desktop {
            position: absolute;
            left: 0;
            top: 0;
          }
          
          @include mobile {
            height: 0px;
            position: relative;
            top: -55px
          }

          @include tablet-portrait {
            height: 0;
            position: relative;
            top: -55px
          }
        }

        &.footer-right-column {
          @include font-size(14);
          
          @include desktop {
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }

      @include desktop {
        display: block;
        height: 22px;
        position: relative;
        text-align: center;
      }

      @include tablet-landscape {
        height: 22px;
      }
    }

    @include desktop {
      padding: 14px 0;
    }

    .container {
      max-width: 1760px;  
    }
  }

  &.first-block {
    background-color: $ms-blue;
    padding: 60px 0px 50px 0px;

    .custom-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 1730px;

      @include mobile {
        padding: 0px 30px;
      }

      @include tablet-portrait {
        padding: 0 30px 0 40px;
      }
      .footer-columns {
        text-align: left;
        margin-bottom: 40px;
        width: 50%;

        @include desktop {
          margin-bottom: 0;
          margin-right: 25px;
          width: 17%;
        }

        @include tablet-landscape {
          margin-bottom: 0;
          margin-right: 25px;
          width: 18%;
        }

        &:first-child {
          width: 150px;
          @include mobile {
            text-align-last: center;
            width: 100%;
          }

          @include tablet-portrait {
            text-align-last: center;
            width: 100%;
          }
        }

        &:last-child {
          margin-right: 0;

          @include desktop {
            width: 120px;
          }

          @include tablet-landscape {
            width: 120px;
          }
        }

        .title {
          color: $white;
          @include font-size(15);
          font-weight: $extra-bold;
          line-height: 20px;
          margin-bottom: 20px;
        }

        .text {
          color: $white;
          @include font-size(15);
          margin-bottom: 10px;
        }

        .img-logo {
          margin-bottom: 20px;
          width: 114px;
        }

        .img-wrap {
          display: flex;
          flex-flow: wrap;
          justify-content: space-between;
          width: 100px;

          .img-inner-wrap {
            margin-bottom: 20px;
            
            .img {
              border-radius: 8px;
              height: 40px;
              width: 40px;
            }
          }
        }
      }

      .blank-block {
        width: 100px;

        @include mobile {
          display: none;
        }

        @include tablet-portrait {
          display: none;
        }

        @include tablet-landscape {
          width: 6%;
        }
      }
    }
  }

  &.last-block {
    background-color: #006590;
  }
}
