@mixin min-width-1280 {
  @media (min-width: #{$desktop-large-width}) {
    @content;
  }
}

@mixin min-width-992 {
  @media (min-width: #{$laptop-width}) {
    @content;
  }
}

.blur-menu-wrapper {
  .popup-wrap {
    position: relative;

    .btn {
      background: none;
      box-shadow: none;
      border: none;
      cursor: pointer;
      outline: none;
      position: relative;
      z-index: 9;
    }

    .popup-content {
      box-shadow: 0px 1px 6px #00000029;
      border-radius: 20px;
      background-color: #ffffff;
      width: 240px;
      position: absolute;
      top: 40px;
      right: 0px;
      outline: none;
      z-index: 10;
    }

    .popup-content::before {
      content: '';
      position: absolute;
      top: -7px;
      right: 20px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $white;
    }

    &.More {
      .popup-content {
        .more-content {
          padding-top: 30px;
          padding-bottom: 12px;
          max-height: calc(100vh - 225px);
          overflow-y: auto;

          .property-wrap {
            text-align: left !important;
            padding: 0 30px;
            margin-bottom: 8px;

            .more-title {
              color: $ms-blue;
              text-align: left;
              @include font-size(15);
              font-weight: $bold;
              text-transform: uppercase;
            }

            .button-flat {
              background-color: transparent;
              color: #000000;
              border: 1px solid #d8d8d9;
              border-radius: 23px;
              padding: 7px 13px;
              margin: 0 2px;
              margin-bottom: 10px;
              outline: 0;
              cursor: pointer;
              min-width: 60px;
              text-transform: capitalize;

              &:hover {
                background-color: $ms-blue;
                border-color: $ms-blue;
                color: $white;
              }

              &.selected {
                background-color: $ms-blue;
                border-color: $ms-blue;
                color: $white;
              }
            }
          }
        }

        .btn-wrapper {
          @include flex-row;
          justify-content: space-between;
          border-top: 0.5px rgba(112, 112, 112, 0.2) solid;
          padding: 0 30px;
          padding-top: 8px;
          padding-bottom: 8px;

          .md-btn--hover {
            background: none;
          }

          .btn-clear {
            cursor: pointer;
            border: none;
            background-color: transparent;
            color: #006dd1;
            outline: none;

            @include desktop {
              padding-left: 0 !important;
              text-align: left;
            }
          }
          .btn-apply {
            cursor: pointer;
            border: none;
            background-color: transparent;
            color: $ms-blue;
            font-weight: $black-bold;
            text-transform: uppercase;
            outline: none;

            @include desktop {
              text-transform: none;
            }

            @include tablet-landscape {
              text-transform: none;
            }
          }
        }

        @include desktop {
          width: 780px !important;
        }

        @include tablet-landscape {
          width: 600px !important;
        }
      }
    }

    &.Start {
      &.Date {
        .popup-content {
          width: 394px;
        }
      }
    }
  }
}

.blur {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(#000000, 0.5);
  z-index: 1;
  top: 0;
  right: 0;
}

.search-wrap {
  width: 100%;
  padding: unset;
  margin: unset;
}

&.space-page {
  .search-wrap {
    @include desktop {
      width: 90%;
    }

    .navigation-section {
      .search-bar-wrap {
        .search-btn {
          @include desktop {
            right: 25px !important;
          }
        }
      }

      &.white-bg-navbar {
        .left-wrap {
          @include mobile {
            display: none;
          }

          @include tablet-portrait {
            display: none;
          }
        }

        .middle-wrap {
          display: none;
        }
        
        .right-wrap {
          @include mobile {
            display: none;
          }

          @include tablet-portrait {
            display: none;
          }
        }
      }
    }
  }
  .search-result-bar {
    .flex-wrap {
      .blur-menu-wrapper .btn:focus {
        outline: none;
        &.used-filter {
          background-color: $ms-blue;
          color: $white;

          .md-text {
            color: $white;
            @include font-size(14);

            &.btn-clear {
              color: $ms-blue;
            }

            &.btn-apply {
              color: $ms-blue;
            }

            @include desktop {
              @include font-size(15);
            }
          }

          .btn {
            border-color: $ms-blue;
            color: $white;
          }

          .md-btn {
            min-width: auto;
            width: max-content;
            height: 36px;
          }
        }

        .result-filter {
          background: $white;

          &.used-filter {
            background: $ms-blue;
            color: $white;
          }
        }
      }
    }
  }
  .background-color{
    background-color: #ffffff;
  }
}
