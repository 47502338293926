.btn-white {
  background-color: $white;
  border: 1px solid $white;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 35px;
  color: $bright-blue;
  cursor: pointer;
  outline: 0;
  
  &:active{
    outline: 0 !important;
  }

  &.button-disabled {
    pointer-events: none;
  }
}

  
.btn-yellow {
  background-color: $yellow;
  border: 1px solid $yellow;
  border-radius: 23px;
  color: $black;
  cursor: pointer;
  outline: 0;
  
  &:active {
    outline: 0 !important;
  }

  &.button-disabled {
    pointer-events: none;
  }
}

.btn-green {
  background-color: $green;
  border: 1px solid $green;
  border-radius: 23px;
  color: $white;
  cursor: pointer;
  outline: 0;
  
  &:active {
    outline: 0 !important;
  }

  &.button-disabled {
    pointer-events: none;
  }
}

.btn-pink {
  background-color: $pink;
  border: 1px solid $pink;
  border-radius: 23px;
  color: $white;
  cursor: pointer;
  outline: 0;
  
  &:active {
    outline: 0 !important;
  }

  &.button-disabled {
    pointer-events: none;
  }

  &:disabled {
    background-color: $pink;
    border: 1px solid $pink;
    opacity: 0.5;
  }
}

.btn-teal {
  background-color: #2a8485;
  border: 1px solid #2a8485;
  border-radius: 23px;
  color: $white;
  cursor: pointer;
  outline: 0;
  
  &:active {
    outline: 0 !important;
  }

  &.button-disabled {
    pointer-events: none;
  }
}

.btn-teal-outline {
  background-color: transparent;
  border: 1px solid $teal;
  cursor: pointer;
  color: $teal;
  display: inline-block;
  @include font-size(13);
  font-weight: $bold;
  text-align: center;
  outline: none;
  padding: 10px 30px;
}

.md-chip {
  background-color: $white !important;
  
  &:active {
    outline: 0 !important;
    background-color: $white !important;
    color: $black !important;
  }

  &:focus {
    outline: 0 !important;
    background-color: $white !important;
    color: $black !important;

      .md-chip-text{
        color: $black !important;
      }
  }

  &:visited {
    outline: 0 !important;
    background-color: $white !important;
    color: $black !important;
  }

  &:hover {
    background-color:#158bf7 !important;

      .md-chip-text {
        color: $white !important;
      }
   
  }

  &.selected-chip {
    background-color:$bright-blue !important;

      .md-chip-text {
        color: $white !important;
      }
  }
}

.btn-outline {
  background-color: transparent;
  border: 1px solid $light-gray;
  box-shadow: none;
  border-radius: 35px;
  color: $black;
  cursor: pointer;
  outline: 0;
  padding: 5px 15px;
  
  &:active{
    outline: 0 !important;
  }

  &.button-disabled {
    pointer-events: none;
  }
}

button:disabled {
  pointer-events: none;
  border: 1px solid $light-gray;
  background-color: $light-gray; 
  color: $black;
  cursor: not-allowed;
}