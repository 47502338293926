.host-dashboard-modal {
    width:355px !important;

    .ant-modal-content {
        @include desktop {
          border-radius: 24px;
        }

        @include tablet-portrait {
          border-radius: 24px;
        }
    }

    .fixed-header {
      background: $white;
      border-bottom: 1px solid $light-gray;
      padding: 25px 28px;
      position: fixed;
      width: 100%;
      top: 0;
      z-index: 11;

      .arrow-img {
        cursor: pointer;
        display: inline-block;
        height: 10px;
        @include font-size(8);
        margin-right: 1px;
        transform: rotate(180deg);
      }

      .text {
        color: $black;
        display: inline-block;
        @include font-size(15);
        font-weight: $black-bold;
        vertical-align: middle;
      }

      @include desktop {
        display: none;
      }

      @include tablet-portrait {
        display: none;
      }
    }

    .ant-modal-close-x {
        display: none;
    }

    .ant-modal-body {
        padding: 0px;
    }

    .modal-title {
        color: $black;
        display: block;
        @include font-size(20);
        font-weight: $black-bold;        
        margin-bottom: 16px;
        text-align: left;

        @include desktop {
          @include font-size(15);
          text-align: center;
        }

        @include tablet-portrait {
          @include font-size(15);
          text-align: center;
        }
    }

    .sub-text {
      color: rgba($color: $black, $alpha: 0.35);
      font-size: 9px;
      font-weight: $black-bold;
      margin-bottom: 8px !important;
      text-transform: uppercase;
    }

    .description-wrapper {
      .md-text-field {
        border-radius: 10px;
        height: 100px !important;
        padding: 10px 20px !important;
      }
    }

    .name-wrap {
      display: inline-block;
      width: 48%;

      &.left-wrap {
         margin-right: 10px;
      }
    }

    .radio-btn-wrapper {
      margin-bottom: 10px !important;

      .ant-radio-group {
        width: 100%;

        .radio-btn-inner-wrapper {
          display: flex;
          justify-content: center;

          .ant-radio-button-wrapper {
            padding: 3px 0;
            height: 40px;
            border-color: $light-gray2;
            box-shadow: none !important;
            color: $black;
            flex-grow: 1;
            text-align: center;

            @include mobile {
              padding: 0 30px;
              padding-top: 4px;
            }

            @include tablet-portrait {
              padding: 0 30px;
              padding-top: 4px;
            }

            @include tablet-landscape {
              padding: 8px 15px;
            }

            &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
              color: $white;
              background-color: #1C8487;
            }

            &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
              outline: none;
            }
          }
        }

        .ant-radio-button-wrapper:first-child {
          border-radius: 20px 0 0 20px;
        }

        .ant-radio-button-wrapper:last-child {
          border-radius: 0 20px 20px 0;
        }

        .ant-radio-button-wrapper:only-child {
          border-radius: 20px;
        }
      }
    }

    .start-end-wrapper {
      width: 100%;
      position: relative;

      .start-end-inner-wrapper {
        display: flex;
        width: 100%;

        .md-text-field--floating-margin {
           margin-top: 0;
        }

        hr {
          display: none;
        }

        .md-layover {
          width: 48%;

          &:not(:last-of-type) {
            margin-right: 10px;
          }

          .md-list {
            top: unset;
            width: 100%;
            padding: 0;
          }

          .md-floating-label {
            background-color: $white;
            top: -17px;
            @include font-size(11);
            color: $black;
            transform: none;
            z-index: 1;
            left: 1px;
          }
        }
      }

      .md-menu-container {
        border: 1px solid $light-gray;
        border-radius: 23px;
        padding: 10px 18px;
      }

      .material-icons {
        display: none;
      }
    }

    .md-text-field-container {
        .md-divider {
          display: none;
        }

        .md-floating-label {
          background-color: $white;
          color: $black;
          @include font-size(11);
          position: absolute;
          transform: translate3d(0, -6px, 0);
          left: 20px;
          z-index: 1;
        }

        .md-text-field-icon--positioned {
          position: absolute;
          bottom: 13px;
          left: 20px;
          width: 14px;
          height: auto;
          margin-bottom: 0 !important;

          @include mobile {
            bottom: 13px;
          }

          @include tablet-landscape {
            bottom: 0px;
            width: 14px;
            left: 15px;
          }
        }

        .md-text-field-icon+.md-text-field-divider-container {
           margin-left: 0 !important;
        }
  
        .md-text--theme-primary {
          color: $black;
          outline: none;
        }
  
        .md-text-field {
          color: $black;
          border: 1px solid #e9e9e9;
          border-radius: 25px;
          height: 40px;
          padding: 0 20px;
        }

        .md-text-field--floating-margin {
          margin-top: 0 !important;
        }

        .md-text-field--margin {
          margin-top: 0 !important;
        }
    }

    .input-margin-wrapper {
        margin-bottom: 13px;
        position: relative;   
    }

    .content {
      padding: 25px 30px;
      padding-bottom: 0;

      &.custom-margin {
        padding-top: 0;
        padding-bottom: 16px;
      }

      @include mobile {        
          padding-top: 80px;        
      }
    }

    .footer {
      border-top: 1px solid $light-gray;
      padding: 10px 30px;

      .btn-reservation {
        color: $black;
        font-weight: $black-bold;
        height: 45px;
        width: 100%;
        padding-left: 22px;
        padding-right: 22px;

        .number-of-seats {
          color: $black;
          font-weight: normal !important;
          @include font-size(11);
          display: block;
        }
      }

      @include mobile {
        position: fixed;
        bottom: 0;
        right: 0;
        border: none;
        z-index: 1;

        .btn-reservation {
          @include font-size(13);
        }
      }
    }

    .booking-range-picker {
      .md-calendar-date--date {
        background-color: $white;
        border-radius: none;
        padding: 0;
      }
    }

    .guest-input {
      margin-bottom: 10px !important;

      .md-text-field {
          padding-left: 40px;
      }

      .md-text-field-container {
        width: 100%;
      }

      .md-text-field-divider-container {
        width: 100%;
      }
    }

    .seating-wrapper {
      width: 100%;
      position: relative;
      border-radius: 25px;
      height: 45px;
      padding-left: 0;
      border: 1px solid $light-gray2;
      z-index: 9;

      .md-ink-container {
        .md-ink {
          background: none;
        }
      }

      .img {
        position: absolute;
        top: 12px;
        left: 20px;
        z-index: 3;
      }

      .md-layover {
        width: 100%;
        z-index: 4;

        .md-btn {
          width: 100%;
          text-align: left;
          padding-left: 40px;
          font-size: 13px;
          padding-bottom: 0;
          text-transform: capitalize;
          color: rgba(0, 0, 0, 0.4);
          cursor: pointer;

          &.md-btn--color-primary-active {
            background-color: transparent;
          }
        }

        .md-list {
          width: 100%;
          text-align: left;
          top: 45px;
          padding: 0;

          .md-list-item {
            margin-bottom: 10px;

            .img {
              right: 15px;
              left: unset;
              width: 30px;
            }
          }
        }
      }

      .seating-list {
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';

        &:focus {
          outline: none;
        }

        .md-list {
          text-align: left;
          top: unset;
          width: 100%;
        }

        .md-icon {
          display: none;
        }

        .md-icon-separator {
          position: relative;

          .img {
            position: absolute;
            left: 0;
            left: 20px;
          }
        }
      }

      .seating-menu-wrapper {
        z-index: 2;
        display: flex;
        justify-content: space-between;


          &.md-list-item {
            margin-bottom: 10px;

            .img {
              right: 15px;
              left: 0 !important;
              width: 30px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

        .img {
          position: relative;
          top: unset;
          left: unset;
          width: 30px;
        }

        .md-fake-btn {
          width: 100%;
          padding: 0 20px;
        }

        .title {
          position: absolute;
          width: 70%;
          text-align: left;
          display: inline-block;
          left: 20px;
          font-size: 15px;
          word-break: break-word;
        }
      }

      .select-item-wrapper {
        z-index: 1;
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        border-radius: 25px;
        height: 40px;
        background: white;
        padding-left: 50px;
        position: absolute;
        top: 1px;
        width: 100%;

        .img {
          position: relative;
          top: unset;
          left: unset;
          width: 30px;
        }

        .title {
          .light-gray {
            @include font-size(15);
            color: rgba(0, 0, 0, 0.54);
          }
        }
      }
    }

    .date-block-wrapper {
      display: flex;
      position: relative;
      flex-wrap: nowrap;
      flex-direction: column;

      .md-picker .md-calendar-date--btn {
        &::after {
          width: 0 !important;
          height: 0 !important;
        }
      }

      .md-text-field {
        padding-left: 40px;
      }

      .md-picker {
        @include react-md-pickers-make-portrait;
        box-shadow: unset;

        &.md-picker--inline-icon {
          left: 0px;
        }

        .md-picker-header {
          display: none;
        }

        .md-picker-content-container {
          border-radius: 5px;
          -webkit-box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.26);
          -moz-box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.26);
          box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.26);

          .md-picker-content {
            width: 280px;
            height: auto !important;

            @include tablet-landscape {
              width: 280px;
            }

            .md-calendar-header {
              .md-calendar-controls {
                border-bottom: 1px solid #d9d9d9;
                margin-bottom: 15px;

                .md-title {
                  font-weight: 800;
                }
              }
              .md-calendar-dow {
                width: 36px;
              }
            }
            .md-calendar-month {
              .md-calendar-date {
                width: 36px;
                height: 36px;
                margin-bottom: 5px;

                .md-calendar-date--btn {
                  height: 36px;
                  padding: 0;

                  .md-calendar-date--date {
                    color: #1C8487;

                    .disable-date-cls {
                      color: rgba(0, 0, 0, 0.3);
                      background: none;
                      cursor: not-allowed;
                    }

                    .normal-date-cls {
                      height: 36px;
                      width: 36px;
                      display: block;
                      padding: 8px 6px;
                      border-radius: 36px;

                      &.current-date {
                        border: 1px solid #1C8487;
                        background: $white !important;
                        color: #1C8487;

                        &.disable-date-cls {
                          color: rgba(0, 0, 0, 0.3);
                          background: none !important;
                        }
                      }

                      &.current-date:hover {
                        border: 1px solid #1C8487;
                        background: #1C8487 !important;
                        color: $white;

                        &.disable-date-cls {
                          color: rgba(0, 0, 0, 0.3);
                          background: none !important;
                        }
                      }
                    }

                    .calendar-select-days {
                      background: #edf5fc;
                    }

                    &.md-picker-text--active {
                      span {
                        background: #1C8487 !important;
                      }

                      .disable-date-cls {
                        background: none !important;
                        cursor: not-allowed;
                      }

                      .calendar-select-days {
                        color: $white;
                      }
                    }
                  }

                  &.md-calendar-date--btn-active {
                    .md-calendar-date--date {
                      color: $white;

                      span {
                        background: #1C8487;
                      }
                      .calendar-select-days {
                        background: #edf5fc;
                      }
                    }

                    &:after {
                      background: transparent;
                    }
                  }
                  &.current-date {
                    .md-calendar-date--date {
                      background: $white;
                      color: #1C8487;
                      border: 1px solid #1C8487;
                      border-radius: 36px;
                      display: block;
                      height: 36px;                    
                      padding: 8px 6px;
                      width: 36px;
                    }
                  }

                  &.current-date:hover {
                    .md-calendar-date--date {
                      background: #1C8487;
                      color: $white;
                      border: 1px solid #1C8487;
                    }
                  }

                  &.day-with-events {
                    background: #edf5fc;
                  }
                  &.start-range-date {
                    border-top-left-radius: 36px;
                    border-bottom-left-radius: 36px;
                    padding-left: 0;

                    .calendar-select-days {
                      background: #1C8487;
                      color: $white;
                    }

                    .md-calendar-date--date {
                      .current-date {
                        background: #1C8487 !important;
                        color: $white;

                        &.disable-date-cls {
                          color: rgba(0, 0, 0, 0.3);
                          background: none !important;
                        }
                      }
                    }
                  }

                  &.end-range-date {
                    border-top-right-radius: 36px;
                    border-bottom-right-radius: 36px;
                    padding-right: 0;

                    .calendar-select-days {
                      background: #1C8487;
                      color: $white;
                    }
                  }
                }
              }
            }
          }

          .md-dialog-footer {
            padding: 8px 0;
            border-top: 1px solid #d9d9d9;
            display: flex;
            justify-content: space-between;
            margin: 0 8px;

            .md-text--theme-primary {
              color: #1C8487;
            }

            .md-btn {
              text-transform: uppercase;
              font-weight: 800;

              .clear-all-btn {
                text-transform: capitalize;
                font-weight: 500;
              }

              &.md-btn--color-primary-active {
                background-color: $white;
              }
            }
          }
          &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 0;
            left: 20%;
            box-sizing: border-box;
            border: 10px solid black;
            border-color: transparent transparent $white $white;
            transform-origin: 0 0;
            transform: rotate(135deg);
            box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.26);
          }
        }
      }

      .date-block {
        width: 100%;

        @include tablet-landscape {
          width: 100%;
        }

        .md-picker-container {
          .md-text-field-container {
            visibility: hidden;
            height: 0;
          }

          .md-picker--inline-icon {
            left: 0px;

            &.md-picker--inline {
              position: relative;
            }
          }
        }

        .md-text-field-container {
          .md-text-field-icon--positioned {
            position: absolute;
            bottom: 13px;
            left: 20px;
            width: 14px;
            height: auto;
            margin-bottom: 0;

            @include mobile {
              bottom: 14px;
            }

            @include tablet-landscape {
              bottom: 0px;
              width: 14px;
              left: 15px;
            }
          }

          .md-text-field-divider-container--grow {
            margin-left: 0 !important;
          }

          .md-text-field {
            @include font-size(13);
          }
        }

        .booking-range-picker {
          .md-calendar-date--date {
            background-color: $white;
            border-radius: none;
            padding: 0;
          }
        }
      }
      .plus-btn {
        width: 45px;
        height: 45px;
        border-radius: 45px;
        position: absolute;
        right: 0;
        top: 15px;
        background-color: #edf5fc;
        font-weight: 900;
        color: #1C8487;

        @include tablet-landscape {
          width: 35px;
          height: 35px;
          border-radius: 35px;
        }
      }

      .minus-btn-wrapper {
        position: relative;
        margin-bottom: 0;

        &:not(:first-of-type) {
          .date-block {
            .md-text-field--margin {
              margin-top: 0;
            }
          }
        }
      }

      .minus-btn {
        width: 45px;
        height: 45px;
        border-radius: 45px;
        position: absolute;
        right: 0;
        top: 0;
        background-color: #fee5e5;
        font-weight: 900;
        color: #f35050;

        @include tablet-landscape {
          width: 35px;
          height: 35px;
          border-radius: 35px;
        }
      }

      &.common-date-block-wrapper {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;

        &.meet-class {
          .weekly-range-picker {
            width: 48%;
          }
        }

        .weekly-range-picker {
          width: 48%;

          .md-floating-label {
            left: 25px;
            top: -3px;
            @include font-size(13);
            color: $black;
            font-weight: 700;
            background-color: $white;

            @include mobile {
              top: -5px;
            }

            @include tablet-portrait {
              top: -5px;
            }
          }

          .md-picker--inline {
            position: relative;
          }
          .md-text-field-container {
            .md-text-field-icon-container {
              .img {
                position: absolute;
                bottom: 8px;
                left: 20px;
                width: 14px;
                height: auto;

                @include mobile {
                  bottom: 3px;
                }

                @include tablet-portrait {
                  bottom: 3px;
                }

                @include tablet-landscape {
                  bottom: 0px;
                  left: 15px;
                }
              }
              .md-text-field-divider-container {
                margin-left: 0;
              }
            }
          }
        }
        .start-end-wrapper {
          width: 100%;
          position: relative;

          .start-end-inner-wrapper {
            display: flex;
            width: 100%;

            .md-layover {
              width: 48%;

              &:not(:last-of-type) {
                margin-right: 10px;
              }

              .md-list {
                top: unset;
                width: 100%;
                padding: 0;
              }

              .md-floating-label {
                background-color: $white;
                top: 9px;
                @include font-size(13);
                color: $black;
                font-weight: 700;
                transform: none;
                z-index: 1;
                left: 20px;
              }

              .md-text-field {
                padding-left: 20px;
              }
            }
          }

          .material-icons {
            display: none;
          }
        }
        .available-slot-wrapper {
          margin-top: 15px;
          .text-gray {
            color: rgba(0, 0, 0, 0.5);
            @include font-size(13);
            text-align: left;
          }
          .available-slot-inner-wrapper {
            display: flex;
            flex-wrap: wrap;

            .md-chip {
              flex: 0 0 48%;
              justify-content: center;
              margin: 5px;
              margin-bottom: 0;
              margin-left: 0;
              background-color: $white;
              border: 1px solid #d8d8d9;

              &:hover {
                background-color: #1C8487;
              }

              &:focus {
                box-shadow: none;
                background-color: #1C8487;

                .md-chip-text {
                  color: $white;
                }
              }

              .md-chip-text {
                @include font-size(13);

                &:focus {
                  outline: none;
                  color: $white;
                }
              }

              &.md-chip--hover {
                .md-chip-text--hover {
                  color: $white;
                }
              }
            }
          }
        }
        .duration-wrapper {
          width: 48%;

          .md-floating-label {
            margin-left: 25px;
            top: -3px;
            @include font-size(13);
            color: $black;
            font-weight: 700;
            background-color: $white;

            @include mobile {
              top: -5px;
            }

            @include tablet-portrait {
              top: -5px;
            }
          }
        }
      }
      
      &.meet-class {
        flex-wrap: wrap;

        .weekly-range-picker {
          width: 100%;
        }
      }

      &.hourly-block-class {
        .weekly-range-picker {
          width: 100% !important;
        }
      }
    }

    .error {
      color: $error;
      display: block;
      @include font-size(13);
      margin-bottom: 10px;
      text-align: left;
    }

    @include mobile {
      max-width: calc(100vw) !important;
      margin: 0px auto !important;
      padding-bottom: 0 !important;
      top:0 !important;
    }

    &.create-reservation-modal {
       @include mobile {
         width: 100% !important;
       }

       .ant-modal-content {
         height: 100%;
       }

       .available-slot-wrapper {
        .text-gray {
          color: rgba(0, 0, 0, 0.5);
          @include font-size(13);
          text-align: left;
        }

        .available-slot-inner-wrapper {
          display: flex;
          flex-wrap: wrap;

          .md-chip {
            cursor: pointer;
            flex: 0 0 48%;
            justify-content: center;
            margin: 5px;
            margin-bottom: 0;
            margin-left: 0;
            background-color: $white;
            border: 1px solid #d8d8d9;

            &.selected-chip {
              background-color: #1C8487 !important;
            }

            &:hover {
              background-color: #1C8487 !important;
            }

            &:focus {
              box-shadow: none;
            }

            .md-chip-text {
              @include font-size(13);

              &:focus {
                outline: none;
                color: $white;
              }
            }

            &.md-chip--hover {
              .md-chip-text--hover {
                color: $white;
              }
            }
          }
        }
      }
    }
    
}

.cancel-reservation-modal {

  .md-dialog {
    border-radius: 24px;
    width:305px !important;
    text-align: center;
  }

  .md-dialog-content--padded {
    max-height: 200px !important;
    padding: 0;
  }

  .content {
      color: $black;
      padding: 30px;

      .title {
        color: $black;
        @include font-size(18);
        font-weight: $black-bold;
        margin: 0 auto;
        margin-bottom: 10px;
        width: 88%;
      }

      .sub-title {
        color: $black;
        @include font-size(15);
        margin-bottom: 0;
      }
  }

  .footer {
    border-top: 1px solid $light-gray;
    padding: 10px 30px;

      .btn {
        border-radius: 23px;
        box-shadow: none;
        border: none;
        cursor: pointer;
        @include font-size(13);
        font-weight: $black-bold;
        height: 40px;
        display: inline-block;
        padding: 10px 15px;
        min-width: 100px;
        outline: none;
        text-align: center;
        text-transform: uppercase;

        &.btn-no {       
          color: $black;
          border: 0.5px solid $black;
          background-color: transparent;
          margin-right: 10px;
        }
  
        &.btn-yes {
          color: $white;
          background-color: #f35050;
        }

        @include desktop {
          min-width: 115px;
        }
      }
  }

  .ant-modal-close-x {
      display: none;
  }
}