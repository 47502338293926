.booking-details {
    .header {
        border: none;
        padding: 0 0 20px 0;
        text-align: left;

        .flex-wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .ellipsis-img {
          cursor: pointer;
          height: 11px;
          width: 20px;
          max-width: 100%;  
        }

        .arrow-img {
          cursor: pointer;
          display: inline-block;
          height: 14px;
          margin-right: 3px;
          transform: rotate(180deg);
          vertical-align: middle;
        }

        .title {
            color: $black;
            display: inline-block;
            @include font-size(15);
            font-weight: $bold;
            margin-bottom: 0;
            text-align: left;
            vertical-align: middle;
            word-break: break-word;
        }
    }

    &.booking-cancellation {
      
      .header-content {
        padding: 20px 0;   
        text-align: left; 
        
        .type {
            color: $black-op-50;
            display: inline-block;
            @include font-size(13);
            font-weight: 600;
            margin-right: 6px;
            text-transform: uppercase;
        }

        .address{
          color: $black-op-50;
          display: inline-block;
          @include font-size(13);
          font-weight: 600;
          margin-right: 6px;
        }

        .separator {
         border: 1px solid #f6f6f6;
         margin: 20px 0;
        }

        .map-wrap {
          display: inline-block;
          cursor: pointer;

          .map-text {
            color: $bright-blue;
            @include font-size(13);
            font-weight: 600;
            margin-left: 2px;
            text-decoration: underline;
          }
        }

        .sub-title {
            color: $black;
            display: block;
            @include font-size(25);
            font-weight: $black-bold;
        }

        .status {
            border-radius: 23px;
            background-color: rgba($color: $black, $alpha: 0.05);
            color: $black-op-60;
            display: inline-block;
            @include font-size(12);
            padding: 5px 6px;

            &.active {
                background-color: rgba($color: #0671d4, $alpha: 0.07);
                color: #0671d4; 
            }

            &.declined {
                background-color: rgba($color: #f35150, $alpha: 0.07);
                color: #f35150; 
            }

            &.pending {
                background-color: rgba($color: #997C00, $alpha: 0.07);
                color: #997C00; 
            }
        }

        .flex-wrap {
            @include flex-row;
            justify-content: space-between;
        }

        .popover {
            cursor: pointer;
        }

        .favourites {
            .icon {
                color: #f35050;
                height: 10px;
                width: 10px;
            }

            .number {
                color: $black;
                @include font-size(13);
                font-weight: $black-bold;
                margin: 0 3px;                                 
            }

            .gray-text {
                color: $black-op-50;
                @include font-size(13);
            }
        }
      }

      .content { 
        text-align: left;
        margin-bottom: 30px;

        .available-slot-inner-wrapper {
          display: flex;
          flex-flow: wrap;
          margin-top: 16px;
          margin-bottom: 30px;

          .slot {
            background-color: $silver-gray;
            border-radius: 23px;
            color: $black;
            @include font-size(13);
            justify-content: center;
            margin: 0 5px 5px 0;
            padding: 10px;
          } 
        }
        
        .img-wrap {
          margin-top: 30px;
          margin-bottom: 30px;
          overflow-x: scroll;
          white-space: nowrap;

          .img {
            border-radius: 5px;
            display: inline-block;
            margin-right: 10px;
            height: 100px;
            width: 100px;
            max-width: 100%; 
            @include background-property();
            background-size: cover !important;    
          }

          @include desktop {
             overflow-x: hidden;
             white-space: normal;
          }
        }
        
        .bg-img {
          @include background-property;
          background-size: cover;
          height: 189px;
          display: block;

          @include desktop {
            height: 313px;
          }
        }

        .guest-wrap {
          color: $black;
          @include font-size(13);
          margin-bottom: 10px;

          .icon {
            margin-right: 10px;
            height: 13px;
          }

          .guest {
            vertical-align: middle;
          }
        }

        .date-wrap {
          color: $black;
          @include font-size(13);
          margin-bottom: 10px;

          .icon {
            margin-right: 10px;
            height: 13px;
          }

          .date {
            vertical-align: middle;
          }
        }

        .ant-flex-wrap {
          .ant-collapse-content {
            .ant-collapse-content-box {
              @include flex-row;
              justify-content: space-between;
            }
          }
        }

        .collapse-content {
          background-color: $white;
          border-color: #dddddd;
          box-shadow: 0px 0px 3px #0000001A;
          border-radius: 5px;
          margin-top: 20px;
          margin-bottom: 10px;

          .initiated-content {
            .ant-collapse-content-box {
              padding: 0 !important;
            }
  
            .initiated-wrap {  
              .custom-bold {
                color: $black;
                font-weight: $extra-bold;
              }
              .currency {
                margin-right: 3px;
              }

              .p-20 {
                padding: 20px;
              }
  
              .border-wrap {
                border-top: 0.5px solid #e2e2e2;
                border-bottom: 0.5px solid #e2e2e2;
                width: 100%;
                padding: 20px;
              }

              .inline-wrap {
                display: inline-block;
                align-items: top !important;

                .icon {
                  margin-right: 10px;
                }
              }
      
              .flex-wrap {
                @include flex-row;
                justify-content: space-between;
                flex-wrap: nowrap;
        
                &.custom-flex-wrap {
                  align-items: flex-start !important;
                }
              }
  
              .border {
                  border-top: 1px solid #e2e2e2;
              }
  
              .mb-8 {
                margin-bottom: 8px;
              }
        
              .mt-8 {
                margin-top: 8px;
              }

              .pt-8 {
                padding-top: 8px;
              }
        
              .p-tb-8 {
                padding: 8px 0;
              }
            }
          }

          .panel-content {
            border-bottom: 1px solid #dddddd;
            border-radius: 5px;

            .ant-collapse-header {
              color: $black;
              font-weight: $bold;
              font-size: 13px;
              padding: 14px 20px;

              .anticon {
                right: 20px;
                text-align: right;
              }
            }

            .ant-collapse-arrow {
              svg {
                path {
                  color: $bright-blue;
                }
              }
            }

            .ant-collapse-content-box {
              padding: 20px;
              color: $black;

              .content-item {
                font-size: 13px;

                .contact-details {
                  color: $bright-blue;
                  text-decoration: underline;
                }

                .first-text-block {
                  font-size: 15px;
                  margin-bottom: 10px;
                }

                .mr-10 {
                  margin-right: 10px;
                }

                .link {
                  color: $bright-blue;
                  font-size: 13px;
                  font-weight: $bold;
                  text-decoration: underline;
                }

                .text-black-bold {
                  font-weight: $black-bold;
                }

                .text-extra-bold {
                  font-weight: $extra-bold;
                }

                .text-bold {
                  font-weight: $bold;
                }

                .text-gray {
                  color: $black-op-50;
                  margin-bottom: 0;
                }

                .second-text-block {
                  font-size: 15px;
                  display: block;
                }

                .sub-title {
                  color: $black;
                  font-weight: $bold;
                }

                &.border {
                  border-bottom: 1px solid $light-gray;
                }
              }
            }

            &.border-content {
              .ant-collapse-content-box {
                padding: 0px;
              }

              .content-item {
                padding: 20px;

                .sub-text-block {
                  font-size: 13px;
                }
              }
            }

            &.custom-panel-content {
              position: relative;

              .custom-title {
                background: white;
                color: $bright-blue;
                font-size: 11px;
                font-weight: $black-bold;
                padding: 13px 20px;
                text-transform: uppercase;
                width: 54%;

                position: absolute;
                top: 2px;
                left: 0;
              }

              .font-18 {
                font-size: 18px;
              }

              .text-bold {
                font-weight: $extra-bold !important;
              }

              .text-gray {
                font-size: 13px;
                color: $black-op-50;
              }

              .ant-collapse-content {
                border-top: 0;
                overflow: visible;
              }

              .ant-collapse-content-box {
                padding-top: 0;
              }

              .content-item {
                display: block;
              }
            }
          }

          .blue-title {
            color: $bright-blue;
            display: block;
            font-size: 11px;
            font-weight: $black-bold;
            margin-bottom: 8px;
          }

          &.cancellation-content {
            border: 1px solid rgba($color:#f94d45, $alpha: 0.3);
            box-shadow: 0px 0px 3px #0000001A;
            background-color: #fff3f2;

            .cancellation-title {
              color: #f64f4B;
              display: block;
              font-size: 11px;
              font-weight: $black-bold;
              margin-bottom: 8px;
            }

            .cancellation-panel-content {
              border-bottom: none;
            }

            .flex-wrap {
              @include flex-row;
              justify-content: space-between;
              align-items: flex-start;
            }

            .cancellation-link {
              color: #f94d45;
              font-size: 13px;
              font-weight: $bold;
              text-decoration: underline;
            }

            .ant-collapse-arrow {
              svg {
                path {
                  color: #f94d45;
                }
              }
            }
          }
        }
      }

      @include mobile {
        position: fixed;
        top: 75px;
        right: 0;
        width: 100%;
        background-color: $white;
        padding: 24px;
        z-index: 10;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
      }
    }
}