.promocode-notification-popup {
    .md-paper {
        border-radius: 24px;
        min-width: 285px;
        width: 325px;

        .md-dialog-content {
            max-height: 450px !important;
            padding: 0;

            .bg-img {
                border-radius: 24px;
                height: 445px;
            }

            .btn-wrapper {
                .btn-submit {
                    background: $pink;
                    border: 1px solid $pink;
                    border-radius: 25px;
                    cursor: pointer;
                    color: $white;
                    font-weight: $extra-bold;
                    height: 45px;
                    @include font-size(13);
                    outline: none;
                    padding: 13px;
                    text-transform: uppercase;
                    width: 140px;

                    &:disabled {
                        opacity: 0.5;
                    }

                    &.disabled {
                        opacity: 0.5;
                    }
                }
            }

            .header {
                padding: 30px 30px 0 30px;

                .close-btn {
                    height: 11px;
                    position: absolute;
                    right: 30px;
                }
                .main-title {
                    color: $pink;
                    @include font-size(30);
                    font-weight: $bold;
                    margin-bottom: 20px;
                    padding-top: 35px;
                }
            }

            .text-block {
                color: $black;
                margin: 0 35px;

                .email-input {
                    background: #F0F5F7;
                    border: 1px solid $pink;
                    border-radius: 30px;
                    height: 50px;
                    margin: 30px 0 25px 0;

                    .md-divider {
                        display: none;
                    }

                    .md-text-field  {
                        box-shadow: none;
                        color: $black;
                        margin-top: 14px;
                        padding: 0 10px;
                        width: 98%;
                    }

                    .md-floating-label {
                        color: $pink;
                        font-weight: $bold;
                        height: 7px;
                        @include font-size(14);
                        padding: 0 2px;
                        transform: translate3d(18px, -6px, 0);

                        &.md-floating-label--inactive {
                            transform: translate3d(66px, 18px, 0);

                            @include mobile {
                                transform: translate3d(50px, 18px, 0);
                            }
                        }

                        &.md-floating-label--floating {
                            background: #d1dfe4;
                            @include font-size(11);
                        }
                    }
                }

                .text-bold {
                    font-weight: $bold;
                    @include font-size(16);
                    margin: 0 30px;
                    text-transform: uppercase;

                    @include mobile {
                        margin: 0 15px;
                    }
                }

                .text {
                    @include font-size(14);  
                    line-height: 23px;  
                    margin: 0 10px; 
                }

                .text-wrap {
                    line-height: 23px;
                    margin: 0 48px 33px 48px;

                    @include mobile {
                        margin: 0 30px 33px 30px;
                    }
                }
            }
        }
    }

    &.response-popup {
        .md-paper {
            border-radius: 24px;
            min-width: 285px;
            width: 325px;

            .bg-img {
                border-radius: 24px;
                height: 310px;
            }
            
            .text-block {
                margin: unset;
            }
        } 
    }
}

.promo-code-slider {
    display: flex;
    position: absolute;
    top: 90px;
    right: 0;

    @include mobile {
        top: 85px;
        z-index: 2;
    }

    @include tablet-portrait {
        z-index: 1;
    }

    &.close {    
        .background-image {
            display: none;
        }
    }

    .animated {
        display: flex;
        position: absolute;
        right: 0;
    }

    .amount-wrap {
        background-color: $white;
        border-radius: 27px;
        color: #FB4164;
        height: 53px;
        padding: 5px 30px 10px 30px;
        width: 157px;

        .amount {
            font-weight: $black-bold;
            font-size: 20px;
        }

        .text {
            font-size: 10px;
        }
    }

    .background-image {
        @include background-property();
        background: transparent linear-gradient(270deg, #FD4298 0%, #FF4164 100%) 0% 0% no-repeat padding-box;
        border-bottom-left-radius: 25px;
        height: 183px;
        margin-left: -1px;
        opacity: 0.85;
        padding: 15px 33px 20px 34px;
        width: 225px;

        @include mobile {
            margin-left: -1px;
        }
    }

    .date {
        color: $white;
        font-size: 13px;
        margin-bottom: 20px;
    }

    .icon-wrap {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        background-color:  #FF4164;
        height: 55px;
        opacity: 0.85;
        padding: 15px;
        width: 55px;

        .img {
            &.arrow-icon {
                transform: rotate(180deg);
            }
        }
    }

    .title {
        color: $white;
        font-weight: $bold;
        font-size: 16px;
        margin-bottom: 10px;
        margin-top: 15px;
        text-transform: uppercase;
    }
}