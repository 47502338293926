.title-section {
    background-color: #EFF5F6 !important;
    margin: 50px 0px 0px 0px;
    padding: 35px 0 0 0;
    text-align: -webkit-center;
    text-align: -moz-center;

    @include mobile {
        margin-top: 50px;
    }

    @include desktop{
        padding: 30px 0 0 0;
    }

    @include laptops {
        padding: 20px 0 0 0;
    }

    .title-wrap {
        width: 100%;
        
        .title {
            @include font-size(28);
            color: $pink;
            font-weight: $extra-bold;
            font-family: $font-family2 !important;
            line-height: 32px;
            margin-bottom: 20px;
            width: 180px;

            @include desktop {
                @include font-size(40);
                line-height: 55px;
                margin-bottom: 5px;
                width: 100%;
            }

            @include laptops {
                @include font-size(30);
                margin-bottom: 0;
            }

            @include mobile {
                width: 280px;
            }

            @include tablet-portrait {
                background-clip: text;
                margin-bottom: 20px;
                width: 100%;
            }
        }
    }

    .sub-title {
        font-family: $font-family;
        @include font-size(14);
        color: $black;
        padding: 0 50px;
        margin-bottom: 0;

        @include desktop {
            @include font-size(16);
            display: block;
            line-height: 25px;
            max-width: 900px;
            width: 705px;
        }

        @include laptops {
            @include font-size(14);
            line-height: 22px;
            width: 630px;
        }

        @include tablet-landscape {
            @include font-size(16);
            line-height: 30px;
            width: 65%;
        }

        @include mobile {
            line-height: 23px;
            margin-top: 12px;
        }
    }

   
      
}

.option-wrap {
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    align-items: flex-start;
    padding-top: 30px;
    padding-bottom: 16px;
    color: $black;
    font-weight: 500;
    font-size: 9px;
    overflow-y: auto;

    .option {
        cursor: pointer;
        text-transform: uppercase;
        white-space: nowrap;

        span {
            @include mobile {
                // width: 50px;
                display: block;
             
                
                
            
            }

            @include tablet-portrait {
                // width: 50px;
                display: block;
                white-space: nowrap;
            
            }
        }
    }
    

    .icon-wrap {
        background: $white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 100px;
        cursor: pointer;
        padding: 11px 25px;
        margin-bottom: 4px;
        width: 80px;

        .icon {
            height: 25px;
        }

        @include mobile {
            width: 70px;
        }

        @include tablet-portrait {
            width: 70px;
        }
    }

    &.floating {
        right: 0;
        position: fixed;
        top: 72px;
        width: 100%;
        max-width: 100% !important;

        background: white;
        display: flex;
        justify-content: flex-start;
        align-items: center; 

        padding: 8px 24px;
        z-index: 5;
        border-top: 1px solid #D9D9D9;
        font-size: smaller;
        

        .icon {

            @include desktop {
                margin-right: 12px !important;
                height: 17px;
            }
        }

        .option {
            @include desktop {
                border: 1px solid #D8D8D9;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 6px;
                padding: 12px;
                height: 19px;
            }

            @include mobile {
                margin-bottom: 0;
            }

            @include tablet-portrait {
                margin-bottom: 0;
            }
        }

        .icon-wrap {
            @include desktop {
                box-shadow: none;
                background-color: transparent;
                padding: 0px;
                margin-bottom: 0 !important;
                width: auto;
            }
        }

        @include mobile {
            top: 70px;
            flex-wrap: nowrap;
            overflow-y: auto;
            gap: 11px;
            align-items: flex-start;
        }

        @include tablet-portrait {
            top: 70px;
            flex-wrap: nowrap;
            overflow-y: auto;
            gap: 11px;
            align-items: flex-start;
        }
        
        
    }

    @include mobile {
        flex-wrap: wrap;
        padding: 20px;
        gap: 8px;
        align-items: flex-start;
        max-width: 90%;

        .option {
            text-transform: uppercase;
            margin-bottom: 18px;
            flex: 1 1 22%;
        }
    }

    @include tablet-portrait {
        flex-wrap: wrap;
        padding: 20px;
        gap: 11px;
        max-width: 80%;

        .option {
            text-transform: uppercase;
            margin-bottom: 18px;
            flex: 1 1 22%;
        }
    }
}

