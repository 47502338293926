@mixin min-width-1280 {
  @media (min-width: #{$desktop-large-width}) {
    @content;
  }
}

@mixin min-width-992 {
  @media (min-width: #{$laptop-width}) {
    @content;
  }
}
.search-result-wrapper {
  padding: 60px 0;
  padding-top: 145px;

  .result-loader {
    height: 40px;
    margin: 8px auto;
    width: 50px;
  }

  .no-more-wrap {
    margin: 0 auto;
    margin-top: 20px;

    .img {
      height: 150px;
    }

    .title {
      @include font-size(28);
      font-weight: $black-bold;
      margin: 20px 0;
    }

    .sub-title {
      margin: 10px 0 20px 0;
    }

    .btn {
      border: 1px solid #cccccc;
      background-color: transparent;
      cursor: pointer;
      border-radius: 17.5px;
      height: 35px;
      padding: 0 20px;
      margin: 0 5px;
    }

    @include desktop {
      margin-top: 70px;
    }

    @include tablet-landscape {
      margin-top: 60px;
    }
  }

  .columns {
    .result-grid {
      min-height: 65vh;
      padding: 8px 0;
    }

    .search-result-column {
      position: relative;

      @include tablet-portrait {
        flex: 0 0 49%;
        min-height: 240px;
      }

      @include desktop {
        min-height: 240px;
      }

      .heart-img {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 14px;
        width: 22px;
        height: 18px;
        z-index: 1;

        @include desktop {
          width: 37px;
          height: 22px;
        }
      }

      .wrap {
        border-radius: 4px;

        @include tablet-portrait {
          margin-right: 0;
        }

        @include mobile {
          margin-right: 0;
        }
      }

      .search-result-thumbnail {
        @include background-property;
        background-position: center;
        background-size: cover;
        border-radius: 4px;
        cursor: pointer;
        height: 180px;
        padding: 27%;
        position: relative;
        width: 100%;

        .number-of-people {
          background-color: rgba($black, 0.5);
          border-radius: 23px;
          color: $white;
          position: absolute;
          padding: 1px 6px;
          bottom: 10px;
          right: 10px;

          .people {
            @include font-size(13);
          }

          .img {
            width: 10px;
            height: 11px;
            margin-right: 3px;
          }
        }

        .distance-wrap {
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 23px;
          color: $white;
          @include flex-row;
          justify-content: space-between;
          position: absolute;
          padding: 1px 6px;
          top: 13px;
          left: 10px;

          .img {
            width: 10px;
            height: 11px;
            top: 4px;
            left: 5px;
          }

          .distance {
            display: inline-block;
            @include font-size(13);
            font-weight: $light;
            margin-left: 4px;
            vertical-align: middle;

            @include mobile {
              @include font-size(10);
            }
          }
        }

        .popular-picks-icon {
          position: absolute;
          right: 3px;
          top: 5px;

          .tooltip-wrapper {
            color: white;
            display: none;
            top: 41px;
            font-size: 10px;
            position: absolute;
            left: -80px;
            padding: 10px;
            background-color: rgba(0, 0, 0, 0.8);
            border-radius: 10px;
            width: 125px;
            z-index: 1;
  
            &::before {
              content: '';
              position: absolute;
              top: -10px;
              left: 93px;
              width: 0;
              height: 0;
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-bottom: 10px solid rgba(0, 0, 0, 0.7);
            }
          }
  
          &:hover {
            .tooltip-wrapper {
              display: block;
            }
          }
        }

        @include tablet-portrait {
          padding: 0;
          height: 187px;
        }

        @include mobile {
          padding: 0;
          height: 187px;
        }
      }

      .thumbnail-content {
        padding: 10px;
        padding-left: 0;
        text-align: left;
        line-height: 1.4;
        cursor: pointer;

        .content-title {
          color: rgba($black, 0.5);
          display: block;
          font-weight: 600;
          @include font-size(13);
          margin-bottom: 3px;
          text-transform: uppercase;
        }

        .content-sub-title {
          color: $black;
          display: block;
          font-weight: $bold;
          @include font-size(18);
          line-height: 1.1;
          text-transform: unset;
        }

        .sub-title {
          color: $gray;
          display: block;
          @include font-size(13);
          text-transform: uppercase;
        }

        .price-wrap {
          .deal {
            color: $error;
            @include font-size(13);
            font-weight: $bold;
          }

          .deal-tag {
            background-color: $error;
            border-radius: 23px;
            color: $white;
            @include font-size(13);
            padding: 1px 8px;
            width: 52px;
            height: 18px;
            margin-right: 5px;
            text-transform: uppercase;
          }

          &.deal-wrap {
            .price {
              text-decoration: line-through;
            }
          }
        }

        .price {
          color: $black;
          @include font-size(13);
          margin-right: 2px;
        }
        .price-description {
          color: $black;
          @include font-size(13);
        }

        .street-name {
          color: $black;
          @include font-size(13);
        }
      }

      @include min-width-1280 {
        flex: 0 0 23.8%;
      }

      @include min-width-992 {
        flex: 0 0 23%;
      }

      &.search-result-column:nth-child(odd) {
        @include tablet-portrait {
          margin-right: 10px;
        }
      }
    }
  }

  @include desktop {
    padding-top: 123px;
  }
}

.search-result-bar {
  width: 100%;
  position: fixed;
  background-color: $white;
  border-bottom: 1px solid $gray;
  border-top: 1px solid $gray;
  z-index: 2;
  top: 70px;

  .flex-wrap {
    @include flex-row;
    padding: 7px 24px;

    @include tablet-portrait {
      padding-top: 25px;
    }

    @include desktop {
      width: 88%;
    }

    @include tablet-landscape {
      width: 85%;
    }

    .blur {
      z-index: 10;
    }

    .blur-menu-wrapper {
      background-color: $white;
      color: $black;
      border-radius: 100px;
      margin-right: 5px;

      @include mobile {
        border: 0.5px solid $light-gray;
      }

      @include tablet-portrait {
        border: 0.5px solid $light-gray;
      }

      &.active {
        background-color: $white;
        color: $black;
        z-index: 11;

        .md-text {
          color: $white;
        }
      }

      .btn {
        min-width: 50px;
        width: max-content;
        padding: 7px 15px;
        border-radius: 100px;
        border: 1px solid #d8d8d9;
        outline: none;

        &:hover {
          background-color: $ms-blue;
          border-color: $ms-blue;
          color: $white;

          .md-text {
            color: $white;
          }
        }
      }

      .popup-content {
        left: 0;
        border-radius: 24px;
        top: 45px;
        width: 420px;

        .content-wrap {
          .range-selector {
            border-top: 1px solid $gray;
            margin: 22px 30px 0 30px;
            padding-top: 20px;
            padding-bottom: 0px;
          }

          .property-wrap {
            border-bottom: 1px solid $gray;
            @include flex-row;
            padding: 30px;
            padding-bottom: 10px;
            padding-top: 30px;

            .button-flat {
              background-color: transparent;
              color: $black;
              border: 1px solid #d8d8d9;
              border-radius: 23px;
              padding: 7px 13px;
              margin: 0 2px;
              margin-bottom: 10px;
              outline: 0;
              cursor: pointer;
              min-width: 60px;
              text-transform: capitalize;

              &.selected {
                background-color: $ms-blue;
                border-color: $ms-blue;
                color: $white;
              }
            }
          }

          .button-wrap {
            padding: 10px 30px;
            @include flex-row;
            justify-content: space-between;
            border-top: 0.5px rgba(112, 112, 112, 0.2) solid;

            .btn-clear {
              cursor: pointer;
              border: none;
              background-color: transparent;
              color: $ms-blue;
              outline: none;
            }

            .btn-apply {
              cursor: pointer;
              border: none;
              background-color: transparent;
              color: $ms-blue;
              font-weight: $black-bold;
              text-transform: uppercase;
              outline: none;

              @include desktop {
                text-transform: none;
              }
  
              @include tablet-landscape {
                text-transform: none;
              }
            }

            &.single-btn {
              display: block;
              float: right;
            }
          }
        }
        
        .btn-price-popup  {
          background-color: transparent;
          border: 1px solid #d8d8d9;
          border-radius: 23px;
          color: $black;
          cursor: pointer;
          margin: 0 4px;
          margin-top: 20px;
          min-width: 60px;
          outline: 0;
          padding: 7px 28px;
          text-transform: capitalize;

          &.selected {
            background-color: $ms-blue;
            border-color: $ms-blue;
            color: $white;
          }
        }
      }

      .popup-content::before {
        left: 23px;
      }

      .popup-wrap {
        &.More {
          .popup-content {
            left: -352px;

            @include tablet-landscape {
              left: -310px;
            }

            &::before {
              left: 380px;

              @include tablet-landscape {
                left: 350px;
              }
            }
          }
        }
      }

      .md-btn {
        padding: 5px 12px !important;
        min-width: auto;

        @include tablet-portrait {
          margin: 0;
          padding: 0;
          box-shadow: none;
        }
        @include mobile {
          margin: 0;
          padding: 0;
          box-shadow: none;
        }

        &.md-btn--text {
          border-radius: 24px;
          text-transform: capitalize;
          width: max-content;
          @include font-size(14);
        }
      }

      .price-popup-wrapper {
        padding-top: 0;

        .range-selector {
          border-bottom: none !important;

          .price-range-wrapper {
            .md-text-field-icon-container {
              .md-text-field-icon {
                bottom: -1px;
                left: 10px;
                width: 15px;
                position: absolute;

                @include desktop {
                  bottom: 2px;
                  left: 25px;
                }
              }
            }
          }

          .ant-slider {
            .ant-slider-track {
              background-color: $ms-blue;
            }
            .ant-slider-handle {
              border: solid 1px $ms-blue;
              width: 28px;
              height: 28px;
              margin-top: -12px;

              &:after {
                content: '|||';
                display: block;
                position: absolute;
                font-size: 12px;
                top: 2px;
                left: 8px;
                color: $bright-blue;
              }

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }
          }

          .price-slider-text {
            float: left;
            color: rgba(0, 0, 0, 0.35);
            padding-top: 30px;
            padding-left: 10px;
            text-transform: uppercase;
          }

          .md-text-field-container {
            float: left;
            width: auto;
            margin-bottom: 25px;

            .md-text-field-icon-container {
              .md-text-field-divider-container {
                .md-text-field {
                  @include font-size(15);
                  border: 1px solid #d8d8d9;
                  border-radius: 23px;
                  color: rgba(0, 0, 0, 0.6);
                  font-weight: 300;
                  height: 45px;
                  padding-left: 45px;
                  text-align: left;
                  width: 127px;

                  @include mobile {
                    @include font-size(16);
                  }
                }

                ::placeholder {
                  position: relative;
                  top: 2px;
                }

                .md-divider {
                  display: none;
                }
              }
            }
          }
        }

        .button-wrap {
          width: 100%;

          @include desktop {
            padding: 10px 15px !important;
          }
        }

        .price-range-mobile-view {
          padding: 0 34px;

          .price-range-wrapper {
            display: flex;
            justify-content: center;

            .md-text-field-divider-container {
              margin: 0;
            }

            .price-slider-text {
              padding-left: 0;
              margin: 0 10px;
            }
          }

          @include mobile {
            border-top: 0.5px solid #d8d8d9;
            height: 300px;
            margin: 0 34px;
            margin-top: 50px;
            padding: 0;
            padding-top: 25px;
          }

          @include tablet-portrait {
            border-top: 0.5px solid #d8d8d9;
            height: 300px;
            margin: 0 34px;
            margin-top: 50px;
            padding: 0;
            padding-top: 25px;
          }
        }

        .header-sec-wrapper {
          @include mobile {
            position: relative;
            top: -50px;
          }

          @include tablet-portrait {
            position: relative;
            top: -65px;
          }
        }
      }

      .md-dialog-content  {
        .btn-price-popup {
          @include mobile {
            background-color: transparent;
            border: 1px solid #d8d8d9;
            border-radius: 23px;
            color: $black;
            cursor: pointer;
            margin: 20px 4px 0 4px;
            min-width: 60px;
            outline: 0;
            padding: 7px 28px;
            position: relative;
            top: 70px;
            text-transform: capitalize;
            width: 40%;
            z-index: 1;
          }

          @include tablet-portrait {
            background-color: transparent;
            border: 1px solid #d8d8d9;
            border-radius: 23px;
            color: $black;
            cursor: pointer;
            margin: 20px 4px 0 4px;
            min-width: 60px;
            outline: 0;
            padding: 7px 13px;
            position: relative;
            top: 85px;
            text-transform: capitalize;
            z-index: 1;
          }
  
          &.selected {
            @include mobile {
              background-color: $ms-blue;
              border-color: $ms-blue;
              color: $white;
            }

            @include tablet-portrait {
              background-color: $ms-blue;
              border-color: $ms-blue;
              color: $white;
            }
          }
  
        }
      }
    }

    #simple-list-dialog {
      min-height: 100%;
      width: 100% !important;
      max-width: 100% !important;
      overflow-y: auto;
      color: $black;

      .more-title {
        color: $ms-blue;
        display: block;
        @include font-size(14);
        text-transform: unset;
        text-align: left;
        font-weight: 800;
        margin: 0 20px;
        margin-bottom: 10px;

        @include mobile {
          color: $black;
        }

        @include tablet-portrait {
          color: $black;
        }
      }

      .md-dialog-content {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

        .selection-filter {
          .property-wrap {
            height: 100%;
          }

          .button-wrap {
            border-top: none;
          }
        }

        .header-sec-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 24px;
          padding-bottom: 20px;
          border-bottom: 0.5px rgba(112, 112, 112, 0.2) solid;
          margin-bottom: 30px;

          @include mobile {
            padding-top: 0;
          }

          &.duration {
            .more-title {
              flex: 1;
              text-align: center;
            }
          }

          .more-title {
            @include font-size(18);
            font-weight: 800;
            margin: 0px;
            text-transform: unset;
            text-align: center;
          }

          .btn-clear {
            cursor: pointer;
            border: none;
            background-color: transparent;
            color: $ms-blue;
            outline: none;
            padding: 0;
            text-align-last: left;
          }

          .close-icon-wrap {
            text-align-last: right;
          }
        }

        .more-content {
          max-height: 500px;
          overflow-y: auto;

          .more-title {
            text-align: left;
            margin-left: 20px;
            @include font-size(15);
            font-weight: 900;
            text-transform: uppercase;
          }
        }

        .mobile-buttons-wrap {
          text-align: left;
          margin: 0 20px;
          margin-bottom: 30px;
        }

        .button-flat {
          background-color: transparent;
          border: 1px solid #d8d8d9;
          border-radius: 23px;
          color: $black;
          cursor: pointer;
          height: 36px;
          margin-right: 4px;
          margin-bottom: 10px;
          min-width: 70px;
          outline: 0;
          padding: 7px 13px;
          text-transform: capitalize;

          &.selected {
            background-color: $ms-blue;
            border-color: $ms-blue;
            color: $white;
          }
        }
        .mobile-apply-btn-wrapper {
          position: fixed;
          bottom: 0;
          width: 100%;
          padding: 10px 20px;
          left: 0;
          z-index: 2;
          background-color: $white;

          .btn-apply {
            width: 100%;
            padding: 12px 0;
            text-transform: uppercase;
            font-weight: 800;
          }

          .btn-pink {
            background-color: $pink;
            border: 1px solid $pink;
            border-radius: 23px;
            color: $white;
            cursor: pointer;
            outline: 0;
            height: 45px;
          }

          @include mobile {
            border: 0.5px solid rgba(112, 112, 112, 0.2);
          }

          &.mobile-more-btn {
            position: sticky !important;
          }
        }
      }
    }

    @include mobile {
      flex-wrap: nowrap;
      overflow-x: auto;
    }
  }

  @include desktop {
    top: 69px;
  }

  @include tablet-portrait {
    top: 65px;
  }
}
