.request-to-book-wrapper {
    border: 1px solid $light-gray;
    border-top: none;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    position: relative;

    @include desktop {
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        height: auto;
        max-height: 85vh;
    }

    @include tablet-landscape {
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
        height: auto;
        max-height: 85vh;
    }

    .btn-wrapper {
        background-color: transparent;
        bottom: 0;
        position: fixed;
        text-align: center;
        width: 100%;
        z-index: 9;
    
        @include desktop {
           position: relative;
           width: 100%;
        }
    
        .btn-inner-wrapper {
            border: 1px solid $light-gray;
            background-color: $white;
            padding: 10px 24px;

            .submit-btn {
                border-radius: 25px;
                background: $pink;
                border: none;
                color: $white;
                cursor: pointer;
                @include font-size(13);
                font-weight: $black-bold;
                height: 50px;
                width: 100%;
            }
        }
        
      }

    .inner-wrapper {
        padding: 30px 24px;

        .description {
            @include font-size(15);
            margin-bottom: 20px;
            text-align: left;

            .text {
                display: block;
                margin-bottom: 10px;

                .blue-text {
                    color: $ms-blue;
                }
            }
        }

        .request-message {
            border: 1px solid #E9E9E9;
            border-radius: 10px;
            min-height: 155px;
            outline: none;
            padding: 13px 22px;
            width: 100%;
        }

        .title-wrapper {
            display: flex;
            margin-bottom: 4px;

            .title {
                @include font-size(25);
                font-weight: $black-bold;
                text-align: left;
            }

            .sub-text {
                color: $black;
                @include font-size(15);
                margin-left: 8px;
                opacity: 0.5;
            }
        }
    }

    .top-section-wrapper {
        border-bottom: 1px solid $light-gray;
        padding: 30px 24px;
        text-align: left;

        .back-icon {
            cursor: pointer;
            height: 15px;
            transform: rotate(180deg);
        }
    }

}