.popular-space-section {
  padding: 60px 0;
  text-align: left;
  background-color: $white;

  .popular-space-wrap {
    .title {
      @include font-size(25);
      font-weight: $black-bold;
      text-align: left;
      margin-bottom: 25px;
      background: transparent linear-gradient(271deg, $yellow 0%, $error 100%) 0% 0% no-repeat padding-box;
      width: 260px;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: unset;
      -webkit-text-stroke-color: unset;

      @include desktop {
        @include font-size(28);
        margin-bottom: 30px;
      }

      @include mobile {
        padding: 0 20px;
      }
    }

    .btn-arrow {
      background-color: $ms-blue;
      border: 1px solid $ms-blue;
      box-shadow: 0px 1px 4px #00000029;
      cursor: pointer;
      height: 50px;
      width: 50px;
      border-radius: 36px;
      position: absolute;
      outline: 0 !important;
      top: 45%;
      @include flex-row;
      justify-content: center;
      align-items: center;

      &.right {
        right: -54px;

        @include desktop {
          right: -35px;
        }

        @include tablet-landscape {
          right: -35px;
        }
      }

      &.left {
        left: -54px;

        @include desktop {
          left: -35px;
        }

        @include tablet-landscape {
          left: -35px;
        }
      }

      .arrow {
        filter: invert(1);
        height: 18px;
        width: 18px;
        margin-right: 3px;
        

        &.right {
          transform: rotate(180deg);
          margin-left: 3px;
          
        }
      }
    }

    .popular-space-column {
      .wrap {
        border-radius: 10px;
        box-shadow: 0px 1px 6px #00000029;
        height: 485px;
        margin: 0 10px 15px 10px;

        @include tablet-portrait {
          margin-right: 25px;
          margin-left: 25px;
        }

        @include mobile {
          margin-right: 20px;
        }

        &.wrap:first-child() {
          @include mobile {
            margin-left: 30px;
          }
        }

        &.wrap:last-child() {
          @include mobile {
            margin-right: 25px;
          }
        }
      }

      .mobile-empty {
        @include mobile {
          min-width: 10px;
          height: 50px;
        }
      }

      .popular-space-thumbnail {
        @include background-property;
        background-position: center;
        background-size: cover;
        border-radius: 10px 10px 0px 0px;
        cursor: pointer;
        height: 250px;
        position: relative;
        width: 100%;

        .img {
          cursor: pointer;
          position: absolute;
          right: 10px;
          top: 13px;
          width: 22px;
          height: 18px;

          @include desktop {
            width: 37px;
            height: 22px;
          }
        }

        @include tablet-portrait {
          padding: 0;
        }

        @include mobile {
          padding: 0;
        }

        .number-of-people {
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 23px;
          color: #ffffff;
          position: absolute;
          padding: 1px 6px;
          bottom: 10px;
          right: 10px;

          .img {
            width: 10px;
            height: 11px;
            top: 5px;
            left: 5px;
          }

          .people {
            @include font-size(13);
            margin-left: 10px;
          }
        }

        .distance-wrap {
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 23px;
          color: $white;
          @include flex-row;
          justify-content: space-between;
          position: absolute;
          padding: 1px 6px;
          top: 10px;
          left: 10px;

          .img {
            width: 10px;
            height: 11px;
            top: 4px;
            left: 5px;
          }

          .distance {
            display: inline-block;
            @include font-size(13);
            font-weight: $light;
            margin-left: 12px;

            @include mobile {
              @include font-size(10);
            }
          }
        }
      }

      .thumbnail-content {
        cursor: pointer;
        height: 160px;
        padding: 20px 25px 0px 25px;
        text-align: left;

        .address-text {
          color: $black;
          @include font-size(14);
        }

        .content-title {
          color: $black;
          display: block;
          font-weight: 700;
          @include font-size(12);
          text-transform: uppercase;
          line-height: 1.4;
          opacity: 0.5;

          @include desktop {
            @include font-size(13);
          }
        }

        .content-sub-title {
          color: $black;
          display: block;
          @include font-size(20);
          font-weight: $bold;
          text-transform: unset;
          opacity: 1;
        }

        .sub-title {
          color: $black;
          display: block;
          @include font-size(13);
          text-transform: uppercase;
        }

        .number-of-people {
          @include font-size(12);
          display: inline-block;
          margin-left: 10px;
          vertical-align: middle;

          .img {
            width: 12px;
            height: 12px;
            opacity: 0.5;
            margin-right: 2px;
          }
        }

        .price {
          color: $black;
          @include font-size(14);
        }

        .price-description {
          @include font-size(14);
          color: $black;
        }

        .strick-price {
          text-decoration: line-through;
          color: $black;
        }
      }

      .btn-wrap {
        border-top:1px solid $light-blue;
        padding: 15px 0;
        text-align: center;

        .btn-pink {
          font-weight: $extra-bold;
          height: 45px;
          @include font-size(12);
          text-transform: uppercase;
          width: 200px;

          @include desktop {
            @include font-size(14);
          }

          @include tablet-landscape {
            @include font-size(14);
          }
        }
      }

      @include tablet-portrait {
        align-items: top !important;
        overflow-y: auto;
      }

      @include mobile {
        align-items: top !important;
        overflow-y: auto;
      }
    }

    .custom-container {
      @include mobile {
        padding: 0px;
        width: 100%;
      }

      @include desktop {
        padding-right: 0px;
        padding-left: 0px;
        width: 89%;
      }
    
      @include large-desktop{
        width: 83%;
      }
    
      @include exlarge-desktop{
        width: 90%;
      }

      &.container {
        max-width: 1760px;
      }
    }

    .quill {
      .ql-toolbar {
        display: none;
      }
  
      .ql-container {
        &.ql-snow {
          border: none;
        }
  
        .ql-editor {
  
          p {
            @include font-size(45);
            font-weight: $extra-bold;
            margin-bottom: 42px;
            text-align: center;

            @include mobile {
              font-size: 27px;
              line-height: 35px;
            }
          }
        }
      }
    }
  }

  @include desktop {
    padding: 67px 0 60px 0;
  }

  @include tablet-portrait {
    padding-top: 20px;
  }

  @include mobile {
    padding-top: 20px;
  }

  .scroll-btn-wrap {
    display: flex;
    place-content: center;
    justify-content: center;

    .scroll-btn {
      background: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      margin-top: 90px;
      position: absolute;
      scroll-behavior: smooth;
      z-index: 1;
  
      .arrow-icon {
        width: 32px;
      }
  
      @include mobile {
        margin-top: 0;
      }
    }
  }
}
