$max-width-991: 991px;

$max-width-320: 320px;

@mixin max-width-991 {
  @media (max-width: #{$max-width-991}) {
    @content;
  }
}

@mixin max-width-320 {
  @media (max-width: #{$max-width-320}) {
    @content;
  }
}

.md-picker {
  @include react-md-pickers-make-portrait;
  box-shadow: unset;

  .md-picker-header {
    display: none;
  }

  .md-picker-content-container {
    -webkit-box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.26);
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.26);
    border-radius: 0 0 24px 24px;

    .md-picker-content {
      width: 327px;

      @include tablet-landscape {
        width: 280px;
      }

      .md-calendar-header {
        .md-calendar-controls {
          border-bottom: 1px solid #d9d9d9;
          margin-bottom: 15px;

          .md-title {
            font-weight: 800;
          }

          .md-btn {
            .md-ink-container {
              span {
                background: none;
              }
            }

            &.md-btn--hover {
              background: none;
            }
          }
        }

        .md-calendar-control:disabled {
          border: none;
          background-color: $white;
          color: $light-gray;
          cursor: not-allowed;
          pointer-events: none;
        }

        .md-calendar-dow {
          width: 14%;
        }
      }
      .md-calendar-month {
        .md-calendar-date {
          width: 14%;
          height: 36px;
          margin-bottom: 5px;

          .md-text--disabled {
            .md-calendar-date--date {
              color: rgba(0, 0, 0, 0.3) !important;
              cursor: not-allowed;
            }
          }

          .md-calendar-date--btn {
            background-color: $white;
            border: none;
            height: 36px;
            padding: 0 !important;

            .md-calendar-date--date {
              border-radius: 36px;
              color: $bright-blue;
              display: block;
              height: 36px;
              padding: 6px;
              width: 36px;

              .disable-date-cls {
                color: rgba(0, 0, 0, 0.3);
                background: none !important;
                cursor: not-allowed;
              }
              &.md-picker-text--active {
                span {
                  background: $bright-blue !important;
                }

                .disable-date-cls {
                  background: none !important;
                  cursor: not-allowed;
                }
              }
            }

            &.md-calendar-date--btn-active {
              .md-calendar-date--date {
                color: $white;
                background: $bright-blue;

                span {
                  background: $bright-blue;
                }
              }

              &:after {
                background: transparent;
              }
            }

            &.current-date {
              .md-calendar-date--date {
                background-color: $white;
                color: $bright-blue;
                border: 1px solid $bright-blue;
              }

              .md-picker-text--active {
                border: 1px solid $bright-blue !important;
                color: $white !important;
                background-color: $bright-blue !important;
              }
            }

            &:after {
              background: transparent;
            }
          }
        }
      }
    }
    .md-dialog-footer {
      padding: 8px 0;
      border-top: 1px solid #d9d9d9;
      display: flex;
      justify-content: space-between;
      margin: 0 8px;

      .md-text--theme-primary {
        color: $bright-blue;
      }

      .md-btn {
        text-transform: uppercase;
        font-weight: 800;

        .clear-all-btn {
          text-transform: capitalize;
          font-weight: 500;
        }

        &.md-btn--color-primary-active {
          background-color: $white;
        }
      }
    }
    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 20%;
      box-sizing: border-box;
      border: 10px solid black;
      border-color: transparent transparent $white $white;
      transform-origin: 0 0;
      transform: rotate(135deg);
      box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.26);
    }
  }
}

&.space-page {
  .date-selector {
    margin: 0;

    @include mobile {
      height: calc(100vh - 100px);
    }

    @include desktop {
      margin: 30px 0;
      margin-bottom: 0;
    }

    .md-picker-container {
      width: auto;
      margin: 30px 0;

      @include max-width-320 {
        margin: 0 -24px;
      }

      .md-text-field-container {
        display: none;
      }

      .md-picker {
        position: relative;
        width: 100%;
        margin: 0 auto;
        left: unset;

        .md-picker-content-container {
          box-shadow: unset;
          width: 100%;
          margin: 0 auto;

          .md-picker-content {
            box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.1);
            width: 100%;
            max-width: 335px;
            margin: 0 auto;
            height: auto;
            padding: 0;

            .md-calendar-header {
              .md-calendar-controls {
                padding: 15px 12px;

                .md-btn--icon {
                  height: 26px;
                  width: 26px;
                  min-width: 26px;
                  background: $bright-blue;

                  .md-icon {
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include font-size(26);
                    color: $white;
                  }

                  &.md-btn--hover {
                    background: $bright-blue;
                  }
                }
              }
            }

            .md-calendar-month {
              padding-bottom: 12px;
            }
          }

          &::before {
            display: none;
          }
        }
      }

      .md-dialog-footer {
        margin: 0 -8px;
        border-top: 0.5px solid rgba(112, 112, 112, 0.2);
        margin-top: 30px;
        margin-bottom: 5px;
        padding: 0 25px;

        @include max-width-991 {
          margin: 0 !important;
          position: fixed;
          bottom: 0;
          width: 100%;
          padding: 10px 20px !important;
          left: 0;
        }

        .md-btn {
          background-color: $white;
          min-width: auto;

          @include max-width-991 {
            background-color: #ffdd57;
            border: 1px solid #ffdd57;
            border-radius: 23px;
            color: #000000;
            cursor: pointer;
            height: 45px;
            outline: 0;
            width: 100% !important;
            padding: 12px 0;
          }

          .clear-btn {
            background-color: $white;
            border: none;
            color: $bright-blue;
            cursor: pointer;
            @include font-size(15);

            @include desktop {
              padding-left: 0;
            }

            &:focus {
              outline: 0 !important;
            }
          }

          .apply-btn {
            border: none;
            background-color: $white;
            color: $bright-blue;
            cursor: pointer;
            font-weight: $black-bold;
            @include font-size(15);
            outline: none;

            @include max-width-991 {
              background-color: $yellow;
              color: $black;
              font-weight: 800;
            }
          }

          &:first-child {
            @include max-width-991 {
              display: none;
            }
          }
        }
      }

      @include desktop {
        margin: 0;
      }
    }

    .mobile-view-btn-wrapper {
      padding-bottom: 10px;
      border-bottom: 0.5px solid rgba(112, 112, 112, 0.2);

      .mobile-view-inner-btn-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 24px;

        .clear-btn {
          cursor: pointer;
          border: none;
          background-color: transparent;
          color: #006dd1;
          outline: none;
          padding: 0;
        }

        .title {
          @include font-size(18);
          font-weight: 800;
        }
      }
    }
  }
}
