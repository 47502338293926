.message-section {
    background-color: $white;
    padding: 100px 0;

    .message-wrap {
        margin-top: 10px;
        
        .title {
            color: $black;
            @include font-size(26);
            font-weight: $black-bold;
            margin-bottom: 20px;
            text-align: left;
        }

        .content-filters {
            @include flex-row;
            margin: 10px 0;

            .btn-round {
                background-color: $white;
                box-shadow: 0px 1px 3px #00000029;
                border: none;
                border-radius: 100px;
                height: 45px;
                color: $black;
                @include font-size(13);
                margin-right: 10px;          
                min-width: 110px;  
                outline: none;    
                position: relative;
                text-transform: uppercase;
                vertical-align: middle;

                &.active {
                    background-color: $bright-blue;
                    color: $white;
                    font-weight: $bold;
                }

                .number-notification {
                    border-radius: 10px;
                    background-color: #f35250;
                    color: $white;
                    @include font-size(11);
                    padding: 1px 3px;

                    position: absolute;
                    right: 18px;
                    top: -7px;
                }
            }
        }

        .message-grid {
            @include flex-row;
            align-items: flex-start;

            margin: 0;
            margin-top: 10px;
            padding: 0;         
          
            .left-wrap {
               margin-left: 0;              
                              
               .message-list {
                    border: 1px solid #ececec;
                    border-radius: 5px;

                    .message-list-item {
                        @include flex-row;
                        align-items: flex-start;
                        justify-content: space-between;

                        border-bottom: 1px solid #d9d9d9;
                        padding: 20px;                       

                        .message-content {
                            text-align: left;

                            .message-content-wrap {
                                display: inline-block;
                                @include font-size(15);
                                margin: 0;

                                .message-title {
                                    font-weight: $black-bold;
                                }
    
                                .message-sub-title {
                                    color: rgba($color: $black, $alpha: 0.5);
                                    display: block;
                                }
                            }

                            .message-image {
                                border-radius: 50%;
                                height: 40px;
                                margin-right: 10px;
                                width: 40px;
                                vertical-align: top;
                            }
                           
                        }
                
                        .message-date {
                            color: rgba($color: $black, $alpha: 0.4);
                            @include font-size(13);
                            font-weight: $bold;
                        }

                        &:last-child {
                            border-bottom: none;
                        }

                        &.active {
                            background-color: #ececec;

                            .message-sub-title {
                                color: $black;
                            }
                        }
                    }
                }
            }

            .right-wrap {
               margin-right: 0;

               .message-tab {
                   .guest-tab {
                        .md-tab-label {
                            border-bottom: 2px solid $bright-blue;
                            color: $bright-blue;                           
                        }
                    }

                    .host-tab {
                        .md-tab-label {
                            border-bottom: 2px solid #1c8487;
                            color: #1c8487;                           
                        }
                    }

                    .md-tab-label {
                        @include font-size(15);
                        font-weight: $bold;
                        padding: 0 20px 10px 0;
                        text-transform: uppercase;
                    }

                   .md-tab-indicator {
                       display: none;
                   }
               }

               .content {
                    border: 1px solid #ececec;
                    border-radius: 5px;
                    position: relative;

                    .content-header {
                       @include flex-row;
                       align-items: center;
                       justify-content: space-between;

                       background-color: #ececec;
                       padding: 15px 30px;       
                       
                        .content-image {
                            border-radius: 50%;
                            height: 40px;
                            margin-right: 5px;
                            vertical-align: middle;
                            width: 40px;
                        }

                        .content-title {
                            @include font-size(15);
                            font-weight: $bold;
                        }

                       .content-link {
                           .link {
                                color: #006dd1;
                                @include font-size(13);
                                font-weight: $bold;
                                text-decoration: underline;
                           }
                       }
                   }

                   .content-body {
                        overflow-y: auto;
                        max-height: 600px;
                        padding: 15px 10px; 
                        padding-bottom: 75px;
                        
                        .content-message {
                            padding: 10px 15px;
                            text-align: left;

                            .text {
                                border-radius: 10px;
                                min-width: 85px;
                                padding: 10px 15px;

                                .content-time {
                                    display: block;
                                    @include font-size(13);
                                    text-align: right;
                                }
                            }

                            &.received-message {
                                max-width: 100%;

                                .text {
                                    background-color: #ececec;
                                    max-width: 481px;

                                    .content-time {
                                        color: $black-op-50;
                                    }
                                }
                            }

                            &.sent-message {
                                max-width: 100%;

                                .text {
                                    background-color: #006dd1;                                    
                                    color: $white;
                                    float: right;
                                    max-width: 393px;

                                    .content-time {
                                        color: rgba($color: $white, $alpha: 0.5);
                                    }
                                }
                            }
                        }    
                        
                        .content-day {
                            color: rgba($color: $black, $alpha: 0.4);
                            display: block;
                            @include font-size(13);
                            font-weight: $bold;
                        }

                        .clear {
                            clear: both;
                            display: block;
                            overflow: hidden;
                        }
                   }

                   .content-footer {
                        @include flex-row;
                        justify-content: space-between;    

                        border-top: 1px solid #ececec;
                        background-color: $white;
                        bottom: 0;
                        padding: 15px 30px; 
                        position: absolute;
                        width: 100%;
                        z-index: 99;

                        .footer-message {
                            width: 80%;
                            word-break: break-all;

                            hr {
                                display: none;
                            }
                        }

                        .footer-action {
                            @include flex-row;
                            justify-content: flex-end;
                            align-items: center;

                            width: 18%;

                            .icon-image {
                                height: 23px;
                                margin-right: 10px;
                                vertical-align: middle;
                                width: 23px;
                            }

                            .btn-send {
                                border-radius: 25px;
                                background-color: #ffdf49;
                                border: 1px solid #ffdf49;
                                color: $black;
                                height: 40px;
                                font-weight: $black-bold;
                                float: right;
                                text-transform: uppercase;
                                width: 110px;
                            }
                        }                                                   
                    }
               }
            }
        }
    }
} 