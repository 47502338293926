.space-column {
  text-align: left;
  border-radius: 32px;

  @include tablet-portrait {
    overflow-x: hidden;
  }

  @include mobile {
    overflow-x: hidden;
  }

  .space-description {
    color: $black;
    padding: 27px 0px;

    @include tablet-portrait {
      padding: 30px 25px;
    }

    @include mobile {
      padding: 30px 25px;
    }

    .quill {
      .ql-container {
        font-family: "Nunito Sans", sans-serif;
        font-size: 15px !important;

        p {
          font-size: 15px;
        }

        &.ql-snow {
          border: none;

          .ql-editor {
            padding: 10px 0px;

            .ql-size-small {
              font-size: 11px;
            }

            .ql-size-medium {
              font-size: 15px;
            }

            .ql-size-large {
              font-size: 18px;
            }

            .ql-size-huge {
              font-size: 28px;
              line-height: 25px;
            }
          }
        }
      }
      .ql-toolbar {
        &.ql-snow {
          display: none;
        }
      }
    }
  }

  .divider {
    background: $gray;
    margin: 0px;
    opacity: 0.3;

    @include mobile {
      margin: 0 20px;
    }

    @include tablet-portrait {
      margin: 0 20px;
    }
  }

  .space-details {
    display: block;
    padding: 27px 0px;

    .space-details-inner-wrap {
      align-items: baseline;
      display: flex;

      .img {
        margin-right: 22px;
        height: 12px;
        width: 12px;
      }

      .sub-text {
        display: inline-block;
        @include font-size(15);
        font-weight: $bold;
        width: 100%;

        .description {
          font-weight: $regular;

          @include mobile {
            display: block;
            margin-bottom: 16px;
          }

          @include tablet-portrait {
            display: block;
            margin-bottom: 16px;
          }
        }

        @include desktop {
          margin-bottom: 8px;
        }

        @include tablet-landscape {
          margin-bottom: 8px;
        }

      }
    }

    @include tablet-portrait {
      padding: 30px 25px 15px 25px;
    }

    @include mobile {
      padding: 30px 25px 15px 25px;
    }
  }

  .btn-more {
    color: $bright-blue;
    cursor: pointer;
    display: block;
    font-weight: $black-bold;
    margin-bottom: 20px;

    @include tablet-portrait {
      padding: 0 25px;
    }

    @include mobile {
      padding: 0 25px;
    }

    .arrow-img {
      width: 11px;
      transform: rotate(90deg);
      margin-bottom: 4px;
      margin-left: 5px;
    }
  }

  .title-wrap {
    @include flex-row;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
    padding: 25px;
    padding-top: 10px;

    .left-wrap {
      width: 100%;
      line-height: 1.4;

      .btn-map {
        background-color: $ms-blue;
        border-radius: 23px;
        border: none;
        color: $white;
        cursor: pointer;
        font-weight: $bold;
        height: 25px;
        @include font-size(13);
        margin: 0 0 3px 5px;
        outline: none;
        padding: 6px;
        text-align: center;
        text-transform: uppercase;
        width: 90px;

        .icon {
          @include desktop {
            margin-right: 5px;
          }
        }

        .blue-icon {
          margin-right: 3px;
          margin-bottom: 3px;
          width: 8px;
        }

        @include desktop {
          display: none;
        }
      }

      .space-type {
        color: $ms-blue;
        @include font-size(13);
        font-weight: $semi-bold;
        line-height: 18px;
        margin-bottom: 3px;
        text-transform: uppercase;
      }

      .text-country {
        color: rgba($color: #000000, $alpha: 0.5);
        display: inline-block;
        @include font-size(13);
        font-weight: $medium;
        text-transform: uppercase;
      }

      .text-space {
        @include font-size(28);
        display: block;
        font-weight: $black-bold;
        color: $black;
        line-height: 1.2;

        @include tablet-landscape {
          @include font-size(18);
        }
      }

      .text-type {
        color: $bright-blue;
        @include font-size(13);
        font-weight: $medium;
        text-transform: uppercase;
      }

      @include desktop {
        width: 80%;
      }
    }

    .right-wrap {
      margin-bottom: 20px;
      width: 100%;

      .text-right {
        float: right;

        @include mobile {
          height: 20px;
        }
      }

      .align-wrap {
        vertical-align: middle;
      }

      .fav-img {
        margin-right: 5px;
      }

      .text-rating {
        font-weight: $bold;
        color: $black;
        @include tablet-landscape {
          @include font-size(13);
        }

        .text-rating-count {
          color: $black-op-60;
          font-weight: $medium;
        }
      }

      .btn {
        border: 1px solid $ms-blue;
        background-color: $white;
        cursor: pointer;
        outline: none;
        padding: 6px;
        width: 35px;
        text-align: center;
        margin-left: 10px;
        border-radius: 16px;
        @include font-size(13);

        .icon {
          @include desktop {
            margin-right: 5px;
          }
        }

        .text {
          display: none;

          @include desktop {
            display: inline-block;
            vertical-align: middle;
          }
        }

        @include desktop {
          width: auto;
          margin-left: 10px;
          padding: 4px 6px;
        }

        @include tablet-landscape {
          width: auto;
          padding: 3px;
          @include font-size(11);
        }

        @include mobile {
          position: relative;
          bottom: 16px;
        }
      }

      @include desktop {
        width: auto;
        margin-bottom: 0;
      }
    }

    @include desktop {
      flex-direction: row;
      padding: 28px 0px;
    }
  }

  .btn-arrow {
    background-color: $ms-blue;
    border: 1px solid $ms-blue;
    box-shadow: 0px 1px 4px #00000029;
    cursor: pointer;
    height: 36px;
    width: 36px;
    border-radius: 36px;
    color: $white;
    position: absolute;
    outline: 0 !important;
    top: 22%;

    @include flex-row;
    justify-content: center;
    align-items: center;

    &.right {
      right: -20px;
      @include tablet-portrait {
        right: -40px;
      }

      @include mobile {
        right: -40px;
      }
    }

    &.left {
      left: -20px;
      @include tablet-portrait {
        left: -40px;
      }

      @include mobile {
        left: -40px;
      }
    }

    .arrow {
      filter: invert(1);
      width: 12px;
      height: 12px;

      &.right {
        transform: rotate(180deg);
      }
    }

  }

  .wrap {
    border-radius: 10px;
    margin-bottom: 20px;

    @include tablet-portrait {
      margin-left: 15px;
      margin-right: 15px;
    }

    @include mobile {
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  .responsive-slider-wrapper {
    margin-bottom: 15px;
    position: relative;

    .dots {
      display: block;
      margin: 0 auto;
      text-align: center;

      @include desktop {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
      @include tablet-landscape {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
      .dot {
        width: 7px;
        height: 7px;
        display: inline-block;
        border-radius: 50%;
        margin: 10px 4px;

        &.blue {
          background-color: $bright-blue;

          @include desktop {
            background-color: $white;
          }

          @include tablet-landscape {
            background-color: $white;
          }
        }
        &.gray {
          background-color: rgba($color: $ms-blue, $alpha: 0.25);

          @include desktop {
            background-color: rgba($color: $white, $alpha: 0.25);
          }

          @include tablet-landscape {
            background-color: rgba($color: $white, $alpha: 0.25);
          }
        }
      }
    }

    @include mobile {
      margin-bottom: 10px;
    }
  }

  .space-thumbnail {
    @include background-property;
    background-position: center;
    background-size: cover;
    width: 100%;
    padding: 22.2%;
    position: relative;
    border-radius: 10px;

    @include tablet-portrait {
      width: 100%;
      padding: 27%;
    }

    @include mobile {
      padding: 0;
      height: 230px;
    }

    .expand-icon {
      bottom: 25px;
      height: 22px;
      position: absolute;
      right: 25px;
      width: 22px;
    }
  }

  .slider-images-wrap {
    @include mobile {
      margin-top: 10px !important;
    }

    @include tablet-portrait {
      margin-top: 10px !important;
    }

    .slider-images {
      height: 66px;
      padding: 30%;

      @include mobile {
        border-radius: 12px !important;
        height: 65px;
      }

      @include tablet-portrait {
        border-radius: 12px !important;
      }
    }
  }

  &.space-detail-wrap {
    .overview-dialog {
      .carousel-space-thumbnail {
        @include background-property;
        background-position: center;
        background-size: cover;
        width: 116px;
        margin: 20px 25px 25px 0px;
        height: 77px;
        border-radius: 10px;
      }

      .md-dialog-content {
        height: 100%;
        overflow: hidden;

        .popup-slider-details-wrapper {
          color: $white;

          padding-left: 20px;
          padding-right: 20px;
          position: relative;
          @include flex-row;
          justify-content: space-between;

          @include desktop {
            margin-bottom: 10px;
            height: 50px;
            padding: 0;
            justify-content: center;
          }

          .title-score-wrapper {
            width: 70%;

            @include desktop {
              left: 0;
              position: absolute;
              top: 0;
            }

            .title {
              word-break: break-word;
              
              @include desktop {
                @include font-size(18);
              }
            }

            .score-box {
              .star-icon {
                margin-right: 2px;

                img {
                  margin-bottom: 5px;
                }
              }
              .score-value {
                margin-right: 2px;
              }
              .total-value {
                margin-right: 2px;
              }
            }
          }

          .close-icon {
            cursor: pointer;

            @include desktop {
              position: absolute;
              right: 0;
            }
            .close-btn {
              background: $black;
              border: 1px solid $black;
              cursor: pointer;
              padding: 0;

              &:focus {
                outline: none;
              }

              .img {
                filter: invert(1);

                &:focus {
                  outline: none;
                }
              }
            }
          }
        }

        .image-count {
          &.desktop-view {
            display: none;
            @include desktop {
              display: block;
            }
          }
        }

        .image-count-wrapper {
          color: $white;
          position: absolute;
          bottom: calc(100vh - 545px);
          width: 100%;
          text-align: center;
          .image-count {
            .mobile-view {
              @include desktop {
                display: none;
              }
            }
          }
        }

        .ant-carousel {
          @include tablet-portrait {
            padding-top: 35%;
          }

          @include mobile {
            padding-top: 35%;
          }
        }
        &.md-dialog-content--padded {
          padding: 0;

          @include desktop {
            padding: 24px;
          }
        }
      }

      .md-background--card {
        background-color: transparent;
      }

      .md-dialog {
        width: 100%;
        height: 100%;
        max-width: calc(100% - 194px);

        @include mobile {
          max-width: 100% !important;
        }

        .list {
          @include flex-row;
          width: 100%;

          .list-item {
            width: 100% !important;
            height: calc(100vh - 225px);
            @include background-property;
            border-radius: 10px;
            background-size: contain;

            @include tablet-portrait {
              height: 543px !important;
            }

            @include mobile {
              height: 252px !important;
              border-radius: unset;
            }
          }
        }

        .slick-dots {
          display: flex !important;
          justify-content: flex-start;
          height: 120px;

          @include desktop {
            justify-content: center !important;
          }

          @include tablet-portrait {
            justify-content: center;
          }

          @include mobile {
            justify-content: center;
          }
        }

        .slick-dots-bottom {
          button {
            width: 7px;
            height: 7px;
            border-radius: 7px;
            margin-right: 7px;
          }

          bottom: -110px;
          @include tablet-portrait {
            bottom: -145px;
          }

          @include mobile {
            bottom: -145px;
          }
        }
      }

      .text-rating {
        vertical-align: middle;
      }

      &.md-overlay {
        background: rgba(0, 0, 0, 1);
      }
    }

    .share-dialog {
      padding: 0;

      .md-dialog {
        width: 400px;
        border-radius: 24px;
      }

      .md-dialog-content {
        padding: 0 !important;
        border-radius: 24px;
      }

      .header {
        @include flex-row;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid $gray;

        .title {
          @include font-size(18);
          font-weight: $black-bold;
          margin-bottom: 0;
        }

        .close-icon {
          cursor: pointer;
        }
      }

      .content {
        .link {
          padding: 15px 35px;
          border-bottom: 1px solid $gray;
          display: block;
          font-weight: $bold;
          color: $black !important;
        }
      }
      &.social-media-popup {
        .md-dialog-content {
          .header {
            padding: 15px 35px;
          }
          .link {
            padding-left: 35px;

            .img-wrapper {
              text-align: center;
              width: 16px;
              display: inline-block;
              margin-right: 25px;
            }
          }
        }
      }
    }
    .continue-btn-wrapper {
            background-color: transparent;
            bottom: 0;
            position: fixed;
            right: 0;
            text-align: center;
            width: 100%;
            z-index: 9;

            @include desktop {
              right: 5%;
              width: 29%;
            }

            .btn-grid-wrapper {
              padding-left: 0;
              padding-bottom: 0;

              @include desktop {
                margin: 0 -8px;
                width: 100%;
              }

              .continue-btn-inner-wrapper {
                padding: 0 24px;
                border-top: 1px solid $light-gray;
                border-left: 1px solid $light-gray;
                border-right: 1px solid $light-gray;
                background-color: $white;
                padding-top: 10px;
                padding-bottom: 10px;

                .description {
                  display: block;
                  @include font-size(10.5);
                  padding-bottom: 10px;

                  .blue-text-link {
                    color: $bright-blue;
                    cursor: pointer;
                    display: inline-block;
                    text-decoration: underline;
                    font-weight: $bold;
                  }
                }

                .md-btn {
                  color: $black;
                  cursor: pointer;
                  background-color: #ffdd57;
                  border-radius: 25px;
                  height: 50px;
                  width: 100%;
                  font-size: 13px;

                  .small-text {
                    color: rgba(0, 0, 0, 0.7);
                    display: block;
                    line-height: 9px;
                    font-size: 11px;
                    font-weight: 600;
                    text-transform: none;

                    .mr-4 {
                      margin-right: 4px;
                    }
                  }

                  &:disabled {
                    pointer-events: none;
                    border: 1px solid $light-gray !important;
                    background-color: $light-gray !important;
                    color: $black !important;
                    cursor: not-allowed;
                  }
                }
              }
            }
          }

    .sc-bxivhb {
      margin: 0 5px;
      width: 115px;

      &:first-child {
        margin-left: 0px;

        @include mobile {
          margin-left: 15px;
        }

        @include tablet-portrait {
          margin-left: 15px;
        }
      }

      &:last-child {
        margin-right: 0px;
      }

      @include mobile {
        width: 100px;
      }
    }
  }

  @include desktop {
    background-color: $white;
  }
}
