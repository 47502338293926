.the-board {
  .content-container-wrapper {
    &.mobile-slider {
      .ant-carousel {
        .slick-dots {
          bottom: -30px;
          li {
            button {
              width: 7px;
              height: 7px;
              background-color: $bright-blue;
              border-radius: 32px;
            }
          }
        }
      }
    }
  }
}

.leadership {
  .content-container-wrapper {
    &.mobile-slider {
      .ant-carousel {
        .slick-dots {
          bottom: -30px;
          li {
            button {
              width: 7px;
              height: 7px;
              background-color: $bright-blue;
              border-radius: 32px;
            }
          }
        }
      }
    }
  }
}

.testimonials-section {
  .testimonials-wrap {
    .custom-container {
      .ant-carousel {
        .slick-dots-bottom {
          @include mobile {
            bottom: 30px;
          }
        }
      }
    }
  }
}

.cancellation-tooltip {
  width: 305px;

  .ant-tooltip-inner {
    background-color: $black;
    border-radius: 3px;
    padding: 20px;

    .title {
      font-size: 15px;
      font-weight: $extra-bold;
    }

    .text {
      font-size: 13px;
    }

    .separator {
      border: none;
      border-bottom: 0.5px solid rgba($color: $white, $alpha: 0.2);
    }

    .flex-wrap {
      @include flex-row;
      justify-content: space-between;
    }
  }
}

.report-tooltip {
  .ant-tooltip-inner {
    font-size: 13px;
  }
}

//packages menu
.packages-submenu-block {
  width: 25%;
}

.covid-tooltip {
  &.ant-tooltip {
    border-radius: 5px;
    color: $white;
    max-width: unset;
    padding: 0;
    text-align: left;
    width: 93%;
    left: 15px !important;

    @include desktop {
      left: 70px !important;
      max-width: 850px;

      width: 100%;
    }

    @include tablet-landscape {
      max-width: 850px;
      width: 100%;
    }

    .ant-tooltip-content {
      margin-top: 20px;
    }

    .ant-tooltip-arrow {
      left: 238px;
      top: 7px;
      width: 20px;
      height: 20px;

      @include desktop {
        left: 22%;
      }

      @include tablet-landscape {
        left: 22%;
      }

      @include tablet-portrait {
        left: 238px;
      }

      &:before {
        background-color: $ms-blue;
        width: 20px;
        height: 20px;
      }
    }
    .ant-tooltip-inner {
      background-color: $ms-blue;
      box-shadow: unset;
      padding: 15px;
      font-size: 15px;
      width: 100%;

      @include desktop {
        padding: 25px;
        width: 100%;
      }

      @include tablet-landscape {
        padding: 25px;
        width: 100%;
      }

      .yellow-text {
        color: #ffdd57;
        font-weight: $semi-bold;
      }
    }
  }
}

.newsletter-dialog {
  text-align: -webkit-center;
  text-align: -moz-center;

  .ant-modal-content {
    border-radius: 24px;
    width: 335px;

    @include desktop {
      width: 373px;
    }

    .ant-modal-body {
      padding: 0;

      .btn-close {
        cursor: pointer;
        position: absolute;
        right: 22px;
        top: 20px;

        .icon-close {
          width: 28px;
        }
      }

      .btn-wrap {
        border-top: 1px solid $gray;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding: 10px 25px 0px 25px;
        width: 100%;

        .btn-skip {
          font-weight: $bold;
          height: 45px;
          @include font-size(13);
          padding: 10px 0px;
          width: 157px;

          @include mobile {
            width: 138px;
          }

          @include tablet-portrait {
            width: 138px;
          }
        }

        .btn-subscribe {
          font-weight: $black-bold;
          height: 45px;
          @include font-size(13);
          padding: 10px 0px;
          width: 157px;

          &:disabled {
            background: $yellow;
            opacity: 0.5;
            pointer-events: none;
          }

          @include mobile {
            width: 138px;
          }

          @include tablet-portrait {
            width: 138px;
          }
        }
      }

      .form-wrapper {
        padding: 20px 0px 15px 0px;

        @include desktop {
          padding: 30px 0px 15px 0px;
        }

        .newsletter-form {
          .ant-input {
            box-shadow: 0px 0px 6px #00000033;
            border-radius: 60px;
            border: none;
            color: $black;
            font-size: 15px;
            font-weight: $bold;
            height: 45px;
            padding: 4px 46px 4px 26px;
            width: 314px;
            -webkit-appearance: none;
            -webkit-box-shadow: 0px 0px 6px #00000033;

            @include mobile {
              width: 282px;
            }
          }

          .btn-submit {
            background: transparent;
            border: none;
            cursor: pointer;
            float: right;
            outline: none;
            position: relative;
            padding-right: 10px;
            top: -38px;

            .icon-blue {
              width: 30px;
            }
          }

          .error-wrap {
            .error {
              color: $error;
              display: block;
              font-size: 13px;
              margin: 10px 25px;
              padding-left: 5px;
              text-align: left;
            }
          }
        }

        .title {
          font-weight: $black-bold;
          font-size: 18px;
          text-align: center;

          @include desktop {
            font-size: 20px;
          }
        }

        .text {
          font-size: 13px;
          text-align: center;
          margin: 0px 10px 20px 10px;
          padding: 0px 15px;

          @include desktop {
            font-size: 15px;
            margin: 0px 25px 20px 25px;
            padding: 0;
          }
        }
      }

      .img {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        background-size: cover;
        height: 190px;

        @include desktop {
          height: 210px;
        }
      }
    }

    .ant-modal-close-x {
      display: none;
    }
  }
}

.space-listing-drop-down {
  background-color: transparent !important;
  box-shadow: unset !important;
  margin: -2px 0 0 0 !important;

  .icon-text-wrapper {
    align-items: flex-start !important;

    .icon {
      height: 15px;
      margin-top: 5px;
    }
  }

  .ant-select-dropdown-menu {
    background-color: $white !important;
    border: 1px solid $light-gray3;
    border-radius: 24px;
    padding: 15px 0;

    .ant-select-dropdown-menu-item {
      color: $black;
      padding-left: 20px;
      padding-right: 20px;

      &.ant-select-dropdown-menu-item-active {
        background-color: $light-gray3;
      }

      &.ant-select-dropdown-menu-item-selected {
        background-color: $light-gray3;
      }

      .icon-text-wrapper {
        @include flex-row();
        align-items: end;

        .text-wrapper {
          margin-left: 10px;

          .title {
            font-weight: $bold;
            text-transform: capitalize;
          }

          .description {
            @include font-size(11);
            color: $dark-gray;
            line-height: 13px;
            max-width: 355px;
            width: 100%;
            white-space: normal;
          }
        }
      }
      .ant-select-selected-icon {
        visibility: hidden;
      }

      &.list-text {
        cursor: auto;
        color: $dark-gray;
        font-size: 13px;
      }
    }

    .ant-select-dropdown-menu-item-group {
      .ant-select-dropdown-menu-item-group-title {
        border-bottom: 0.5px solid $light-gray3;
        padding-left: 20px;
        padding-right: 20px;
        height: auto;

        .introduction {
          display: block;
          font-size: 13px;
          line-height: 18px;
          max-width: 260px;
          padding-bottom: 10px;
          width: 100%;
        }
      }
    }
  }

  &.cancellation-policy {
    .ant-select-dropdown-menu-item {
      padding: 5px 25px;

      &.ant-select-dropdown-menu-item-selected {
        background-color: $light-gray3;

        .option-content-wrapper {
          .img {
            visibility: visible;
          }
        }
      }

      &:hover {
        background-color: $light-gray3;
      }

      &.ant-select-dropdown-menu-item-active {
        background-color: $light-gray3;
      }
    }

    .option-content-wrapper {
      @include flex-row();
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .title-description-wrapper {
        .option-title {
          @include font-size(15);
          color: $black;
          font-weight: $bold;
          line-height: 20px;
        }

        .option-description {
          @include font-size(13);
          color: $dark-gray;
        }
      }
      .img {
        width: 14px;
        height: 10px;
        visibility: hidden;
      }
    }
  }
  &.package-drop-down {
    .ant-select-dropdown-menu {
      .wrapper {
        @include flex-row();
        .title {
          color: $black;
          font-size: 15px;
          line-height: 20px;
        }
        .introduction {
          color: $dark-gray;
          font-size: 11px;
          max-width: unset;
          width: auto;
        }
      }
      .ant-select-dropdown-menu-item-group {
        .ant-select-dropdown-menu-item-group-title {
          .wrapper {
            @include flex-row();
            .title {
              color: $black;
              font-size: 15px;
              line-height: 20px;
            }
            .introduction {
              font-size: 10px;
              max-width: unset;
              width: auto;
            }
          }
        }
      }
    }
  }
}

.popup-tooltip {
  max-width: 232px !important;
  width: 100%;

  .ant-tooltip-content {
    .ant-tooltip-arrow {
      &::before {
        background-color: $black;
        width: 8px;
        height: 8px;
      }
    }
    .ant-tooltip-inner {
      background-color: $black;

      .tooltip-content {
        text-align: center;
      }
    }
  }
}

.ant-time-picker-panel {
  &.hourly-picker-popup {
    left: unset !important;
    right: calc(50% + 100px);
    padding-top: 45px;

    .ant-time-picker-panel-input-wrap {
      height: 0;
      padding: 0;
      visibility: hidden;
    }
  }

  &.card-hourly-picker-popup {
    padding-top: 45px;
    right: unset;

    .ant-time-picker-panel-input-wrap {
      height: 0;
      padding: 0;
      visibility: hidden;
    }
  }
}

.country-select-wrap {
  margin-bottom: 20px;

  .radio-btn-wrapper {
    .radio-btn-inner-wrapper {
      border: 1px solid $white;
      border-radius: 23px;
      height: 47px;
      opacity: 1;

      .ant-radio-button-wrapper {
        background: transparent;
        border: none;
        border-radius: 19px;
        font-size: 13px;
        font-weight: $semi-bold;
        height: 35px;
        margin: 5px;
        padding: 0 12px;
        transition: 0.4s;
        width: 105px;

        &.ant-radio-button-wrapper-checked {
          background: $white 0% 0% no-repeat padding-box;
          border: none;
          box-shadow: none;
          color: $black;
          opacity: 1;
          outline: none;
        }

        .img {
          margin-right: 5px;
        }
      }

      .ant-radio-button-wrapper:not(:first-child)::before {
        display: none;
      }

      .ant-radio-button-wrapper:hover {
        color: $black;
      }
    }
  }
}

.navbar-location-dropdown {
  &.ant-select-dropdown {
    box-shadow: 0px 1px 6px #00000029;
    border-radius: 24px;
    margin:5px 10px 0px 10px;
    padding:15px 10px 15px 10px;
    width: 340px !important;

    @include mobile {
      left: 20px !important;
      padding-top: 12px;
      width: 330px !important;
    }

    @include tablet-portrait {
      left: 20px !important;
      padding-top: 12px;
      width: 330px !important;
    }
  }

  .ant-select-dropdown-menu {
    max-height: fit-content;

    .ant-select-dropdown-menu-item {
      color: $black;
      font-size: 15px;
      padding: 5px 15px;

      &.ant-select-dropdown-menu-item-disabled {
        opacity: 0.5;
        padding: 5px 0px;
      }

      .location-icon {
        margin-right: 8px;
      }
    }

    .ant-select-dropdown-menu-item:hover {
      background: #EFF5F6;
      border-radius: 8px;
    }
    
    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: #EFF5F6;
      border-radius: 8px;
    }

    .img {
      height: 14px;
      margin-right: 10px;
    }
  }

  .country-select-wrap {
    margin-bottom: 0px;

    .radio-btn-inner-wrapper {
      background: #f2f2f2;
      border: none;

      .ant-radio-button-wrapper {
        .img {
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}

.homepage-location-dropdown {
  &.ant-select-dropdown {
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 24px;
    margin:5px 10px 0px 10px;
    padding:15px 10px 15px 10px;
    // top: 302px !important;
    width: 340px !important;
    z-index: 1;

    @include mobile {
      left: 20px !important;
      position: fixed;
      // top: 260px !important;
      width: 335px !important;
      z-index: 112;
    }

    @include tablet-portrait {
      left: 20px !important;
      position: fixed;
      // top: 260px !important;
      width: 335px !important;
      z-index: 112;
    }

    @include tablet-landscape {
      // top: 302px !important;
    }

    @include laptops {
      // top: 269px !important;
    }
  }

  .ant-select-dropdown-menu {
    max-height: fit-content;

    .ant-select-dropdown-menu-item {
      color: $black;
      font-size: 15px;
      padding: 5px 15px;

      &.ant-select-dropdown-menu-item-disabled {
        opacity: 0.5;
        padding: 5px 15px;

        hr {
          position: relative;
          left: -20px;
          width: 350px;
        }
      }

      .location-icon {
        margin-right: 10px;
      }
    }

    .ant-select-dropdown-menu-item:hover {
      background: #EFF5F6;
      border-radius: 8px;

      &.ant-select-dropdown-menu-item-disabled {
        background: none;
        cursor: auto;
      }
    }

    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: #EFF5F6;
      border-radius:8px;
    }

    .img {
      height: 14px;
      margin-right: 10px;
    }
  }
}

.homepage-activity-select,
.navbar-activity-select {
  &.ant-select-dropdown {
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 24px;
    padding:12px 0px 0px 0px;
    width: 270px !important;
  }

  .ant-select-dropdown-menu {
    max-height: unset;

    .ant-select-dropdown-menu-item {
      border-radius: 8px;
      color: $black;
      font-size: 15px;
      margin-left: 5px;
      margin-top: 5px;
      margin-right: 5px;
      padding: 5px 15px;
      

      &:last-child {
        border-top: 0.5px solid #d9d9d9;
        border-radius:0px;
        color: #FD4164;
        margin-top: 5px;
        text-align: center;     
      }
    }

    .ant-select-dropdown-menu-item:hover {
      background: #EFF5F6;

      &:last-child {
        background: unset;
      }
    }
    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: #EFF5F6;
    }

    .img {
      height: 14px;
      margin-right: 10px;
    }
  }
}

.navbar-activity-select {
  &.ant-select-dropdown {
    margin-top:54px; 
  }
}

.homepage-activity-select {
  &.ant-select-dropdown {
    padding:12px 0px 0px 0px;
    // top: 307px !important;
    z-index: 1;

    @include mobile {
      left: 20px !important;
      position: fixed;
      // top: 200px !important;
      width: 335px !important;
      z-index: 112;
    }

    @include tablet-portrait {
      left: 20px !important;
      position: fixed;
      // top: 200px !important;
      width: 335px !important;
      z-index: 112;
    }

    @include tablet-landscape {
      // top: 307px !important;
    }

    @include laptops {
      // top: 274px !important;
    }
  }
}

.custom-ant-space-modal-calandar {
  .ant-modal {
    margin: 0 auto;
    max-width: 380px;
    cursor: pointer;
    @include mobile {
      max-width: 100%;
    }

    .ant-modal-content {
      border-radius: 20px;
      text-align: center;

      .ant-modal-close {
        display: none;
      }

      .ant-modal-header {
        padding: 25px 25px 8px 25px ;
        @include flex-row;
        justify-content: flex-start;
        border-radius: 16px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        .ant-modal-title {
          font-size: 18px;
          font-weight: $bold;
          color: $black;
          width: 100%;
          .header-container-calnder{
            @include flex-row;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%

          }
          .google-container{
            @include flex-row;
            align-items: flex-start
          }
          .google-container-col{
            @include flex-column;
            align-items: flex-start;
            margin-left: 8px;
            .google-text{
             color:$black;
             font-weight: $bold;
             font-size: 13px;
             margin-bottom: 0px;
            }
            .authorized-text{
              color:$dark-green-II;
              font-size: 12px;
              font-weight: $bold;
              margin-bottom: 0px;
              text-decoration: underline;
            }
            .disabled {
              pointer-events: none; 
              opacity: 0.5; 
              // filter: grayscale(100%);
            }

          }
          .img-google{
            width: 15px;
            height: 16px;
           }
           .img-delete{
            height: 13px;
            width: 12px;

           }
           .disabled {
            pointer-events: none; 
            opacity: 0.5; 
            // filter: grayscale(100%);
          }
        }
      }

      .ant-modal-body {
        padding: 20px 20px 20px 24px ;
        @include flex-row;
        align-items: center;
        justify-content: flex-start;

        .radio-group {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex-wrap: wrap;
          
        }
        .radio-item{
          color: $black;
            font-size: 13px;
            font-weight: $regular;
            max-width: 340px;
            word-break: break-all;
            display: flex;
            text-align: left;
            min-width: 100px;
            white-space: unset;
            
}
            
        
        .ant-radio-wrapper {
          margin-bottom: 10px;
        }
        
        .ant-radio-checked .ant-radio-inner {
          border-color: #1C8487;
          box-shadow: none;
        }
        
        .ant-radio-inner::after {
          background-color: #1C8487;
        }
        .async-conatine{
          @include flex-row;
          align-items: center ;
        }
        .retry-main{
          font-size: 13px;
          font-weight: $regular;
          color: $black;
          width: 196px;
        }
        .retry-text{
          font-weight: $bold;
        }
      }

      .ant-modal-footer {
        padding: 10px 0px;
        @include flex-row;
        justify-content: center;

        .assign-button{
         background-color: $dark-green-III;
         @include flex-row;
         min-width: 172px;
         height: 38px;
         padding: 0px 16px;
         border-radius: 100px;
         color: $white;
         font-size: 12px;
         font-weight: $extra-bold;
         border: none;
        }
        .custom-cancel-button-assign {
          border: 0.5px solid #7B7B7B;
          color: $black;
          width: 120px;
          height: 40px;
          font-size: 13px;
          font-weight: $black-bold;
          border-radius: 20px;

          .custom-cancel-button-assign ::after &:hover,&:focus, &:active{
            box-shadow: none !important;
          }
        }
        
      }
    }
  }
}
.custom-ant-space-modal-calandar-resync{
  .ant-modal {
    .ant-modal-content {
      .ant-modal-header{
        .ant-modal-title {
          .google-container-col{
            .authorized-text{
              color:$gray-II;
            }

          }
        }

      }
      .ant-modal-body {
       
        @include flex-row;
        align-items: center;
        justify-content: center;
       padding: 64px 0px;
       
        .async-conatine{
          @include flex-row;
          align-items: center ;
        }
        .retry-main{
          font-size: 13px;
          font-weight: $regular;
          color: $black;
          width: 201px;
        }
        .retry-text{
          font-weight: $bold;
          color: $black;
        }
      }
      .ant-modal-footer {
        .assign-btn-async{
          background-color: $gray-II;

        }

      }

    
    }
  }

}

.custom-ant-space-modal-assign{
  .ant-modal {
    margin: 0 auto;
    max-width: 406px;
    cursor: pointer;

    @include mobile {
      max-width: 100%;
    }

    .ant-modal-content {
      border-radius: 20px;
      text-align: center;

      .ant-modal-close {
        display: none;
      }

      .ant-modal-header {
        padding: 20px 0px;
        @include flex-row;
        justify-content: center;
        border-radius: 20px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        .ant-modal-title {
          font-size: 18px;
          font-weight: $bold;
          color: $black;
        }
      }

      .ant-modal-body {
        padding: 20px 50px;
        @include flex-row;
        align-items: center;
        justify-content: center;

        .modal-text-save-head {
          font-size: 15px;
          font-weight: $regular;
          color: $black;
          text-align: center;
          width: 232px;
          margin: 0 !important;
        }

        .note {
          // color: $light-Gray-10;
          font-size: 13px;
          font-weight: $regular;
          text-align: center;
          margin-top: 10px;
        }
      }

      .ant-modal-footer {
        padding: 10px 0px;
        @include flex-row;
        justify-content: center;

        .ant-btn-primary {
          background-color:#2A8384;
          border: none;
          border-radius: 20px;
          color: $white;
        }

        .ant-btn {
          border-radius: 20px;
          width: 120px;
          height: 40px;
          font-size: 13px;
          font-weight: $black-bold;
        }

        .custom-cancel-button-assign {
          border: 0.5px solid #7B7B7B;
          color: $black;
          width: 120px;
          height: 40px;
          font-size: 13px;
          font-weight: $black-bold;
          border-radius: 20px;

          .custom-cancel-button-assign ::after &:hover,&:focus, &:active{
            box-shadow: none !important;
          }
        }
      }
    }
  }
}

.custom-ant-space-modal-assign-unlink{
  .ant-modal {
    .ant-modal-content {
      .ant-modal-body {
        .green-content{
          background-color: #D4E6E6;
          padding: 12px 15px;
          border-radius: 12px;
          color:#2A8485 ;
          margin-top: 16px;
          @include flex-row;
          justify-content: flex-start;
        }
        .unlinking-text-text{
          color: #2A8485;
          font-size: 13px;
          font-weight:$semi-bold ;
          margin-bottom: 0px !important;
        }
        .unlink-points{
          color:#2A8485 ;
          font-size: 13px;
          font-weight: $regular;
          text-align: justify;
          width: 271px;
        }
        .unlink-head{
          width: 285px;
         
        }
        .info-green{
          height: 14px;
          width: 14px;
          margin-right: 8px
        }
        .icon-conetnt{
          @include flex-row;
          flex-wrap: nowrap;
          align-items: center;
          margin-bottom: 5px;
        }
      }

    
    }
  }
}
.popover-sync{

  max-width: 300px;
  padding: 25px 60px 25px 25px;
  height: 66px;
  width: 300px;
  margin-left: -15px;

}
.sync-text{
  color: $black;
  font-size: 13px;
  font-weight:$extra-bold;
  width: 200px;
}

.ant-popover-inner-content {
  padding:0px !important
}
.custom-ant-space-modal-calandar-google{
  .ant-modal {
    cursor: pointer;
    position: absolute;
    top: calc(20% + 10px);
    left: 50%;
    width: 100%;
    z-index: 1000;
    max-width: 280px;
    
    .ant-modal-content {
      border-radius: 20px;
      text-align: center;
     
      .ant-modal-close {
       cursor: pointer;
      }
      .ant-modal-body {
        padding: 0px 24px 0px 24px;
        @include flex-row;
        align-items: center;
        justify-content: flex-start;
        height: 66px;
        margin: 0 auto;
        .calendar-btn-google{
          background-color: transparent;
          border: none;
          cursor: pointer;

        }
        
      }

    
    }
  }
}
.ant-btn::after {
  box-shadow: none !important;
  border: transparent;
}

.ant-notification-notice-message {
margin-bottom: 0 !important;
}
.ant-notification-notice {
  padding: 0 !important;
}

 