$font-family: 'Nunito Sans', sans-serif;
$font-family2: 'Montserrat', sans-serif;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black-bold: 900;

$black: #000000;
$black-op-60: rgba(0, 0, 0, 0.6);
$black-op-50: rgba(0, 0, 0, 0.5);
$black-op-40: rgba(0, 0, 0, 0.4);
$black-op-30: rgba(0, 0, 0, 0.3);
$gray: #f6f6f6;
$dark-gray: #7f7f7f;
$silver-gray: #f0f0f0;
$light-gray: #d9d9d9;
$light-gray2: #e2e2e2;
$light-gray3: #f2f2f2;
$light-gray4: #b7b7b7;
$dull-gray: #e5e5e5;
$dark-grey: #ababab;
$gray-II:#D9DAD9;
$white: #ffffff;
$yellow: #ffdd57;
$bright-blue: #006dd1;
$ms-blue: #29abe2;
$light-blue: #EBF8FD;
$ms-blue-op-05: rgba(41, 171, 226, 0.05);;
$teal: #09a5a0;
$green: #20ae8f;
$host-green: #248486;
$dark-green: #2a8485;
$dark-green-II:#118488;
$dark-green-III:#2A8384;
$dark-green-op-90: rgba(42, 132, 133, 0.9);
$dark-green-op-05: rgba(42, 132, 133, 0.05);
$gradient1: #00e9ed;
$gradient2: #008be8;
$error: #f35050;
$red: #a00909;
$pink: #ff4164
