.my-bookings {
    margin-top: 60px;

    .custom-container {
        @include desktop {
            width: 60%;
        }
    }

    .header {
        padding: 35px 0 30px 0;
        text-align: left;

        .flex-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .title {
            color: $black;
            @include font-size(25);
            display: inline-block;
            font-weight: $black-bold;
            margin-bottom: 0;
            text-align: left;
        }

        .btn-blue-outline {
            border: 1px solid $bright-blue;
            border-radius: 25px;
            background-color: transparent;
            color: $bright-blue;
            height: 40px;
            padding: 10px 15px;
            min-width: 110px;

            @include desktop {
              display: none;
            }
  
            @include tablet-portrait {
              display: none;
            }
          }


        @include desktop {
          border-bottom: 1px solid $gray;
          padding: 35px 0 20px 0;
        }

        @include tablet-portrait {
          border-bottom: 1px solid $gray;
          padding: 35px 0 20px 0;
        }
    }

    .content {
        .booking-grid {
            padding: 0;

            .spacy-left {
                border-right: 1px solid $gray;
                margin: 0;
                padding: 20px 30px 20px 0;

                .list {
                    .list-items {
                        cursor: pointer;
                        color: $black;
                        @include font-size(13);
                        font-weight: $bold;
                        display: block;
                        padding: 8px 20px;
                        text-align: left;
    
                        &.active {
                            border: 1px solid $ms-blue;
                            border-radius: 25px;
                            color: $ms-blue;
                        }
                    }
                }
            }

            .spacy-right {
                padding: 20px 0px;
                padding-top: 0;
                margin: 0;

                .card-list {
                    margin-top: 20px;

                    .card {
                        background-color: $white;
                        box-shadow: 0px 0px 2px #0000001A;
                        cursor: pointer;

                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 15px;

                        .img {
                            border-radius: 5px 0 0 5px;
                            height: 102px;
                            width: 100px;
                            max-width: 100%; 
                            @include background-property();
                            background-size: cover !important;    
                            
                            @include desktop {
                                height: 103px;
                            }
                        }

                        .ellipsis-img {
                            cursor: pointer;
                            height: 11px;
                            width: 16px;
                            max-width: 100%;  
                        }

                        .content {
                            border: 1px solid $light-gray;
                            border-left: none;
                            border-radius: 0 5px 5px 0;
                            flex: 1;
                            min-height: 102px;
                            min-width: 68%;
                            padding: 5px 15px;   
                            text-align: left; 
                            
                            .type {
                                color: $black-op-50;
                                display: block;
                                @include font-size(11);
                                font-weight: 600;
                                text-transform: uppercase;
                            }

                            .title {
                                color: $black;
                                display: block;
                                @include font-size(15);
                                font-weight: $black-bold;
                                margin-bottom: 3px;
                            }

                            .status {
                                border-radius: 23px;
                                background-color: rgba($color: $black, $alpha: 0.05);
                                color: $black-op-60;
                                display: inline-block;
                                @include font-size(11);
                                margin-bottom: 1px;
                                padding: 5px 6px;

                                &.requested {
                                    background-color: rgba($color: #0671d4, $alpha: 0.07);
                                    color: #0671d4; 
                                }

                                &.declined {
                                    background-color: rgba($color: #f35150, $alpha: 0.07);
                                    color: #f35150; 
                                }

                                &.pending {
                                    background-color: rgba($color: #997C00, $alpha: 0.07);
                                    color: #997C00; 
                                }
                            }

                            .owner {
                                color: $black-op-60;
                                display: inline-block;
                                @include font-size(11);
                                font-style: italic;
                                margin-left: 7px;
                            }

                            .date {
                                color: $black-op-50;
                                display: block;
                                @include font-size(11);
                                margin-top: 3px;

                                .icon {
                                    height: 9px;
                                    margin-bottom: 2px;
                                    margin-right: 2px;
                                    opacity: 0.5;
                                }
                            }

                            @include desktop {
                                min-width: 77%;
                                min-height: 103px;
                            }
                        }
                    }

                    &:first-child {
                        margin-top: 0;
                    }
                }

                @include desktop {
                    padding: 20px 0px 20px 30px;
                }

                @include tablet-portrait {
                    padding: 20px 0px 20px 30px;
                }

                @include mobile {
                    width: 100%;
                }
            }
        }
    }

    .btn-select {
        .ant-select-selection {
            border: 1px solid $bright-blue;
            border-radius: 25px;
            background-color: transparent;
            outline: none;
            box-shadow: none;
            color: $bright-blue;
            font-weight: $bold;
            height: 35px;
            padding: 10px 15px;
            min-width: 118px;

            .ant-select-selection__rendered {
                line-height: 14px;
            }

            .ant-select-arrow {
                svg {
                    path {
                        color: $bright-blue;
                    }
                }
            }
        }

        @include desktop {
            display: none;
        }
    }

    @include desktop {
        margin-top: 70px;
    }

    &.admin-dashboard {
        .content {
            .booking-grid {   
                .spacy-left {
                    .list {
                        .list-items {
                            &.active {
                                border: 1px solid $host-green;
                                color: $host-green;
                            }
                        }
                    }
                }
            }
        }
    }
}