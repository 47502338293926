.coming-soon-dialog {
    width: 100% !important;
    height: 100vh;
    margin: 0 !important;
    max-width: 100% !important;
    top: 0 !important;

    .wrapper {    
        height:100%;
 
        .btn-close-wrap {
            padding: 20px 62px;

            .btn {
                font-weight: $black-bold;
                @include font-size(13);
                height: 45px;
                text-transform: uppercase;
                width: 250px;
            }

            @include desktop {
                left: 150px;
                padding: 0;
                position: absolute;
                top: 495px;
            }

            @include tablet-portrait {
                position: relative;
                text-align: center;
                top: -20px;
            }

            @include tablet-landscape {
                top: 68%;
            }
        }

        .content {
            align-items: center;
            display: flex;
            flex-direction: column;
            height:100%;
            justify-content: flex-start;
            margin: 0px 21px;  
            
            @include desktop {
                display: block;
                justify-content: center;
                left: 126px;
                position: absolute;
                top: 250px;
            }

            @include tablet-portrait {
                position: relative;
                top: -50px;
            }
        }

        .coming-soon-text {
            color: $bright-blue;
            font-weight: $black-bold;
            font-size: 22px;
            left: 116px;
            margin-bottom: 10px;
            position: absolute;
            top: 323px;

            @include desktop {
                font-size: 40px;
                left: 150px;
                margin-bottom: 20px;
                top: 170px;
            }

            @include tablet-portrait {
                left: 40%;
                top: 63%;
            }
        }

        .link {
            color: $bright-blue;
            font-weight: $semi-bold;
            text-decoration: underline;
        }

        .text {
            color: $black;
            display: block;
            font-size: 14px;
            line-height: 18px;
            margin: 0 auto;
            margin-bottom: 10px;
            text-align: center;

            @include desktop {
                font-size: 15px;
                line-height: 22px;
                text-align: left;
                width: 456px;
            }
        }

        .blueoutline-mobile {
            width: 375px;

            @include desktop {
                height: 355px;
                width: 345px;
                margin-top: 0;
            }

            @include tablet-portrait {
                width: 100%;
            }
        }

        .blue-outline-web {
            position: absolute;
            right: 0px;
            height: 755px;
        }

        .sm-blue-outline {
            position: absolute;
        }

        .coming-soon-img {
            display: block;
            left: 78px;
            position: absolute;
            top: 90px;

            @include desktop {
                left: 60%;
                top: 175px;
                width: 420px;
            }

            @include tablet-portrait {
                left: 30%;
                top: 19%;
                width: 300px;
            }
        }
    }

    .ant-modal-header {
        display: none;
    }

    .ant-modal-close-x {
        display: none;
    }

    .ant-modal-content {
        height: 100vh;
        border-radius: 0;
        overflow: hidden;

        .ant-modal-body {
            padding: 0;
        }
    }
}