.become-host-section {
    text-align: left;
    padding: 0;
    position: relative;
    margin-bottom: -50px;
    z-index: 5;

    .become-host-wrap {
        box-shadow: 0px 2px 12px #00000029;
        background-color: $white;
        border-radius: 32px;
        padding: 30px 0;

        .title {
            color: $black;
            @include font-size(25);
            font-weight: $black-bold;
            margin-bottom: 15px;

            @include desktop {
                @include font-size(50);
            }
        }

        .sub-title {
            color: $black;
            @include font-size(15);
            width: 89%;

            @include desktop {
                @include font-size(18);
                width: 55%;
            }

        }

        .btn-host {
            background-color: #20ae8f;
            border-radius: 23px;
            color: $white;
            font-weight: $bold;
            @include font-size(13);
            width: 100%;
            height: 45px;
            margin: 60px 0;
            margin-bottom: 0;
            text-transform: uppercase;

            &.mobile {
                display: block;

                @include desktop {
                    display: none;
                }
            }

            &.web {
                display: none;

                @include desktop {
                    display: block;
                }
            }

            @include tablet-portrait {
                width: 306px;
                margin: 60px auto;
                margin-bottom: 30px;
            }

            @include desktop {
                width: 306px;
                margin: 60px 0;
            }
        }

        .img-wrap {
            text-align: center;

            .image {
                width: 224px;
                height: 273px;
                margin: 0 auto;
            }
        }

        @include desktop {
            padding: 60px;
            height: 380px;
        }
  

    }

    @include desktop {
        padding: 40px 20px;
        padding-bottom: 0;
        margin-bottom: 0px;
    }

    .custom-container {
        padding: 0 !important;

        @include desktop {
            padding: inherit !important;
        }
    }
}






