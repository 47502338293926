.my-calendar {
    .custom-container {
        @include desktop {
            width: 62%;
        }
    }

    .header {
        border-bottom: 1px solid $gray;
        padding: 35px 0 20px 0;
        text-align: left;

        .flex-wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .right {
            @include mobile {
              position: fixed;
              bottom: 0;
              right: 0;
              padding: 30px;
              text-align: right;

              .btn-yellow {
                width: 100%;
              }
            }
          }
        }

        .btn-reservation {
          font-weight: $black-bold;
          font-size: 13px !important;
          height: 40px;
          min-width: 150px;
        }

        .arrow-img {
          cursor: pointer;
          display: inline-block;
          height: 18px;
          @include font-size(8);
          margin-right: 7px;
          transform: rotate(180deg);
        }

        .title {
            color: $black;
            display: inline-block;
            @include font-size(15);
            font-weight: $black-bold;
            margin-bottom: 0;
            text-align: left;
            vertical-align: middle;
        }
    }

    &.host-dashboard {
        .content {
            .calendar-grid {
                padding: 0;
    
                @include mobile {
                  position: relative;
                  top: -22px;
                }

                .btn-back-to-today {
                  @include font-size(13);
                  position: absolute;
                  top: 483px;

                  @include mobile {
                    top: -45px;
                    left: 175px;
                    background: $white;
                  }
                }

                .spacy-left {
                    margin: 0;
                    padding: 20px 30px 20px 0;

                    .ant-calendar-picker-input {
                      @include desktop {
                        display: none;
                      }

                      @include tablet-portrait {
                        display: none;
                      }
                    }    

                    @include desktop {
                      border-right: 1px solid $gray;
                      min-height: 300px;
                    }

                    @include tablet-portrait{
                      border-right: 1px solid $gray;
                      min-height: 300px;
                    }

                    @include mobile {
                      display: none;
                    }
                }
    
                .spacy-right {
                    margin: 0;
                    padding: 20px 0px;
    
                    .card-list {
                        margin-top: 10px;

                        .card {
                            background-color: $white;
                            border: 1px solid #dddddd;
                            border-radius: 5px;
                            box-shadow: 0px 0px 2px #0000001A;
                            display: flex;
                            align-items: flex-start;
                            margin-bottom: 15px;
                            padding: 10px 15px;   
    
                            .img {
                                height: 40px;
                                width: 40px;
                                @include background-property();
                                background-color: $light-gray;
                                background-size: cover !important;
                                border-radius: 50%; 
                                max-width: 100%; 
                                margin-right: 10px; 
                                padding: 8px 0;  
                            }

                            .calendar-img {
                              margin: 0px 2px 2px 0px;
                              height: 10px;
                              width: 10px;
                            }
    
                            .content {
                                flex: 1;
                                min-width: 68%;
                                text-align: left; 
                                
                                .sub-title {
                                    color: $black-op-60;
                                    display: block;
                                    @include font-size(11);
                                    font-style: italic;
                                    margin-bottom: 0px;
                                }
    
                                .title {
                                    color: $black;
                                    display: block;
                                    @include font-size(15);
                                    font-weight: $black-bold;
                                }

                                .date-block {
                                  width: 90%;
                                }
                                
                                .date {
                                    display: inline-block;
                                    @include font-size(11);
                                    color: $black-op-50;
                                    margin-right: 5px;
                                }
    
                                .flex-wrap {
                                    @include flex-row;
                                    justify-content: calendar-between;
                                }
    
                                .popover {
                                    cursor: pointer;
                                }
    
                                .favourites {
                                    .icon {
                                        color: #f35050;
                                        height: 10px;
                                        width: 10px;
                                    }
    
                                    .number {
                                        color: $black;
                                        @include font-size(13);
                                        font-weight: $black-bold;
                                        margin: 0 3px;                                 
                                    }
    
                                    .gray-text {
                                        color: $black-op-50;
                                        @include font-size(13);
                                    }
                                }

                                .space-popover-wrap {
                                  align-self: flex-end;
                                }

                                .time {                                 
                                    background: #F2F2F2;
                                    border-radius: 25px;
                                    color: $black-op-50;
                                    display: inline-block;
                                    @include font-size(11);  
                                    margin-right: 2px;                                  
                                    padding: 1px 5px;
                                }

                                @include desktop {
                                    min-width: 77%;
                                }

                                @include tablet-portrait {
                                  min-width: 77%;
                                }
                            }
                        }

                        @include desktop {
                          margin-top: 10px;
                        }

                        
                        @include tablet-portrait {
                          margin-top: 10px;
                        }
                    }

                    @include desktop {
                        margin: 0;
                        padding: 20px 0px 20px 30px;
                    }

                    @include tablet-portrait {
                      margin: 0;
                      padding: 20px 0px 20px 30px;
                    }
                }
            }

            .flex-wrap {
              @include flex-row;
              justify-content: space-between;

              .ellipsis-img {
                cursor: pointer;
                width: 16px;
              }
            }

            .mobile-only {
              @include desktop {
                display: none;
              }

              @include tablet-portrait {
                display: none;
              }

              .ant-calendar-picker {
                width: 100%;

                .ant-calendar-picker-icon {
                  left: 20px; 

                  svg {
                    path {
                      color: $white !important;
                    }
                  }
                }

                .ant-input {
                  .ant-input::-webkit-input-placeholder {
                    color: rgba($color: $white, $alpha: 0.5);
                  }
                  
                  @include mobile {
                    border-radius: 0;
                    border: none;
                    outline: none;
                    background: #1c8487;
                    color: $white;
                    font-weight: $extra-bold;
                    font-size: 16px;
                    height: 60px;
                    padding-left: 42px;
                    width: 100%;
                  }
                }
              }

              .calendar-dropdown-icon {
                left: 155px;
                position: relative;
                top: -41px;
              }
            }

            &.scrollwrap {
              @include mobile {
                height: 100%;
                overflow-y: auto;
                padding-bottom: 54px;
              }
            }
        }
    }

    @include desktop {
      margin-top: 80px;  
    }

    @include tablet-portrait {
      margin-top: 55px;  
    }

    @include mobile {
      position: fixed;
      width: 100%;
      top: -13px;
      margin-top: 0;
      z-index: 10;
      background-color: $white;
      height: 100%;
      right: 0;
    }
}