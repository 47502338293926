.testimonials-section {
  // background: $ms-blue 0% 0% no-repeat padding-box;
  overflow: hidden;

  .testimonials-wrap {
    .custom-container {
      @include background-property();
      background-repeat: no-repeat;
      background-position: 100%;
      background: #FAFAFA;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 60px;
      padding-bottom: 100px;

      @include desktop {
        background-repeat: no-repeat;
        background-position: 96%;
        box-shadow: 0px 2px 10px #00000029;
        border-radius: 30px;
        height: 444px;
        margin-top: 72px;
        margin-bottom: 100px;
        padding: 0 20px;
      }

      @include tablet-landscape {
        background-repeat: no-repeat;
        background-position: 96%;
        box-shadow: 0px 2px 10px #00000029;
        border-radius: 30px;
        height: 444px;
        margin-top: 100px;
        margin-bottom: 100px;
        padding: 0 20px;
      }

      @include tablet-portrait {
        background-repeat: no-repeat;
        background-position: 100%;
      }

      @include mobile {
        background-size: cover;
      }
      &.container {
        max-width: 1760px;
        width: 100%;

        @include desktop{
          width: 89%;
        }
        @include large-desktop{
          width: 83%;
        }
      
        @include exlarge-desktop{
          width: 90%;
        }
      }


      .title {
        @include font-size(25);
        color: $white;
        font-weight: $black-bold;
        margin-bottom: 25px;
        text-align: left;

        @include desktop {
          @include font-size(28);
          margin-bottom: 11px;
        }
      }

      .ant-carousel .slick-dots li button {
        background: $dull-gray;
        width: 7px;
        height: 7px;
        border-radius: 32px;
        margin-top: 30px;
      }

      .ant-carousel {
        .slick-dots {
          li {
            &.slick-active {
              button {
                background: $ms-blue;
                border-radius: 12px;
                width: 36px;
              }
            }
          }
        }
      }

      .content {
        @include font-size(16);
        padding: 15px 15px;

        @include desktop {
          padding: 15px 50px;
        }

        @include tablet-portrait {
          padding: 15px 50px;
        }

        .card-inner-wrapper {
          @include flex-column();
          align-items: center;

          @include desktop {
            @include flex-row();
            flex-direction: row;
            align-items: normal;
            justify-content: flex-start;
            margin-left: 22px;
            margin-top: 50px;
            padding-top: 70px;
          }

          @include tablet-landscape {
            margin-left: 0;
          }

          .img-wrap {
            position: relative;

            @include desktop {
              margin-right: 36px;
            }

            @include tablet-landscape {
              margin-right: 36px;
            }

            .img-border {
              width: 160px;
              height: 160px;
              border: 5px solid $ms-blue;
              border-radius: 10px;
              position: relative;

              @include desktop {
                width: 166px;
                height: 166px;
              }

              @include tablet-landscape {
                width: 166px;
                height: 166px;
              }

              .image {
                border-radius: 10px;
                width: 150px;
                height: 150px;
                margin: 0;
                position: absolute;
                top: -20px;
                left: -20px;

                @include desktop {
                  width: 170px;
                  height: 170px;
                }

                @include tablet-landscape {
                  width: 170px;
                  height: 170px;
                }

                @include mobile {
                  margin: 0 auto;
                }
              }
            }
          }

          .content-wrap {
            max-width: 440px;
            text-align: center;
            width: 100%;
            display: flex;
            @include flex-column();
            justify-content: space-between;

            @include desktop {
              text-align: left;
            }

            .quote-content-text-wrap {
              .quote {
                position: relative;
                top: unset;
                margin-top: 30px;
                margin-bottom: 20px;

                @include desktop {
                  top: -18px;
                  margin-top: unset;
                  margin-bottom: unset;
                }

                &.left {
                  @include mobile{
                    left: -140px;
                    margin-top: 0;
                  }
                  @include tablet-portrait{
                    left: -30px;
                  }
                }

                &.right {
                  left: 470px;

                  @include tablet-portrait{
                    margin: 0px;
                    right: 30px; 
                  }

                  @include desktop {
                    left: 400px;
                    top: -10px;
                  }
                }

                .quote-img {
                  height: 30px;
                  opacity: 1;
                  width: 40px;
                  margin: 0 auto;

                  @include desktop {
                    margin: 0;
                  }

                  @include mobile {
                    height: 19.12px;
                    width: 100px;
                  }

                  @include tablet-portrait{
                    margin: 0px;
                  }
                }
              }

              .content-text {
                color: $black;
                font-size: 14px;
                font-weight: $semi-bold;
                margin-bottom: 30px;
                margin-top: 5px;

                @include desktop {
                  font-size: 18px;
                  margin-bottom: 10px;
                }

                @include mobile {
                  margin-bottom: 0;
                  padding-bottom: 30px;
                }
              }
            }

            .content-name {
              font-size: 18px;
              color: $ms-blue;
              font-weight: $extra-bold;
              margin-bottom: 0;

              @include mobile {
                margin-top: 40px;
              }

              @include desktop {
                margin-bottom: 15px;
              }
            }
          }
        }
      }

      .carousel-container {
        position: relative;
      }

      .btn-arrow {
        display: none;
        background-color: $white;
        border: 1px solid $white;
        box-shadow: 0px 1px 4px #00000029;
        cursor: pointer;
        height: 36px;
        width: 36px;
        border-radius: 36px;
        color: $bright-blue;
        position: absolute;
        outline: 0 !important;
        top: 50%;

        &.btn-right {
          right: -10px;
        }

        &.btn-left {
          left: -10px;
        }

        .arrow {
          width: 12px;
          height: 12px;

          &.right {
            position: relative;
            transform: rotate(180deg);
          }

          &.left {
            position: relative;
          }
        }

        @include desktop {
          @include flex-row;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  @include desktop {
    border-radius: 0;
    //margin-bottom: 0;
    position: relative;
    //padding-bottom: 0;
    //padding-top: 60px;
    //top: 4px;
  }

  @include tablet-portrait {
    padding-top: 140px;
  }
}
