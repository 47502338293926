.how-works-section {
  background-color: #f6f6f6;
  border-radius: 32px;
  margin-top: -25px;
  padding: 0;

  .how-works-wrap {
    .title {
      @include font-size(25);
      font-weight: $black-bold;
      text-align: left;
      margin-bottom: 25px;

      @include desktop {
        @include font-size(28);
      }
    }

    .custom-container {
      @include tablet-portrait {
        padding: 0;
      }

      @include mobile {
        padding: 0;
      }

      &.container {
        max-width: 1760px;
      }
    }

    .columns {
      display: none;

      @include desktop {
        @include flex-row;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    .column {
      .content {
        padding: 20px;
        text-align: center;

        .title {
          color: $black;
          display: block;
          font-weight: $bold;
          @include font-size(18);
          margin-top: 20px;
          text-align: center;
          margin-bottom: 0;
        }
        .sub-title {
          display: block;
          @include font-size(13);
          color: $black-op-60;
        }

        .main-img {
          height: 125px;
          margin: 0 auto;
        }
      }

      .icon-wrap {
        background: $bright-blue;
        border-radius: 32px 0 32px 0;
        color: $white;
        font-weight: $bold;
        @include font-size(25);
        height: 60px;
        margin: 0 auto;
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;

        .num-wrap {
          margin: 15px auto;
          display: block;
        }
      }

      &:hover {
        transform: scale(1.04);
      }

      @include desktop {
        border-radius: 32px;
        box-shadow: 0px 2px 6px #00000029;
        flex: 0 0 25.4%;
        height: 278px;
        padding: 24px;
        position: relative;
        transition: transform 0.3s;
      }
    }

    .next-icon {
      display: block;
      flex: 0 0 11.9%;
      text-align: center;

      .arrow {
        width: 17%;
      }
    }

    .ant-carousel .slick-dots li button {
      width: 7px;
      height: 7px;
      background-color: $ms-blue;
      border-radius: 32px;
    }

    .bg-img-wrapper {

      .bg-img {
        @include background-property();
        background-size: cover;
        border-radius: 25px;
        height: 400px; 
        width: 100%;

        .text-block-wrapper {
          color: $black;
          letter-spacing: 0px;

          @include desktop {
            padding-top: 80px;
          }

          @include tablet-portrait {
            padding: 60px 0 0 20px;
          }

          .first-text-block {
            display: flex;
            font-weight: $black-bold;
            line-height: 34px;
            margin-bottom: 30px;
            opacity: 1;
            text-align: left;
            width: 100%;         
            
            @include desktop {
              @include font-size(22);
              max-width: 545px;
            }

            @include tablet-portrait {
              @include font-size(18);
            }

            @include tablet-landscape {
              @include font-size(18);
            }

            @include mobile {
              @include font-size(18);
              margin-bottom: 20px;
            }
          }

          .second-text-block {
            font-weight: $semi-bold;
            @include font-size(15);
            line-height: 23px;
            padding-left: 18px;
            text-align: left;

            @include desktop {
              @include font-size(18);
              max-width: 545px;
            }

            @include tablet-portrait {
              max-width: 305px;           
            }

            @include tablet-landscape {
              @include font-size(14);
            }

            @include mobile {   
              max-width: 196px;
              padding-left: 15px;           
            }
          }
        }
      }

      .left-img-wrap {
        display: flex;
        margin-right: 20px;
        position: relative;
        width: 54%;
        z-index: 1;

        .bg-img {
          background-position: 6%;
          
          @include tablet-portrait {
            background-position: 30%;         
          }

          @include tablet-landscape {
            background-position: 29% !important;
          }
  
          @include desktop{
            background-position: 32%;
          }

          .text-block-wrapper {
            padding-left: 39%;

            @include mobile {
              padding: 60px 0 0 30px;
            }

            .second-text-block {
              @include desktop {
                width: 100%;
              }
  
              .second-sub-text{
                color: $white;
                font-weight: $bold;
              }
            }
          }
        }
    
        @include mobile {
          position: relative;
          z-index: 1;
          width: 100%;
        }

        @include tablet-portrait {
          width: 100%;
        }
      }

      .right-img-wrap {
        display: flex;
        width: 54%;

        .bg-img {
          background-position: 51%;

          @include mobile {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
          }

          @include tablet-portrait {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            background-position: 6%;
          }

          @include tablet-landscape {
           background-position: 46%;
          }

          @include desktop {
            background-position: 47%;
          }
          
          .text-block-wrapper {
            padding-left: 8%;
  
            @include mobile {
              padding: 60px 0 0 30px;
            }

            .second-text-block {
              @include desktop {
                width: 250px;
              }
  
              @include mobile {
                width: 150px;
              }

              @include tablet-landscape {
                width: 200px;
              }
  
              .second-sub-text{
                color: $error;
                font-weight: $bold;
              }
            }
          }
        }

        @include mobile {
          position: relative;
          top: -18px;
          width: 100%;
        }

        @include tablet-portrait {
          position: relative;
          top: -18px;
          width: 100%;
        }
      }
     
      @include desktop {
        display: flex;
      }
    }
  }

  @include desktop {
    background-color: $white;
    padding: 60px 0;
    border-radius: 0px;
    margin-top: -25px;
  }

  @include tablet-portrait {
    position: relative;
    top: -25px;
    z-index: 1;
  }

  @include mobile {
    position: relative;
    top: -25px;
    z-index: 1;
  }
}
