.search-section {
  &.search-content {
    padding-top: 21px;
    text-align: left;
    width: 100%;

    .container {
      max-width: 1760px;
    }

    @include desktop {
      z-index: 1;
    }
  }
}

.search-main-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
}

.search-bar-wrap {
  margin: 0;
  outline: none;
  position: relative;
  padding: 20px 0px; 

  .search-bar-inner-wrap {
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #00000033;
    border-radius: 60px;
    display: flex;
    height: 55px;
    opacity: 1;
    padding: 10px 7px 10px 20px;
    place-items: center;
    width: 800px;

    @include mobile {
      padding-left: 20px;
      width: 100%;
    }

    @include tablet-portrait {
      width: 100%;
    }

    .search-blue-icon {
      position: relative;
      width: 14px;
    }

    .search-input-wrap {
      width: 100%;

      .ant-select-selection {
        border: none;

        .ant-select-arrow {
          display: none;
        }

        .ant-select-selection__placeholder {
          color: black;
          font-weight: $bold;
          @include font-size(14);
        }
      }
    }

    .search-location-wrap {
      width: 290px;

      .ant-select {
        width: 90%;

        .ant-select-selection {
          background-color: transparent;
          font-size: 15px;
          font-weight: $semi-bold;
          
          .ant-input {
            border: none;
            color: $black;
            font-weight: $bold;
            padding-left: 18px;
          }
          .ant-input:focus {
            box-shadow: none;
          }
    
          .ant-select-selection__placeholder {
            color: $black;
            font-weight: $semi-bold;
            margin-left: 18px;
            padding-top: 2px;

            @include mobile {
              margin-left: 16px;
            }
          }

          .img {
            height: 14px;
            margin-right: 8px;
          }
        }
      }
    }


    .search-location-wrap-distance {
      width: 290px;
      display:flex;

      .ant-select {
        width: 90%;

        .ant-select-selection {
          background-color: transparent;
          font-size: 15px;
          font-weight: $semi-bold;
          
          .ant-input {
            border: none;
            color: $black;
            font-weight: $bold;
            padding-left: 18px;
          }
          .ant-input:focus {
            box-shadow: none;
          }
    
          .ant-select-selection__placeholder {
            color: $black;
            font-weight: $semi-bold;
            margin-left: 18px;
            padding-top: 2px;

            @include mobile {
              margin-left: 16px;
            }
          }

          .img {
            height: 14px;
            margin-right: 8px;
          }
        }
      }
    }

    .activity-wrap {
      display: flex;
      width: 290px;
      @include font-size(15);
      font-weight: $semi-bold;
    
      .activity-select {
        min-width: 200px;

        .ant-select-open {
          .ant-select-selection {
            border: none;
            outline: none;
            box-shadow: none;
          }
        }

        .ant-select {
          color: $black;
          font-weight: $bold;
          opacity: 1;

          .ant-select-selection {
            border: none;
            box-shadow: none;
            font-size: 15px;

            .ant-select-selection__rendered {
              line-height: 36px;
              margin-left: 16px;
              margin-right: 0px;

              @include desktop {
                margin-left: 6px;
              }

              @include tablet-landscape {
                margin-left: 6px;
              }
              
              .ant-select-selection__placeholder{
                color: $black;
                font-weight: $semi-bold;
                font-size: 15px;
                margin-left: 2px;
              }
            }
          }

          .ant-select-arrow {
            display: none;
          }
        }
      }
    }

    .activity-wrap-distance {
      display: flex;
      align-items: center;
      width: 270px;
      @include font-size(15);
      font-weight: $semi-bold;
      gap:10px;
    
    
      .activity-select {
        min-width: 200px;

        .ant-select-open {
          .ant-select-selection {
            border: none;
            outline: none;
            box-shadow: none;
          }
        }

        .ant-select {
          color: $black;
          font-weight: $bold;
          opacity: 1;

          .ant-select-selection {
            border: none;
            box-shadow: none;
            font-size: 15px;

            .ant-select-selection__rendered {
              line-height: 36px;
              margin-left: 16px;
              margin-right: 0px;

              @include desktop {
                margin-left: 6px;
              }

              @include tablet-landscape {
                margin-left: 6px;
              }
              
              .ant-select-selection__placeholder{
                color: $black;
                font-weight: $semi-bold;
                font-size: 15px;
                margin-left: 2px;
              }
            }
          }

          .ant-select-arrow {
            display: none;
          }
        }
      }
    }


    .date-block {
      margin-top: 3px;
      width: 28%;

      .md-text-field-divider-container {
        @include desktop {
          margin-left: 8px;
        }

        @include tablet-landscape {
          margin-left: 8px;
        }
      }

      .date-placeholder { 
        .img {
          margin-bottom: 2px;
          width: 14px;

          @include mobile {
            margin-bottom: 3px;
          }
        }
        .md-text-field {
          color: $black;
          @include font-size(15);
          font-weight: $semi-bold;
          margin-top: 0px;
          padding-top: 9px;

          @include desktop {
            padding-top: 3px;
          }

          @include tablet-landscape {
            padding-top: 2px;
          }
        }

        .md-divider--text-field {
          display: none;
        }
      }
      
      .date-select {
        .md-text-field-divider-container {
          margin-left: 8px;
          .md-divider--text-field {
            display: none;
          }

          .md-text-field {
            font-size: 15px;
            font-weight: $bold;
            margin-top: 0px;
          }
        }

        .md-text-field-icon {
          margin-bottom: 2px;
          width: 14px;
        }
      }

      .guest-input {
        .md-text-field-container {
          display: none;
        }

        .md-picker {
          left: 15px; 
          top: 8px;
          width: 254px;
          .md-calendar-dows {
            padding-bottom: 5px;
            
            .md-calendar-dow{
              width: 14%;
            } 
          }

          .md-picker-content-container {
            border-radius: 24px;
            font-size: 13px;

            .md-picker-content {
              height: auto !important;
              padding: 0px 8px;
              width: 254px;

              .md-calendar-controls {
                height: 44px;
                margin-bottom: 10px;

                .md-title {
                  font-size: 15px;
                }
              }

              .md-calendar-month {

                .disable-date-cls {
                  color: rgba(0, 0, 0, 0.3);
                  background: none !important;
                  cursor: not-allowed;
                }

                .md-calendar-date {
                  margin-bottom: 0px;
                  width: 32px;
                  height: 32px;

                  .md-calendar-date--btn {
                    height: 32px;

                    .md-calendar-date--date {
                      color: $black;
                      padding: 0px !important;
                      height: 32px;
                      width: 32px;

                      .disable-date-cls {
                        color: rgba(0, 0, 0, 0.3);
                        background: none !important;
                        cursor: not-allowed;
                      }

                      &.md-picker-text--active {
                        .disable-date-cls {
                          background: none !important;
                          cursor: not-allowed;
                        }

                        span {
                          background: $pink !important;
                        }

                      }

                      .normal-date-cls {
                        height: 32px;
                        font-size: 15px;
                        width: 32px;
                        display: block;
                        padding: 5px;
                        border-radius: 36px;

                        &.current-date {
                          border: 1px solid $pink;
                          background: $white !important;
                          color: $pink;

                          &.disable-date-cls {
                            background: none !important;
                            color: rgba(0, 0, 0, 0.3);
                            cursor: not-allowed;
                          }
                        }

                        &.current-date:hover {
                          border: 1px solid $pink;
                          background: $pink !important;
                          color: $white;

                          &.disable-date-cls {
                            background: none !important;
                            color: rgba(0, 0, 0, 0.3);
                            cursor: not-allowed;
                          }
                        }
                      }
                    }

                    &.md-calendar-date--btn-active {
                      .md-calendar-date--date {
                        color: $white;
                        background: none;

                        span {
                          background: $ms-blue;
                        }
                      }

                      &:after {
                        background: transparent;
                      }
                    }

                    &.day-with-events {
                      background:#F2F2F2;
                    }

                    &.start-range-date {
                      border-top-left-radius: 36px;
                      border-bottom-left-radius: 36px;
                      padding-left: 0;

                      .calendar-select-days {
                        background: $pink;
                        color: $white;
                      }

                      .md-calendar-date--date {
                        .current-date {
                          background: $pink!important;
                          color: $white;
                        }
                      }
                    }

                    &.end-range-date {
                      border-top-right-radius: 36px;
                      border-bottom-right-radius: 36px;
                      padding-right: 0;

                      .calendar-select-days {
                        background: $pink;
                        color: $white;
                      }
                      
                    }

                  }
                }
              }
            }

            .md-dialog-footer {
              padding: 0px;

              .md-btn {
                &.md-btn--color-primary-active {
                  background-color: transparent;
                }

                .clear-all-btn {
                  color: $pink;
                }

                .img {
                  height: 30px;
                  margin-top: -5px;
                  width: 54px;
                }
              }     
            }
          }

          .md-picker-content-container::before {
            display: none;
          }

          @include mobile {
            top: -150px;
          }
        }
      }
    } 

    .category-icon {
      position: relative;
      top: 10px;
      height: 14px;
      width: 14px;
    }

    .location-icon {
      position: relative;
      left: 10px;
      height: 14px;
      top: 2px;
      width: 10px;
      z-index: 1;
    }

    .search-btn {
      background: $pink 0% 0% no-repeat padding-box;
      border-radius: 20px;
      color: $white;
      left: 0;
      height: 40px;
      position: relative;
      padding: 11px 15px 12px 16px;
      top: 0px !important;
      width: 81px;

      .img {
        height: 18px;
        width: 18px;
      }

      .btn-text {
        font-size: 12px;
        font-weight: $extra-bold;
      }

      @include mobile {
        border-radius: 25px;
      }
    }
  }

  .country-select-wrap {
    margin-bottom: 20px;

    .radio-btn-wrapper {
      .radio-btn-inner-wrapper {
        border: 1px solid $white;
        border-radius: 23px;
        background: #e5e5e5;
        height: 47px;
        opacity: 1;
  
        .ant-radio-button-wrapper {
          background: transparent;
          border: none;
          border-radius: 19px;
          box-shadow: none;
          font-size: 13px;
          font-weight: $semi-bold;
          height: 35px;
          margin: 5px;
          outline: unset;
          padding: 0 8px;
          padding-top: 2px;
          transition: 0.4s;
          width: 112px;
          
          &.ant-radio-button-wrapper-checked {
            background: $white 0% 0% no-repeat padding-box;
            border: none;
            box-shadow: none;
            color: $black;
            opacity: 1;
            outline: none;
          }
  
          .img {
            margin-right: 5px;
            width: 21px;
          }
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
          display: none;
        }

        @include mobile {
          background: #E5E5E5;
        }

        @include tablet-portrait {
          background: #E5E5E5;
        }
      }
    }
  }

  .search-icon {
    height: 22px;
    margin-bottom: 0;
    opacity: 1;
    position: absolute;
    left: 30px;
    top: 17px;
    z-index: 10;

    &.modal-icon {
      top: 38px !important;
      left: 5% !important;

      @include mobile {
        top: 38px !important;
        left: 37px !important;
      }
    }

    @include mobile {
      width: 11px;
      height: 11px !important;
      left: 20px;
    }

    @include tablet-portrait {
      top: 45%;
    }
  }

  .search-popup {
    .dialog-header {
      border-bottom: 1px solid #d9d9d9;
      background: $white;
      color: $black;
      display: flex;
      font-weight: $bold;
      height: 70px;
      @include font-size(15);
      padding: 25px 18px;
      position: fixed;
      width: 100%;
      z-index: 2;

      .back-icon {
        transform: rotate(180deg);
      }

      .close-icon {
        top: unset;
      }
    }

    .content-wrap {
      color: $black;
      padding: 30px 20px;
      text-align: left;
      position: absolute;
      top: 70px;
      min-height: 510px;
      width: 100%;

      .search-text {
        @include font-size(20);
        font-weight: $black-bold;
        margin-bottom: 20px;
        margin-left: 27px;
      }

      .search-blue-icon {
        margin-top: 6px;
        position: absolute;
        width: 17px;
      }
      .search-location-wrap, .activity-wrap, .date-block {
        box-shadow: 0px 0px 3px #00000029;
        border-radius: 23px;
        height: 45px;
        margin-bottom: 15px;
        padding: 6px 20px;
        width: 100%;
      }

      .date-block {
        .date-select {
          .md-text-field-icon {
            margin-bottom: 5px;
          }

          .md-text-field-divider-container {
            padding: 3px;
          }

          .md-text-field-icon-container {
            margin-top: 5px;
          }
        }
        .guest-input {
          .md-picker {
            padding-bottom: 60px;
            left: 8px;
          }
        }
        
      }

      .activity-wrap {
        .activity-select {
          .ant-select-selection {
            .ant-select-selection__rendered {
              margin-left: 10px;
            }
          }
        }
      }

     

      .search-location-wrap {
        .ant-select {
          .ant-select-selection {
            .ant-input {
              padding-left: 15px;
            }
          }
        }

        .location-icon {
          left: unset;
        }
      }
    }
    .search-btn-wrap {
      .search-btn {
        font-weight: $black-bold;
        height: 50px;
        @include font-size(13);
        left: unset;
        padding: 15px;
        width: 100%;
      }
    }
  }

  &.modal {
    padding: 20px 10px;

    @include tablet-portrait {
      padding: 20px 0;
      text-align: left;
    }
    @include mobile {
      padding: 20px 0;
      text-align: left;
    }

    .search-btn {
      font-weight: 800;
      @include font-size(13);
      font-weight: $extra-bold;
      text-transform: uppercase;
    }
  }

  .search-input {
    background-color: $white;
    border-radius: 60px;
    border: none;
    outline: none;
    box-shadow: 0px 2px 6px #00000029;
    height: 55px;
    width: 100%;
    margin-top: 30px;
    padding: 5px 20px;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 61px;

    &.modal-input {
      width: 85%;
      margin-right: 10px;
      display: inline-block;
      border: 1px solid rgba($color: #404146, $alpha: 0.2);
      box-shadow: none;
      margin-top: 0;

      @include mobile {
        margin: 0 40px 10px 17px;
        width: 80%;
        padding-left: 36px;
      }

      @include tablet-portrait {
        margin: 0 20px;
        width: 85%;
      }

      .md-text-field-icon-container {
        height: 45px;
        align-items: center;
      }

      .search-icon {
        top: 68%;
        left: 42px;
      }
    }

    @include font-size(18);
    font-weight: 800;
    color: $black;

    @include mobile {
      @include font-size(16);
      margin-top: 60px;
      padding-left: 44px !important;
    }

    @include tablet-portrait {
      @include font-size(16);
      margin-top: 10px;
    }

    ::-webkit-input-placeholder {
      color: rgba($color: $black, $alpha: 0.25);
    }

    .md-text-field--margin {
      margin-top: 0;

      @include tablet-portrait {
        margin-top: 0;
        height: 22px;
      }

      @include desktop {
        margin-top: 0;
        height: 22px;
      }
    }

    .md-divider {
      display: none;
    }

    @include tablet-landscape {
      width: 100% !important;
      margin: 0 auto;
      border-radius: 60px;
      box-shadow: 0px 2px 6px #00000029;
      margin-top: 55px;

      &.search-input-focus {
        padding-right: 137px;
      }
    }

    @include desktop {
      width: 100%;
      margin: 0 auto;
      border-radius: 60px;
      box-shadow: 0px 2px 6px #00000029;
      margin-top: 41px;

      &.search-input-focus {
        padding-right: 137px;
      }
    }

    @include mobile {
      height: 45px;
    }
    .md-text-field-icon-container {
      @include mobile {
        align-items: baseline;
      }

      @include tablet-portrait {
        height: 45px;
        align-items: center;
      }

      @include desktop {
        height: 45px;
        align-items: center;
      }

      .md-text-field-divider-container {
        @include mobile {
          margin-left: 10px;
        }
      }
    }
  }

  .close-icon {
    margin-bottom: 15px;
    right: 20px;
    position: absolute;
    top: 35px;

    @include tablet-portrait {
      right: 35px;
    }

    @include mobile {
      top: 35px;
    }
  }

  .autocomplete-content {
    width: 100%;
    border-radius: 24px;
    margin: 0 auto;
    margin-top: 0px;
    padding: 20px 25px;
    padding-bottom: 20px;
    background-color: $white;
    z-index: 100;
    text-align: left;

    @include tablet-portrait {
      display: none;
      border-top: 1px solid $light-gray;
      padding-top: 20px;
      margin-top: 10px;
      border-radius: 0;
    }

    @include mobile {
      display: none;
      border-top: 1px solid $light-gray;
      padding-top: 20px;
      margin-top: 20px;
      border-radius: 0;
    }

    .list {
      margin-bottom: 18px;

      @include mobile {
        margin-bottom: 40px;
      }

      @include tablet-portrait {
        margin-bottom: 40px;
      }

      .title {
        color: $black;
        -webkit-text-fill-color: $black;
        @include font-size(13);
        font-weight: $black-bold;
        margin: 0 6px;
        text-transform: uppercase;
      }

      .list-item {
        border: 1px solid #d8d8d9;
        border-radius: 100px;
        background-color: $white;
        display: inline-block;
        color: $black;
        cursor: pointer;
        height: 40px;
        margin: 3px;
        padding: 9px 20px;

        &.active {
          background-color: $bright-blue;
          border: none;
          color: $white;
          .img {
            filter: invert(0);
          }
        }

        .img {
          margin-right: 5px;
          width: 15px;
          height: 15px;
          margin-bottom: 2px;
          filter: invert(1);
        }
      }

      &:last-child() {
        margin-bottom: 0px;
      }
    }
  }

  .search-menu-list {
    margin: 0 auto;
    background-color: $white;
    z-index: 100;
    text-align: left;
    margin-top: 1px;
    box-shadow: none;

    .md-list-item {
      border-radius: 32px;
      padding: 10px 30px;
      background-color: transparent;
      @include font-size(15);
      color: $black;

      .md-tile-content {
        .md-text {
          &:before {
            content: '';
            width: 12px;
            height: 16px;
            background: url('../images/common/location.svg') no-repeat;
            margin-right: 10px;
            float: left;
          }
        }
      }

      &:active {
        background-color: $light-gray;
      }

      &:hover {
        background-color: $light-gray;
      }

      &:first-child {
        border-radius: 32px;
        padding-top: 20px;
      }

      &:last-child {
        padding-bottom: 20px;
      }
    }

    @include desktop {
      border-radius: 32px;
      box-shadow: inherit;
    }
  }

  .search-btn-wrap {
    @include mobile {
      position: fixed;
      background-color: $white;
      width: 100%;
      left: 0;
      right: 0;
      padding: 15px 20px;
      z-index: 1;
      bottom: 0;
      border-top: 1px solid $light-gray;
    }

    @include tablet-portrait {
      position: fixed;
      background-color: $white;
      width: 100%;
      left: 0;
      right: 0;
      padding: 15px 20px;
      z-index: 1;
      bottom: 0;
      border-top: 1px solid $light-gray;
    }

    .search-btn {
      display: block !important;
    }
  }

  .search-btn {
    width: 100%;
    height: 45px;
    border-radius: 23px;
    padding: 12px 15px;
    cursor: pointer;
    display: block;
    color: $black;
    background-color: $yellow;
    text-align: center;
    @include font-size(13);
    font-weight: $extra-bold;
    text-transform: uppercase;

    @include mobile {
      display: none;
    }

    @include desktop {
      position: absolute;
      top: 8px !important;
      height: 40px;
      width: 119px;
    }

    @include tablet-portrait {
      display: none;
    }

    @include tablet-landscape {
      position: absolute;
      right: 1.5%;
      top: 8px !important;
      width: 119px;
      height: 40px;
    }
  }

  @include tablet-landscape {
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
  }

  @include desktop {
    padding: 0;
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
  }

  @include mobile {
    padding: 0;
    top: -15px;
    text-align: center;
  }

  @include tablet-portrait {
    padding: 0;
    top: -15px;
    text-align: center;
  }
}

.space-main-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
  top: 0;
}
