.section-terms {
  padding-top: 100px;
  text-align: left;

  @include desktop {
    padding-top: 70px;
  }
  .custom-container {
    max-width: 766px;
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;

    @include desktop {
      padding-left: 0px;
      padding-right: 0px;
    }

    .capital-letters {
      text-transform: uppercase;
    }

    .country-wrap {
      @include desktop {
        left: 563px;
        position: relative;
        top: 7px;
      }

      .country-select {
        @include font-size(15);
        background: $white 0% 0% no-repeat padding-box;
        border: 1px solid $light-gray;
        border-radius: 100px;
        font-weight: $semi-bold;
        margin: 0 0 20px 0;
        width: 100%;
        z-index: 0;

        @include desktop {
          width: 203px;
        }

        .md-select-field {
          background: $white;
          box-shadow: none;
          border-radius: 100px;

          .md-text-field {
            height: 40px;
            padding: 7px 10px;

            @include desktop {
              height: 35px;
            }

            .img {
              margin-right: 5px;
            }

            .md-icon {
              color: $bright-blue;
            }
          }
        }

        .md-list {
          border-radius: 24px;
          .md-list-item {
            .md-list-tile {
              padding: 7px 10px;

              .img {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
    .block-wrapper {
      margin-bottom: 25px;

      .description {
        margin-bottom: 10px;

        &.sub-text {
          margin-left: 25px;
        }
      }

      .main-title {
        margin-bottom: 10px;
        @include desktop {
          position: relative;
          top: 50px;
        }
      }

      .title {
        @include font-size(25);
        font-weight: $extra-bold;

        &.sub-title {
          @include font-size(15);
          margin-bottom: 5px;
        }
      }
    }

    .tbl-content-wrap {
      margin-bottom: 25px;
      
      .title-table-of-content {
        @include font-size(15);
        font-weight: $black-bold;
        text-decoration: underline;
      }
    }
  }
}
