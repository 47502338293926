@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700,800,900&display=swap');
@import '~react-md/src/scss/react-md';
@import '~antd/dist/antd.css';
@import 'variables';
@import 'mixins';
@import 'buttons';
@import 'styles';
@import 'ant-styles';
@import 'components/popover/space-popover.scss';
@import 'components/Dropdown/index';
@import 'components/modals/index.scss';
@import 'components/DatePicker/host-date-picker.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,600;0,800;0,900;1,100&display=swap');

html {
  background-color: $white !important;
  padding: 0;
  margin: 0;
}

body {
  background-color: $white;
  font-family: $font-family !important;
  padding: 0;
  margin: 0;
}

.ms-styles-wrapper {
  background-color: $white;
  font-family: $font-family;
  font-size: 15px !important;
  @import 'components/index';

  &.space-page {
    .navigation-section {
      &.white-bg-navbar {
        z-index: 3;
        box-shadow: unset;

        &.filter-open {
          z-index: 2;
        }
      }
    }
    .search-result-bar {
      z-index: 2;
    }
  }
}
