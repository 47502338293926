.payhere-inner-wrapper {
    padding: 25px;
    max-height: 100vh;
    height: calc(100vh - 72px);
    overflow-y: auto;
    border: 1px solid #d9d9d9;
    border-top: none;
    position: relative;
    padding-bottom: 150px;

    @include desktop {
      border-radius: 20px;
      box-shadow: 0px 0px 10px #00000029;
      height: auto;
      min-height: 470px;
      max-height: 85vh;
      padding-bottom: 10px;
    }

    @include mobile {
      border: none;
    }

    @include tablet-landscape {
      border-radius: 20px;
      box-shadow: 0px 0px 10px #00000029;
      height: auto;
      max-height: 85vh;
      padding: 25px 18px;
    }
    
    .section-divider {
      border-bottom: 1px solid #d9d9d9;
      display: block;
      margin: 0 -25px;
      padding-top: 0;
    }

    .value-seat-day-block {
      padding-bottom: 15px;
      text-align: left;
      position: relative;

      .back-icon {
        cursor: pointer;

        .img {
          width: 14px;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
      }

      .total-title {
        @include font-size(13);
        display: inline-block;
        margin-right: 5px;
      }

      .value {
        @include font-size(20);
        margin-right: 3px;
      }

      .close-btn {
        position: absolute;
        right: 0;

        @include desktop {
          display: none;
        }
      }

      .seat {
        margin-right: 5px;
      }
    }

    .summery-block-wrapper {
      display: flex;
      flex-direction: column;
      text-align: left;

      .title {
        font-weight: 900;
      }
      .summery-block-inner-wrapper {
        display: flex;

        img {
          margin-right: 10px;
          width: 13px;
        }
      }
    }

    .blue-text-title {
        color: $ms-blue;
        @include font-size(12);
        font-weight: $bold;
        line-height: 18px;
        margin-bottom: 3px;
        margin-top: 25px;
        text-align: left;
        text-transform: uppercase;
      }

    

    .md-text-field--margin {
      margin-top: 15px;
    }

    .md-text-field--floating-margin {
      margin-top: 15px;
    }

    .space-summary-wrapper {
      padding-top: 27px;

      .title {
        @include font-size(25);
        font-weight: $black-bold;
        text-align: left;
      }

      .detail-block {
        text-align: left;
        display: flex;
        align-items: baseline;
        margin-bottom: 10px;
        font-size: 14px;

        .img {
          width: 14px;
          margin-right: 10px;
        }

        &.detail-block:last-of-type {
          margin-bottom: 20px;
        }

        .time-slot-inner-wrap {
          display: flex;
          flex-wrap: wrap;
        }

        .time-slot {
          background-color: $light-gray3;
          border-radius: 23px;
          font-size: 13px;
          margin-bottom: 5px;
          margin-right: 6px;
          padding: 7px 10px;
        }
      }

      .icon-sentense-wrapper {
        background-color: $ms-blue-op-05;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        text-align: left;
        border-radius: 20px;
        padding: 0;
        margin-top: 25px;

        .icon-sentense-inner-wrapper {
          padding: 20px 25px;

          .blue-text {
            color: $ms-blue;
            font-weight: $semi-bold;
            line-height: 23px;

            @include desktop {
              max-width: 343px;
              width: 100%;
            }
          }

          .blue-text-bold {
            color: $ms-blue;
            font-weight: $extra-bold;
            line-height: 23px;

            @include desktop {
              max-width: 343px;
              width: 100%;
            }
          }
        }
      }

      .payment-method-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 15px 0;

        .payment-method {
          .img {
            margin-right: 10px;
          }
        }
        .blue-text-link {
          color: $ms-blue;
          cursor: pointer;
          font-weight: $bold;
          text-decoration: underline;
        }
      }

      .radio-btn-wrapper {
        padding-top: 20px;
  
        .ant-radio-group {
          width: 100%;
  
          .radio-btn-inner-wrapper {
            display: flex;
            justify-content: center;
  
            .ant-radio-button-wrapper {
              position: relative;
              margin: 5px;
              padding: 5px;
              height: 45px;
              padding-top: 8px;
              border: 1px solid #e2e2e2;
              box-shadow: none !important;
              color: $black;
              flex-grow: 1;

              &::before {
                background-color: transparent;
              }
  
              @include mobile {
                margin: 5px 2px;
                padding: 8px;
              }
  
              @include tablet-landscape {
                margin: 5px 4px;
                padding: 8px 4px;
              }
  
              @include tablet-portrait{
                flex-grow: 0;
                margin: 5px 10px;
                width: 200px;
              }

              &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
                color: $white;
                background-color: $ms-blue;
                border-color: $ms-blue;

                &::before {
                  background-color: transparent !important;
                }
              }
  
              &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
                outline: none;
              }
            }
          }
  
          .ant-radio-button-wrapper:first-child {
            border-radius: 100px 100px 100px 100px;
          }
  
          .ant-radio-button-wrapper:last-child {
            border-radius: 100px 100px 100px 100px;
          }
  
          .ant-radio-button-wrapper:only-child {
            border-radius: 100px 100px 100px 100px;
          }
        }
      }
    }  

    .continue-btn-wrapper {
      background-color: transparent;
      bottom: 0;
      position: fixed;
      right: 0;
      text-align: center;
      width: 100%;
      z-index: 9;

      @include desktop {
        position: inherit;
        padding: 24px;
        width: 100%;
      }

      .btn-grid-wrapper {
        padding-left: 0;
        padding-bottom: 0;

        @include desktop {
          width: 100%;
        }

        .continue-btn-inner-wrapper {
          padding: 0 24px;
          border-top: 1px solid $light-gray;
          border-left: 1px solid $light-gray;
          border-right: 1px solid $light-gray;
          background-color: $white;
          padding-top: 10px;
          padding-bottom: 10px;

          @include desktop {
            border: none;
            padding: 0;
          }

          @include tablet-landscape {
            border: none;
            padding: 0;
          }

          .description {
            display: block;
            @include font-size(10.5);
            padding-bottom: 10px;

            .blue-text-link {
              color: $bright-blue;
              cursor: pointer;
              display: inline-block;
              text-decoration: underline;
              font-weight: $bold;
            }
          }

          .md-btn {
            color: $white;
            cursor: pointer;
            background-color: $pink;
            border-radius: 25px;
            height: 50px;
            width: 100%;
            font-size: 13px;

            .small-text {
              color: $white;
              display: block;
              line-height: 9px;
              font-size: 11px;
              font-weight: 600;
              text-transform: none;

              .mr-4 {
                margin-right: 4px;
              }
            }

            &:disabled {
              pointer-events: none;
              cursor: not-allowed;
              opacity: 0.5;
            }
          }
        }
      }
    }

    .md-text-field {
      border: 1px solid #e2e2e2;
      border-radius: 25px;
      height: 45px;
      @include font-size(13);
      padding-left: 40px;

      &:focus {
        border: 1px solid $bright-blue;
      }

      @include tablet-landscape {
        height: 35px;
        padding-left: 35px;
        @include font-size(13);
      }
    }

    .md-divider {
      display: none;
    }

    .ant-spin-spinning {
      position: relative;
      top: 100px;
    }
  }