.pricing-property-wrap {
  padding: 40px 0 20px 0 !important;

  .sub-title {
    font-size: 13px;
    font-weight: 100;
    color: rgba($black, 0.5);
    display: block;

    @include desktop {
      display: inline;
      margin-left: 10px;
    }
  }

  .card-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: top !important;

    .card {
      border: 1px solid rgba($color: #000000, $alpha: 0.1);
      border-radius: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
      flex: 0 0 100%;

      .header {
        @include flex-row();
        @include font-size(14);
        border-radius: 10px 10px 0 0;
        background-color: $gray;
        flex-wrap: nowrap;
        justify-content: space-between;
        overflow: auto;
        padding: 15px 12px;

        .img {
          margin-right: 5px;
        }

        .left {
          font-weight: $black-bold;
          float: left;
          @include flex-row();
          flex-wrap: nowrap;

          .text-wrap {
            display: inline-block;

            .hourly-text {
              @include font-size(12);
              color: rgba($black, 0.5);
              font-weight: $medium;
              margin-left: 6px;
            }
          }
        }

        .right {
          float: right;

          .price {
            display: block;
            font-weight: $black-bold;
          }

          .seat {
            font-size: 13px;
          }
        }
      }

      .body {
        padding: 15px;
        @include font-size(13);
        color: $black;

        .left-wrap {
          text-align: left;
        }

        .right-wrap {
          float: right;
          text-align: right;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      @include tablet-landscape {
        flex: 0 0 48% !important;
        margin-bottom: 10px;
      }

      @include desktop {
        flex: 0 0 48% !important;
        margin-bottom: 10px;
      }
    }

    @include tablet-portrait {
      padding-bottom: 5px;
    }

    @include mobile {
      padding-bottom: 5px;
    }
  }

  @include desktop {
    padding: 40px 0 !important;
  }

  @include tablet-landscape {
    padding: 40px 0 !important;
  }
}
