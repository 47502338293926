.mobile-number-popup {
    width:355px !important;

    .ant-modal-header {
        display: none;
    }

    .ant-modal-close-x {
        display: none;
    }

    .ant-modal-body {
        padding: 0;
    }

    .mobile-btn-wrap {
        border-top: 1px solid $light-gray !important;
        display: flex;
        justify-content: space-between;
        padding: 10px 30px 0px 30px;

        .btn-signup {
            color: $white;
            font-weight: $black-bold;
            @include font-size(13);
            width: 145px;
            height: 45px;
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        .btn-skip {
            font-weight: $bold;
            height: 45px;
            @include font-size(13);
            padding: 10px 0px;
            width: 145px;
        }
    }

    .ant-modal-content {
        border-radius: 24px;
    }
  
    .content {
       padding: 40px 30px 30px 30px !important;
       padding-bottom: 24px !important;
         
        .md-divider {
            display: none;
        }

        .md-text-field-container {
            width: 100%;
            margin: 0;

            .md-text-field {
                padding-left: 85px;
                padding-top: 3px;
                margin-top: 10px;
            }
        }

        .country-wrap {
            position: relative;
        }

        .select-wrap {
            width: 100%;

            .md-list-item {
                .md-list-tile {
                    background-color: transparent;
                    height: 50px;
                    padding: 0 34px;

                    &:hover, &:active {
                        background-color: #177ad5;

                        .md-text {
                            color: $white;
                        }
                    }
                }

                .md-list-tile--active {
                    background-color: #177ad5;

                    .md-text {
                        color: $white;
                    }

                    .md-text--theme-primary {
                        color: $white;
                    }
                }

                .md-poniter--hover {
                    background-color: #177ad5;

                    .md-text {
                        color: $white;
                    }

                    .md-text--theme-primary {
                        color: $white;
                    }
                }
        
                .md-text--theme-primary {
                    color: $black;
                }
            
                .md-text {
                    color: $black;
                }
            }
            
        }
    
        .input-wrap {
            .mobile-input-grid {  
              .country-code {
                @include font-size(20);
                color: #ababab;
                display: inline-block;
                margin-bottom: 0;
                position: absolute;
                border-right: 1px solid #d9d9d9;
                top: 10px;
                left: 20px;
                height: 45px;
                padding: 14px 0px;
                padding-right: 5px;
                width: 55px;
                white-space: nowrap;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
                z-index: 1;
              }
      
              .md-menu--select-field {      
                .md-icon-separator {
                  text-align: left;
                  margin-top: 0;
      
                  .md-icon-text {
                    padding-right: 0px;
                    font-size: 15px;
                    color: black;}
                  }
    
                .md-divider--text-field::after {
                  background-color: $gray;
                }
      
                .md-list {
                    position: fixed;
                    top: 0;
                    height: 100vh;
                    width: 100vw;
                    max-height: 100vh;
                    max-width: 100vw !important;
                    left: 50%;
                    transform: translate(-50%, 0);   
                    
                    @include desktop {
                        width: 358px !important;
                    }
                }
      
              }
            }
        }

        .link-text {
            padding: 0px 23px !important;
        }

        .md-text-field {
            color: $black;
            border: 1px solid #e9e9e9;
            border-radius: 25px;
            height: 45px;
            padding: 0 20px;
            @include font-size(20);
            width: 100%;
        } 

        .sub-text {
            color: $black;
            margin-bottom: 10px;
        }
    }

    .error-active {
        text-align: center;
        margin-top: 5px;
        color: red;
        margin-bottom: 0;
    }

    .title {
        color: $black;
        display: block;
        @include font-size(20);
        font-weight: $black-bold;        
        margin-bottom: 20px;
        text-align: center;
    }   

    .list {    
        .list-item {
          color: $black;
          cursor:pointer;
          display: block;
          font-size: 15px;
          padding: 15px 30px;
          text-align: left;
          right: 0;
  
          &:hover {
            background: #177ad5 !important;
            color: $white !important;
          }
          
        }
    }

}