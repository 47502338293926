.space-listing-page {
  padding-top: 55px;

  @include desktop {
    padding-top: 71px;
  }

  .cutome-gray-divider {
    margin-bottom: 0 !important;
    flex: 1;
  }

  .mb-15 {
    margin-bottom: 15px;
  }

  .position-relative {
    position: relative;
  }

  .flex-wrap {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
  }

  .custom-gray-txt {
    font-size: 10px;
    font-weight: 700;
    flex: 1;
    max-width: 145px;
    margin-right: 5px;
  }

  .superfix {
    position: absolute;
    top: 13px;
    left: 65px;
    font-size: 14px;
    color: #ababab;

    &.seats {
      top: 12px;
      left: 58px;
    }
  }

  .ant-progress {
    display: block;

    .ant-progress-outer {
      display: flex;
    }
  }

  .full-height-wrapper {
    @include desktop {
      height: calc(100vh - 79px);
    }

    .section-wrapper {
      @include flex-row();
      align-items: unset;
      height: inherit;

      .left-side {
        height: inherit;
        width: 100%;

        @include desktop {
          width: 50%;
        }

        //packages styles
        .package-card-wrapper {
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          height: auto;
          margin-bottom: 10px;
          max-width: 356px;
          width: 100%;

          @include desktop {
            height: 168px;
          }

          .card-header {
            background-color: $gray;
            padding: 8px 20px;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            @include flex-row();
            justify-content: space-between;

            .titles-wrapper {
              .header-title {
                font-size: 15px;
                font-weight: $extra-bold;
                color: $black;
                word-break: break-word;
              }

              .sub-title {
                font-size: 11px;
                color: rgba(0, 0, 0, 0.5);
                word-break: break-word;
              }
            }
            .edit-delete-wrapper {
              @include flex-row();

              .edit {
                cursor: pointer;
                margin-right: 5px;

                .img {
                  width: 12px;
                }
              }

              .delete {
                cursor: pointer;
              }
            }
          }

          .content-area {
            padding: 15px 20px;
            color: $black;

            .content-block {
              .description-title {
                font-size: 13px;
                font-weight: $bold;
                word-break: break-word;
              }

              .description {
                color: #7b7b7b;
                font-size: 11px;
                word-break: break-word;
              }

              .price-tag {
                color: #7b7b7b;
                font-size: 11px;
              }

              .price {
                font-size: 13px;
                font-weight: $bold;
                text-transform: uppercase;
              }

              &.description-wrapper {
                margin-bottom: 10px;
              }
            }
          }
        }

        //packages styles

        .md-text--error {
          .md-text-field-message {
            margin-left: 15px;
          }
        }

        .icon-sentense-wrapper {
          background-color: $dark-green-op-05;
          border-radius: 10px;
          margin-bottom: 15px;
          padding: 10px 15px;

          .icon-sentense-inner-wrapper {
            @include flex-row();
            align-items: end;

            @include desktop {
              align-items: center;
            }
            .icon {
              margin-right: 5px;
              height: 17px;
              width: 17px;
            }
            .green-text {
              @include font-size(13);
              color: $dark-green-op-90;
              flex: 1;
              margin-bottom: 0;
            }
            &.flex-start {
              align-items: flex-start !important;
            }
          }
        }

        .ant-time-picker {
          margin-bottom: 15px;
          width: 100%;

          .ant-time-picker-input {
            width: 100%;
            border: 1px solid $light-gray2;
            border-radius: 25px;
            color: $black;
            height: 45px;
          }
        }

        .md-text-field-container {
          margin-bottom: 15px;
          padding-left: 0;
          padding-right: 0;
          width: 100%;

          @include desktop {
            margin-bottom: 15px;
            padding-left: 0;
            padding-right: 0;
          }

          .md-floating-label {
            color: $black;

            &.md-floating-label--inactive {
              @include font-size(14);
              left: 25px;
              top: -25px;

              @include desktop {
                top: -16px;
              }

              @include tablet-landscape {
                top: -23px;
              }
            }

            &.md-floating-label--floating {
              @include font-size(10);
              background: $white;
              color: $black;
              left: 25px;
              padding: 0 2px;
              top: -20px;

              @include desktop {
                top: -16px;
              }

              @include tablet-landscape {
                top: -19px;
              }
            }
          }

          .md-text-field {
            border: 1px solid $light-gray2;
            border-radius: 25px;
            color: $black;
            height: 45px;
            margin-top: 0;
            @include font-size(13);
            padding-left: 25px;

            &:focus {
              border: 1px solid $light-gray2;
            }

            @include tablet-landscape {
              padding-left: 35px;
              @include font-size(13);
            }
          }
          .md-divider {
            display: none;
          }

          .md-text-field-message-container {
            &.md-text--disabled {
              display: none;
            }
          }

          &.text-area {
            .md-floating-label--inactive {
              top: -15px;
              font-size: 14px;

              @include desktop {
                top: -10px;
              }
            }

            .md-text-field-multiline-container {
              &.md-text-field--floating-margin {
                margin-top: 0;
              }
            }

            .md-floating-label--floating {
              top: -20px;
              background-color: $white;
              z-index: 1;

              @include desktop {
                top: -16px;
              }
            }
            .md-text-field {
              height: 180px;
              padding-top: 15px;
              padding-right: 35px;
            }
          }
        }

        //auto complete
        .auto-complete-wrapper {
          border: 1px solid $light-gray2;
          border-radius: 25px;
          color: $black;
          height: 45px;
          margin-top: 0;
          margin-bottom: 15px;
          padding-left: 25px;
          padding-right: 20px;
          position: relative;
          z-index: 10;

          .search-input {
            border: unset;
            padding-top: 12px;
            width: 100%;

            &::placeholder {
              color: $black;
            }

            &:active {
              border: unset;
              outline: unset;
            }

            &:focus {
              border: unset;
              outline: unset;
            }
          }

          .search-menu-list {
            margin: 0 auto;
            background-color: $white;
            box-shadow: none;
            left: 0;
            margin-top: 1px;
            position: absolute;
            text-align: left;
            width: 100%;
            z-index: 100;

            .md-list-item {
              border-left: 1px solid $light-gray3;
              border-right: 1px solid $light-gray3;
              background-color: $white;
              color: $black;
              padding: 10px 30px;
              @include font-size(15);
              z-index: 100;

              .md-tile-content {
                .md-text {
                  &:before {
                    content: '';
                    width: 12px;
                    height: 16px;
                    background: url('../images/common/location.svg') no-repeat;
                    margin-right: 10px;
                    float: left;
                  }
                }
              }

              &:active {
                background-color: $light-gray;
              }

              &:hover {
                background-color: $light-gray;
              }

              &:first-child {
                border-top: 1px solid $light-gray3;
                border-top-left-radius: 32px;
                border-top-right-radius: 32px;
                padding-top: 20px;
              }

              &:last-child {
                border-bottom-left-radius: 32px;
                border-bottom-right-radius: 32px;
                border-bottom: 1px solid $light-gray3;
                padding-bottom: 20px;
              }
            }

            @include desktop {
              border-radius: 32px;
              box-shadow: inherit;
            }
          }
        }

        //auto complete

        .street-name-selector {
          .ant-input {
            @include font-size(14);
            border: none;
            color: $black;
            width: 97%;

            &:hover {
              border: none;
            }

            &:active {
              border: unset;
              outline: unset;
            }

            &:focus {
              border: unset;
              box-shadow: none;
              outline: unset;
            }
          }
        }

        .ant-select {
          margin-bottom: 15px;
          padding-left: 0;
          padding-right: 0;
          width: 100%;

          .ant-select-selection__placeholder {
            color: $black;
            padding-left: 25px;
            font-size: 14px;
          }

          .ant-select-arrow {
            color: $black;
          }

          &.ant-select-focused {
            .ant-select-selection__placeholder {
              transition-duration: 0.15s;
              transition-property: transform, font-size, color;
              z-index: 3;
              display: block !important;
              left: 25px;
              top: 0;
              font-size: 10px;
              background: white;
              width: fit-content;
              padding: 0 2px;
            }
          }

          &.country-disable {
            .ant-select-arrow .ant-select-arrow-icon {
              display: none;
          } 
          }

          &.placeHolderTop {
            .ant-select-selection__placeholder {
              transition-duration: 0.15s;
              transition-property: transform, font-size, color;
              z-index: 3;
              display: block !important;
              left: 25px;
              top: -1px;
              font-size: 10px;
              background: white;
              width: fit-content;
              padding: 0 2px;

              @include tablet-landscape {
              }
            }
          }

          @include desktop {
            margin-bottom: 15px;
            padding-left: 0;
            padding-right: 0;
          }

          .ant-select-selection {
            border: 1px solid $light-gray2;
            border-radius: 25px;
            box-shadow: none;
            color: $black;
            cursor: pointer;
            height: 45px;

            .ant-select-selection__rendered {
              margin: 0;
              outline: unset;
              padding-left: 15px;
              padding-top: 7px;

              .ant-select-selection-selected-value {
                padding-left: 10px;
                @include font-size(13);
              }

              .ant-select-selection__choice {
                @include flex-row();
                align-items: center;
                margin-top: 0;
                margin-bottom: 2px;
                z-index: 4;

                .ant-select-selection__choice__content {
                  @include font-size(13);
                }
              }
            }

            .ant-select-selection__clear {
              top: 50%;
            }

            .ant-select-arrow {
              top: 50%;
            }

            &.ant-select-selection--multiple {
              height: auto;
              padding-top: 3px;

              &:active {
                border: 1px solid $light-gray2;
                box-shadow: unset;
                outline: unset;
              }

              &:focus {
                border: 1px solid $light-gray2;
                box-shadow: unset;
                outline: unset;
              }

              .ant-select-selection__choice {
                background-color: $light-gray3;
                border: 1px solid $light-gray3;
                border-radius: 23px;
                height: 30px;
              }
            }

            &:active {
              border: 1px solid $light-gray2;
              box-shadow: unset;
              outline: unset;
            }

            &:focus {
              border: 1px solid $light-gray2;
              box-shadow: unset;
              outline: unset;
            }
          }

          &.ant-select-disabled {
            .ant-select-selection {
              background-color: $white;
              color: $dark-gray;
              cursor: not-allowed;

              .ant-select-selection__placeholder {
                color: $dark-gray;
              }

              .ant-select-arrow {
                color: $dark-gray;
              }
            }
          }

          &.input-mrt {
            .ant-select-selection__placeholder {
              background: $white;
              display: block !important;
              font-size: 10px;
              margin-left: 28px;
              padding: 0;
              transform: translate3d(0, -19px, 0);
              width: 55px;
            }

            &.ant-select-focused {
              .ant-select-selection__placeholder {
                transform: translate3d(0, 0, 0);
                margin-left: 0;
              }
            }
          }

          &.parking-available-input {
            .ant-select-selection__placeholder {
              background: $white;
              display: block !important;
              font-size: 10px;
              margin-left: 28px;
              padding: 0;
              transform: translate3d(0, -19px, 0);
              width: 80px;
            }

            &.ant-select-focused {
              .ant-select-selection__placeholder {
                transform: translate3d(0, 0, 0);
                margin-left: 0;
              }
            }
          }

          &.street-name-selector {
            .ant-select-selection__placeholder {
              background: $white;
              margin-left: 1px;
              padding-left: 23px;
            }

            &.ant-select-focused {
              .ant-select-selection__placeholder {
                padding-left: 0;
              }
            }

            &.placeHolderTop {
              .ant-select-selection__placeholder {
                padding-left: 0;
              }
            }
          }
        }

        .common-max-width-sm {
          max-width: 327px;
          width: 100%;
          .md-text-field-container {
            @include desktop {
              // max-width: 327px;
              width: 100%;
            }

            @include exlarge-desktop {
              // max-width: 62%;
              width: 100%;
            }
          }

          .ant-select {
            @include desktop {
              // max-width: 327px;
              width: 100%;
            }

            @include exlarge-desktop {
              // max-width: 62%;
              width: 100%;
            }
          }
        }

        .common-max-width-ex-sm {
          max-width: 225px;
          width: 100%;
        }

        .no-floating {
          display: none;
        }

        .circle-btn {
          border-radius: 50%;
          cursor: pointer;
          height: 40px;
          width: 40px;
        }

        .btn-green {
          background-color: $dark-green;
          border: 1px solid $dark-green;
          border-radius: 23px;
          color: $white;
          cursor: pointer;
          height: 40px;
          width: fit-content;

          &:active {
            outline: 0 !important;
          }

          &.button-disabled {
            pointer-events: none;
          }

          &:focus {
            outline: unset;
          }
        }

        .yellow-btn {
          background-color: #fcdd57;
          border-radius: 23px;
        }

        .border-btn {
          background-color: $white;
          border: 1px solid $light-gray2;
          border-radius: 23px;
          cursor: pointer;
          height: 40px;
          width: 84px;

          &:disabled {
            background-color: $light-gray3;
            cursor: not-allowed;
          }

          &.green-border {
            background-color: #f4ffff;
            border: 1px dashed $dark-green;
            color: $dark-green;
          }

          &:hover {
            background-color: $dark-green;
            border: 1px solid $dark-green;
            color: $white;
          }

          &:active {
            background-color: $dark-green;
            border: 1px solid $dark-green;
            color: $white;
            outline: 0 !important;
          }

          &.active {
            background-color: $dark-green;
            border: 1px solid $dark-green;
            color: $white;
            outline: 0 !important;
          }

          &.button-disabled {
            pointer-events: none;
          }

          &:focus {
            outline: unset;
          }
        }

        .red-btn {
          background-color: $error;
          border: 1px solid $error;
          border-radius: 23px;
          cursor: pointer;

          &:active {
            background-color: $error;
            outline: 0 !important;
          }

          &:focus {
            outline: unset;
          }
        }

        .gray-btn {
          background-color: $dull-gray;
          border: 1px solid $dull-gray;
          border-radius: 23px;
        }

        .floating-btn-wrapper {
          border-top: 1px solid #e8e8e8;
          bottom: 0;
          background-color: $white;
          position: fixed;
          width: 100%;
          z-index: 1;

          @include desktop {
            width: calc(100% - 50%);
          }

          @include tablet-landscape {
            padding-left: 100px;
          }

          .floating-btn-inner-wrapper {
            overflow: hidden;
            padding: 10px 25px;
            padding-top: 10px;
            padding-bottom: 10px;

            @include desktop {
              float: right;
              max-width: 555px;
              padding-left: 0;
              padding-right: 0;
              width: 100%;
            }

            .btn {
              @include font-size(13);
              color: $black;
              cursor: pointer;
              font-weight: $black-bold;
              background-color: unset;
              border: unset;
              height: 45px;
              width: 120px;

              &:focus {
                outline: unset;
              }

              &.back-btn {
                float: left;
                text-align: left;
                padding: 0;
                width: 136px;

                .back-icon {
                  height: 11px;
                  margin-right: 7px;
                  transform: rotate(180deg);
                }
              }

              &.next-btn {
                background-color: #fcdd57;
                border-radius: 23px;
                float: right;

                @include desktop {
                  margin-right: 45px;
                }
              }
            }
          }
        }

        .link-text-wrapper {
          margin-top: 40px;
          text-align: center;

          .link-text {
            color: $dark-green;
            cursor: pointer;
            font-weight: $bold;
            text-decoration: underline;
          }
        }

        .common-popup {
          color: $black;

          .md-dialog {
            border-radius: 24px;

            .md-dialog-content--padded {
              padding: 30px 24px 10px 24px;

              .btn-seperater {
                border-top: 1px solid #e8e8e8;
                margin-left: -24px;
                margin-right: -24px;
                margin-bottom: 10px;
              }

              .gray-text {
                color: $dark-gray;
              }
              .main-title {
                @include font-size(18);
                font-weight: $black-bold;
                line-height: 22px;
                margin-bottom: 20px;
              }

              .btn-wrapper {
                @include flex-row();
                flex-wrap: nowrap;

                .border-btn {
                  @include font-size(13);
                  cursor: pointer;
                  font-weight: $black-bold;
                  height: 40px;
                  margin-right: 2.5px;
                  text-transform: uppercase;
                  width: 140px;

                  &:hover {
                    background-color: $white;
                    border: 1px solid $light-gray2;
                    color: $black;
                  }

                  &:active {
                    background-color: $white;
                    border: 1px solid $light-gray2;
                    color: $black;
                    outline: 0 !important;
                  }

                  &.button-disabled {
                    pointer-events: none;
                  }

                  &:focus {
                    outline: unset;
                  }
                }

                .gray-btn {
                  @include font-size(13);
                  cursor: pointer;
                  font-weight: $black-bold;
                  height: 40px;
                  margin-left: 2.5px;
                  text-transform: uppercase;
                  width: 140px;

                  &:hover {
                    background-color: $dark-green;
                    border: 1px solid $dark-green;
                    color: $white;
                  }

                  &:active {
                    background-color: $dark-green;
                    border: 1px solid $dark-green;
                    color: $white;
                    outline: 0 !important;
                  }

                  &.active {
                    background-color: $dark-green;
                    border: 1px solid $dark-green;
                    color: $white;
                    outline: 0 !important;
                  }

                  &.button-disabled {
                    pointer-events: none;
                  }

                  &:focus {
                    outline: unset;
                  }
                }
              }
            }
          }
        }

        .md-menu-container {
          color: $black;
          height: 45px;

          .md-select-field__toggle {
            height: 45px;
            padding-left: 25px;

            .md-floating-label {
              color: $black;

              &.md-floating-label--inactive {
                top: -25px;
                font-size: 14px;

                @include desktop {
                  top: -20px;
                }

                @include tablet-landscape {
                  top: -25px;
                }
              }

              &.md-floating-label--floating {
                @include font-size(10);
                background: $white;
                top: -22px;

                @include desktop {
                  top: -17px;
                }

                @include tablet-landscape {
                  top: -22px;
                }
              }
            }

            .md-text-field--floating-margin {
              margin-top: 0;
            }

            .md-paper {
              .md-icon {
                visibility: hidden;
              }
              .md-divider {
                display: none;
              }
            }
          }

          .md-list {
            top: unset;
            width: 100%;
          }
        }

        .left-side-inner-wrapper {
          letter-spacing: 0;
          max-height: calc(100vh - 140px);
          overflow: auto;
          padding-top: 40px;
          padding-left: 25px;
          padding-right: 25px;
          padding-bottom: 40px;
          text-align: left;

          @include desktop {
            max-height: calc(100vh - 140px);
            padding-bottom: 0;
          }

          h1 {
            letter-spacing: 0;
          }

          p {
            letter-spacing: 0;
          }

          @include desktop {
            color: $black;
            float: right;
            height: 100%;
            padding-left: 0;
            max-width: 555px;
            padding-right: 80px;
            width: 100%;
          }

          @include tablet-landscape {
            padding-left: 100px;
          }

          .md-text--disabled {
            color: $dark-gray;
            cursor: not-allowed;
          }

          .md-text-field-container--disabled {
            .md-floating-label {
              &.md-text--disabled {
                color: $dark-gray;
              }
            }
          }

          .listing-page-title {
            @include font-size(25);
            color: $black;
            font-weight: $black-bold;
            line-height: 28px;
            margin-bottom: 20px;
          }

          .divider {
            margin-right: -25px;
            margin-left: -25px;
            margin-top: 0;
            margin-bottom: 15px;
            color: #707070;
            opacity: 0.2;
            overflow: hidden;

            @include desktop {
              margin-right: -80px;
              margin-left: unset;
            }
          }

          .page-title-no-margin {
            margin-bottom: 0;
          }

          .price-type {
            border-radius: 23px;
            background-color: $light-gray3;
            color: $black;
            font-size: 13px;
            height: 30px;
            margin-bottom: 10px;
            padding: 6px 13px;
            width: fit-content;

            .img {
              height: 15px;
              margin-right: 6px;
              width: 15px;
            }
          }

          .monthly-text-wrap {
            color: $dark-gray;
            font-size: 13px;

            .deposit-amount {
              float: right;
              margin-right: 12px;
            }
          }

          .monthly-text {
            color: $dark-gray;
            margin-bottom: 18px;
          }

          .date-wrapper {
            display: flex;

            .availability-time {
              background-color: $light-gray3;
              border-radius: 25px;
              margin-right: 5px;
              height: 40px;
              width: 50%;

              .md-text--disabled {
                color: $black;
                height: 40px;
              }
            }

            .md-menu-container {
              border-radius: 25px;
              border: 1px solid #e9e9e9;
              height: 40px;
              margin-right: 5px;
              width: 110px;

              .md-paper {
                padding-top: 13px;
              }
            }
          }
          .bold-cls {
            font-weight: $bold;
          }

          .page-switch-btn-wrapper {
            margin-top: 20px;
            width: 100%;

            @include exlarge-desktop {
              width: 100%;
            }

            .switch-name-wrapper {
              @include flex-row();
              align-items: flex-start;
              border-bottom: 0.5px solid rgba(112, 112, 112, 0.2);
              justify-content: space-between;
              padding: 10px 0;

              &:last-child {
                border-bottom: unset;
              }

              .block-wrapper {
                max-width: 85%;

                .name-price-wrapper {
                  @include flex-row();

                  .price {
                    margin-left: 4px;
                    color: #909090;
                  }
                }
                .description {
                  @include font-size(13);
                  color: #767676;
                  word-break: break-word;
                }
              }

              .ant-switch {
                background-color: $dull-gray;
                border: 1px solid $dull-gray;

                &.ant-switch-checked {
                  background-color: $dark-green;
                  border: 1px solid $dark-green;
                }
              }
            }
          }

          .package-section {
            margin-top: 30px;

            .border-btn {
              @include font-size(13);
              font-weight: $black-bold;
              margin-bottom: 20px;
              max-width: 250px;
              width: 100%;

              &:hover {
                background-color: #f4ffff;
                border: 1px dashed $dark-green;
                color: $dark-green;
              }

              &:active {
                background-color: #f4ffff;
                border: 1px dashed $dark-green;
                color: $dark-green;
                outline: 0 !important;
              }
            }
          }

          .calendar-section{

            margin-top:20px;
            
            
            .calendar-btn {
              background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
              @include font-size(13);
              background: #FFFFFF 0% 0% no-repeat padding-box;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content:start;
              border-radius: 23px;
              border: 1px solid #C6C6C6;
              margin-top:20px;
              max-width: 350px;
              height:50px;
              width:100%;
             
             
            }
            .btn-name{
              font-weight: $black-bold;
              margin-top:10px;
              display: flex;
              align-items: center;
              justify-content:start;

            }
            .img{
              margin-left:8px;

            }
            .correct-img{
              margin-right:0;
            
            }

           .cancel-img{
             display:none;
           }
          
         
          .correct-cancel:hover{
             .cancel-img{
              display:flex;
             }
             .correct-img{
              display:none;
             }
          }
            .success-msg{
              // align-self:left;
              text-align:left;
              margin-left:9px;
              color: $dark-green;
              font-size:x-small;
              margin-bottom: 0 !important;
            }

            .msg-and-icon{
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 40px;
            }

            .google-and-msg{
              font-weight: $black-bold;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
            }
          }

          .name-wrapper {
            display: flex;
            padding-right: 0;
            padding-left: 0;
            width: 100%;

            @include desktop {
              padding-left: 0;
            }

            .md-text-field-container {
              padding-left: 0;
              padding-right: 0;

              @include desktop {
                padding-right: 0 !important;
              }

              @include tablet-landscape {
                margin-right: 0 !important;
              }

              &:nth-child(1) {
                margin-right: 10px !important;
              }
            }
          }

          &.common-max-width {
            .md-text-field-container {
              width: 100%;

              @include desktop {
                width: 100%;
              }

              @include exlarge-desktop {
                width: 100%;
              }

              @include tablet-landscape {
                margin-right: 20px;
                width: 100%;
              }
            }

            .ant-select {
              @include desktop {
                width: 100%;
              }

              @include exlarge-desktop {
                width: 100%;
              }

              @include tablet-landscape {
                width: 100%;
              }
            }
          }

          &.external-system-wrapper {
            .input-wrapper {
              margin-top: 20px;
            }
          }

          .btn-wrapper {
            .btn {
              &:nth-child(1) {
                margin-right: 5px;
              }
            }
          }

          p {
            @include font-size(15);
          }

          .quill {
            border: 1px solid $light-gray2;
            border-radius: 10px;
            color: $black;
            @include font-size(13);
            margin-bottom: 15px;
            min-height: 180px;
            padding-left: 0;
            padding-right: 0;

            .ql-container {
              &.ql-snow {
                border: none;
                color: $black;
                margin-bottom: 20px;
                z-index: 1;

                .ql-tooltip {
                  margin: 8px 15px;
                  transform: translateX(80px);

                  &.ql-editing {
                    transform: translateX(130px);
                  }

                  &.ql-flip {
                    transform: translateX(120px);
                  }
                }

                .ql-editor {
                  padding-bottom: 0px;

                  &.ql-blank {
                    &::before {
                      font-style: normal;
                    }
                  }
                }

                h1 {
                  color: $black;
                }

                p {
                  color: $black;
                }
              }
            }
            .ql-toolbar {
              &.ql-snow {
                border: none;
                border-bottom: 1px solid $light-gray2;

                .ql-picker-options {
                  z-index: 2;
                }
              }
            }
          }
        }

        .sm-input-height {
          .md-text-field-container {
            color: $black;

            .md-floating-label {
              color: $black;

              &.md-floating-label--inactive {
                top: -25px;
                font-size: 14px;

                @include desktop {
                  top: -20px;
                }
              }

              &.md-floating-label--floating {
                @include font-size(10);
                top: -20px;

                @include desktop {
                  top: -17px;
                }
              }
            }
            .md-text-field {
              height: 40px;
            }
          }

          .ant-time-picker {
            margin-bottom: 0;
            .ant-time-picker-input {
              height: 40px;
            }
          }

          .ant-select {
            .ant-select-selection {
              height: 40px;

              .ant-select-selection__rendered {
                padding-left: 20px;

                .ant-select-selection__placeholder {
                  top: 16px;
                  padding-left: 20px;
                }
              }

              &.ant-select-selection--multiple {
                .ant-select-selection__rendered {
                  padding-top: 1px;
                }
              }
            }

            &.placeHolderTop {
              .ant-select-selection {
                .ant-select-selection__rendered {
                  .ant-select-selection__placeholder {
                    left: 20px;
                    padding-left: 1px;
                    top: -4px;
                  }
                }
              }
            }
            &.ant-select-focused {
              .ant-select-selection {
                .ant-select-selection__rendered {
                  .ant-select-selection__placeholder {
                    left: 20px;
                    padding-left: 1px;
                    top: -4px;
                  }
                }
              }
            }
          }

          .md-menu-container {
            height: 40px;

            .md-select-field__toggle {
              height: 40px;
              padding-left: 20px;

              .md-floating-label {
                &.md-floating-label--inactive {
                  top: -25px;
                  font-size: 14px;

                  @include desktop {
                    top: -20px;
                  }

                  @include tablet-landscape {
                    top: -25px;
                  }
                }

                &.md-floating-label--floating {
                  @include font-size(10);
                  top: -22px;

                  @include desktop {
                    top: -17px;
                  }

                  @include tablet-landscape {
                    top: -22px;
                  }
                }
              }

              .md-paper {
                .md-text-field {
                  height: 40px;
                }
              }
            }
          }
        }

        .gray-text {
          color: $dark-gray;

          &.sm-text {
            @include font-size(13);
          }
        }
      }

      .right-side {
        @include flex-row();
        align-items: unset;

        @include desktop {
          height: inherit;
          width: 50%;
        }

        &.map-section {
          height: 50vh;
          width: 100%;

          @include desktop {
            height: inherit;
            width: 50%;
          }
        }

        .background-img-container {
          @include background-property();
          background-size: cover;
          width: 100%;
        }

        .map-content-wrapper {
          @include flex-row();
          align-items: unset;
          flex: 1;

          .map-title-wrapper {
            @include flex-row();
            height: 45px;
            justify-content: center;
            position: absolute;
            top: 30px;
            width: 100%;
            z-index: 1;

            .map-title {
              @include font-size(13);
              background-color: rgba(255, 255, 255, 0.8);
              border-radius: 24px;
              font-weight: $black-bold;
              margin: 0 auto;
              max-width: 350px;
              padding: 13px;
              width: 100%;
            }
          }

          .container-element {
            @include flex-row();
            align-items: unset;
            flex: 1;

            .map-element {
              @include flex-row();
              align-items: unset;
              flex: 1;
            }
          }
        }
      }
    }
  }
}
