.bg-img-section {
  @include background-property;
  background-size: cover;
  background-position: top;
  height: 272px;
  position: relative;

  .bg-img-overlay {
    height: inherit;

    .award-img {
      position: absolute;
      bottom: 40px;
      right: 100px;
      width: 100%;

      @include flex-row;
      justify-content: flex-end;

      .img {
        @include mobile {
          width: 130px;
        }

        @include laptops {
          width: 150px;
        }
      }

      .mr30 {
        margin-right: 30px;
      }

      @include tablet-landscape {
        right: 50px;
      }

      @include mobile {
        bottom: 26px;
        justify-content: space-around;
        right: 0;
      }

      @include tablet-portrait {
        justify-content: space-around;
        right: 0;
      }

      @include laptops {
        bottom: 20px;
      }
    }

    .white-overlay {
      height: 272px;
      opacity: 1;

      @include desktop {
        height: 792px;
      }

      @include exlarge-desktop {
        height: 820px;
      }
    }

    @include desktop {
      border-radius: 0;
    }

    @include tablet-landscape {
      border-radius: 0;
    }
  }

  .scroll-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    left: 49%;
    outline: none;
    position: absolute;
    scroll-behavior: smooth;
    top: calc(100vh - 358px);

    @include laptops {
      top: calc(100vh - 300px);
    }

    @include mobile {
      left: unset;
      position: relative;
      top: -65px;
    }

    @include tablet-portrait {
      left: unset;
      position: relative;
      top: -70px;
    }

    .arrow-icon {
      width: 32px;
    }
  }

  @include desktop {
    border-radius: 0;
    height: calc(100vh - 270px);
  }

  @include laptops {
    height: calc(100vh - 240px);
  }

  @include tablet-landscape {
    background-position: center;
  }

  @include mobile {
    height: 400px;
  }

  @include tablet-portrait {
    height: 760px;
  }
  .navigation-section {
    .search-icon {
      left: 21px !important;
      top: 13px !important;
    }

    &.white-bg-navbar {
      .left-wrap {
        @include mobile {
          display: none;
        }

        @include tablet-portrait {
          display: none;
        }
      }
      
      .middle-wrap {
        display: none;
      }
      
      .right-wrap {
        @include mobile {
          display: none;
        }

        @include tablet-portrait {
          display: none;
        }
      }
    }
  }

  .location-wrap {
    position: absolute;
    top: 113%;
    left: 8.5%;

    .text {
      color: $black;
      font-weight: $semi-bold;
      @include font-size(12);
      margin-right: 10px;
    }

    .img {
      border-radius: 25px;
      height: 21px;
      margin-right: 5px;
      width: 21px;
    }

    .custom-img {
      &.web {
        @include mobile {
          display: none;
        }

        @include tablet-portrait {
          display: none;
        }
      }

      &.mobile {
        @include desktop {
          display: none;
        }
      }
    }

    @include tablet-portrait {
      top: 111%;
    }

    @include desktop {
      top: unset;
      bottom: 320px;

      .text {
        @include font-size(15);
      }
    }

    @include exlarge-desktop {
      bottom: 350px;
    }

    @include mobile {
      top: 108%;
    }
  }

  .booking-notification {
    background-color: $black-op-50;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    color: $white;
    display: inline-block;
    position: relative;
    padding: 15px 23px 14px 22px;
    top: 495px;

    &.mobile {
      @include desktop {
        display: none;
      }

      @include tablet-landscape {
        display: none;
      }

      @include tablet-portrait {
        display: none;
      }
    }

    .booking-text-wrap {
      display: inline-grid;

      .bold-text {
        font-weight: $black-bold;
        @include font-size(20);
        line-height: 18px;
        text-align: left;
      }

      .booking-text {
        font-weight: $semi-bold;
        @include font-size(11);
      }
    }

    .badge-icon {
      margin-right: 10px;
      vertical-align: -webkit-baseline-middle;
    }
  }
}
