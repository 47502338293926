.activity-property-wrap {
    .activity-wrap {
        @include flex-row;

        .activity {      
            margin-right: 8px;
            margin-bottom: 8px;
            @include background-property;
            background-size: cover;
            height: 67px;
            width: 22.3%;
            position: relative;
            border-radius: 10px;

            .bg-img-overlay {
                background: transparent linear-gradient(180deg, #FFFFFF00 30%, #000000 100%);
                border-radius: 10px;
                height: 100%;
                @include flex-row;
                justify-content: center;
                align-items: flex-end;
            }

            .text-activity{               
                @include font-size(11);
                font-weight: $medium;
                color: $white;
                text-align: center;
                padding: 5px;
            }

            @include tablet-portrait {
                height: 76px;
                width: 76px;
                margin-right: 10px;
            }

            @include desktop {
                height: 76px;
                width: 76px;
                margin-right: 10px;
            }
        }
    }
}