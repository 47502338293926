.booking-cancellation-modal {
  width: 305px !important;

  .ant-modal-content {
    @include desktop {
      border-radius: 24px;
    }

    @include tablet-portrait {
      border-radius: 24px;
    }
  }

  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-body {
    padding: 0px;
  }

  .content {
    padding: 30px;
    padding-bottom: 20px;
    text-align: center;
  }

  .title {
    color: $black;
    display: block;
    @include font-size(18);
    font-weight: $black-bold;
    margin-bottom: 10px;
    text-align: center;
  }

  .link {
    color: #106dd1;
    text-decoration: underline;

    &.bold {
      font-weight: $extra-bold;
    }
  }

  .text {
    color: $black;
    @include font-size(15);
    margin-bottom: 0px !important;
  }

  .footer {
    border-top: 1px solid $light-gray;
    padding: 10px 30px;

    .btn {
      @include font-size(13);
      height: 40px;
      width: 117px;
      font-weight: $black-bold;
    }

    .btn-red {
      background-color: #f35050;
      color: $white;
      margin-left: 10px;
    }
  }
}

.booking-recipt-modal {
  width: 475px !important;
  top: 50px !important;

  .ant-modal-content {
    border-radius: 0;
  }

  .ant-modal-body {
    padding: 0px;
  }

  .ant-modal-close-x {
    display: none;
  }

  .receipt {
    .receipt-header {
      background-color: #106dd1;
      color: $white;
      @include flex-row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 40px;

      .right-wrap {
        .date {
          color: $white;
          font-size: 10px;
        }

        .title {
          font-size: 18px;
          font-weight: $black-bold;
          text-transform: uppercase;
          word-break: break-word;
        }
      }

      @include mobile {
        padding: 30px 20px;
      }
    }

    .text-right {
      text-align: right;
    }

    .receipt-body {
      padding: 40px;

      .block {
        display: block;
      }

      .inline-block {
        display: inline-block;
      }

      .w-50 {
        width: 50%;
      }

      .currency {
        margin-right: 2px;
      }

      .border {
        border-top: 1px solid #e2e2e2;
      }

      .bold {
        font-weight: $black-bold;
      }

      .mb-8 {
        margin-bottom: 8px;
      }

      .mt-8 {
        margin-top: 8px;
      }

      .p-tb-8 {
        padding: 8px 0;
      }

      .gray-wrap {
        color: $black-op-50;
        font-size: 10px;
        font-weight: $semi-bold;
      }

      .flex-wrap {
        @include flex-row;
        justify-content: space-between;
        flex-wrap: nowrap;

        &.custom-flex-wrap {
          align-items: flex-start !important;
        }

        .package-details-price {
          width: 100%;
          .detail-price-wrapper {
            @include flex-row();
            justify-content: space-between;
          }
        }
      }

      .address-title {
        display: block;
        font-size: 9px;
        font-weight: $bold;
        margin-bottom: 6px;
      }

      .address-content {
        font-size: 11px;
        word-break: break-word;
      }

      .border-wrap {
        border: 0.5px solid #e2e2e2;
        border-radius: 5px;
        color: $black;
        font-size: 11px;
        margin-top: 24px;

        .font-11 {
          font-size: 11px;
        }

        .font-15 {
          font-size: 15px;
        }

        .custom-bold {
          color: $black;
          font-weight: $extra-bold;
        }

        .inline-wrap {
          display: inline-block;
          align-items: top !important;
          width: 50%;

          .icon {
            margin-right: 5px;
          }
        }

        .padding-wrap {
          padding: 14px;

          &.border {
            border-top: 1px solid #e2e2e2;
          }
        }
      }

      @include mobile {
        padding: 20px;
      }
    }

    .footer {
      border-top: 0.5px solid $bright-blue;
      font-size: 10px;
      color: $black;
      margin-top: 30px;
      padding-top: 10px;
    }
  }
}

.booking-map-modal {
  width: 81% !important;
  top: 25px !important;

  .close-img {
    cursor: pointer;

    &.web {
      @include mobile {
        display: none;
      }
    }

    &.mobile {
      @include desktop {
        display: none;
      }

      @include tablet-portrait {
        display: none;
      }
    }
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-content {
    box-shadow: none;
    border: none;
    background: transparent;

    .map-height {
      height: calc(100vh - 170px) !important;
      width: 100%;

      @include mobile {
        height: calc(100vh) !important;
      }
    }

    .header {
      @include flex-row;
      justify-content: space-between;
      margin-bottom: 20px;

      .left-wrap {
        .text {
          color: $white;
          display: block;
          font-size: 18px;
          font-weight: $bold;
          line-height: 1;

          @include mobile {
            color: $black;
          }
        }

        .favourite {
          color: $white;
          display: block;
          font-size: 15px;
          font-weight: $extra-bold;
          line-height: 1;
        }
      }

      @include mobile {
        background-color: $white;
        margin-bottom: 0;
        padding: 17px 24px;
      }
    }
  }

  .favourites {
    display: inline-block;
    .icon {
      color: #f35050;
      height: 10px;
      width: 10px;
    }

    .number {
      color: $black;
      @include font-size(13);
      font-weight: $black-bold;
      margin: 0 3px;

      @include desktop {
        color: $white;
      }
    }

    .gray-text {
      color: $black-op-50;
      @include font-size(13);

      @include desktop {
        color: rgba($color: $white, $alpha: 0.5);
      }
    }
  }

  .ant-modal-body {
    @include mobile {
      padding: 0;
    }
  }

  @include mobile {
    width: 100% !important;
    top: 0 !important;
    max-width: 100% !important;
    margin: 0 !important;
  }
}
