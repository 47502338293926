@mixin min-width-992 {
  @media (min-width: #{$laptop-width}) {
    @content;
  }
}

//---TEMP EDIT by Azhar
.price-block-wrapper {
  margin-top: 20px;
  position: relative;
  width: 100%;

  .price-block-title {
    position: absolute;
    top: -32px;
    width: 100%;
    text-transform: uppercase;
    color: #d10000;
    @include font-size(15);
  }
  .inner-price-block-wrapper {
    display: flex;
    justify-content: space-between;
    @include font-size(13);
    padding-bottom: 7px;
    position: relative;
    word-break: break-all;

    &.total-value {
      @include font-size(15);
    }

    .value-cls {
      min-width: 30%;
      text-align: right;

      .blue-text-link {
        color: $bright-blue;
        text-decoration: underline;
      }

      .img {
        margin-left: 5px;
      }
    }

    .label-cls {
      text-align: left;
      word-break: break-word;

      .tooltip-icon {
        cursor: pointer;
        .tooltip-wrapper {
          display: none;
          top: 28px;
          position: absolute;
          padding: 20px;
          padding-bottom: 10px;
          background-color: rgba(0, 0, 0, 0.8);
          border-radius: 10px;
          width: 100%;
          z-index: 11;

          .detail-price-wrapper {
            display: flex;
            justify-content: space-between;
            color: white;
            padding-bottom: 10px;

            &.final-row {
              font-weight: 700;
              padding-top: 10px;
              border-top: 0.5px solid $white;
            }

            &.price-tooltip {
              display: block;
            }

            .package-name {
              color: #7f7f7f;
              font-size: 11px;
              font-weight: $bold;
            }

            .price-detail {
              font-size: 13px;
            }

            .right-text {
              display: flex;
              justify-content: space-between;
            }
          }
          &::before {
            content: '';
            position: absolute;
            top: -13px;
            left: 50px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 13px solid rgba(0, 0, 0, 0.7);
          }

          &.tax-tip {
            left: 0;

            &::before {
              left: 34px;
            }
          }

          &.package-tooltip {
            .price-tooltip{
              display: block !important;
            }
          }
        }

        &:hover {
          .tooltip-wrapper {
            display: block;
          }
        }
      }
    }
  }

  .section-divider {
    margin: 0;
    margin-bottom: 10px;
    padding-top: 0;
  }
}
//---TEMP EDIT END

.space-right {
  display: none;

  @include desktop {
    position: relative;
    right: 5%;
    width: 29%;
    left: 66%;
    height: 2500px;
    margin-top: 0;
  }

  @include tablet-landscape {
    height: 2500px;
    left: 65%;
    margin-top: 0;
    position: relative;
  }

  @include min-width-992 {
    display: block;
  }

  .booking-wrapper {
    position: fixed;
    top: 0;
    width: inherit;
    bottom: 0;

    @include desktop {
      position: sticky !important;
      position: -webkit-sticky !important;
      top: 100px;
      width: 100%;
    }

    @include tablet-landscape {
      position: sticky !important;
      position: -webkit-sticky !important;
      top: 100px;
      width: 100%;
    }

    .bold-cls {
      font-weight: $black-bold;
    }

    .title {
      @include font-size(18);
      line-height: 24px;
      margin-bottom: 20px;
      text-align: left;
    }

    .close-btn {
      @include desktop {
        display: none;
      }

      @include tablet-landscape {
        display: none;
      }
    }

    .md-picker-content {
      height: auto !important;
    }

    .space-grid {
      padding-top: 72px;

      .text-center {
        text-align: center;
      }

      .space-grid-inner {
        color: $black !important;
        display: flex;
        flex-direction: column;

        .error {
          display: block;
          position: relative;
          top: -12px;

          &.error-text {
            @include font-size(13);
            color: red;
            text-align: left;
          }
        }

        @include desktop {
          width: 100%;
        }

        .stripe-wrap {
          margin: 30px 0;
          text-align: left;

          .stripe-header {
            padding: 30px;
            padding-bottom: 0;
            padding-top: 23px;
          }

          .head-wrap {
            padding: 25px 30px;
            padding-top: 19px;
            text-align: left;
            position: relative;
            border-bottom: 1px solid #d9d9d9;

            .back-icon {
              cursor: pointer;

              .img {
                width: 14px;
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
              }
            }

            .total-title {
              @include font-size(13);
              display: inline-block;
              margin-right: 5px;
            }

            .value {
              @include font-size(20);
            }

            .close-btn {
              position: absolute;
              right: 0;
            }
          }

          .add-new-card {
            position: relative;

            .CardField-child {
              border: 1px solid $light-gray;
              border-radius: 10px;
              display: block;
              margin-bottom: 10px;
            }

            .error-text {
              color: red;
              display: block;
              @include font-size(13);
              margin-top: 10px;
              text-align: center;
            }

            .card {
              border: 1px solid #e9e9e9;
              border-radius: 10px;

              .header {
                padding: 14px 20px;
                border-bottom: 1px solid #e9e9e9;

                .add-new-title {
                  margin-bottom: 0;
                }
              }

              .content {
                padding: 18px 20px;
              }
            }

            .padding-wrap {
              @include desktop {
                min-height: 310px !important;
              }
            }
          }

          .existing-add-card-wrap {
            position: relative;

            @include desktop {
              min-height: 470px;
            }

            @include tablet-landscape {
              min-height: 450px;
            }

            .small-title {
              margin: 0 30px 15px 30px;
            }

            .padding-wrap {
              padding: 30px;
              padding-top: 0;
              overflow-y: auto;

              @include desktop {
                min-height: 380px;
                padding-bottom: 50px;
              }
            }

            .flex-wrapper {
              margin: 0 auto;
              margin-top: 7px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 56%;

              .terms-wrap {
                color: $black !important;
                text-decoration: none !important;
                cursor: pointer;
                font-size: 10px;
                margin-right: 10px;

                b {
                  font-weight: $black-bold;
                }
              }
            }

            .card-holder {
              border: 1px solid $light-gray;
              border-radius: 10px;
              cursor: pointer;
              display: block;
              @include font-size(15);
              font-weight: $black-bold;
              margin-bottom: 10px;
              padding: 17px 20px;

              &.active {
                border: 1px solid $ms-blue;
              }

              &:first-child {
                margin-top: 20px;
              }

              .img {
                margin-right: 13px;
              }
            }

            .existing-add-btn-wrapper {
              border-top: 1px solid #d9d9d9;
              background-color: $white;
              padding: 11px 30px;
              position: fixed;
              bottom: 0;
              width: 100%;

              .existing-add-btn-add {
                color: $white;
                cursor: pointer;
                background-color: $pink;
                border-radius: 25px;
                height: 45px;
                width: 100%;
                font-weight: $black-bold;
              }

              @include desktop {
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                bottom: 0;
                position: relative;
                width: 100%;
              }

              @include tablet-landscape {
                bottom: 0;
                position: relative; 
              }
            }
          }

          .title {
            font-size: 25px;
            font-weight: $black-bold;
            margin-bottom: 0px;
          }

          .small-title {
            font-size: 11px;
            color: $black-op-50;
          }

          .add-new-link {
            font-weight: $bold;
            color: #106dd1;
            text-decoration: underline;
          }

          .add-new-title {
            text-align: left;
            font-weight: $black-bold;
            font-family: $font-family;
            font-size: 15px;
          }

          .btn-add {
            color: #000000;
            cursor: pointer;
            background-color: $yellow;
            border-radius: 25px;
            height: 45px;
            width: 88%;
            font-weight: $black-bold;
            position: absolute;
            bottom: 30px;
            right: 24px;
          }

          .btn-wrapper {
            @include mobile {
              position: fixed;
              width: 100%;
              right: 0px;
              bottom: 0px;
            }
          }
        }

        #dropin-container {
          margin-top: 25px;

          .braintree-dropin {
            .braintree-heading {
              @include font-size(25);
              font-family: $font-family;
              font-weight: $extra-bold;
            }

            .braintree-options-initial {
              margin-top: 20px;

              .braintree-options-list {
                text-align: left;
              }
            }
          }
        }

        .booking-inner-wrapper {
          padding: 25px;
          max-height: 100vh;
          height: calc(100vh - 72px);
          overflow-y: auto;
          border: 1px solid #d9d9d9;
          border-top: none;
          position: relative;
          padding-bottom: 150px;

          @include mobile {
            border: none;
          }

          @include desktop {
            border-radius: 20px;
            box-shadow: 0px 0px 10px #00000029;
            height: auto;
            max-height: 85vh;
            overflow-x: hidden;
            padding-bottom: 0;
          }
          
          @include tablet-landscape {
            border-radius: 20px;
            box-shadow: 0px 0px 10px #00000029;
            height: auto;
            max-height: 80vh;
            padding-bottom: 0;
          }

          .section-divider {
            border-bottom: 1px solid #d9d9d9;
            display: block;
            margin: 0 -25px;
            padding-top: 0;
          }

          .value-seat-day-block {
            padding-bottom: 15px;
            text-align: left;
            position: relative;

            .back-icon {
              cursor: pointer;

              .img {
                width: 14px;
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
              }
            }

            .total-title {
              @include font-size(13);
              display: inline-block;
              margin-right: 5px;
            }

            .value {
              @include font-size(20);
              margin-right: 3px;
            }

            .close-btn {
              position: absolute;
              right: 0;

              @include desktop {
                display: none;
              }
            }

            .seat {
              margin-right: 5px;
            }
          }

          .summery-block-wrapper {
            display: flex;
            flex-direction: column;
            text-align: left;

            .title {
              font-weight: 900;
            }
            .summery-block-inner-wrapper {
              display: flex;

              img {
                margin-right: 10px;
                width: 13px;
              }
            }
          }

          .md-text-field--margin {
            margin-top: 15px;
          }

          .md-text-field--floating-margin {
            margin-top: 15px;
          }

          .space-summary-wrapper {
            padding-top: 27px;

            @include desktop {
              min-height: 445px;
            }

            @include tablet-landscape {
              min-height: 445px;
            }

            .title {
              @include font-size(25);
              font-weight: $black-bold;
              text-align: left;
            }

            .detail-block {
              text-align: left;
              display: flex;
              align-items: baseline;
              margin-bottom: 10px;
              font-size: 14px;

              .img {
                width: 14px;
                margin-right: 10px;
              }

              &.detail-block:last-of-type {
                margin-bottom: 20px;
              }

              .time-slot-inner-wrap {
                display: flex;
                flex-wrap: wrap;
              }

              .time-slot {
                background-color: $light-gray3;
                border-radius: 23px;
                font-size: 13px;
                margin-bottom: 5px;
                margin-right: 6px;
                padding: 7px 10px;
              }
            }

            .payment-method-wrapper {
              display: flex;
              justify-content: space-between;
              padding: 15px 0;

              .payment-method {
                .img {
                  margin-right: 10px;
                }
              }
              .blue-text-link {
                color: $ms-blue;
                cursor: pointer;
                font-weight: $bold;
                text-decoration: underline;
              }
            }
          }

          .radio-btn-wrapper {
            padding-top: 20px;

            .ant-radio-group {
              width: 100%;

              .radio-btn-inner-wrapper {
                display: flex;
                justify-content: center;

                .ant-radio-button-wrapper {
                  padding: 0;
                  height: 45px;
                  padding-top: 8px;
                  border-color: #e2e2e2;
                  box-shadow: none !important;
                  color: $black;
                  flex-grow: 1;

                  @include mobile {
                    padding: 0 30px;
                    padding-top: 8px;
                  }

                  @include tablet-landscape {
                    padding: 8px 15px;
                  }

                  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
                    color: $white;
                    background-color: $ms-blue;
                    border-color: $ms-blue;
                  }

                  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
                    outline: none;
                  }
                }
              }

              .ant-radio-button-wrapper:first-child {
                border-radius: 100px 0 0 100px;
              }

              .ant-radio-button-wrapper:last-child {
                border-radius: 0 100px 100px 0;
              }

              .ant-radio-button-wrapper:only-child {
                border-radius: 100px;
              }
            }
          }

          .common-date-wrapper {
            width: 100%;

            .md-calendar-date--date {
              background: $white !important;
              padding: 0px !important;
            }

            .current-date {
              .md-calendar-date--date {
                border: 1px solid $ms-blue !important;
                background: $white !important;
                color: $ms-blue !important;
                padding: 6px !important;
              }

              .md-picker-text--active {
                border: 1px solid $ms-blue !important;
                color: $white !important;
                background-color: $ms-blue !important;
              }
            }

            .normal-date-cls {
              background: transparent !important;
            }

            .md-floating-label {
              left: 25px;
              top: -3px;
              @include font-size(10);
              color: $black;
              background-color: $white;

              @include mobile {
                top: -5px;
              }

              @include tablet-portrait {
                top: -5px;
              }
            }

            .md-icon {
              color: $black !important;
              font-size: 16px;
            }

            .md-text-field-icon--positioned {
              @include desktop {
                bottom: 7px !important;
              }

              @include tablet-portrait {
                bottom: 3px !important;
              }
            }

            .md-picker--inline {
              position: relative;
            }

            .md-text-field-container {
              width: 100%;

              .md-text-field-icon-container {
                .img {
                  position: absolute;
                  bottom: 4px;
                  left: 20px;
                  width: 14px;
                  height: auto;

                  @include mobile {
                    bottom: 3px;
                  }

                  @include tablet-portrait {
                    bottom: 3px;
                  }

                  @include tablet-landscape {
                    bottom: 0px;
                    left: 15px;
                  }
                }

                .md-text--theme-primary {
                  color: $black !important;
                }

                .md-text-field-divider-container {
                  margin-left: 0;
                  width: 100%;
                }
              }
            }

            &.common-inline-wrapper {
              width: 50%;
            }
          }

          .guest-input {
            .md-text-field-icon--positioned {
              position: absolute;
              bottom: 7px;
              left: 20px;
              width: 14px;
              height: auto;

              @include mobile {
                bottom: 4px;
              }

              @include tablet-portrait {
                bottom: 4px;
              }

              @include tablet-landscape {
                bottom: 0px;
                width: 14px;
                left: 15px;
              }
            }

            .md-text-field-divider-container--grow {
              margin-left: 0 !important;
            }

            .md-floating-label {
              transform: translate3d(0, 33px, 0);
              background: $white;
              color: $black;
              font-weight: normal;
              @include font-size(10);
              left: 23px;
              padding: 0 2px;
              top: -24px;

              @include desktop {
                top: -24px;
              }
            }
          }
          .seating-wrapper {
            width: 100%;
            position: relative;
            border-radius: 25px;
            height: 45px;
            padding-left: 0;
            margin-top: 15px;
            border: 1px solid #e2e2e2;
            z-index: 9;

            .seating-wrapper-label {
              background: $white;
              color: $black;
              @include font-size(10);
              left: 20px;
              position: absolute;
              padding: 0 2px;
              top: -10px;
              z-index: 2;
            }

            .md-ink-container {
              .md-ink {
                background: none;
              }
            }

            .img {
              position: absolute;
              top: 14px;
              left: 20px;
              z-index: 3;
            }

            .md-layover {
              width: 100%;
              z-index: 4;

              .md-btn {
                width: 100%;
                text-align: left;
                padding-left: 40px;
                font-size: 13px;
                padding-bottom: 0;
                text-transform: capitalize;
                color: rgba(0, 0, 0, 0.4);
                cursor: pointer;

                &.md-btn--color-primary-active {
                  background-color: transparent;
                }
              }

              .md-list {
                width: 100%;
                text-align: left;
                top: 45px;
                padding: 5px 0;

                .md-list-item {
                  margin-bottom: 5px;

                  .img {
                    right: 15px;
                    left: unset;
                    width: 30px;
                  }

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }

            .seating-list {
              width: 100%;
              -webkit-appearance: none;
              -moz-appearance: none;
              text-indent: 1px;
              text-overflow: '';

              &:focus {
                outline: none;
              }

              .md-list {
                text-align: left;
                top: unset;
                width: 100%;
              }

              .md-icon {
                display: none;
              }

              .md-icon-separator {
                position: relative;

                .img {
                  position: absolute;
                  left: 0;
                  left: 20px;
                }
              }
            }

            .seating-menu-wrapper {
              z-index: 2;
              display: flex;
              justify-content: space-between;

              &.md-list-item {
                margin-bottom: 10px;

                .img {
                  right: 15px;
                  left: 0 !important;
                  width: 30px;
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }

              .img {
                position: relative;
                top: unset;
                left: unset;
                width: 30px;
              }

              .md-fake-btn {
                width: 100%;
                padding: 0 20px;
              }

              .title {
                position: absolute;
                width: 70%;
                text-align: left;
                display: inline-block;
                left: 20px;
                font-size: 15px;
                margin-bottom: 0;
                word-break: break-word;
              }
            }

            .select-item-wrapper {
              z-index: 1;
              display: flex;
              justify-content: space-between;
              padding: 10px 20px;
              border-radius: 25px;
              height: 40px;
              background: white;
              padding-left: 50px;
              position: absolute;
              top: 1px;
              width: 100%;

              .img {
                position: relative;
                top: unset;
                left: unset;
                width: 30px;
              }

              .title {
                font-size: 15px;

                .light-gray {
                  font-size: 15px;
                  color: rgba(0, 0, 0, 0.54);
                }
              }
            }
          }

          .date-block-wrapper {
            display: flex;
            position: relative;
            flex-wrap: nowrap;
            flex-direction: column;

            .md-picker .md-calendar-date--btn {
              &::after {
                width: 0 !important;
                height: 0 !important;
              }
            }

            .md-picker {
              @include react-md-pickers-make-portrait;
              box-shadow: unset;

              .md-picker-header {
                display: none;
              }

              .md-picker-content-container {
                border-radius: 5px;
                -webkit-box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.26);
                -moz-box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.26);
                box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.26);

                .md-picker-content {
                  width: 327px;

                  @include tablet-landscape {
                    width: 280px;
                  }

                  .md-calendar-header {
                    .md-calendar-controls {
                      border-bottom: 1px solid #d9d9d9;
                      margin-bottom: 15px;

                      .md-title {
                        font-weight: 800;
                      }
                    }
                    .md-calendar-dow {
                      width: 36px;
                    }
                  }
                  .md-calendar-month {
                    .md-calendar-date {
                      width: 36px;
                      height: 36px;
                      margin-bottom: 5px;

                      .md-calendar-date--btn {
                        height: 36px;
                        padding: 0;

                        .md-calendar-date--date {
                          color: $ms-blue;

                          .disable-date-cls {
                            color: rgba(0, 0, 0, 0.3);
                            background: none !important;
                            cursor: not-allowed;
                          }

                          .normal-date-cls {
                            height: 36px;
                            width: 36px;
                            display: block;
                            padding: 6px;
                            border-radius: 36px;

                            &.current-date {
                              border: 1px solid $ms-blue;
                              background: $white !important;
                              color: $ms-blue;

                              &.disable-date-cls {
                                background: none !important;
                                color: rgba(0, 0, 0, 0.3);
                                cursor: not-allowed;
                              }
                            }

                            &.current-date:hover {
                              border: 1px solid $ms-blue;
                              background: $ms-blue !important;
                              color: $white;

                              &.disable-date-cls {
                                background: none !important;
                                color: rgba(0, 0, 0, 0.3);
                                cursor: not-allowed;
                              }
                            }
                          }

                          .calendar-select-days {
                            background: #edf5fc;
                          }

                          &.md-picker-text--active {
                            span {
                              background: $ms-blue !important;
                            }

                            .disable-date-cls {
                              background: none !important;
                              cursor: not-allowed;
                            }

                            .calendar-select-days {
                              color: $white;
                            }
                          }
                        }

                        &.md-calendar-date--btn-active {
                          .md-calendar-date--date {
                            color: $white;

                            span {
                              background: $ms-blue;
                            }
                            .calendar-select-days {
                              background: #edf5fc;
                            }
                          }

                          &:after {
                            background: transparent;
                          }
                        }
                        &.current-date {
                          .md-calendar-date--date {
                            background: $white;
                            border: 1px solid $ms-blue;
                            padding: 5px;
                          }

                          .md-picker-text--active {
                            background: $ms-blue;
                          }
                        }

                        &.current-date:hover {
                          .md-calendar-date--date {
                            background: $ms-blue;
                            color: $white;
                            border: 1px solid $ms-blue;
                          }
                        }

                        &.day-with-events {
                          background: #edf5fc;
                        }

                        &.start-range-date {
                          border-top-left-radius: 36px;
                          border-bottom-left-radius: 36px;
                          padding-left: 0;

                          .calendar-select-days {
                            background: $ms-blue;
                            color: $white;
                          }

                          .md-calendar-date--date {
                            .current-date {
                              background: $ms-blue !important;
                              color: $white;
                            }
                          }
                        }

                        &.end-range-date {
                          border-top-right-radius: 36px;
                          border-bottom-right-radius: 36px;
                          padding-right: 0;

                          .calendar-select-days {
                            background: $ms-blue;
                            color: $white;
                          }
                        }
                      }
                    }
                  }
                }

                .md-dialog-footer {
                  padding: 8px 0;
                  border-top: 1px solid #d9d9d9;
                  display: flex;
                  justify-content: space-between;
                  margin: 0 8px;

                  .md-text--theme-primary {
                    color: $ms-blue;
                  }

                  .md-btn {
                    text-transform: uppercase;
                    font-weight: 800;

                    .clear-all-btn {
                      text-transform: capitalize;
                      font-weight: 500;
                    }

                    &.md-btn--color-primary-active {
                      background-color: $white;
                    }
                  }
                }
                &::before {
                  content: '';
                  position: absolute;
                  width: 0;
                  height: 0;
                  top: 0;
                  left: 20%;
                  box-sizing: border-box;
                  border: 10px solid black;
                  border-color: transparent transparent $white $white;
                  transform-origin: 0 0;
                  transform: rotate(135deg);
                  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.26);
                }
              }
            }

            .date-block {
              width: 100%;

              @include tablet-landscape {
                width: 100%;
              }

              .md-picker-container {
                .md-text-field-container {
                  visibility: hidden;
                  height: 0;
                }

                .md-picker--inline-icon {
                  left: -5px;

                  @include desktop {
                    left: 0;
                  }

                  @include tablet-landscape {
                    left: -24px;
                  }

                  &.md-picker--inline {
                    position: relative;
                  }
                }
              }

              .md-text-field-container {
                .md-text-field-icon--positioned {
                  position: absolute;
                  bottom: 7px;
                  left: 20px;
                  width: 14px;
                  height: auto;

                  @include mobile {
                    bottom: 4px;
                  }

                  @include tablet-portrait {
                    bottom: 4px;
                  }

                  @include tablet-landscape {
                    bottom: 0px;
                    width: 14px;
                    left: 15px;
                  }
                }

                .md-text-field-divider-container--grow {
                  margin-left: 0 !important;
                }

                .md-text-field {
                  @include font-size(13);
                }

                .md-floating-label {
                  background: $white;
                  color: $black;
                  @include font-size(10);
                  left: 21px;
                  margin-top: -3px;
                  padding: 0 2px;
                }
              }

              .booking-range-picker {
                .md-calendar-date--date {
                  background-color: $white;
                  border-radius: none;
                  padding: 0;
                }
              }
            }
            .plus-btn {
              width: 45px;
              height: 45px;
              border-radius: 45px;
              position: absolute;
              right: 0;
              top: 15px;
              background-color: #edf5fc;
              font-weight: 900;
              color: $bright-blue;

              @include tablet-landscape {
                width: 35px;
                height: 35px;
                border-radius: 35px;
              }
            }

            .minus-btn-wrapper {
              position: relative;
              width: 100%;

              &:not(:first-of-type) {
                .date-block {
                  .md-text-field--margin {
                    margin-top: 0;
                  }
                }
              }
            }

            .minus-btn {
              width: 45px;
              height: 45px;
              border-radius: 45px;
              position: absolute;
              right: 0;
              top: 0;
              background-color: #fee5e5;
              font-weight: 900;
              color: #f35050;

              @include tablet-landscape {
                width: 35px;
                height: 35px;
                border-radius: 35px;
              }
            }

            &.common-date-block-wrapper {
              flex-direction: row;
              justify-content: space-between;
              margin-bottom: 20px;

              &.meet-class {
                .weekly-range-picker {
                  width: 48%;
                }
              }

              .weekly-range-picker {
                width: 48%;

                .md-floating-label {
                  left: 25px;
                  top: -3px;
                  @include font-size(10);
                  color: $black;
                  background-color: $white;

                  @include mobile {
                    top: -5px;
                  }

                  @include tablet-portrait {
                    top: -5px;
                  }
                }

                .md-picker--inline {
                  position: relative;
                }
                .md-text-field-container {
                  .md-text-field-icon-container {
                    .img {
                      position: absolute;
                      bottom: 8px;
                      left: 20px;
                      width: 14px;
                      height: auto;

                      @include mobile {
                        bottom: 3px;
                      }

                      @include tablet-portrait {
                        bottom: 3px;
                      }

                      @include tablet-landscape {
                        bottom: 0px;
                        left: 15px;
                      }
                    }
                    .md-text-field-divider-container {
                      margin-left: 0;
                    }
                  }
                }
              }
              .start-end-wrapper {
                width: 100%;
                position: relative;

                .start-end-inner-wrapper {
                  display: flex;
                  width: 100%;

                  .md-layover {
                    width: 48%;

                    &:not(:last-of-type) {
                      margin-right: 10px;
                    }

                    .md-list {
                      top: unset;
                      width: 100%;
                      padding: 0;
                    }

                    .md-floating-label {
                      background-color: $white;
                      top: 9px;
                      @include font-size(10);
                      color: $black;
                      transform: none;
                      z-index: 1;
                      left: 20px;
                    }

                    .md-text-field {
                      padding-left: 20px;
                    }

                    .md-text--theme-primary {
                      color: $black;
                    }
                  }
                }

                .material-icons {
                  display: none;
                }
              }
              .available-slot-wrapper {
                margin-top: 15px;
                .text-gray {
                  color: rgba(0, 0, 0, 0.5);
                  @include font-size(13);
                  text-align: left;
                }
                .available-slot-inner-wrapper {
                  display: flex;
                  flex-wrap: wrap;

                  .md-chip {
                    flex: 0 0 48%;
                    justify-content: center;
                    margin: 5px;
                    margin-bottom: 0;
                    margin-left: 0;
                    background-color: $white;
                    border: 1px solid #d8d8d9;

                    &.selected-chip {
                      background-color: $ms-blue !important;
                    }

                    &:hover {
                      background-color: $ms-blue !important;
                    }

                    &:focus {
                      box-shadow: none;
                      background-color: $bright-blue;

                      .md-chip-text {
                        color: $white;
                      }
                    }

                    .md-chip-text {
                      @include font-size(13);

                      &:focus {
                        outline: none;
                        color: $white;
                      }
                    }

                    &.md-chip--hover {
                      .md-chip-text--hover {
                        color: $white;
                      }
                    }
                  }
                }
              }
              .duration-wrapper {
                width: 48%;

                .md-floating-label {
                  margin-left: 25px;
                  top: -3px;
                  @include font-size(10);
                  color: $black;
                  font-weight: normal;
                  background-color: $white;

                  @include mobile {
                    top: -5px;
                  }

                  @include tablet-portrait {
                    top: -5px;
                  }
                }
              }
            }
            &.meet-class {
              flex-wrap: wrap;

              .weekly-range-picker {
                width: 100%;
              }
            }

            &.hourly-block-class {
              .weekly-range-picker {
                width: 100% !important;
              }
            }
          }

          .md-picker--inline-icon {
            left: -3px;

            @include desktop {
              left: 0;
            }
          }

          .price-block-wrapper {
            margin-top: 20px;
            position: relative;

            @include mobile {
              margin-top: 30px;
            }

            .price-block-title {
              position: absolute;
              top: -32px;
              width: 100%;
              text-transform: uppercase;
              color: #d10000;
              @include font-size(15);
            }
            .inner-price-block-wrapper {
              display: flex;
              justify-content: space-between;
              @include font-size(13);
              padding-bottom: 7px;
              position: relative;
              word-break: break-all;

              &.total-value {
                @include font-size(15);
              }

              .value-cls {
                .blue-text-link {
                  color: $bright-blue;
                  text-decoration: underline;
                }

                .img {
                  margin-left: 5px;
                }
              }

              .label-cls {
                .tooltip-icon {
                  cursor: pointer;
                  .tooltip-wrapper {
                    display: none;
                    top: 28px;
                    position: absolute;
                    padding: 20px;
                    padding-bottom: 10px;
                    background-color: rgba(0, 0, 0, 0.7);
                    border-radius: 10px;
                    width: 100%;
                    z-index: 11;

                    .detail-price-wrapper {
                      display: flex;
                      justify-content: space-between;
                      color: white;
                      padding-bottom: 10px;

                      &.final-row {
                        font-weight: 700;
                        padding-top: 10px;
                        border-top: 0.5px solid $white;
                      }
                    }
                    &::before {
                      content: '';
                      position: absolute;
                      top: -13px;
                      left: 50px;
                      width: 0;
                      height: 0;
                      border-left: 10px solid transparent;
                      border-right: 10px solid transparent;
                      border-bottom: 13px solid rgba(0, 0, 0, 0.7);
                    }

                    &.tax-tip {
                      left: 0;

                      &::before {
                        left: 34px;
                      }
                    }
                  }

                  &:hover {
                    .tooltip-wrapper {
                      display: block;
                    }
                  }
                }
              }
            }

            .section-divider {
              margin: 0;
              margin-bottom: 10px;
              padding-top: 0;
            }
          }
          .continue-btn-wrapper {
            background-color: transparent;
            bottom: 0;
            position: fixed;
            right: 0;
            text-align: center;
            width: 100%;
            z-index: 9;

            @include desktop {
              position: relative;
              width: 100%;
            }

            @include tablet-landscape {
              position: relative;
              width: 100%;
            }

            .btn-grid-wrapper {
              padding-left: 0;
              padding-bottom: 0;

              @include desktop {
                margin: 0;
                width: 100%;
              }

              .continue-btn-inner-wrapper {
                padding: 0 24px;
                border-top: 1px solid $light-gray;
                background-color: $white;
                margin: 0 2px;
                padding-top: 10px;
                padding-bottom: 10px;

                @include desktop {
                  border-bottom-right-radius: 20px;
                  border-bottom-left-radius: 20px;
                  padding: 10px 0 15px 0;
                }

                @include tablet-landscape {
                  padding: 10px 0 15px 0;
                }

                .description {
                  display: block;
                  @include font-size(10.5);
                  padding-bottom: 10px;

                  .blue-text-link {
                    color: $bright-blue;
                    cursor: pointer;
                    display: inline-block;
                    text-decoration: underline;
                    font-weight: $bold;
                  }
                }

                .md-btn {
                  color: $white;
                  cursor: pointer;
                  background-color: $pink;
                  border-radius: 25px;
                  height: 50px;
                  width: 100%;
                  font-size: 13px;

                  .small-text {
                    color: $white;
                    display: block;
                    line-height: 9px;
                    font-size: 11px;
                    font-weight: 600;
                    text-transform: none;

                    .mr-4 {
                      margin-right: 4px;
                    }
                  }

                  &:disabled {
                    pointer-events: none;
                    cursor: not-allowed;
                    opacity: 0.5;
                  }
                }
              }
            }
          }

          .md-text-field {
            border: 1px solid #e2e2e2;
            border-radius: 25px;
            height: 45px;
            @include font-size(13);
            padding-left: 40px;

            &:focus {
              border: 1px solid $bright-blue;
            }

            @include tablet-landscape {
              height: 35px;
              padding-left: 35px;
              @include font-size(13);
            }
          }

          .md-divider {
            display: none;
          }

          .coupon-code-popup {
            #simple-list-dialog {
              max-width: 335px !important;
              width: 100% !important;
              background-color: #009dff;
              color: $white;
              border-radius: 10px;

              .md-dialog-content {
                text-align: left;
                padding: 30px 25px 20px 25px;

                .error-text {
                  color: $yellow;
                  font-weight: $semi-bold;
                  margin-left: 5px;
                  position: relative;
                  top: -25px;
                }

                .main-title {
                  color: $white;
                  font-weight: 800;
                }

                .md-text-field-container {
                  .md-text-field {
                    margin-top: 0;
                    margin-bottom: 30px;
                    height: 50px;
                    padding-left: 20px;
                    background-color: $white;
                    @include font-size(15);
                    color: rgba(0, 0, 0, 0.4);
                  }
                }

                .promo-btn-wrapper {
                  text-align: right;

                  .md-btn {
                    color: $white;
                    @include font-size(13);
                    cursor: pointer;

                    &.cancel-btn {
                      margin-right: 15px;
                    }
                  }
                }
              }
            }
          }

          .promo-code-slider {
            @include mobile {
              position: relative;
              right: -25px;
              top: -27px;
            }
          }
          .ant-spin-spinning {
            position: relative;
            top: 100px;
          }
        }

        .payment-status {
          padding-top: 50px;

          .img {
            width: 70px;
          }

          .message {
            display: block;
            font-weight: $extra-bold;
            margin-top: 15px;
          }

          &.payment-unsuccess {
            .message {
              color: #f35150;
            }
          }
        }
      }

      @include desktop {
        padding-top: 0;
      }
    }

    &.payment-wrapper {
      .stripe-wrap {
        margin: 0 !important;
      }

      .payment-btn-wrapper {
        background: $white;
        border-top: 1px solid #d9d9d9;
        bottom: 0;
        left: 0;
        position: absolute;
        padding: 10px 25px;
        width: 100%;
        z-index: 3;

        .payment-btn {
          @include font-size(15);
          border-radius: 25px;
          font-weight: $extra-bold;
          left: 0;
          width: inherit;
        }
      }

      @include desktop {
        border: 1px solid #d9d9d9;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 20px;
      }
    }

    &.payment-status-outer-wrapper {
      @include flex-row();
      align-items: center;
      justify-content: center;

      @include mobile {
        min-height: calc(100vh - 50px);
      }

      @include tablet-portrait {
        min-height: calc(100vh - 50px);
      }

      @include desktop {
        border: 1px solid #d9d9d9;
        padding: 0 25px;
      }
      .space-grid {
        padding-top: 0;

        .space-grid-inner {
          .payment-status {
            padding-top: 0;

            .payment-btn-wrapper {
              background: #fff;
              border-top: 1px solid #d9d9d9;
              bottom: 0;
              left: 0;
              position: absolute;
              padding: 10px 25px;
              width: 100%;
              z-index: 3;

              .payment-btn {
                border-radius: 25px;
                font-weight: 800;
                height: 44px;
                left: 0;
                width: inherit;
              }
            }
          }
        }
      }
    }
  }
}

.booking-mbl-view {
  .space-right {
    display: block;
    margin: 0;
    width: 100%;

    .booking-wrapper {
      position: relative;

      .space-grid {
        padding-top: 0;

        .space-grid-inner {
          .booking-inner-wrapper {
            max-height: unset;
            min-height: 100vh;
            height: auto;
          }

          .header {
            border-bottom: 0.5px solid $light-gray;
            display: flex;
            justify-content: space-between;
            padding: 24px;

            @include desktop {
              border-bottom: none;
            }

            @include tablet-landscape {
              border-bottom: none;
            }

            .close-btn {
              cursor: pointer;
              flex: 0 0 195%;
            }
          }

          .title-wrap {
            display: flex;
            justify-content: space-between;

            .title {
              display: flex;
              @include font-size(25);
              font-weight: $black-bold;

              &.title-web {
                display: none;
                @include desktop {
                  display: block;
                }
                @include tablet-landscape {
                  display: block;
                }
              }
            }
          }
        }
      }

      &.payment-wrapper {
        @include mobile {
          min-height: calc(100vh - 50px);
        }
        @include tablet-portrait {
          min-height: calc(100vh - 50px);
        }
      }
    }
  }
}

.stripe-confirmation-popup {
  .md-dialog {
    color: $black;
    overflow: hidden;
    // max-width: 600px;
    max-height:fit-content;
    // overflow: auto;

    @include desktop {
      width: 400px;
      border-radius: 24px;
    }
  }

  .md-dialog-content--padded {
    padding: 30px !important;
    max-height: 500px !important;
    padding-top: 25px !important;

    @include desktop {
      max-height: 550px !important;
    }
  }

  .booking-inner-wrapper {
    .card {
      background-color: $white;

      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;

      .img {
        height: 102px;
        width: 100px;
        max-width: 100%;
        @include background-property();
        background-size: cover !important;

        @include desktop {
          height: 103px;
        }
      }

      .content {
        border: 1px solid #dddddd;
        border-left: none;
        border-radius: 0 5px 5px 0;
        flex: 1;
        min-height: 102px;
        min-width: 60%;
        padding: 5px 15px;
        text-align: left;

        .title {
          color: $black-op-50;
          display: block;
          font-size: 13px !important;
          font-weight: 600;
          text-transform: uppercase;
          word-break: break-word;
        }

        .sub-title {
          color: $black;
          display: block;
          font-size: 15px !important;
          font-weight: $black-bold;
          margin-bottom: 3px;
        }

        .flex-wrap {
          @include flex-row;
          justify-content: space-between;
        }

        .popover {
          cursor: pointer;
        }

        .favourites {
          .icon {
            color: #f35050;
            height: 10px;
            width: 10px;
          }

          .number {
            color: $black;
            @include font-size(13);
            font-weight: $black-bold;
            margin: 0 3px;
          }

          .gray-text {
            color: $black-op-50;
            @include font-size(13);
          }
        }

        @include desktop {
          min-width: 60%;
          min-height: 103px;
        }
      }
    }

    .md-expansion-panel {
      border: 0.3px solid #dddddd;
      box-shadow: 0px 0px 3px #0000001a;
      border-radius: 5px;
      list-style: none;
      margin-top: 27px;
      padding: 20px 0;
      padding-bottom: 10px;

      .md-dialog-footer {
        display: none;
      }

      .md-icon {
        color: $bright-blue;
      }

      .md-fake-btn {
        padding: 20px;
      }

      .md-panel-content {
        padding: 0;
      }

      .expansion-wrapper {
        .expansion-title {
          color: $bright-blue;
          @include font-size(11);
          font-weight: $black-bold;
          margin-bottom: 9px;
          text-align: left;
          text-transform: uppercase;
        }

        .list {
          .price-text {
            color: $black;
            display: block;
            @include font-size(18);
            font-weight: $extra-bold;
            text-align: left;
          }

          .date-text {
            color: $black-op-50;
            display: block;
            @include font-size(13);
            text-align: left;
          }
        }
      }
    }

    .collapse-wrapper {
      border: none;
      box-shadow: none;
      border-radius: 5px;
      margin-top: 20px;
      text-align: left;

      .bold-cls {
        font-weight: $black-bold;
      }

      .blue-title {
        color: $bright-blue;
        @include font-size(11);
        font-weight: $black-bold;
        margin-bottom: 0px;
        text-transform: uppercase;
        padding: 20px;
      }

      .border-wrap {
        padding: 20px !important;
        border-top: 1px solid $light-gray;
        border-bottom: 1px solid $light-gray;
      }

      .padding-wrap {
        padding: 20px;
        padding-bottom: 0;

        .price-block-wrapper {
          margin-top: 0 !important;
        }
      }
    }

    .package-outer-wrapper {
      .package-inner-wrapper {
        .package-type {
          @include mobile {
            height: 85px;
          }

          @include tablet-portrait {
            height: 85px;
          }
        }
      }
    }

    .text-block {
      .icon-img {
        @include mobile {
          top: 73px;
        }

        @include tablet-portrait {
          right: 60px;
          top: 73px;
        }
      }
    }

    .space-summary-wrapper {
      .title {
        @include font-size(25);
        font-weight: $black-bold;
        text-align: left;
      }

      .detail-block {
        text-align: left;
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        .img {
          width: 14px;
          margin-right: 10px;

          &.seating-img {
            width: 12px;
            margin-right: 12px;
          }

          &.seating-icon {
            width: 18px;
          }
        }

        &.detail-block:last-of-type {
          margin-bottom: 20px;
        }

        &.available-slot-inner-wrapper {
          display: flex;
          flex-wrap: wrap;

          .slot {
            justify-content: center;
            margin: 0 5px 5px 0;
            background-color: $light-gray3;
            border-radius: 23px;
            color: $black;
            font-size: 13px;
            padding: 10px;
          }
        }
      }

      .payment-method-wrapper {
        display: flex;
        justify-content: space-between;

        .payment-method {
          .img {
            margin-right: 10px;
          }
        }
        .blue-text-link {
          color: $bright-blue;
          cursor: pointer;
          font-weight: $bold;
          text-decoration: underline;
        }
      }
    }

    .title-mobile {
      @include desktop {
        display: none;
      }

      @include tablet-landscape {
        display: none;
      }
    }

    .text-block {
      background: $white;
      color: $ms-blue;
      display: block;
      @include font-size(13);
      margin-top: 15px;
      text-align: left;

      @include mobile {
        width: 100%;
      }

      .bold {
        color: $ms-blue;
        font-weight: $bold;
        text-decoration: underline;

        a {
          color: $ms-blue;
        }
      }

      .second-block {
        display: block;
        margin-top: 10px;
      }
    }
  }

  .space-summary-wrapper {
    @include mobile {
      padding-top: 10px !important;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: top;

    .title {
      font-size: 25px;
      font-weight: $black-bold;

      @include mobile {
        margin-bottom: 0;
      }
    }

    .close-btn {
      cursor: pointer;
      @include mobile {
        flex: unset !important;
      }

      @include tablet-portrait {
        flex: unset !important;
      }

      .img {
        width: 10px;
        height: 10px;
      }
    }

    @include mobile {
      text-align: right;
    }
  }
}

.confirmation-container {
  max-width: 100%;
  padding: 20px;
}

&.confirmation-popup {
  .continue-btn-inner-wrapper {
    display: none;
  }
}

.stripe-error-popup {
  .md-dialog {
    width: 290px;
    border-radius: 24px;
    color: black;
  }

  .main-title {
    font-size: 18px;
    text-align: center;
    font-weight: $black-bold;
  }

  .content {
    font-size: 15px;
    display: block;
    margin-bottom: 20px;
  }

  .btn {
    border-radius: 23px;
    box-shadow: none;
    cursor: pointer;
    outline: none;
    height: 40px;
    font-weight: $extra-bold;
    font-size: 13px;
    min-width: 110px;
    text-transform: uppercase;

    &.btn-close {
      border: 0.5px solid #000000;
      background-color: transparent;
      margin-right: 10px;
    }

    &.btn-again {
      background-color: #106dd1;
      border: none;
      color: $white;
    }
  }
}

.notice-period-popup {
  .btn-wrap {
    border-top: 1px solid #d9d9d9;
    padding: 10px;

    .btn {
      width: 245px;
    }
  }

  .content {
    margin: 0 42px 30px 42px;
  }

  .md-dialog {
    width: 305px;

    .md-dialog-content {
      overflow: hidden;
      padding: 0 !important;
      padding-top: 24px !important;
    }
  }

  .mobile-number {
    font-weight: $bold;
    text-decoration: underline;
  }

  .sub-text {
    font-weight: $bold;
  }
}
