.host-picker {
    &.ant-calendar-picker-container {
      top: 136px !important;
      z-index: 1 !important;
  
        .ant-calendar {
          font-size: 12px;
  
          @include mobile {
            width: 100%;
          }

          @include tablet-portrait {
            box-shadow: none;
            border-radius: 5px;
            
            thead {
              border: 0.5px solid $gray;
              border-top: none;
            }
          }
  
          @include desktop {
            box-shadow: none;
            border-radius: 5px;
            
            thead {
              border: 0.5px solid $gray;
              border-top: none;
            }
          }
        }
        
        .ant-calendar-year-panel-tbody {
          background-color: #f9f9f9;
        }
  
        .ant-calendar-body {
          padding: 0;
          
          .ant-calendar-tbody {
            background-color: #f9f9f9;
  
            .ant-calendar-date {
              border-radius: 17px;
              font-weight: normal;
              width: 27px;
              height: 27px;

                .dot {
                  border-radius: 50%;
                  display: block;
                  height: 4px;
                  width: 4px;
                  margin: 0 auto;
                  margin-top: -2px;
                  background-color: #1c8487;                
                }
            }

            .ant-calendar-disabled-cell.ant-calendar-selected-day .ant-calendar-date::before {
              border-radius: 17px;
              top: 0px;
              left: 0px;
              width: 27px;
              height: 27px;
            }
            
            .ant-calendar-date:active {
              color: #1c8487;
              border-color: #1c8487;
              border-radius: 17px;
              background: transparent;
            }
  
            .ant-calendar-today, .ant-calendar-selected-day {
              .ant-calendar-date {
                color: #1c8487;
                border-color: #1c8487;
                border-radius: 17px;
                background: transparent;
                font-weight: normal;
              }
            }
            .ant-calendar-selected-day {
              .ant-calendar-date {
                background: #1c8487;
                color: $white;

                .dot {
                  background: $white;
                }
              }
            }

            .ant-calendar-date:hover, .ant-calendar-date:focus {
              background: rgba( #1c8487, 0.5);
              border-radius: 17px;
            }
          }
        }
  
        .ant-calendar-year-panel-selected-cell {
          .ant-calendar-year-panel-year {
            color: #fff;
            background: #1c8487;
  
            &:active,  &:hover, &:focus{
              color: #fff;
              background: #1c8487;
            }
          }      
        }
  
        .ant-calendar-year-panel-year {    
          &:active,  &:hover, &:focus{
            color: #fff;
            background: #1c8487;
          }
        }
  
        .ant-calendar-input-wrap {       
            display: none;        
        }

        .ant-calendar-disabled-cell {
          .ant-calendar-date {
            background: transparent !important;
            border: none !important;
            color: rgba(0, 0, 0, 0.25) !important;
          }
        }
  
        .ant-calendar-column-header {
          .ant-calendar-column-header-inner{
            color: $black;
          }
        }
        
        .ant-calendar-header {  
  
          .ant-calendar-my-select, .ant-calendar-month-select, .ant-calendar-year-select {
            color: #1c8487 !important;
          }
  
          .ant-calendar-prev-year-btn, .ant-calendar-next-year-btn, .ant-calendar-next-month-btn, .ant-calendar-prev-month-btn, .ant-calendar-prev-century-btn, .ant-calendar-next-century-btn {
            border-color: #1c8487 !important;
  
            &::before {
              border-color: #1c8487 !important;
            }
  
            &::after {
              border-color: #1c8487 !important;
            }
          }
  
          @include desktop {
              background-color: #1c8487;
              border-radius: 5px 5px 0 0;
      
              .ant-calendar-my-select, .ant-calendar-month-select, .ant-calendar-year-select {
                color: $white !important;
              }
      
              .ant-calendar-prev-year-btn, .ant-calendar-next-year-btn, .ant-calendar-next-month-btn, .ant-calendar-prev-month-btn, .ant-calendar-prev-century-btn, .ant-calendar-next-century-btn {
                border-color: $white !important;
      
                &::before {
                  border-color: $white !important;
                }
      
                &::after {
                  border-color: $white !important;
                }
              }
          }

          @include tablet-portrait {
            background-color: #1c8487;
            border-radius: 5px 5px 0 0;
    
            .ant-calendar-my-select, .ant-calendar-month-select, .ant-calendar-year-select {
              color: $white !important;
            }
    
            .ant-calendar-prev-year-btn, .ant-calendar-next-year-btn, .ant-calendar-next-month-btn, .ant-calendar-prev-month-btn, .ant-calendar-prev-century-btn, .ant-calendar-next-century-btn {
              border-color: $white !important;
    
              &::before {
                border-color: $white !important;
              }
    
              &::after {
                border-color: $white !important;
              }
            }
          }
        }
  
        .ant-calendar-year-panel-header {
          
            .ant-calendar-year-panel-decade-select-content {
                color: #1c8487;
            }
  
            .ant-calendar-year-panel-prev-decade-btn, .ant-calendar-year-panel-next-decade-btn {
              border-color: #1c8487 !important;
      
              &::before {
                border-color: #1c8487 !important;
              }
      
              &::after {
                border-color: #1c8487 !important;
              }
            }
  
            @include desktop {
                background-color: #1c8487;
            
                .ant-calendar-year-panel-decade-select-content {
                    color: $white;
                }
      
                .ant-calendar-year-panel-prev-decade-btn, .ant-calendar-year-panel-next-decade-btn {
                  border-color: $white !important;
          
                  &::before {
                    border-color: $white !important;
                  }
          
                  &::after {
                    border-color: $white !important;
                  }
                }
            }

            @include tablet-portrait {
              background-color: #1c8487;
          
              .ant-calendar-year-panel-decade-select-content {
                  color: $white;
              }
    
              .ant-calendar-year-panel-prev-decade-btn, .ant-calendar-year-panel-next-decade-btn {
                border-color: $white !important;
        
                &::before {
                  border-color: $white !important;
                }
        
                &::after {
                  border-color: $white !important;
                }
              }
          }
        }
  
        .ant-calendar-month-panel-header {
  
          .ant-calendar-month-panel-year-select-content {
              color: #1c8487;
          }
  
          .ant-calendar-month-panel-prev-year-btn, .ant-calendar-month-panel-next-year-btn {
            border-color: #1c8487 !important;
  
            &::before {
              border-color: #1c8487 !important;
            }
  
            &::after {
              border-color: #1c8487 !important;
            }
          }
  
          @include desktop {
              background-color: #1c8487;
  
            .ant-calendar-month-panel-year-select-content {
                color: $white;
            }
  
            .ant-calendar-month-panel-prev-year-btn, .ant-calendar-month-panel-next-year-btn {
              border-color: $white !important;
  
              &::before {
                border-color: $white !important;
              }
  
              &::after {
                border-color: $white !important;
              }
            }
          }

          @include tablet-portrait {
            background-color: #1c8487;

          .ant-calendar-month-panel-year-select-content {
              color: $white;
          }

          .ant-calendar-month-panel-prev-year-btn, .ant-calendar-month-panel-next-year-btn {
            border-color: $white !important;

            &::before {
              border-color: $white !important;
            }

            &::after {
              border-color: $white !important;
            }
          }
        }
        }
  
        .ant-calendar-decade-panel-header {
  
            .ant-calendar-decade-panel-century {
                color: #1c8487;
            }
  
            .ant-calendar-decade-panel-prev-century-btn, .ant-calendar-decade-panel-next-century-btn {
              border-color: #1c8487 !important;
  
              &::before {
                border-color: #1c8487 !important;
              }
  
              &::after {
                border-color: #1c8487 !important;
              }
            }
  
            @include desktop {
              background-color: #1c8487;
  
                .ant-calendar-decade-panel-century {
                    color: $white;
                }
  
              .ant-calendar-decade-panel-prev-century-btn, .ant-calendar-decade-panel-next-century-btn {
                border-color: $white !important;
  
                &::before {
                  border-color: $white !important;
                }
  
                &::after {
                  border-color: $white !important;
                }
              }
            }

            @include tablet-portrait{
              background-color: #1c8487;
  
                .ant-calendar-decade-panel-century {
                    color: $white;
                }
  
              .ant-calendar-decade-panel-prev-century-btn, .ant-calendar-decade-panel-next-century-btn {
                border-color: $white !important;
  
                &::before {
                  border-color: $white !important;
                }
  
                &::after {
                  border-color: $white !important;
                }
              }
            }
        }
  
        .ant-calendar-month-panel-cell {
          .ant-calendar-month-panel-month {
            &:active,  &:hover, &:focus {
              color: #fff;
              background: rgba( #1c8487, 0.5);
            }
          }
        }

        .ant-calendar-cell {
          cursor: pointer;
        }
  
        .ant-calendar-decade-panel-cell {
          .ant-calendar-decade-panel-decade {
            &:active,  &:hover, &:focus {
              color: #fff;
              background: rgba( #1c8487, 0.5);
            }
          }
        }
  
        .ant-calendar-month-panel-selected-cell {
          .ant-calendar-month-panel-month  {
            background-color: #1c8487;
  
            &:active,  &:hover, &:focus {
              color: #fff;
              background: #1c8487;
            }
          }
        }
  
        .ant-calendar-decade-panel-selected-cell {
          .ant-calendar-decade-panel-decade  {
            background-color: #1c8487;
  
            &:active,  &:hover, &:focus {
              color: #fff;
              background: #1c8487;
            }
          }
        }
  
        @include desktop {
          top: 198px !important;
          left: 20.5% !important;
        }
  
        @include mobile {
          width: 100%;
          z-index: 10 !important;
        }

        @include tablet-portrait {
          top: 179px !important;
          left: 2.5% !important;
        }
    }
  }