.affiliates-section {
  padding: 40px 0;
  border-radius: 0px;

  &.statistic-wrap {
    .affiliates-wrap {
      background-color: #eff5f6;
      padding: 60px 0px;

      @include mobile{
        padding: 5px 0px 2px 0px;
      }
     

      @include tablet-landscape{
        padding: 10px 0px 10px 0px;
      }

      @include desktop{
        padding: 10px 0px 10px 0px;
      }
      
      
    }
    .media-card {
      @include flex-column;
      align-items: center;
    }

    .stat-title {
      font-size: 16px;
      font-weight: $black-bold;
      margin-bottom: 5px;
      line-height: 25px;

      @include desktop {
        font-size: 20px;
        line-height: 16px;
      }
    }

    .img {
      
      margin-bottom: 25px;

      @include mobile {
        margin-bottom: 10px;
      }
    }

    .stat-sub-title {
      font-size: 14px;
      line-height: 25px;
      margin-bottom: 0;
      text-transform: uppercase;

      @include mobile {
        font-size: 10px;
        margin-bottom: 60px;
        line-height: 16px;
      }

      @include desktop{
        margin-top:5px;
      }
    }

    .slick-track {
      @include mobile {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-self: center;
      }

      @include tablet-portrait {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-self: center;  
      }
    }
    .slick-slide {
      @include mobile {
        width: 50% !important;
      }
    }

    .scroll-btn {
      background: transparent;
      border: none;
      cursor: pointer;
      left: 49%;
      margin-top: 100px;
      outline: none;
      scroll-behavior: smooth;

      .arrow-icon {
          width: 32px;
      }
    }
  }

  &.partner-wrap {
    @include background-property();
    // background-origin: content-box;
    background-size: cover;
    border-radius: 0;
    padding: 36px 0;

    .slick-track {
     // @include flex-row;
     display: flex;
      justify-content: space-between !important;
    }

    .slick-slide {
      width: 16% !important;
      @include flex-row;
      align-self: center;
      justify-content: center;
    }

    @include tablet-portrait {
      top: 0;
      padding: 0 !important;

      .slick-slide {
        width: 33% !important;
      }

      .slick-track {
        flex-wrap: nowrap;     
      }

      .affiliates-wrap {
         padding: 30px 0;
      }
    }

    @include mobile {
      top: 0;
      padding: 0 !important;

      .slick-slide {
        width: 33% !important;
      }

      .slick-track {
        flex-wrap: nowrap;     
      }

      .affiliates-wrap {
         padding: 30px 0;
      }

    }
  }

  .affiliates-wrap {
    border-radius: 0;

    .title {
      @include font-size(25);
      font-weight: $black-bold;
      text-align: left;

      @include desktop {
        @include font-size(28);
        margin-bottom: 25px;
      }
    }

    .columns {
      @include flex-row;
      flex-direction: row;
      flex-wrap: wrap;

      .thumbs-wrap {
        @include flex-row;
        border: 3px solid $gray;
        background: $silver-gray 0% 0% no-repeat padding-box;
        height: 80px;
        justify-content: center;
        width: 19%;

        @include mobile {
          width: 33.33%;
        }

        @include tablet-portrait {
          width: 16.66%;
        }

        @include desktop {
          width: 16.66%;
          height: 100px;
        }

        .image {
          @include background-property;
          background-position: inherit;
          height: 65px;
          margin: 0 20px 20px 0;
          width: 100%;

          @include mobile {
            background-position: center;
            background-size: auto;
            margin: 0 auto;
            width: 95%;
            height: 60px;
          }

          @include tablet-portrait {
            background-position: center;
            background-size: auto;
            height: 65px;
            margin: 0 15px 15px 0;
            width: 100%;
          }

          @include desktop {
            background-position: center;
            height: 65px;
            margin: 10px;
            width: 100px;
          }
        }
      }

      @include mobile {
        margin-right: 0;
      }

      @include tablet-portrait {
        justify-content: space-between;
      }

      @include desktop {
        justify-content: space-between;
      }
    }

    .container {
      max-width: 1760px;
    }
  }

  @include desktop {
    padding: 0px;
  }

  @include tablet-portrait {
    padding: 0;
    position: relative;
  }

  @include mobile {
    padding: 0;
    position: relative;
  }
}
