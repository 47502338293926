.space-listing-page {
  &.host-onboarding-page {
    .full-height-wrapper {
      .section-wrapper {
        .left-side {
          .left-side-inner-wrapper {
            @include desktop {
              max-width: 533px;
              width: 100%;
            }

            .listing-page-title {
              margin-bottom: 15px;
            }

            .company-details-wrapper {
              margin-bottom: 35px;

              .country-select {
                margin-bottom: 0;
              }
            }

            &.welcome-screen {
              .listing-page-title {
                @include desktop {
                  max-width: 430px;
                  width: 100%;
                }
              }

              .img-wrapper {
                margin-top: 40px;
              }
            }

            &.host-agreement {
              overflow-y: hidden;
              padding-right: 50px;

              .title-btn-wrapper {
                @include flex-row();
                cursor: pointer;
                justify-content: space-between;
                margin-bottom: 20px;

                .listing-page-title {
                  margin-bottom: 0;
                }

                .download-btn {
                  @include flex-row();
                  color: $dark-green;
                  font-weight: $bold;

                  .icon {
                    margin-right: 5px;
                  }
                }
              }

              .check-box {
                background: $white;
                color: $dark-green;
                font-weight: $semi-bold;
                width: 100%;

                .ant-checkbox {
                  .ant-checkbox-inner {
                    border: 1px solid $dark-green;
                  }

                  &.ant-checkbox-checked {
                    .ant-checkbox-inner {
                      background-color: $white;
                      &::after {
                        border-color: $dark-green;
                      }
                    }
                  }
                }
              }

              .host-agreement-wrapper {
                background-color: #fafafa;
                border-radius: 4px;
                color: $black;
                height: calc(100vh - 285px);
                overflow-y: auto;
                padding: 25px;

                .main-title {
                  font-weight: $black-bold;
                  @include font-size(14);
                  margin-bottom: 15px;
                  margin-top: 20px;
                }

                ol {
                  list-style-type: none;
                  counter-reset: item;
                  margin: 0;
                  padding: 0;
                  margin-top: 13px;
                }

                li {
                  display: table;
                  counter-increment: item;
                  margin-bottom: 10px;
                  text-align: justify;
                }

                li:before {
                  content: counters(item, '.') '. ';
                  display: table-cell;
                  font-weight: $bold;
                  padding-right: 9px;
                }

                li li {
                  margin: 0;
                  margin-bottom: 10px;
                }

                li li:before {
                  content: counters(item, '.') ' ';
                }

                .sub-item {
                  counter-increment: item;
                  display: list-item;
                  font-size: 13px;
                  list-style-type: lower-alpha;

                  &:before {
                    content: none;
                    font-weight: $bold;
                    margin-right: 10px;
                    padding-left: 30px;
                  }

                  &::marker {
                    font-weight: $bold;
                  }
                }

                .sub-sub-item {
                  counter-increment: item;
                  display: list-item;
                  font-size: 13px;
                  list-style-type: lower-roman;

                  &:before {
                    content: none;
                    margin-right: 10px;
                  }
                }
              }
            }

            &.commission-details {
              .common-max-width-sm {
                max-width: unset;
              }
              .icon-sentense-wrapper {
                background-size: contain;
                background-repeat: no-repeat;
                background-position: right;
                border-radius: 20px;
                padding: 0;

                .icon-sentense-inner-wrapper {
                  padding: 20px 25px;

                  .green-text {
                    font-weight: $semi-bold;
                    line-height: 23px;

                    @include desktop {
                      max-width: 343px;
                      width: 100%;
                    }
                  }
                }
              }
            }

            &.payout-details {
              .green-text {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}
