.special-event-section {
    text-align: left;
    z-index: 1;

    .special-event-wrap {
        @include background-property;
        background-size: cover;
        background-position: center;
        box-shadow: 0px 2px 12px #00000029;
        padding: 80px 0px;
            
            .title {
                color: $white;
                @include font-size(50);
                font-weight: $black-bold;
                margin-bottom: 20px;
                line-height: 1;
                width: 55%;
    
                @include desktop {
                    @include font-size(50);
                    margin-bottom: 15px;
                    line-height: 1;
                    width: 100%;
                }
            }
    
            .sub-title {
                color: $white !important;
                @include font-size(15);
                width: 75%;
    
                @include desktop {
                    @include font-size(18);
                    width: 50%;
                }
    
            }
    
            .btn-learn {
                font-weight: $black-bold;
                @include font-size(13);
                width: 100%;
                height: 45px;
                margin: 30px 0 !important;
                margin-bottom: 0;
                text-transform: uppercase;

                @include tablet-landscape {                
                    margin: 30px 0 !important;
                }

                @include tablet-portrait {
                    width: 306px;
                    margin: 60px auto;
                    margin-bottom: 30px;
                }
    

                 @include desktop {
                    margin: 60px 0;
                    width: 306px;
                }
            }

            @include tablet-landscape {                
                height: auto !important;
                padding: 30px !important;
            }

            @include desktop {
                max-height: 380px;
                height: 100%;
                padding: 60px;
                border-radius: 32px;
            }
        }     

    @include desktop {
        padding: 50px 20px;
    }

    .custom-container {
        padding: 0 !important;

        @include desktop {
            padding: inherit !important;
        }
    }
}






