.help-dialog {
  width: 100% !important;
  height: 100vh;
  margin: 0 !important;
  max-width: 100% !important;
  position: absolute;
  top: 0 !important;
  z-index: 1031;

  .section-help-support {
    background: $white;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    position: relative;
    text-align: left;

    .bg-img-section {
      @include background-property;
      background-size: cover;
      border-radius: 0 0 32px 32px;
      height: 370px;
      padding: 30px;
      text-align: -webkit-center;
      text-align: -moz-center;

      @include desktop {
        border-radius: 0;
        height: 390px;
        padding: 35px 50px;
      }

      @include large-desktop {
        height: 500px;
      }

      .bg-img-overlay {
        @include flex-row();
        align-items: center;
        height: 290px;
        justify-content: center;
        position: relative;
        top: 40px;
        width: 335px;

        @include desktop {
          border-radius: 25px;
          height: 270px;
          top: 60px;
          width: 910px;
        }
      }

      .close-btn {
        cursor: pointer;
        float: right;

        .img {
          width: 18px;
        }
      }

      .header-block-inner-wrapper {
        color: $white;
        text-align: center;

        .main-title {
          @include font-size(30);
          color: $white;
          font-weight: $extra-bold;

          @include desktop {
            @include font-size(50);
          }
        }
        .main-description {
          @include font-size(20);
          font-weight: $extra-bold;
          max-width: 305px;
          margin: 0 auto;
          margin-top: 30px;
          width: 100%;

          @include desktop {
            @include font-size(35);
            max-width: 632px;
            width: 100%;
          }
        }
      }
    }

    .custom-container {
      @include desktop {
        max-width: 766px;
        width: 100%;
      }

      .capital-letters {
        text-transform: uppercase;
      }
      .title {
        @include font-size(25);
        font-weight: $extra-bold;
      }

      .img-wrapper {
        margin-bottom: 60px;
        padding-top: 25px;
        text-align: center;

        .img {
          width: 100%;

          @include desktop {
            width: auto;
          }
        }
      }

      &.form-outer-wrapper {
        @include desktop {
          margin-top: -75px;
        }

        position: relative;

        .form-wrapper {
          padding: 60px 0;
          padding-top: 40px;

          @include desktop {
            background-color: $white;
            border-radius: 10px;
            margin-bottom: 60px;
            padding: 60px 95px;
            -webkit-box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.16);
            -moz-box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.16);
            box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.16);
            text-align: right;
          }

          .title {
            @include font-size(20);
            font-weight: $extra-bold;
            margin-bottom: 0;
            text-align: left;
          }
          .small-text {
            display: block;
            text-align: left;
            width: 100%;
          }

          .input-wrapper {
            margin-top: 30px;

            @include desktop {
              @include flex-row();
              flex-wrap: nowrap;
              justify-content: space-between;
            }

            &.half-width {
              margin-top: 0;

              @include desktop {
                width: 50%;
              }
            }
          }

          .md-text-field-container {
            margin-left: 0;
            margin-right: 0;
            width: 100%;

            @include desktop {
              margin: 8px;
            }

            .md-text-field {
              border: 1px solid #e2e2e2;
              border-radius: 25px;
              height: 45px;
              margin-top: 0;
              padding-left: 25px;
            }
            .md-divider {
              display: none;
            }
          }
          .msg-cls {
            border: 1px solid #e2e2e2;
            border-radius: 25px;
            height: 117px;
            margin: 8px;
            outline: unset;
            padding-top: 13px;
            padding-left: 25px;
            width: 100%;
          }
          .btn-yellow {
            @include font-size(13);
            font-weight: $extra-bold;
            height: 45px;
            text-transform: uppercase;
            width: 100%;

            @include desktop {
              width: 250px;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }

  .help-support-footer-section {
    color: $white;
    display: block;
    height: 300px;
    position: relative;
    top: -160px;
    text-align: left;
    z-index: 3;
    margin-left: 505px;

    @include desktop {
      margin-top: 0;
      top: -180px;
    }

    .blue-background {
      padding: 30px 0;

      .custom-container {
        @include desktop {
          max-width: 1000px;
          width: 100%;
        }

        &.address-wrapper {
          padding-top: 48px;
          text-align: -webkit-center;
          text-align: -moz-center;

          @include desktop {
            @include flex-row();
            align-items: baseline;
            padding: 0;
            place-content: space-between;
          }

          @include tablet-portrait {
            @include flex-row();
            align-items: baseline;
            padding: 60px 35px 0 35px;
            place-content: space-between;
          }

          .country-wrap {
            padding: 30px;
            text-align: left;

            .img-inner-wrap {
              display: flex;
              justify-content: space-between;
              margin-bottom: 25px;

              .person-img {
                border-radius: 100px;
                height: 92px;
                width: 92px;
              }

              .img {
                height: 40px;
                width: 40px;
              }
            }

            .person-name {
              color: $ms-blue;
              display: inline-block;
              font-weight: $black-bold;
              @include font-size(25);
              margin-bottom: 3px;
            }
            .detail-icon-block {
              @include flex-row();
              align-items: baseline;
              flex-wrap: nowrap;

              .detail {
                color: $black;
                @include font-size(20);
                margin-bottom: 2px;
                text-decoration: none;

                &.a {
                  cursor: pointer;
                }
              }
            }
          }

          .img-wrapper {
            background-size: cover;
            border: 1px solid $ms-blue;
            border-radius: 23px;
            margin-bottom: 30px;
            width: 100%;

            @include desktop {
              height: 270px;
              width: 491px;
            }
          }
        }

      }
    }
    .black-background {
      background-color: $black;
      padding: 15px 0;
      text-align-last: center;

      .custom-container {
        @include desktop {
          max-width: 766px;
          width: 100%;
        }

        &.social-media-wrapper {
          text-align: center;

          @include desktop {
            text-align: left;
          }
          a {
            margin-right: 20px;
          }
        }
      }
    }
  }

  .ant-modal-header {
    display: none;
  }

  .ant-modal-close-x {
      display: none;
  }

  .ant-modal-content {
    height: 100vh;
    border-radius: 0;
    overflow: hidden;

    .ant-modal-body {
        padding: 0;

        @include mobile {
          height: 945px;
        }
    }

    @include mobile {
      height: auto;
      overflow: auto;
    }

    @include tablet-portrait {
      overflow: auto;
    }

    .ant-modal-footer {
      display: none;
    }
  }
}