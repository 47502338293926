.btn-map-filter {
  border: 1px solid $light-gray;
  border-radius: 100px;
  bottom: 20px;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  height: 40px;
  outline: none;
  position: fixed;
  right: 40%;
  width: 80px;

  .map-icon {
    margin-right: 5px;
    width: 11px;
  }

  &.btn-map-close {
    background: $white 0% 0% no-repeat padding-box;
    color: $black;

    @include mobile {
      z-index: 19;
    }
  }

  &.btn-map-visible {
    background: $black 0% 0% no-repeat padding-box;
    color: $white;

    @include mobile {
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 4;
    }

    @include tablet-portrait {
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 4;
    }
  }

  @include desktop {
    bottom: 8px;
    box-shadow: none;
    height: 38px;
    position: absolute;
    right: 26px;
    width: 74px;
  }

  @include tablet-portrait {
    right: 44%;
  }
}

.btn-clear-all-filter{
  min-width: 50px;
  width: max-content;
  padding: 7px 15px;
  border-radius: 100px;
  border: 1px solid #d8d8d9;
  outline: none;
  cursor: pointer;
  color: $black;
  background-color: white;
  
  &:hover {
    background-color: $ms-blue;
    border-color: $ms-blue;
    color: $white;
  }
}

.nearest-space-wrap {
  z-index: 1;

  @include desktop {
    position: fixed;
    top: 122px;
    right: 0px;
    height: 637px;
    width: 371px;
  }

  @include tablet-landscape {
    position: fixed;
    top: 122px;
    right: 0px;
    height: 637px;
    width: 371px;
  }

  .content {
    .map-container {
      z-index: 3;

      .gm-style {
        @include desktop {
          height: 81% !important;
        }

        .gm-control-active {
          &:focus {
            outline: none;
          }
        }

        .gm-bundled-control {
          .gmnoprint {
            @include desktop {
              bottom: -20px !important;
              top: unset !important;
            }
          }
        }

        .map-price-marker {
          background: $white;
          border: 0.5px solid $light-gray;
          border-radius: 36px;
          box-shadow: 0px 1px 2px #00000029;
          color: $black;
          font-weight: $bold;
          height: 34px;
          @include font-size(13);
          line-height: 34px;
          width: 70px;

          @include desktop {
            height: 30px;
            line-height: 30px;
            width: 67px;
          }
        }

        .map-price-marker:after {
          left: 31px;
          top: 19px;
          border-width: 5px;
          border-color: $white transparent transparent;
        }

        .map-price-marker-hover {
          background-color: $black;
          color: $white;
          z-index: 200 !important;
        }
      }
    }

    .space-card-map {
      .md-overlay {
        background: none;
      }

      .md-paper {
        border-radius: 10px;
        height: fit-content;
        max-height: 100%;
        width: 290px;

        @include desktop {
          left: 84%;
          top: 60%;
        }

        .md-dialog-content {
          border-radius: 10px;
          max-height: 600px !important;
          padding: 0px;

          .space-result-thumbnail {
            background-size: cover;
            cursor: pointer;
            height: 180px;

            .number-of-people {
              background-color: $black-op-50;
              border-radius: 23px;
              color: $white;
              @include flex-row;
              position: absolute;
              padding: 4px 8px;
              right: 10px;
              top: 144px;

              .img {
                height: 11px;
                left: 5px;
                top: 7px;
                width: 10px;
              }

              .people {
                @include font-size(13);
                margin-left: 10px;
              }
            }

            .distance-wrap {
              background-color: $black-op-50;
              border-radius: 23px;
              color: $white;
              @include flex-row;
              justify-content: space-between;
              left: 10px;
              position: absolute;
              padding: 1px 6px;
              top: 13px;

              .img {
                height: 11px;
                left: 5px;
                top: 4px;
                width: 10px;
              }

              .distance {
                display: inline-block;
                font-weight: $light;
                @include font-size(13);
                margin-left: 12px;

                @include mobile {
                  @include font-size(10);
                }
              }
            }
          }

          .space-thumbnail-content {
            line-height: 1.4;
            padding: 10px;
            text-align: left;
            width: 100%;

            .space-content-title {
              display: block;
              font-weight: $semi-bold;
              @include font-size(13);
              text-transform: uppercase;
              width: 100%;
            }

            .content-sub-title {
              color: $black;
              display: block;
              font-weight: $bold;
              @include font-size(20);
              line-height: 1.1;
              padding: 0px;
              width: 100%;
            }

            .price-wrap {
              .price {
                color: $black;
                @include font-size(15);
                font-weight: $extra-bold;
              }

              .price-description {
                color: $black;
                @include font-size(13);
              }
            }
          }

          .multiple-spaces-wrap {
            align-items: center;
            background: $light-gray3 0% 0% no-repeat padding-box;
            display: flex;
            height: 45px;
            justify-content: space-between;
            padding: 0px 20px;

            .arrow-icon {
              cursor: pointer;
              height: 12px;
              width: 10px;

              &.left {
                transform: rotate(180deg);
              }
            }

            .space-count {
              color: $black;
              font-weight: $bold;
              @include font-size(15);
              letter-spacing: 0px;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.search-result-section {
  &.map-active {
    @include desktop {
      .space-result-container {
        margin-left: 0px;

        .space-wrap {
          display: flex;

          .space-result-wrap {
            display: inline-block;
            width: 75%;
          }

          .nearest-space-wrap {
            display: inline-block;
            width: 32%;
          }
        }

        .no-more-wrap {
          margin-left: 11.5%;
        }
      }
    }

    @include mobile {
      .ant-pagination {
        display: none;
      }
    }

    @include tablet-landscape {
      .space-wrap {
        display: flex;

        .space-result-wrap {
          display: inline-block;
          width: 75%;
        }

        .nearest-space-wrap {
          display: inline-block;
          width: 29%;
        }
      }
    }

    .search-result-wrapper {
      @include mobile {
        padding: 0px;
      }

      @include tablet-portrait {
        padding: 0px;
      }

      .custom-container {
        @include mobile {
          padding: 0px;
        }

        @include tablet-portrait {
          padding: 0px;
        }
      }
    }
  }

  .featured-space-section {
    .title {
      color: $pink;
      font-weight: $extra-bold;
      @include font-size(25);
      text-align: left;

      @include desktop {
        @include font-size(35);
      }
    }

    .sub-title {
      @include font-size(14);
      text-align: left;

      @include desktop {
        @include font-size(15);
      }
    }
  }

  .space-result-wrap {
    .space-count {
      align-items: flex-end;
      border-bottom:  1px solid $silver-gray;
      display: flex;
      padding: 23px 0 10px 0px;
      text-align: left;

      .img {
        height: 35px;
        padding-bottom: 4px;
        margin-right: 10px;
      }
    }
  }
}

.md-no-scroll {
  &.md-no-scroll {
    .btn-map-filter  {
      right: 43px;
    }

    .space-page {
      overflow-y: scroll;
    }
  }
}

.ant-pagination {
  margin-top: 20px !important;

  @include mobile {
    margin-bottom: 20px !important;
  }

  .ant-pagination-item {
    border: none;
    font-weight: $bold;
    @include font-size(15);
    margin-right: 16px;

    @include desktop {
      margin-right: 20px;
    }

    a {
      color: $black;

      &:hover {
        color: $black;
      }
    }
  }

  .ant-pagination-item-active {
    background: $bright-blue 0% 0% no-repeat padding-box;
    border-radius: 35px;
    height: 36px;
    line-height: 36px;
    width: 36px;

    a {
      color: $white;

      &:hover {
        color: $white;
      }
    }

    .ant-pagination-item-active:focus {
      border-color: $white;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    height: 36px;
    line-height: 36px;
    margin-right: 20px;

    .ant-pagination-item-link {
      background: $white;
      border: none;
      border-radius: 35px;
      box-shadow: 0px 1px 5px #00000029;
      height: 36px;
      width: 36px;

      svg {
        color: $black;
      }
    }
  }
}
