.similar-space-wrap {
  .btn-arrow {
    background-color: $ms-blue;
    border: 1px solid $ms-blue;
    box-shadow: 0px 1px 4px #00000029;
    cursor: pointer;
    height: 36px;
    width: 36px;
    border-radius: 36px;
    color: $bright-blue;
    position: absolute;
    outline: 0 !important;
    top: 22%;

    @include flex-row;
    justify-content: center;
    align-items: center;

    &.right {
      right: -20px;
    }

    &.left {
      left: -20px;
    }

    .arrow {
      filter: invert(1);
      width: 12px;
      height: 12px;

      &.right {
        transform: rotate(180deg);
      }
    }
  }

  .columns {
    .similar-space-column {
      .wrap {
        border-radius: 4px;
        cursor: pointer;
        margin-bottom: 15px;

        @include tablet-portrait {
          margin-right: 10px;
        }

        @include mobile {
          margin-right: 10px;
        }
      }

      .similar-space-thumbnail {
        @include background-property;
        background-position: center;
        background-size: cover;
        width: 100%;
        padding: 27%;
        height: 146px;
        position: relative;
        border-radius: 4px;

        .heart-img {
          cursor: pointer;
          position: absolute;
          right: 18px;
          top: 12px;
          width: 18px;
          height: 18px;

          @include desktop {
            width: 18px;
            height: 18px;
          }
        }

        .number-of-people {
          background-color: rgba($black, 0.5);
          border-radius: 23px;
          color: $white;
          position: absolute;
          padding: 1px 6px;
          bottom: 10px;
          right: 10px;

          .people {
            @include font-size(13);
          }

          .img {
            width: 10px;
            height: 11px;
            margin-right: 3px;
            filter: invert(1);
          }
        }

        .distance-wrap {
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 23px;
          color: #ffffff;
          position: absolute;
          padding: 1px 6px;
          top: 10px;
          left: 10px;

          .img {
            width: 10px;
            height: 11px;
            top: 4px;
            left: 5px;
          }

          .distance {
            display: inline-block;
            @include font-size(13);
            font-weight: $light;
            margin-left: 12px;

            @include mobile {
              @include font-size(10);
            }
          }
        }

        @include tablet-portrait {
          padding: 0;
          width: 193px;
          height: 110px;
        }

        @include mobile {
          padding: 0;
          width: 193px;
          height: 110px;
        }
      }

      .thumbnail-content {
        padding: 10px;
        padding-left: 0;
        text-align: left;
        line-height: 1.4;

        .content-title {
          color: rgba($black, 0.5);
          display: block;
          font-weight: 600;
          @include font-size(13);
          text-transform: uppercase;

          @include desktop {
            @include font-size(13);
          }
        }

        .content-sub-title {
          color: $black;
          display: block;
          font-weight: $bold;
          @include font-size(15);
          text-transform: unset;

          @include desktop {
            @include font-size(18);
          }
        }

        .sub-title {
          color: $gray;
          display: block;
          @include font-size(13);
          text-transform: uppercase;
        }

        .deal-wrap {
          .deal {
            margin-right: 10px;
            color: #f35050;
            @include font-size(13);
            font-weight: $bold;
          }

          .deal-tag {
            background-color: #f35050;
            border-radius: 23px;
            color: $white;
            @include font-size(13);
            padding: 1px 8px;
            width: 52px;
            height: 18px;
            margin-right: 5px;
            text-transform: uppercase;
          }

          .price {
            text-decoration: line-through;
          }
        }

        .deal-wrapper {
          .price {
            @include font-size(15);
            font-weight: $extra-bold;
          }
          .price-description {
            @include font-size(13);
          }
        }

        .price {
          color: $black;
          @include font-size(13);
          font-weight: $bold;
        }
      }

      @include tablet-portrait {
        @include flex-row;
        align-items: baseline;
        flex-wrap: nowrap;
        overflow-x: auto;
      }

      @include mobile {
        @include flex-row;
        align-items: baseline;
        flex-wrap: nowrap;
        overflow-x: auto;
      }
    }
  }
}
