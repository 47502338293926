.space-listing-page {
  .full-height-wrapper {
    .section-wrapper {
      .left-side {
        .common-popup {
          color: $black;

          p {
            color: $black;
          }

          &.add-on-popup {
            .md-dialog {
              max-width: 327px;
              width: 100%;

              .md-text-field-container {
                &.text-area {
                  .md-floating-label {
                    &.md-floating-label--inactive {
                      top: -16px;
                    }

                    &.md-floating-label--floating {
                      top: -22px;

                      @include desktop {
                        top: -16px;
                      }
                    }
                  }
                  .md-text-field-multiline-container {
                    margin-top: 0;

                    .md-text-field {
                      height: 100px;
                      padding-top: 15px;
                      padding-left: 25px;
                      width: 277px;
                    }
                  }
                }
              }
            }
          }

          &.payout-popup {
            .md-dialog {
              max-width: 335px;
              width: 100%;
            }
          }

          &.package-details-popup {
            .md-dialog {
              max-width: 355px;
              width: 100%;

              .md-tooltip-container {
                .arrow-head-tooltip {
                  background: $black;
                  border-color: $black;
                  border-radius: 5px;
                  max-width: calc(100% - 50px);
                  width: 100%;

                  &::after {
                    content: '';
                    position: absolute;
                    left: 45%;
                    top: 98%;
                    width: 0;
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 10px solid $black;
                    clear: both;
                  }

                  .tooltip-content {
                    font-size: 13px;
                  }
                }
              }

              .main-title {
                margin-bottom: 0;
              }

              .text-area {
                .md-text-field-multiline-container {
                  margin-top: 0;

                  .md-text-field {
                    height: 70px;
                  }
                }
              }

              .btn-wrapper {
                justify-content: space-between;

                .border-btn {
                  width: 150px;
                }

                .gray-btn {
                  width: 150px;
                }
              }
            }
          }
          &.delete-popup {
            .md-dialog {
              max-width: 305px;
              width: 100%;

              .md-dialog-content--padded {
                p {
                  font-size: 14px;
                }
                .btn-wrapper {
                  border-top: 0.5px solid rgba(112, 112, 112, 0.2);
                  justify-content: center;
                  margin-top: 20px;
                  margin-left: -24px;
                  margin-right: -24px;
                  padding-top: 10px;

                  .border-btn {
                    height: 40px;
                    width: 120px;
                  }
                  .red-btn {
                    color: $white;
                    font-weight: $black-bold;
                    height: 40px;
                    width: 120px;
                  }
                }
              }
            }
          }
        }
        .left-side-inner-wrapper {
          .payout-option-wrapper {
            max-width: 327px;
            width: 100%;

            @include exlarge-desktop {
              max-width: 62%;
              width: 100%;
            }

            .ant-radio-group {
              width: 100%;

              label.ant-radio-wrapper {
                color: $black;
                font-size: 15px;
                display: block;
                border-bottom: 0.5px solid rgba(112, 112, 112, 0.2);
                padding-bottom: 10px;
                padding-top: 10px;

                &:last-child {
                  border-bottom: unset;
                }

                &:focus {
                  outline: unset;
                }

                .ant-radio {
                  .ant-radio-inner {
                    border-color: rgba(0, 0, 0, 0.25);
                    box-shadow: unset;
                    height: 21px;
                    width: 21px;

                    &::after {
                      background-color: $dark-green;
                      height: 13px;
                      width: 13px;
                    }
                  }

                  &:hover {
                    .ant-radio-inner {
                      border-color: rgba(0, 0, 0, 0.25);
                    }
                  }
                }
              }
            }
          }
          &.welcome-screen {
            padding-right: 90px;

            .listing-page-title {
              @include desktop {
                max-width: 314px;
                width: 100%;
              }
            }

            .paragraph-wrapper {
              @include desktop {
                width: 100%;
              }

              @include exlarge-desktop {
                width: 100%;
              }

              .text-bold {
                font-weight: $bold;
              }

              .text-italic {
                font-style: italic;
              }
            }

            .img-wrapper {
              margin-top: 70px;
              text-align: center;
            }
          }
          &.space-details-wrapper {
            .pricing-options {
              .ant-select-selection {
                .ant-select-selection__rendered {
                  .ant-select-selection-selected-value {
                    .description {
                      display: none;
                    }
                  }
                }
              }
            }
          }
          &.host-details-wrapper {
            .prefix-tp-wrapper {
              @include flex-row();
              border: 1px solid $light-gray2;
              border-radius: 25px;
              flex-wrap: nowrap;
              height: 45px;
              margin-bottom: 15px;
              width: 100%;

              .prefix {
                @include flex-row();
                border-right: 1px solid $light-gray2;
                justify-content: center;
                height: inherit;
                max-width: 68px;
                width: 100%;

                .prefix-text {
                  color: $dark-gray;
                }
              }

              .md-text-field-container {
                border-radius: unset;
                border-color: transparent;
                margin-bottom: 0;

                .md-text-field {
                  border-radius: unset;
                  border-color: transparent;
                  margin-bottom: 0;
                }

                .md-text--error {
                  position: absolute;
                  left: -70px;
                }
              }
            }
          }
          &.space-notice-wrapper {
            @include desktop {
              padding-right: 60px;
            }

            .content-wrapper {
              margin-top: 10px;
              margin-bottom: 20px;

              .btn-wrapper {
                margin-bottom: 15px;

                .border-btn {
                  margin-right: 5px;
                  margin-top: 8px;
                  padding-left: 15px;
                  padding-right: 15px;
                  height: 40px;
                  width: max-content;

                  &:last-of-type {
                    margin-right: 0;
                  }

                  &:active {
                    outline: 0 !important;
                  }

                  &.button-disabled {
                    pointer-events: none;
                  }

                  &:focus {
                    outline: unset;
                  }
                }
              }
            }

            .divider {
              margin-right: -60px;
            }
          }
          &.upload-img-page {
            @include desktop {
              padding-right: 40px;
            }

            .gray-text {
              margin-bottom: 30px;
            }

            .upload-description {
              margin-bottom: 5px !important;
            }

            .upload-subtitle {
              margin-bottom: 5px !important;
            }

            .img-tile-wrapper {
              overflow: hidden;

              .img-tile {
                border: 0.5px #bfbfbf solid;
                border-radius: 4px;
                float: left;
                height: 158px;
                margin-right: 10px;
                margin-bottom: 10px;
                transition: 0.4s;
                width: 158px;
                display: flex;
                justify-content: center;
                overflow-x: hidden;
                overflow-y: hidden;

                .img-tile-bgimg {
                  background-size: contain;
                  border-radius: 2px;
                  height: 158px;
                  // margin: 10px;
                  position: relative;
                  width: 100%;
                  background-color: #000;

                  &.select {
                    .tick-icon {
                      visibility: visible;
                    }
                  }

                  .tick-icon {
                    height: 25px;
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    visibility: hidden;
                    width: 25px;
                  }

                  &:hover {
                    .hover-content {
                      @include flex-row();
                      background-color: rgba(0, 0, 0, 0.6);
                      height: 100%;
                      justify-content: center;
                      width: 100%;

                      .hover-content-inner-wrapper {
                        @include flex-row();

                        @include desktop {
                          @include flex-column();
                        }

                        .btn-wrapper {
                          border: 1px solid $white;
                          border-radius: 23px;
                          cursor: pointer;
                          margin-bottom: 10px;
                          padding: 5px 10px;

                          :last-of-type {
                            margin-bottom: 0;
                          }

                          .img {
                            margin-right: 5px;
                          }

                          .title {
                            @include font-size(11);
                            color: $white;
                            text-transform: capitalize;
                          }
                        }
                      }
                    }
                  }

                  .hover-content {
                    border-radius: 2px;
                    display: none;
                  }
                }

                &:nth-of-type(3n) {
                  @include desktop {
                    margin-right: 0;
                  }
                }

                @include desktop {
                  width: 158px;
                  height: 158px;
                }

                .img-upload {
                  @include flex-column();
                  background-color: transparent;
                  cursor: pointer;
                  height: inherit;
                  text-align: center;
                  width: inherit;

                  .img {
                    margin-bottom: 10px;
                  }

                  .title {
                    @include font-size(15);
                    color: $dark-green;
                  }
                }

                &.img-upload-wrapper {
                  border: 1px dashed #bfbfbf;
                  background-color: $light-gray3;
                }
              }
            }
          }

          .video-upload-section {
            margin-top: 30px !important;
          }

          &.tax-details {
            .btn-wrapper {
              margin-bottom: 20px;
            }
          }

          &.seating-details-wrapper {
            .ant-select-selection {
              .ant-select-selection__rendered {
                .ant-select-selection-selected-value {
                  .option-content-wrapper {
                    .title-description-wrapper {
                      .option-description {
                        display: none;
                      }
                    }
                    .img {
                      display: none;
                    }
                  }
                }
              }
            }
            .policy-drop-down {
              .ant-select-selection {
                .ant-select-selection__rendered {
                  .ant-select-selection__choice {
                    .option-content-wrapper {
                      @include flex-row();

                      .title-description-wrapper {
                        .description {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
            .pricing-options {
              .ant-select-selection {
                .ant-select-selection__rendered {
                  .icon-text-wrapper {
                    @include flex-row();

                    .icon {
                      margin-right: 5px;
                    }
                    .text-wrapper {
                      .description {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
          &.availability-detais-wrapper {
            .pricing-option-label {
              @include flex-row();
              background-color: $light-gray3;
              border-radius: 23px;
              height: 30px;
              margin-bottom: 5px;
              min-width: 125px;
              padding: 0 9px;
              width: fit-content;
              .icon {
                margin-right: 5px;
              }

              .title {
                @include font-size(13);
              }
            }
            .ant-select {
              .ant-select-selection__rendered {
                padding-left: 5px;
              }
            }
            .content-wrapper {
              .md-text-field-container {
                margin-bottom: 20px;
              }
              .ant-select {
                margin-top: 10px;
              }
              .card-wrapper {
                @include flex-row();
                flex-wrap: nowrap;
                margin-bottom: 10px;

                .card-border {
                  flex: 1;
                  width: 100%;
                  border: 1px solid $light-gray2;
                  border-radius: 25px;
                  padding: 15px;

                  .title-btn-wrapper {
                    @include flex-row();
                    justify-content: space-between;
                    margin-bottom: 8px;

                    .title {
                      @include font-size(11);
                      color: #8c8c8c;
                      font-weight: $bold;
                      margin-left: 5px;
                      text-transform: uppercase;
                    }

                    .remove-btn {
                      @include font-size(11);
                      color: #8c8c8c;
                      cursor: pointer;
                      font-weight: $bold;
                    }
                  }

                  .card {
                    width: 100%;

                    color: $black;

                    .pickers-wrapper {
                      @include flex-row();
                      flex-wrap: nowrap;
                      margin-right: 45px;
                      margin-bottom: 15px;
                      position: relative;

                      .label {
                        @include font-size(10);
                        background: $white;
                        left: 25px;
                        padding: 0 2px;
                        position: absolute;
                        top: -6px;
                        z-index: 1;
                      }

                      .md-menu-container {
                        flex: 1;
                        margin-right: 7px;
                        border: 1px solid $light-gray2;
                        border-radius: 25px;
                        color: $black;

                        &.end-time {
                          margin-right: 0;
                        }
                      }

                      .md-text-field-container {
                        flex: 1;
                        margin-bottom: 0;
                      }

                      .minus-btn {
                        cursor: pointer;
                        width: 40px;
                        height: 40px;
                        position: absolute;
                        right: -45px;

                        .icon {
                          width: 40px;
                          height: 40px;
                        }
                      }
                    }
                  }
                }
              }
              .link-text-wrapper {
                @include flex-row();
                background-color: #f4ffff;
                border: 0.5px solid $dark-green;
                border-radius: 25px;
                height: 30px;
                margin-top: 15px;
                padding-left: 20px;
                padding-right: 20px;
                width: fit-content;

                .link-text {
                  @include font-size(11);
                  color: $dark-green;
                  font-weight: $extra-bold;
                  text-decoration: unset;
                  text-transform: uppercase;
                }
              }
            }

            &.daily-wrapper {
              .content-wrapper {
                .card-wrapper {
                  .card-border {
                    .card {
                      .pickers-wrapper {
                        margin-right: 0;
                      }
                    }
                  }
                }
              }
            }

            &.hourly-pricing {
              .time-picker-wrapper {
                position: relative;

                .label {
                  @include font-size(10);
                  color: $black;
                  background: $white;
                  left: 25px;
                  padding: 0 2px;
                  position: absolute;
                  top: -6px;
                  z-index: 1;
                }
              }

              .sub-title {
                color: $dark-gray;
                margin-bottom: 8px;
              }

              .content-wrapper {
                .card-wrapper {
                  .card-border {
                    .card {
                      .ant-time-picker {
                        flex: 1;
                        margin-right: 5px;
                      }
                      .md-text-field-container {
                        flex: 1;
                      }
                    }
                  }
                }
              }
            }
          }

          &.seating-arrangement {
            .ant-select-selection {
              min-height: 45px;

              .ant-select-selection__rendered {
                padding-top: 3px;
              }
            }
            .content-wrapper {
              margin-top: 10px;

              .card-wrapper {
                @include flex-row();
                flex-wrap: nowrap;
                margin-bottom: 10px;

                .icon-title-wrapper {
                  background-color: $light-gray3;
                  border: 1px solid #e9e9e9;
                  border-radius: 25px;
                  @include flex-row();
                  flex-wrap: nowrap;
                  height: 45px;
                  justify-content: left;
                  margin-right: 5px;
                  padding-left: 20px;
                  width: 100%;

                  .icon {
                    margin-right: 10px;
                  }

                  .title {
                    @include font-size(13);
                  }
                }

                .md-text-field-container {
                  margin-bottom: 0;
                  margin-right: 5px;
                  max-width: 147px;
                  width: 100%;

                  .md-text-field {
                    font-size: 13px;
                  }
                }
              }
            }
          }

          &.commission-details {
            .gray-text {
              margin-bottom: 20px;
            }

            .icon-sentense-wrapper {
              margin-bottom: 20px;

              .icon-sentense-inner-wrapper {
                align-items: end;

                .green-text {
                  flex: 1;
                }
              }
            }
          }
          
          &.congrats-screen {
            max-height: inherit;

            .content-wrapper {
              max-width: 440px;
              text-align: center;
              width: 100%;

              .paragraph-wrapper {
                text-align: left;

                .text {
                  color: $ms-blue;
                }
              }

              .congrats-img {
                margin-top: 100px;
              }

              .btn-wrapper {
                margin-top: 100px;
                margin-bottom: 50px;
                .btn {
                  @include font-size(13);
                  @include flex-row();
                  cursor: pointer;
                  font-weight: $black-bold;
                  height: 40px;
                  justify-content: center;
                  line-height: 18px;
                  margin: 0 auto;
                  max-width: 297px;
                  text-transform: uppercase;
                  width: 100%;

                  &.border-btn {
                    background-color: $white;
                    border: 1px solid $dark-green;
                    color: $dark-green;
                    margin-bottom: 10px;

                    &:hover {
                      background-color: $white;
                      border: 1px solid $dark-green;
                      color: $dark-green;
                    }
                  }
                }
              }
            }
          }
        }

        .italic {
          font-style: italic;
        }
      }
    }
  }
}
