.section-about-us {
  text-align: left;

  @include desktop {
    padding-top: 68px;
  }

  .bg-img-section {
    @include background-property;
    background-size: cover;
    border-radius: 0 0 32px 32px;
    height: 465px;
    position: relative;
    text-align: -webkit-center;
    text-align: -moz-center;

    @include desktop {
      border-radius: 0;
      height: 600px;
      margin-top: 0px;
    }

    .bg-img-overlay {
      @include flex-row();
      background:  rgba(255, 255, 255, 0.75) 0% 0% no-repeat padding-box;
      height: 100%;
      justify-content: center;
      position: relative;
      padding-top: 25px;
      width: 100%;

      @include desktop {
        align-items: center;
        border-radius: 0px;
        height: 100%;
        width: 100%;
        padding-top: 0;
      }

      .header-block-inner-wrapper {
        color: $white;
        text-align: center;

        @include desktop {
          max-width: 1045px;
          width: 100%;
        }

        .main-title {
          @include font-size(30);
          color: $white;
          font-weight: $extra-bold;

          @include desktop {
            @include font-size(50);
          }
        }
        .main-description {
          color: $ms-blue;
          max-width: 305px;
          text-align: -webkit-center;
          width: 100%;

          @include desktop {
            max-width: 920px;
            width: 100%;
          }

          @include tablet-portrait {
            max-width: 600px;
            width: 100%;
          }

          .first-block {
            margin-bottom: 30px;    
            font-weight: $bold;

            @include desktop {
              @include font-size(28);
              line-height: 40px;
            } 

            .text-bold {
              font-weight: $extra-bold;
            }
          }

          .second-block {

            @include desktop {
              @include font-size(18);
              line-height: 25px;
              max-width: 665px;
            }           
          }
        }
      }
    }
  }

  .custom-container {
    @include desktop {
      max-width: 1760px;
      margin-right: auto !important;
      margin-left: auto !important;
      padding: 0;
      width: 80%;
    }

    &.about-us-block {
      background: $white;
      border-bottom-left-radius: 32px;
      border-bottom-right-radius: 32px;
      position: relative;

      @include desktop {
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
      }
    }

    &.group {
      margin-top: -25px;
      padding: 0;

      @include desktop {
        margin-right: auto;
        margin-left: auto;
        margin-top: 0;
      }

      .img {
        border-top-left-radius: 32px;
        border-top-right-radius: 32px;
        width: 100%;

        @include desktop {
          border-top-left-radius: unset;
          border-top-right-radius: unset;
        }
      }
    }
    .capital-letters {
      text-transform: uppercase;
    }
    .title {
      @include font-size(25);
      font-weight: $extra-bold;
    }

    .img-wrapper {
      text-align: center;

      .img {
        width: 100%;

        @include desktop {
          width: auto;
        }
      }
    }
    .main-description {
      padding-bottom: 40px;
      margin: 0 auto;
      margin-top: 2px;
      text-align: center;
      width: 100%;

      @include desktop {
        max-width: 584px;
        margin-top: 40px;
      }
    }
  }


  .story-block {
    margin-top: 100px;
    margin-bottom: 100px;
    @include flex-row;
    // justify-content: space-between;
    align-items: flex-start;

    @include tablet-portrait{
      display: grid;
      grid-template-columns: 1fr;
    }

    @include tablet-landscape{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .right-wrap {
      width: 630px;

      @include mobile {
        width: 100%;
      }

      @include tablet-portrait {
        justify-self: center;
        margin-top: 20px;
        width: 80%;
      }

      @include tablet-landscape {
        width:100%  
      }
    }

    .left-wrap {
      width: 375px;

      @include mobile {
        width: 100%;
        text-align: center;
      }

      @include tablet-portrait{
        justify-self: center;
        width:auto;
      }
    }

    .story-description {
      color: $black;
      @include font-size(15);
      text-align: left;
      line-height: 35px;

      @include mobile {
        text-align: center;
      }

      @include tablet-landscape {
        line-height: 30px;
      }

      @include tablet-portrait {
        line-height: 30px;
        text-align: center;
      }
    }

    .img {
      width: 326px;
      height: 326px;
      border-radius: 30px;

      @include mobile {
        height: auto;
        width: 100%;
      }
    }

    .story-title {
      @include font-size(35);
      font-weight: $black-bold;
      line-height: 30px;
      margin-bottom: 20px;

      @include tablet-portrait{
        text-align: center;
      }

      .blue-text {
        color: $ms-blue;
      }

      @include mobile {
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: center;
      }
    }

    @include mobile {
      margin: 0px;
      padding: 50px 30px;
    }

    @include tablet-portrait {
      display: grid;
      grid-template-columns: 1fr;
      margin: 0px;
      padding: 50px 30px;
    }
  }

  .mission-block {
    background-color: #f0edec;
    border-radius: 30px;
    align-items: center;
    box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.2);
    display: flex;
    justify-content: space-between;

    @include tablet-landscape{
      display:grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .mission-custom-padding {
       padding-left: 80px;

       @include mobile {
         padding-left: 0;
       }

       @include tablet-portrait {
        padding-left: 0;
      }
    }

    .right-wrap {
      background-position: right;
      background-size: contain;
      background-repeat: no-repeat;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      height: 345px;
      margin-left: 10px;
      width: 450px;

      @include mobile {
        background-image: none !important;
        height: 0;
      }

      @include tablet-portrait {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        display:none;
        height: 191px;
      }

    }

    .left-wrap {
      width: 54%;

      @include mobile {
        width: 100%;
      }

      @include tablet-portrait {
        width: 50%;
      }

      @include tablet-landscape{
        padding-top: 40px;
        padding-bottom: 40px;
        width:100%;
      }
    }

    @include mobile {
      border-radius: 0;
      margin-bottom: 50px;
    }

    @include tablet-portrait {
      border-radius: 0;
      display: grid;
      grid-template-columns: 1fr;
      margin-bottom: 50px;
    }
  }

  .our-goal {
    background: $white;
    padding: 70px 0;
    position: relative;
    top: 33px;

    @include desktop {
      margin-top: 0;
      padding: 50px 0;
      top: 0px;
    }

    .custom-container {
      padding: 0px 34px;

      @include desktop {
        padding: 0px;
      }

      .title {
        @include font-size(20);
        font-weight: $black-bold;
        text-align: center;

        @include desktop {
          @include font-size(35);
          margin-bottom: 50px;
        }
      }

      .description {
        width: 100%;
        @include desktop {
          max-width: 686px;
        }
      }

      .detail-icon-block-wrapper {
        margin-top: 30px; 

        @include desktop {
          @include flex-row();
          flex-wrap: nowrap;
          justify-content: space-between;
        }

        @include mobile {
          &.detail-icon-block-wrapper:nth-of-type(even) {
            margin-top: 0px;
          }
        }

        @include tablet-portrait {
          &.detail-icon-block-wrapper:nth-of-type(even) {
            margin-top: 0px;
          }
        }
        
        .detail-icon-block {
          @include flex-row();
          align-items: end;
          color: $black;
          flex-wrap: nowrap;
          margin-bottom: 10px;

          .img {
            border-radius: 8px;
            width: 80px;

            @include mobile {
              width: 50px;
            }
          }

          @include desktop {
            width: 44%;
          }

          @include mobile {
            margin-bottom: 20px;
          }

          .detail-wrapper {
            margin-left: 30px;
          }

          &.detail-icon-block:nth-of-type(odd) {
            margin-right: 80px;

            @include mobile {
              margin-right: 0px;
            }
          }

          .sub-title {
            display: block;
            font-weight: $bold;
            @include font-size(18);
          }
        }
      }
    }
  }
  .the-board {
    background: $white;
    border-radius: 32px;
    padding: 50px 0;
    padding-bottom: 70px;
    position: relative;

    @include desktop {
      padding: 50px 0;
      border-radius: unset;
      margin-top: 0;
    }

    .custom-container {
      padding: 0px 34px;

      @include desktop {
        padding: 0px;
      }
      
      .title {
        @include font-size(20);
        margin-bottom: 30px;
      }
      .content-container-wrapper {
        @include desktop {
          @include flex-row();
          align-items: flex-start;
          flex-wrap: nowrap;
          justify-content: space-between;
        }

        .content-container {
          @include font-size(15);
          max-width: 350px;
          text-align: center;
          width: 100%;

          .top-section {
            justify-self: center;
            margin-bottom: 15px;
            text-align: center;          

            .img-wrapper {
              border-radius: 50%;
              display: block;
              margin: 0 auto;
              text-align: center;
              width: 110px;

              @include desktop {
                width: 70px;
              }

              .img {
                border: 2px solid $white;
                border-radius: 50%;
                display: block;
                margin: 0 auto;
                height: 110px;
                width: 110px;
                -webkit-box-shadow: 0px 2px 3px 0px rgba(50, 50, 50, 0.4);
                -moz-box-shadow: 0px 2px 3px 0px rgba(50, 50, 50, 0.4);
                box-shadow: 0px 2px 3px 0px rgba(50, 50, 50, 0.4);

                @include desktop {
                  height: 70px;
                  width: 70px;
                }
              }
            }

            .name {
              display: block;
              font-weight: $extra-bold;
              margin-top: 10px;
            }
          }
          
          .content-section {
            @include font-size(15);

            @include desktop {
              @include font-size(13);
            }
          }
          &.content-container:not(:last-child) {
            @include desktop {
              margin-right: 50px;
            }
          }
        }
        &.second-block {
          margin-top: 50px;
        }
        &.mobile-slider {
          text-align: center;
        }
      }
    }
  }
  .leadership {
    background-color: #FBFDFF;
    border-radius: 32px 32px 0px 0px;
    // margin-top: -25px;
    padding: 40px 0;
    padding-bottom: 90px;
    position: relative;

    @include desktop {
      margin-top: 0;
      padding: 100px 0;
    }

    .custom-container {
      padding: 0px 34px;

      @include desktop {
        padding: 0px;
      }

      .title {
        @include font-size(35);
        margin-bottom: 30px;
        text-align: center;
        font-weight: $black-bold;

        @include desktop {
          @include font-size(35);
          margin-bottom: 50px;
        }

        .blue-text {
          color: $ms-blue;
        }
      }
      
      .content-container-wrapper {
        @include desktop {
          @include flex-row();
          align-items: flex-start;
          flex-wrap: nowrap;
        }

        .content-container {
          display: grid;
          grid-template-rows: 1fr;
          text-align: center;
          width: 100%;
          
          .top-section {
            text-align: center;
            margin-bottom: 15px;
            color: $black;

            .img-wrapper {
              border-radius: 50%;
              display: block;
              margin: 0 auto;
              text-align: center;
              width: 110px;

              @include desktop {
                width: 127px;
              }

              .img {
                @include background-property;
                background-size: cover;
                background-position-y: top;
                border: 4px solid $white;
                border-radius: 50%;
                display: block;
                margin: 0 auto;
                height: 110px;
                width: 110px;

                @include desktop {
                  height: 127px;
                  width: 127px;
                }
              }
            }

            .name {
              display: block;
              font-weight: $extra-bold;
              margin-top: 10px;

              @include desktop {
                @include font-size(20);
              } 
            }

            .position {
              @include font-size(13);
              display: block;
              font-weight: $bold;
              margin-top: 5px;

              @include desktop {
                @include font-size(15);
              }
            }
          }
          .content-section {
            @include font-size(15);
            justify-self: center;          

            @include desktop {
              @include font-size(13);
              width: 242px;
            }
          }
          &.content-container:not(:last-child) {
            @include desktop {
              margin-right: 38px;
            }
          }

          .link {
            display: inline-block;
            margin-top: 15px;
            width: 21px;
          }
        }

        &.second-block {
          justify-content: center;
          margin-top: 50px;

          .content-container {
            width: 100%;
          }
        }

        &.last-block {
          justify-content: center;
          margin-top: 50px;

          .content-container {
            width: 31%;
          }
        }
        &.mobile-slider {
          text-align: center;
        }
      }
    }
  }
  .footer-section {
    .custom-container {
      @include desktop {
        max-width: unset;
        width: 90%;
      }
    }
  }
}
