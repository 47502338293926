.newsletter-section {
    margin-top: 10px;
    margin-bottom: 100px;

    .custom-container {
        background: #EBEBEC;
        background-repeat: no-repeat;
        max-width: 1760px;
        width: 100%;
        

        @include desktop {
            border-radius: 30px;
            box-shadow: 0px 2px 10px #00000029;
            height: 430px;
            padding: 0px;
            width: 89%;
        }

        @include tablet-landscape {
            border-radius: 30px;
            box-shadow: 0px 2px 10px #00000029;
            height: 430px;
            padding: 0px;
        }

        @include mobile {
            background-size: cover;
            width: 100%;
        }

        @include tablet-portrait {
            background-size: cover;
        }
          @include large-desktop{
            width: 83%;
          }
        
          @include exlarge-desktop{
            width: 90%;
          }
    }

    .form-wrapper {
        height: 550px;
        padding: 60px 7px 40px 7px;

        @include desktop {
            border-radius: 30px;
            box-shadow: 0px 2px 10px #00000029;
            display: grid;
            height: 430px;
            justify-items: end;
            padding: 60px 70px 60px 0px;
            text-align: -webkit-right;     
        }

        @include tablet-landscape {
            border-radius: 30px;
            box-shadow: 0px 2px 10px #00000029;
            height: 430px;
            padding: 60px 70px 60px 0px;
            text-align: -webkit-right;
        }

        .newsletter-form {

            @include desktop {
                display: flex;
                float: right;
            }

            .input-wrap {
                .ant-input {
                    background: $white;
                    box-shadow: 0px 0px 20px #0000001A;
                    border: 1px solid #FFF5F7;
                    border-radius: 23px;
                    font-weight: $extra-bold;
                    font-size: 14px;
                    height: 45px;
                    padding-left: 20px;                   
                    width: 100%;

                    @include desktop {
                        margin-right: 10px;
                        font-size: 16px;
                        width: 315px;
                    }

                    @include tablet-landscape {
                        margin-right: 10px;
                        width: 315px;
                    }

                    &::placeholder {
                        color: $black;
                    }
                }
                
                .error-wrap {
                    .error {
                      color: $error;
                      display: block;
                      font-size: 13px;
                      margin: 10px 0px;
                      padding-left: 5px;
                      text-align: left;
                    }
                }
            }

            .btn-wrap {
                .btn-subscribe {
                    color: $white;
                    @include font-size(12);
                    font-weight: $extra-bold;
                    height: 45px;
                    margin-top: 30px;
                    text-transform: uppercase;
                    width: 108px;

                    @include desktop {
                        margin-top: 0;
                        padding-top: 2px;
                    }

                    @include tablet-landscape {
                        margin-top: 0px;
                    }
                }
            }
        }

        .text {
            @include font-size(16);
            line-height: 30px;
            margin-bottom: 90px;
            color: black;
            text-align: center;
            margin-top: 0px;

            @include desktop {
                margin-bottom: 23px;
                text-align: right;
                width: 375px;
                margin-top: -10px;
            }      
        }

        .quill {
            .ql-toolbar {
              display: none;
            }
        
            .ql-container {
              &.ql-snow {
                border: none;
              }
        
              .ql-editor {
        
                p {
                  display: grid;
                  @include font-size(50);
                  font-weight: $extra-bold;
                  margin-bottom: 18px;
                  line-height: 55px;
                  text-align: center;
      
                  @include mobile {
                    font-size: 27px;
                    line-height: 35px;
                  }

                  @include desktop {
                    margin-bottom: 0;
                    text-align: right;
                  }

                  @include tablet-landscape {
                    margin-bottom: 0;
                    text-align: right;   
                  }
                }
              }
            }
          }
    }

    @include desktop {
        margin-top: 40px;
        margin-bottom: 30px;
    }

    @include tablet-landscape {
        margin-top: 40px;
    }

    @include mobile {
        margin-bottom: 16px;
    }

    .scroll-btn {
        background: transparent;
        border: none;
        cursor: pointer;
        left: 49%;
        margin-top: 100px;
        outline: none;
        scroll-behavior: smooth;
  
        .arrow-icon {
            width: 32px;
        }

        @include mobile {
            margin-top: 60px;
        }
    }
}