.my-booking-dropdown {
    .ant-select-dropdown-menu-item {
        border-bottom: 1px solid $light-gray;
        color: $black;
        font-weight: 500;
        padding: 10px 18px;

        &:last-child() {
            border-bottom: none; 
        }
    }
}

.manage-visits-dropdown {
    border-radius: 10px !important;

    li {
        a {
            color: $host-green;
            font-size: 11px;
            font-weight: $semi-bold;
        }
    }
}