.payment-method-popup {
    padding: 0;
  
    .md-dialog-content--padded {
      padding: 0px;
    }
  
    .md-text-field--floating-margin {
      margin-top: 11px !important;
    }
  
    .payment-method-popup-wrap {
      .header {
        @include flex-row;
        @include font-size(16);
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid $gray;
        padding: 12px 24px;
        padding-top: 24px;
  
        @include desktop {
          padding: 18px 30px;
          padding-top: 20px;
          justify-content: space-between;
        }
  
        @include mobile {
          display: flex !important;
          padding: 0 !important;
          padding-bottom: 20px !important;
        }

        .title-text {
          color: $black;
          @include font-size(16);
        }
      }
  
      .error-active {
        color: $error !important;
        margin-bottom: 5px;
  
        .md-text-field {
          border-color: $error !important;
          color: $error !important;
        }
        .error-text {
          color: $error !important;
        }
  
        .md-password-btn {
          .material-icons {
            color: $error !important;
          }
        }
  
        .md-floating-label {
          color: $error !important;
        }
      }
  
      .btn-disable {
          opacity: 0.5;
          pointer-events: none;
      }

      .btn {
        width: 100%;
        border-radius: 23px;
        height: 45px;
        box-shadow: none;
        margin: 10px 0;
        padding: 0 20px;
        font-weight: 600;
        @include font-size(15);
        outline: none;
        cursor: pointer;
        vertical-align: middle;
        margin-bottom: 15px;
        margin-top: 0;

        &.btn-black {
            color: $black;
            background-color: transparent;
            border: 1px solid #d8d8d9;
          }
      }
      
      .btn-wrap {
        padding-top: 25px;
        padding-bottom: 40px;
        align-items: center;

        .yellow-btn-wrapper {
          background-color: transparent;
          bottom: 0;
          position: fixed;          
          right: 0;
          text-align: center;
          width: 100%;
          z-index: 9;

            .yellow-btn-inner-wrapper {
              padding: 0 24px;
              padding-top: 10px;
              padding-bottom: 10px;

              .description {
                display: block;
                @include font-size(10.5);
                padding-bottom: 10px;

                .blue-text-link {
                  color: $bright-blue;
                  cursor: pointer;
                  display: inline-block;
                  text-decoration: underline;
                  font-weight: $bold;
                }
              }

              .md-btn {
                color: $white;
                cursor: pointer;
                background-color: $pink;
                border-radius: 25px;
                height: 50px;
                width: 100%;
                font-size: 13px;

                .small-text {
                  color: $white;
                  display: block;
                  line-height: 9px;
                  font-size: 11px;
                  font-weight: 600;
                  text-transform: none;

                  .mr-4 {
                    margin-right: 4px;
                  }
                }

                &:disabled {
                  pointer-events: none;
                  border: 1px solid $light-gray !important;
                  background-color: $light-gray !important;
                  color: $black !important;
                  cursor: not-allowed;
                }
              }
            }
        }

        @include tablet-landscape {
          width: 80%;
        }
      }

      .left-wrap {
        text-align: center;
  
        .arrow-icon {
          transform: rotate(180deg);
          width: 14px;
        }
  
        .link-text {
          color: $black;
          @include font-size(15);
  
          .link {
            color: $bright-blue;
            font-weight: 500;
          }
  
          @include desktop {
            margin-bottom: 0;
          }
  
        &.header-sub-text {
  
          @include desktop {
            position: absolute;
            top:48px;
          }
        }
        }
      }
  
      .right-wrap {
        float: right;
  
        .close-icon {
          cursor: pointer;
        }
      } 

      .check-box {
        background: $white;
        color: $black;
        width: 100%;
        margin-top: 25px;
        margin-bottom: 20px;

        .ant-checkbox {
          .ant-checkbox-inner {
            border: 1px solid #00000029;
            border-radius: 4px;
          }

          &.ant-checkbox-checked {
            .ant-checkbox-inner {
              background-color: $white;
              &::after {
                border-color: $black;;
              }
            }
          }
        }
      }

    }
  
    .content {
      text-align: left;
      padding: 30px 24px;
  
      .black-text {
        @include font-size(14);
        line-height: 18px;
        margin-bottom: 3px;
      }

      .black-text-bold{
        @include font-size(14);
        font-weight: $bold;
        line-height: 18px;
        margin-bottom: 3px;
      }

      .blue-text {
        color: $ms-blue;
        @include font-size(11);
        font-weight: $semi-bold;
        line-height: 18px;
        margin-bottom: 3px;
        text-transform: uppercase;
      }

      .blue-text-large {
        color: $ms-blue;
        @include font-size(14);
        font-weight: $semi-bold;
        line-height: 18px;
        margin-bottom: 3px;
        text-transform: uppercase;
      }

      .blue-text-link {
        color: $ms-blue;
        @include font-size(14);
        cursor: pointer;
        font-weight: $semi-bold;
        text-decoration: underline;
      }

      .space-top{
        margin-top: 20px;
      }
  
      &.bank-trasnfer-content {
        padding: 25px 38px 15px 38px;

        @include mobile {
          padding: 25px 0 0 0;
        }
      }
  
      @include desktop {
        min-height: 450px;
        height: auto;
        overflow: auto;
        padding: 30px 38px;
        padding-top: 25px;
        margin-bottom: 40px;
      }
  
      @include mobile {
        &.custom-content {
          overflow-y: scroll;
          height: 460px;

          @include mobile {
            height: calc(100vh - 145px);
          }
        }
      }

      .ant-flex-wrap {
            @include flex-row;
            justify-content: space-between;
      }

      .detail-content {
        font-size: 14px !important;
        margin-top: 10px;

        .content-left{
          align-items: flex-start;
          width: 30%;
        }

        .content-center{
          align-items: center;
          width: 10%;
        }

        .content-right{
          align-items: flex-start;
          width: 40%;
        }
      }

      .red-border-box{
        background-color: rgba(253, 65, 100, 0.05);
        border-radius: 10px;
        margin-top: 20px;
        padding: 15px 25px 15px 25px; 
       

          .red-box-content{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            padding: 0px 0px 5px 0px;
            
            .red-text{
              @include font-size(11);
              color: #FD4164;
              opacity: 1;
              width: 90%;
            }
    
            .red-circle{
              width: 10%;
              width: 14px;
              height: 14px;
              display: inline-block;
              border-radius: 7px;
              text-align: center;
              @include font-size(9);
              color: white;
              margin-right: 10px;
              background: #FD4164;
              opacity: 1  
            }
          }
  
      }
      

    }
  
    .btn-wrap {
      padding: 24px;
      text-align: center;
      bottom: 0;
      border-top: 1px solid $gray;
      background-color: $white;
      width: 100%;
  
      @include desktop {
        border-top: 1px solid $gray;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 35px 20px 35px;
        position: fixed;
      }
  
    }

    &.response-modal{
      .md-paper {

        @include desktop {
          height: 350px;
          width: 350px;
        }
  
        @include tablet-landscape {
          height: 265px;
          width: 350px;
        }

        .bank-trasnfer-content {
          min-height: auto;
          padding: 15px 35px 20px 30px;

          .black-text {
            line-height: 22px;
          }

          .text-block-wrap {
            color: $ms-blue;
            font-size: 13px;
            margin-top: 15px;
            text-align: left;

            .second-block {
              display: block;
              margin-top: 10px;

              .bold {
                color: $ms-blue;
                font-weight: $bold;
                text-decoration: underline;

                a {
                  color: $ms-blue;
                }
              }
            }
          }
        }
      }
    }
  
    .md-paper {
      max-height: 100%;
      width: 100%;
  
      .md-dialog-content {
        max-height: 100% !important;
        width: 100%;
  
        @include desktop {
          width: 100% !important;
          border-radius: 24px;
        }
      }
  
      &.md-dialog--centered {
        margin-top: 45px;

        @include mobile {
          height: 100vh;
          margin-top: 0;
          max-width: 100vw;
          width: 100vw;
        }
      }

      @include desktop {
        width: 530px;
        height: 87%;
        max-height: 650px;
        border-radius: 24px;
      }
    }
  
  }
  