.section-space {
  color: $black;

  .radio-btn-group {
    cursor: pointer;
    left: 25px !important;
    position: absolute;
    top: 360px !important;
  }

  .main-thumbnail {
    position: relative !important;
    height: 409px !important;
    width: 100% !important;
  }

  .main-video-thumbnail {
    position: relative !important;
    .video-js {
      padding-top: 409px !important;
      border-radius: 10px !important;
    }
  }

  .image-video-toggle-btn {
    color: #39AAE1;
    font-size: 12px;
    font-weight: 800px;
    height: 30px;
    width: 90px;
  }

  .img-radio-btn {
    border-radius: 25px 0px 0px 25px;
  }

  .video-radio-btn {
    border-radius: 0px 25px 25px 0px;
  }

  .radio-btn-icon {
    margin-right: 3px !important;
  }

  .small-images {
    position: relative;
  }

  .navigation-arrow {
    align-items: center;
    background-color: #39AAE1;
    border-radius: 35px;
    color: #fff;
    flex-wrap: wrap;
    height: 36px;
    padding-top: 12px;
    padding-left: 14px;
    position: absolute;
    top: 20px;
    width: 36px; 
  }

  .left-navigation-arrow {
    left: 0px;
  }

  .right-navigation-arrow {
    left: 800px;
  }

  .custom-container {
    width: 100%;
    position: relative;
  }

  .space-grid {
    padding: 0;
    @include flex-row;

    .title-wrap {
      @include flex-row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 25px;
      padding-top: 10px;

      .left-wrap {
        width: 100%;
        line-height: 1.4;

        .btn-map {
          background-color: $ms-blue;
          border-radius: 23px;
          border: none;
          color: $white;
          cursor: pointer;
          font-weight: $bold;
          height: 25px;
          @include font-size(13);
          margin: 0 0 3px 5px;
          outline: none;
          padding: 6px;
          text-align: center;
          text-transform: uppercase;
          width: 90px;

          .icon {
            @include desktop {
              margin-right: 5px;
            }
          }

          .blue-icon {
            margin-right: 3px;
            margin-bottom: 3px;
            width: 8px;
          }

          @include desktop {
            display: none;
          }
        }

        .description {
          font-size: 13px;

          @include desktop {
            font-size: 15px;
          }

          @include tablet-landscape {
            font-size: 15px;
          }
        }

        .link {
          color: $ms-blue;
          @include font-size(13);
          font-weight: $bold;
          margin-left: 5px;
          text-decoration: underline;

          &.link-view-map {
            @include mobile {
              display: block;
              margin-left: 0;
            }
          }
        }

        .space-type {
          color: $ms-blue;
          @include font-size(13);
          font-weight: $semi-bold;
          line-height: 18px;
          margin-bottom: 3px;
          text-transform: uppercase;
        }

        .text-country {
          color: rgba($color: #000000, $alpha: 0.5);
          display: inline-block;
          @include font-size(13);
          font-weight: $medium;
          text-transform: uppercase;
        }

        .text-space {
          @include font-size(28);
          display: block;
          font-weight: $black-bold;
          color: $black;
          line-height: 1.2;

          @include tablet-landscape {
            @include font-size(18);
          }

          .space-name {
            margin-right: 10px;
          }
        }

        .text-type {
          color: $bright-blue;
          @include font-size(13);
          font-weight: $medium;
          text-transform: uppercase;
        }

        .value-seat-day-block {
          display: inline-block;
          font-size: 15px;
          font-weight: normal;
        }

        @include desktop {
          text-align: left;
          width: 80%;
        }
      }

      .right-wrap {
        margin-bottom: 20px;
        width: 100%;

        .text-right {
          float: right;

          @include mobile {
            height: 20px;
          }
        }

        .align-wrap {
          vertical-align: middle;
        }

        .fav-img {
          margin-right: 5px;
        }

        .text-rating {
          font-weight: $bold;
          color: $black;

          @include tablet-landscape {
            @include font-size(13);
          }

          .text-rating-count {
            color: $black-op-60;
            font-weight: $medium;
          }
        }

        .btn {
          border: 1px solid $ms-blue;
          background-color: $white;
          cursor: pointer;
          outline: none;
          padding: 6px;
          width: auto;
          text-align: center;
          border-radius: 16px;
          @include font-size(11);

          &.btn-share {
            @include mobile {
              margin-left: 10px;
            }
          }

          &.btn-schedule-visit {
            background: $ms-blue;

            .text {
              color: $white;
            }
          }

          .icon {
            margin-right: 5px;
          }

          .text {
            color: $ms-blue;
            display: inline-block;
            font-weight: $bold;
            line-height: 11px;
            vertical-align: middle;
            text-transform: uppercase;
          }

          @include desktop {
            width: auto;
            margin-left: 10px;
          }

          @include tablet-landscape {
            width: auto;
            padding: 3px;
            @include font-size(11);
          }

          @include mobile {
            position: relative;
            margin-top: 30px;
          }

          @include tablet-portrait {
            position: relative;
            margin-top: 30px;
          }
        }

        .promo-code-slider {
          top: 165px;
          z-index: 1;

          @include mobile {
            position: relative;
            right: -20px;
            top: 10px;
          }
        }

        @include desktop {
          width: auto;
          margin-bottom: 0;
        }
      }

      @include desktop {
        flex-direction: row;
        margin: 0 5%;
        padding: 28px 0px;
        width: 100%;
      }

      @include mobile {
        margin-top: 100px;
        padding: 0;
        text-align: left;
      }

      @include tablet-portrait {
        margin-top: 100px;
        padding: 0;
        text-align: left;
      }
    }

    .md-cell {
      margin: 0;
    }

    @include desktop {
      padding-top: 100px;
    }
  }

  .space-column {
    .wrap {
      margin: 60px 0 8px 0;

      @include desktop {
        margin: inherit;
      }

      &.slider-images-wrap {
        background: $black;

        @include desktop {
          margin-bottom: 20px;
        }

        @include tablet-landscape {
          margin-bottom: 20px;
        }

        .slider-images {
          &.active {
            opacity: 0.5;
          }
        }
      }
    }

    .space-thumbnail {
      border-radius: 0 0 32px 32px;
      cursor: pointer;

      @include desktop {
        border-radius: inherit;
      }

      @include mobile {
        border-radius: 0px;
      }

      @include tablet-portrait {
        border-radius: 0px;
      }
    }
  }

  .space-left {
    @include tablet-portrait {
      width: 100%;
    }

    @include desktop {
      left: 6%;
      position: absolute;
      top: 230px;
      width: 60%;
    }

    .space-property-wrap {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
      border-top: 0.5px solid rgba(0, 0, 0, 0.2);
      padding: 20px 0;
      text-align: left;

      @include desktop {
        border-top: 1px solid rgba(#707070, 0.2);
        padding-bottom: 20px;
      }

      &.add-ons-section {
        padding: 40px 0 30px 0;

        .flex-row {
          justify-content: space-between;
          align-items: baseline !important;

          .list-item-add-ons-wrapper {
            margin-bottom: 10px;
            width: 100%;

            @include desktop {
              margin-bottom: 15px;
              padding-right: 25px;
              width: 46%;
            }

            @include tablet-landscape {
              margin-bottom: 15px;
              width: 47%;
            }

            .list-item {
              &.add-ons {
                display: flex;
                margin-bottom: 0;
                justify-content: space-between;
                width: 100%;

                .main-wrapper {
                  width: 100%;
                  @include flex-row();
                  align-items: flex-start;
                  flex-wrap: wrap;
                  justify-content: space-between;

                  @include desktop {
                    width: 100%;
                  }

                  @include tablet-landscape {
                    width: 100%;
                  }

                  .inline-wrap {
                    display: flex;
                    padding-right: 5px;
                    width: 100%;
                  }

                  .inline-wrapper {
                    display: inline-block;
                    word-break: break-word;
                    overflow: hidden;
                    white-space: nowrap;
                  }

                  .addon-img-wrapper {
                    display: inline-block;
                    font-size: 15px;
                    margin-right: 5px;
                    vertical-align: middle;
                  }

                  .price-wrap {
                    font-weight: $bold;
                    float: right;
                    text-align: right;
                  }
                }

                .price-value {
                  font-weight: 800;
                  float: right;
                }
              }
            }

            .add-on-description {
              color: rgba(0, 0, 0, 0.5);
              display: inline-block;
              font-size: 14px;
              padding-left: 20px;
              vertical-align: middle;
              word-break: break-all;
              width: 100%;
            }
          }
        }

        @include desktop {
          width: 100%;
        }

        @include tablet-landscape {
          width: 100%;
        }
      }

      &.space-property-wrap {
        .list-item {
          .img {
            max-width: 16px;
          }
        }
      }

      .title {
        @include font-size(18);
        color: $black;
        font-weight: $black-bold;
        margin-bottom: 10px !important;
      }

      .text-gray {
        color: rgba($black, 0.5);
        @include font-size(13);
        font-weight: $medium !important;
        margin-left: 5px;

        @include desktop {
          @include font-size(15);
        }
      }

      .content {
        padding: 10px 0;
        padding-bottom: 0;

        .special-grid {
          padding: 0;
          padding-bottom: 10px;
          flex-wrap: nowrap;

          .md-cell {
            margin: 0;
          }

          .grid-cell {
            margin: 0;

            .img {
              border-radius: 50%;
              width: 50px;
              height: 50px;
            }

            .grid-title {
              color: $black;
              font-weight: $bold;
              @include font-size(15);
              margin-bottom: 0;
            }

            .review-comment {
              border-bottom: 1px solid $light-gray;
              margin-top: 10px;
              padding-bottom: 30px !important;
            }
          }
        }

        .flex-wrap {
          @include font-size(15);
          color: $black;

          .flex-row {
            @include flex-row;
          }

          .list-item {
            display: block;
            margin-bottom: 10px;
            width: 100%;

            &.amenities-item {
              width: 50%;
              @include flex-row();
              flex-wrap: nowrap;
              align-items: baseline;

              .img {
                max-width: 16px;
              }

              @include desktop {
                width: 33%;
              }
            }

            @include desktop {
              width: 32%;
            }

            .vertical-list {
              margin-left: 10px;
              display: inline;
            }

            .img {
              margin-right: 5px;
            }

            .text-gray {
              color: rgba($color: #000000, $alpha: 0.5);
              margin-left: 5px;
            }

            .text-right {
              text-align: right;
              font-weight: $bold;
              margin-left: 30px;
            }

            &.flex-list-item {
              @include flex-row;
              align-items: center;
            }

            &.disabledClass {
              text-decoration: line-through;
              color: #7e7e7e;
            }
          }
        }

        &:last-child {
          .review-comment {
            border: none !important;
          }
        }

      }

      &.keep-in-mind {
        padding: 40px 0 30px 0;

        .wrap {
          .flex-wrap {
            .flex-row {
              .list-item {
                @include flex-row();
                flex-wrap: nowrap;
                align-items: baseline;

                &.disabledClass {
                  .img {
                    filter: grayscale(1);
                  }
                }
              }
            }
          }
        }
      }

      &.similar-space-wrap {
        padding: 40px 0px 80px 0px;

        @include desktop {
          padding-top: 40px;
        }
      }

      &.amenities-wrap {
        padding: 40px 0 30px 0;
      }

      &.seating-property-wrap {
        padding: 40px 0;
      }
    }

    @include desktop {
      padding-right: 25px;
    }

    @include mobile {
      width: 100%;
    }

    .policies-block-wrapper {
      @include desktop {
        @include flex-row;
        align-items: baseline;
      }

      width: 100%;

      .space-property-wrap {
        @include desktop {
          width: 50%;
        }

        .wrap {
          .special-grid {
            .text-gray {
              @include font-size(13);
            }

            .icon-wrap {
              max-width: 60px;
              width: 100%;
            }

            .description {
              @include font-size(13);
            }
          }
        }
      }
    }
  }

  .offer {
    border: 1px dashed $white;
    border-radius: 10px;
    height: 100%;
    padding: 20px;
    background-image: linear-gradient(to left, rgba(0, 233, 237, 0.8), rgba(0, 139, 232, 0.8));
    @include flex-row;
    justify-content: flex-start;

    .offer-content {
      color: $white;
      list-style-position: inside;
      display: list-item;
      list-style-type: disc;
      width: 100%;
    }

    @include desktop {
      height: 66px;
    }
  }

  .map-view-container {
    .md-dialog--centered {
      max-height: calc(100%);

      .md-dialog-content {
        .map-header-wrapper {
          background: $white;

          .map-header {
            display: flex;
            justify-content: space-between;
            padding: 17px 24px;
            font-weight: 700;

            .name-rating-wrapper {
              .name {
                @include font-size(15);
              }

              .rating-wrapper {
                @include font-size(13);

                .star {
                  padding-right: 3px;
                }

                .value {
                  padding-right: 3px;
                }

                .gray-color-value {
                  color: rgba(0, 0, 0, 0.5);
                  padding-right: 3px;
                }
              }
            }

            .close-btn {
              border: none;
              background: none;
            }
          }
        }

        .space-property-wrap {
          .wrap {
            margin-top: 0;

            .map-height {
              height: 100vh;

              @include desktop {
                height: 400px;
              }

              .gmnoprint {
                top: unset !important;
                bottom: 50px;

                @include desktop {
                  top: 0 !important;
                  bottom: unset;
                }
              }
            }
          }
        }
      }
    }
  }

  .space-property-wrap {
    .wrap {
      .content {
        .map-height {
          height: 100vh;

          @include desktop {
            height: 400px;
          }
        }
      }
    }
  }

  .reserve-block-wrapper {
    border-top: 0.5px solid rgba(0, 0, 0, 0.2);
    position: fixed;
    bottom: 0;
    background-color: $white;
    width: 100%;
    padding: 15px;
    left: 0;

    .reserve-block {
      @include flex-row;

      flex-wrap: nowrap;
      justify-content: space-between;

      @include desktop {
        flex-wrap: wrap;
      }

      .price-section {
        .strick-price {
          display: inline-block;
          @include font-size(15);
          margin-right: 5px;
          text-decoration: line-through;
        }

        .price-days {
          @include font-size(13);
          display: inline-block;

          .big-font {
            font-size: 15px;
            font-weight: 800;
          }
        }
      }

      .btn-section {
        .btn {
          width: 180px;
          height: 50px;

          .capital-cls {
            display: block;
            @include font-size(13);
            font-weight: 800;
          }

          .btn-desc {
            display: block;
            @include font-size(13);
          }
        }
      }
    }
  }
}

.space-property-wrap {
  .wrap {
    .content {
      .map-height>div:first-child {
        @include mobile {
          overflow: visible !important;
          position: absolute !important;
          width: 100%;
          z-index: 12;
        }

        @include tablet-portrait {
          overflow: visible !important;
          position: absolute !important;
          width: 100%;
          z-index: 12;
        }
      }

      .gm-control-active {
        &.gm-fullscreen-control {
          @include mobile {
            right: 35px !important;
          }

          @include tablet-portrait {
            right: 35px !important;
          }
        }
      }
    }
  }
}

.close-map-view {
  @include mobile {
    position: absolute;
    right: 12px;
    top: 20px;
    width: 20px;
    z-index: 13;
  }

  @include tablet-portrait {
    position: absolute;
    right: 12px;
    top: 20px;
    width: 20px;
    z-index: 13;
  }
}

.profile-navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 72px;
  background-color: $white;
  border-bottom: 1px solid $gray;

  &.mobile-space-profile-navbar {
    z-index: 12;
  }

  .custom-container {
    height: 100%;
  }

  .mobile-wrap {
    overflow: hidden;
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .left-wrap {
      float: left;
      text-align: left;
      width: 70%;

      .title {
        display: block;
        font-weight: $bold;
        color: $black;
        word-break: break-word;
      }

      .text-rating {
        font-weight: $bold;
        color: $black;
        vertical-align: middle;
        margin-left: 2px;
        @include font-size(13);

        .text-rating-count {
          color: $black-op-60;
          font-weight: $medium;
        }
      }
    }

    .right-wrap {
      float: right;

      .btn {
        @include font-size(13);
        background-color: $white;
        border: 1px solid $ms-blue;
        border-radius: 50%;
        cursor: pointer;
        height: 35px;
        margin-left: 10px;
        outline: none;
        padding: 6px;
        text-align: center;
        width: 35px;
      }

      .btn-heart {
        background-color: rgba($color: $bright-blue, $alpha: 0.15);
      }

      .img {
        width: 15px;
      }
    }
  }

  .list {
    display: flex;
    align-items: flex-end;
    height: 80px;
    line-height: 35px;
    justify-content: flex-start;

    .list-item {
      color: $black;
      border-bottom: 3px solid transparent;
      cursor: pointer;

      @include tablet-landscape {
        @include font-size(12);
      }

      .dot {
        display: inline-block;
        width: 5px;
        height: 5px;
        background-color: $black;
        border-radius: 40px;
        margin: 0 10px;
        vertical-align: middle;
      }

      &.active {
        border-bottom: 3px solid $bright-blue;
        font-weight: $bold;
      }
    }

    &.web {
      display: none;

      @include desktop {
        display: flex;
      }
    }
  }

  .mobile {
    display: flex;

    @include desktop {
      display: none;
    }
  }
}

.inactive-space {
  margin-top: 100px;

  .btn {
    background: $bright-blue;
    color: $white;
    margin-top: 12px;
  }

  .inactive-space-text {
    @include font-size(15);

    @include desktop {
      @include font-size(28);
    }
  }
}

.share-dialog {
  padding: 0;

  .md-dialog {
    width: 400px;
    border-radius: 24px;
  }

  .md-dialog-content {
    padding: 0 !important;
    border-radius: 24px;
  }

  .header {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid $gray;

    .title {
      @include font-size(18);
      font-weight: $black-bold;
      margin-bottom: 0;
    }

    .close-icon {
      cursor: pointer;
    }
  }

  .content {
    .link {
      padding: 15px 35px;
      border-bottom: 1px solid $gray;
      display: block;
      font-weight: $bold;
      color: $black !important;
    }
  }

  &.social-media-popup {
    .md-dialog-content {
      .header {
        padding: 15px 35px;
      }

      .link {
        padding-left: 35px;
        text-align: left;

        .img-wrapper {
          text-align: center;
          width: 16px;
          display: inline-block;
          margin-right: 25px;
        }
      }
    }
  }
}