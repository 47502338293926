.schedule-visit-popup {
  .md-paper  {
    border-radius: 24px;
    width: 500px;

    @include desktop {
      height: 95%;
      max-height: 565px;
    }

    @include tablet-landscape {
      height: 95%;
      max-height: 565px;
    }
  
    @include mobile {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
    }

    @include tablet-portrait{
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
    }

    .md-dialog-content {
      max-height: 100% !important;
      padding: 0;
      width: 100%;

      @include desktop {
        width: 100% !important;
        border-radius: 24px;
      }
    }
  }

  .schedule-visit-popup-wrap {
    .header {
      @include flex-row;
      @include font-size(16);
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid $gray;
      padding: 12px 24px;
      padding-top: 24px;

      @include desktop {
        padding: 20px 30px;
        padding-top: 25px;
        justify-content: space-between;
      }

      .title-text {
        color: $black;
        @include font-size(16);
      }

      .close-icon {
        cursor: pointer;
      }
    }

    .btn-wrap {
      align-items: center;
      border-top: 1px solid #f6f6f6;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
      bottom: 0;
      background: $white;
      display: flex;
      justify-content: center;
      position: fixed;
      width: 100%;

      @include mobile {
        border-radius: unset;
      }

      .btn-inner-wrapper {
        bottom: 0;
        text-align: center;
        width: 260px;
        padding: 10px 0px;

        .md-btn {
          background-color: $pink;
          border-radius: 25px;
          color: $white;
          cursor: pointer;
          font-size: 13px;
          font-weight: $black-bold;
          height: 50px;
          width: 100%;

          .small-text {
            color: $white;
            display: block;
            font-size: 10px;
            font-weight: normal;
            line-height: 9px;
            text-transform: none;

            .mr-4 {
              margin-right: 4px;
            }
          }

          &:disabled {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.5;
          }
        }
      }      
    }
    
    .content {
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      height: 490px;
      overflow: auto;
      padding: 30px;
      padding-bottom: 80px;
      width: 100%;

      @include mobile {
        height: calc(100vh - 135px);
        padding-bottom: 30px;
      }

      @include tablet-portrait {
        height: calc(100vh - 135px);
        padding-bottom: 30px;
      }

      .common-date-wrapper {
        width: 100%;
    
        .md-calendar-date--date {
          background: $white !important;
          padding: 0px !important;
        }
    
        .current-date {
          .md-calendar-date--date {
            border: 1px solid $ms-blue !important;
            background: $white !important;
            color: $ms-blue !important;
            padding: 6px !important;
          }
    
          .md-picker-text--active {
            border: 1px solid $ms-blue !important;
            color: $white !important;
            background-color: $ms-blue !important;
          }
        }
    
        .normal-date-cls {
          background: transparent !important;
        }
    
        .md-floating-label {
          left: 25px;
          @include font-size(14);
          color: $black;
          background-color: $white;
    
          @include mobile {
            top: -5px;
          }
    
          @include tablet-portrait {
            top: -5px;
          }

          &.md-floating-label--floating {
            @include font-size(11);
            transform: translate3d(0, -5px, 0);

            @include mobile {
              transform: translate3d(0, 0px, 0);
            }
          }

          &.md-floating-label--inactive {
            transform: translate3d(0, 14px, 0);
          }
        }
    
        .md-icon {
          color: $black !important;
          font-size: 16px;
        }
    
        .md-text-field-icon--positioned {
          @include desktop {
            bottom: 7px !important;
          }
    
          @include tablet-portrait {
            bottom: 3px !important;
          }
        }
    
        .md-picker--inline {
          position: relative;
        }
        
        .guest-input {
          @include mobile {
            height: 0px;
          }

          @include tablet-portrait {
            height: 0px;
          }
          
          .md-text-field-container {
            display: none;
          }
    
          .md-picker {
            left: 0px;
            top: 6px;
            width: 254px;

            .md-calendar-dows {
              padding-bottom: 5px;
    
              .md-calendar-dow {
                width: 14%;
              }
            }
    
            .md-picker-content-container {
              border-radius: 24px;
              font-size: 13px;
    
              .md-picker-content {
                height: auto !important;
                padding: 0px 8px;
                width: 254px;
    
                .md-calendar-controls {
                  height: 44px;
                  margin-bottom: 10px;
    
                  .md-title {
                    font-size: 15px;
                  }
                }
    
                .md-calendar-month {
                  .disable-date-cls {
                    color: rgba(0, 0, 0, 0.3);
                    background: none !important;
                    cursor: not-allowed;
                  }
    
                  .md-calendar-date {
                    margin-bottom: 0px;
                    width: 32px;
                    height: 32px;
    
                    .md-calendar-date--btn {
                      height: 32px;
    
                      .md-calendar-date--date {
                        color: $black;
                        padding: 0px !important;
                        padding-top: 8px !important;
                        height: 32px;
                        width: 32px;
    
                        .disable-date-cls {
                          color: rgba(0, 0, 0, 0.3);
                          background: none !important;
                          cursor: not-allowed;
                        }
    
                        &.md-picker-text--active {
                          .disable-date-cls {
                            background: none !important;
                            cursor: not-allowed;
                          }
    
                          span {
                            background: $ms-blue !important;
                          }
                        }
    
                        .normal-date-cls {
                          height: 32px;
                          font-size: 15px;
                          width: 32px;
                          display: block;
                          padding: 5px;
                          border-radius: 36px;
    
                          &.current-date {
                            border: 1px solid $ms-blue;
                            background: $white !important;
                            color: $ms-blue;
    
                            &.disable-date-cls {
                              background: none !important;
                              color: rgba(0, 0, 0, 0.3);
                              cursor: not-allowed;
                            }
                          }
    
                          &.current-date:hover {
                            border: 1px solid $ms-blue;
                            background: $ms-blue !important;
                            color: $white;
    
                            &.disable-date-cls {
                              background: none !important;
                              color: rgba(0, 0, 0, 0.3);
                              cursor: not-allowed;
                            }
                          }
                        }
                      }
    
                      &.md-calendar-date--btn-active {
                        .md-calendar-date--date {
                          color: $white;
                          background: $ms-blue !important;
    
                          span {
                            background: $ms-blue;
                          }
                        }
    
                        &:after {
                          background: transparent;
                        }
                      }
    
                      &.day-with-events {
                        background: #f2f2f2;
                      }
    
                      &.start-range-date {
                        border-top-left-radius: 36px;
                        border-bottom-left-radius: 36px;
                        padding-left: 0;
    
                        .calendar-select-days {
                          background: $ms-blue;
                          color: $white;
                        }
    
                        .md-calendar-date--date {
                          .current-date {
                            background: $ms-blue !important;
                            color: $white;
                          }
                        }
                      }
    
                      &.end-range-date {
                        border-top-right-radius: 36px;
                        border-bottom-right-radius: 36px;
                        padding-right: 0;
    
                        .calendar-select-days {
                          background: $ms-blue;
                          color: $white;
                        }
                      }
                    }
                  }
                }
              }
    
              .md-dialog-footer {
                padding: 0px;
    
                .md-btn {
                  &.md-btn--color-primary-active {
                    background-color: transparent;
                  }
    
                  .clear-all-btn {
                    color: $ms-blue;
                  }
                }
              }
            }
    
            .md-picker-content-container::before {
              display: none;
            }
    
            @include mobile {
              left: 30px;
              top: -150px;
            }
          }
        }
    
        .md-text-field-container {
          border: 1px solid #cdcdcd;
          border-radius: 24px;
          height: 40px;
          width: 100%;
    
          .md-text-field-icon-container {
            .img {
              cursor: pointer;
              position: absolute;
              left: 114px !important;
              filter: grayscale(1);
              width: 14px;
              margin-left: 70px;
              top: 12px;
              height: auto;
    
              @include mobile {
                bottom: 3px;
                left: unset !important;
                right: 16px;
              }
    
              @include tablet-portrait {
                bottom: 3px;
              }
    
              @include tablet-landscape {
                bottom: 0px;
                left: 15px;
              }
            }
    
            .md-text--theme-primary {
              color: $black !important;
            }
    
            .md-text-field-divider-container {
              margin-left: 20px;
              width: 100%;

              .md-text-field {
                color: $black;
                font-size: 14px;

                &::placeholder {
                  color: $black;
                }

                &.md-text-field--floating-margin {
                  margin-top: 14px;
                }
              }
            }
          }

          .md-divider {
            display: none;
          }

        }
    
        &.common-inline-wrapper {
          width: 50%;
        }
      }

      .card {
        color: $black;
      
        .pickers-wrapper {
          @include flex-row();
          align-items: center;
          border: 1px solid #cdcdcd;
          border-radius: 24px;
          height: 40px;
          justify-content: center;
          margin-bottom: 0px;
          padding: 2px 10px 2px 20px;

          .icon {
            height: 15px;
            position: relative;
            right: 6px;
            top: -82px;
            width: 15px;
          }

          .label {
            @include font-size(10);
            background: $white;
            left: 25px;
            padding: 0 2px;
            position: absolute;
            top: -6px;
            z-index: 1;
          }

          .md-text-field--floating-margin {
            margin-top: 10px;

            @include mobile {
              margin-top: 10px;
            }

            @include tablet-portrait {
              margin-top: 10px;
            }

            .md-icon-text:first-child {
              padding-left: 10px;
            }
          }

          .md-floating-label {
            background: $white;
            color: $black;

            &.md-floating-label--floating {
              transform: translate3d(-86px, -9px, 0);

              @include mobile {
                transform: translate3d(-134px, -8px, 0);
              }

              @include tablet-portrait {
                transform: translate3d(-134px, -8px, 0);
              }
            }

            &.md-floating-label--inactive  {
              transform: translate3d(-92px, 12px, 0);

              @include mobile {
                transform: translate3d(-136px, 10px, 0);
              }

              @include tablet-portrait {
                transform: translate3d(-136px, 10px, 0);
              }
            }
          }

          .md-divider {
            display: none;
          }
      
          .md-menu-container {
            flex: 1;
            border: none;
            border-radius: 25px;
            color: $black;
      
            &.end-time {
              margin-right: 0;
            }
          }
      
          .md-text-field-container {
            flex: 1;
            margin-bottom: 0;
            height: 100%;
          }
      
          .md-paper {
            left: -11px !important;
            padding-left: 0;
            padding-bottom: 0;
            height: 200px;
            max-height: 200px;
            width: 160px;

            @include mobile {
              border-radius: 20px;
              height: 200px;
              max-height: 100%;
              width: 100%;
            }

            &.md-list {
              background: transparent;

              .md-list-item {
                background: $white;
              }
            }
          }

          .md-list {
            @include mobile {
              margin-top: 42px;
            }

            .md-text--theme-primary {
              color: $black;
            }

            .md-ink-container  {
              .md-ink--active {
                background-color: transparent;
              }            
            }
          }

          .minus-btn {
            cursor: pointer;
            width: 40px;
            height: 40px;
            position: absolute;
            right: -45px;
      
            .icon {
              width: 40px;
              height: 40px;
            }
          }
        }
      }

      .section-divider {
        margin: 0;
        width: 100%;
      }

      .sch-input-wrap {
        width: 49%;
        margin-top: 15px;

        @include mobile {
          width: 100%;
        }

        @include tablet-portrait {
          width: 100%;
        }

        &.full-width {
          width: 100%;
        }

        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type='number']::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        
        .sch-input {
          border: 1px solid #cdcdcd;
          border-radius: 24px;
          height: 40px;
          padding: 2px 10px 2px 20px;

          &.comments-filed {
            height: 90px;
          }

          .md-divider--text-field {
            display: none;
          }

          .md-text {
            color: $black;
            font-size: 14px;
            margin-top: 8px;

            &::placeholder {
              color: $black;
            }
          }

          .md-floating-label {
            background: $white;
            color: $black;

            &.md-floating-label--floating {
              transform: translate3d(0, -7px, 0);
            }

            &.md-floating-label--inactive  {
              transform: translate3d(0, 14px, 0);
            }
          }
        }

        input[type=number]::-webkit-inner-spin-button {
          -webkit-appearance: none; 
          margin: 0; 
        }
        
        input[type=number]::-webkit-outer-spin-button { 
          -webkit-appearance: none; 
          margin: 0; 
        }
      }

      .text {
        color: $black;
        font-size: 14px;

        @include mobile {
          text-align: left;
        }
      }
    } 
  } 
}

.manage-visit-wrap {
  .header {
    .flex-wrap {
      @include mobile {
        margin-top: 65px;
        display: block !important;
      }

      @include tablet-portrait {
        margin-top: 30px;
        display: block !important; 
      }

      .right {
        @include mobile {
          position: unset !important; 
          bottom: unset !important; 
          right: unset !important; 
          margin-top: 10px;
          padding: unset !important; 
          text-align-last: right !important;
          z-index: 1;
        }

        @include tablet-portrait {
          position: unset !important; 
          bottom: unset !important; 
          right: unset !important; 
          margin-top: 10px;
          padding: unset !important; 
          text-align-last: right !important;
          z-index: 1;
        }

        .manage-visits-filter {
          @include mobile {
            margin-right: 0;
          }

          @include tablet-portrait {
            margin-right: 0;
          }
        }

        .icon {
          height: 14px;
          position: relative;
          right: 60px;
          top: 2px;
          width: 13px;

          @include mobile {
            right: 35px;
          }

          @include tablet-portrait {
            right: 35px;
          }
        }
      }
    }
  }
  .manage-visits {
    .card {
      padding: 10px 0 !important;
      .content {

        .comment-block {
          border-top: 0.5px solid #dddddd;
          color: $black;
          @include font-size(12);
          margin: 0 20px;
          opacity: 0.5;
          padding: 15px 0;
        }

        .flex-wrap {
          justify-content: flex-start !important;
          padding: 15px 20px 10px 20px;

          .company-name {
            color: $black-op-50;
            font-size: 11px;
            width: 100%;
          }

          .calendar-img {
            margin-right: 8px !important;
          }

          .telephone-icon {
            margin-right: 8px !important;
          }

          .detail-block {
            align-self: baseline;
            background: none !important;
            color: $black-op-50;
            font-size: 11px;
            margin-bottom: 5px;
            word-break: break-all;
            width: 48%;

            &.time {
              padding: 0 !important;
            }

            @include mobile {
              width: 100%;
            }

            @include tablet-portrait {
              width: 48%;
            }
          }

          .home-icon {
            height: 10px;
            margin-right: 8px;
            opacity: 0.5;
            width: 10px;
          }
        }

        .title-wrap {
          border-bottom: 0.5px solid #dddddd;
          display: flex;
          justify-content: space-between;
          padding: 8px 20px 15px 20px;

          .title {
            width: 65%;
            word-break: break-all;

            @include mobile {
              width: 55%;
            }
          }

          .visit-btn-grp{
            display: flex;
            justify-content: flex-end;
            height: 25px;
            width: 35%;
        
            .approve-btn{
              width: 100px;
              border-radius: 20px;
              border:0;
              color: #ffffff;
              font-size: 9px;
              font-weight: $bold;
              padding: 5px;
              text-transform: uppercase;
            }

            .red{
              border: 1px solid #F64E4B;
              background: $white;
              color: #F64E4B;
            }

            .green{
              border: 1px solid #2A8485;
              background: $white;
              color: #2A8485;
              margin-right: 5px;
            }

            .status-lable{
              width: 70px;
              border-radius: 20px;
              border:0;
              padding: 5px;
              font-size: 9px;
              font-weight: $bold;
              background-color: #f2f2f2;
              text-transform: uppercase;
            }
            .approve{
              color: #229954;
            }
            .reject{
              color: #CB4335;
              margin-left: 6px;
            }
          }
        }
      }
    }
  }
}

.schedule-visit-response-popup {
  .md-paper {
    border-radius: 24px;
    width: 330px;

    @include mobile {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
    }

    @include tablet-portrait{
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
    }
  }

  .md-dialog-content {
    padding: 0;
  }

  .response-popup-wrap {
    .header {
      @include flex-row;
      align-items: flex-start;
      border-bottom: 1px solid $gray;
      justify-content: space-between;
      padding: 25px 30px;

      .title-text {
        @include font-size(16);
        color: $black;
      }
    }

    .content {
      padding: 25px 30px;

      .black-text {
        color: $black;
        @include font-size(14);
      }

      .blue-text-link {
        color: $bright-blue;
        cursor: pointer;
        display: inline-block;
        text-decoration: underline;
        font-weight: $bold;
      }
    }

    .btn-wrap {
      align-items: center;
      border-top: 1px solid #f6f6f6;
      display: flex;
      justify-content: center;

      @include mobile {
        bottom: 0;
        position: fixed;
        width: 100%;
      }

      @include tablet-portrait {
        bottom: 0;
        position: fixed;
        width: 100%;  
      }

      .btn-inner-wrapper {
        bottom: 0;
        text-align: center;
        width: 260px;
        padding: 10px 0px;

        .md-btn {
          background-color: $pink;
          border-radius: 25px;
          color: $white;
          cursor: pointer;
          font-size: 13px;
          font-weight: $black-bold;
          height: 40px;
          width: 176px;
        }
      }
    }
  }
}

.manage-visits-filter {
  border: 1px solid #2A8485;
  border-radius: 25px;
  color: #1C8487;
  display: inline-block;
  font-size: 11px;
  font-weight: $bold;
  margin-right: 30px;
  padding: 8px 30px 8px 15px;
  text-transform: uppercase;
  text-align-last: left;
  width: 135px;
}