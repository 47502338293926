.space-card-section {
  border-radius: 32px;
  background-color: $white;
  margin-top: 24px;
  padding-bottom: 33px;
  padding-top: 99px;

  .space-card-wrap {
    width: 100%;
    margin: 0 auto;

    .btn-wrap {
      padding-bottom: 15px;
      @include flex-row;
      justify-content: space-between;

      .btn-round {
        background-color: $white;
        box-shadow: 0px 1px 3px #00000029;
        border-radius: 100px;
        height: 45px;
        color: $black;
        @include font-size(15);
        text-transform: capitalize !important;
        width: 32%;
        vertical-align: middle;

        .img {
          margin-right: 3px;
          width: 18px;
        }

        .text {
          vertical-align: middle;
        }

        &.active {
          background-color: $error;
          color: $white;
          font-weight: $bold;
        }

        &.inactive {
          .img {
            filter: brightness(0) invert(0);
          }
        }

        @include tablet-portrait {
          margin: 0 4px;
          width: 108px;
          @include font-size(15);
        }

        @include desktop {
          margin: 0 4px;
          width: 108px;
          @include font-size(15);
        }
      }

      @include tablet-portrait {
        padding-bottom: 30px;
        justify-content: center;
      }

      @include desktop {
        padding-bottom: 30px;
        justify-content: center;
      }
    }

    .flex-wrap {
      @include flex-row;
      justify-content: space-between;

      .space-card {
        cursor: pointer;
        width: 49%;
        margin-bottom: 15px;
        z-index: 1;
        box-shadow: 0px 2px 6px #00000029;
        border-radius: 10px;

        &.space-card:nth-child(even) {
          @include mobile {
            margin-left: 2%;
          }
        }

        @include tablet-portrait {
          width: 24%;
          margin: 0 0.5%;
          margin-bottom: 15px;
        }

        @include tablet-landscape {
          width: 24% !important;
        }

        @include desktop {
          width: 24%;
          margin: 0 0.5%;
          margin-bottom: 15px;
        }

        .md-media {
          box-shadow: inset 0px 0px 15px $black-op-50;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        .img {
          @include background-property;
          background-size: cover;
          background-position: 50% 50%;
          border-radius: 10px 10px 0 0;
          height: auto;
          z-index: -1;

          @include desktop {
            height: auto;
          }
        }

        &.coming-soon-card {
          cursor: not-allowed;

          .md-card-text {
            p {
              color: $black-op-50 !important;
            }
          }
        }
      }

      .md-card-text {
        @include font-size(15);
        color: $black !important;
        border-radius: 10px;
        padding: 4px 2px;
        height: 28px;
        font-weight: $semi-bold;

        p {
          color: $black !important;
          @include font-size(15);
          font-weight: 600;
        }

        @include desktop {
          @include font-size(16);
          height: 45px;
          padding: 12px 16px;
        }
      }

      @include tablet-portrait {
        justify-content: center;
      }

      @include tablet-landscape {
        justify-content: center;
      }

      @include desktop {
        justify-content: center;
      }

      @include mobile {
        justify-content: center;
      }
    }

    .btn-see-more {
      display: block;
      height: 40px;
      margin: 15px auto;
      margin-bottom: 0;
      width: 125px;
      background-color: inherit;
      font-weight: 600;

      .arrow-right {
        width: 16px;
        height: 16px;
        margin-left: 6px;
      }

      @include desktop {
        min-width: 114px;
      }
    }

    @include mobile {
      border-top: 0.5px solid #e2e2e2;
      padding-top: 14px;
    }

    @include tablet-portrait {
      border-top: 0.5px solid #e2e2e2;
      padding-top: 20px;
    }
  }

  @include desktop {
    margin-top: 61px;
    border-radius: 0;
    box-shadow: none;
    padding-top: 0;
    padding-bottom: 45px;
  }

  @include tablet-portrait {
    position: relative;
    z-index: 1;
  }

  @include mobile {
    position: relative;
    z-index: 1;
    padding-top: 105px;
  }
}
